'use strict';

var cov_fylj3ej2p = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleItemPriceTypeDetailsView/sampleItemPriceTypeDetailsView.js',
        hash = '997c71e7edc562cf590c9e4bf61ab4d8f0cd877f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleItemPriceTypeDetailsView/sampleItemPriceTypeDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 29
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 9
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 34
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 9
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 52
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 70
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 0
                },
                end: {
                    line: 35,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 36
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 20
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 13
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 47
                    },
                    end: {
                        line: 21,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 70
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 21
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }, {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }],
                line: 9
            },
            '1': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 9,
                        column: 111
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 41
                    }
                }, {
                    start: {
                        line: 9,
                        column: 45
                    },
                    end: {
                        line: 9,
                        column: 76
                    }
                }, {
                    start: {
                        line: 9,
                        column: 81
                    },
                    end: {
                        line: 9,
                        column: 111
                    }
                }],
                line: 9
            },
            '2': {
                loc: {
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                }, {
                    start: {
                        line: 15,
                        column: 8
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                }],
                line: 15
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleItemPriceTypeViewController = function () {
    function SampleItemPriceTypeViewController($state, $rootScope) {
        _classCallCheck(this, SampleItemPriceTypeViewController);

        cov_fylj3ej2p.f[0]++;
        cov_fylj3ej2p.s[0]++;

        this.$state = $state;
        cov_fylj3ej2p.s[1]++;
        this.$rootScope = $rootScope;
        cov_fylj3ej2p.s[2]++;
        this.disabled = true; // default is disable
    }

    _createClass(SampleItemPriceTypeViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_fylj3ej2p.f[1]++;
            cov_fylj3ej2p.s[3]++;

            if ((cov_fylj3ej2p.b[1][0]++, !this.sampleItemPriceType.id) && (cov_fylj3ej2p.b[1][1]++, this.$rootScope.me.isSupervisor) || (cov_fylj3ej2p.b[1][2]++, this.$rootScope.me.isSuperuser)) {
                cov_fylj3ej2p.b[0][0]++;
                cov_fylj3ej2p.s[4]++;

                this.disabled = false;
            } else {
                cov_fylj3ej2p.b[0][1]++;
            }
        }
    }, {
        key: 'autosave',
        value: function autosave(field) {
            cov_fylj3ej2p.f[2]++;
            cov_fylj3ej2p.s[5]++;

            if (this.sampleItemPriceType.id) {
                cov_fylj3ej2p.b[2][0]++;
                cov_fylj3ej2p.s[6]++;

                this.sampleItemPriceType.$save([field]);
            } else {
                cov_fylj3ej2p.b[2][1]++;
            }
        }
    }, {
        key: 'create',
        value: function create() {
            var _this = this;

            cov_fylj3ej2p.f[3]++;
            cov_fylj3ej2p.s[7]++;

            this.sampleItemPriceType.$save().$then(function (sampleItemPriceType) {
                cov_fylj3ej2p.f[4]++;
                cov_fylj3ej2p.s[8]++;

                _this.$state.go('^.details', { id: sampleItemPriceType.id });
            });
        }
    }]);

    return SampleItemPriceTypeViewController;
}();

cov_fylj3ej2p.s[9]++;


angular.module('app').component('sampleItemPriceTypeDetailsView', {
    templateUrl: 'app/components/sampleItemPriceTypeDetailsView/sampleItemPriceTypeDetailsView.html',
    controller: SampleItemPriceTypeViewController,
    bindings: {
        sampleItemPriceType: '<'
    }
});