'use strict';

var cov_7b38ex1vx = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/sampleStateTypes.filter.js',
        hash = '81e29cd143d58bdd3093090ab9f0ab26c21b0388',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/sampleStateTypes.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 15,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 29
                },
                end: {
                    line: 11,
                    column: 90
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 72
                },
                end: {
                    line: 11,
                    column: 89
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 36
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 15,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'sampleStateTypes',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 42
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 11,
                        column: 64
                    },
                    end: {
                        line: 11,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 72
                    },
                    end: {
                        line: 11,
                        column: 89
                    }
                },
                line: 11
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_7b38ex1vx.s[0]++;
(function () {
    'use strict';

    cov_7b38ex1vx.f[0]++;
    cov_7b38ex1vx.s[1]++;
    angular.module('app').filter('sampleStateTypes', sampleStateTypes);

    /* @ngInject */
    function sampleStateTypes($rootScope) {
        cov_7b38ex1vx.f[1]++;
        cov_7b38ex1vx.s[2]++;

        return function (value) {
            cov_7b38ex1vx.f[2]++;

            var filtered = (cov_7b38ex1vx.s[3]++, $rootScope.sampleStateTypes.filter(function (item) {
                cov_7b38ex1vx.f[3]++;
                cov_7b38ex1vx.s[4]++;
                return item.id === value;
            }));
            cov_7b38ex1vx.s[5]++;
            return filtered[0].name;
        };
    }
})();