'use strict';

var cov_1poawcj75g = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/users/userDetails/userDetails.js',
        hash = '70487e4e8c6fbf53617960b0d8cf8bb0ac23a2ff',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/users/userDetails/userDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 69
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 32
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 11
                }
            },
            '6': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 13
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 12,
                    column: 44
                }
            },
            '8': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 95
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 65
                }
            },
            '10': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 15
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 72
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 9
                }
            },
            '14': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 109
                }
            },
            '15': {
                start: {
                    line: 34,
                    column: 23
                },
                end: {
                    line: 36,
                    column: 14
                }
            },
            '16': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 68
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 13
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 16
                },
                end: {
                    line: 39,
                    column: 61
                }
            },
            '19': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 9
                }
            },
            '20': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 19
                }
            },
            '21': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 11
                }
            },
            '22': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 49,
                    column: 104
                }
            },
            '23': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 11
                }
            },
            '24': {
                start: {
                    line: 55,
                    column: 12
                },
                end: {
                    line: 55,
                    column: 89
                }
            },
            '25': {
                start: {
                    line: 56,
                    column: 12
                },
                end: {
                    line: 56,
                    column: 67
                }
            },
            '26': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 35
                }
            },
            '27': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 11
                }
            },
            '28': {
                start: {
                    line: 63,
                    column: 12
                },
                end: {
                    line: 63,
                    column: 26
                }
            },
            '29': {
                start: {
                    line: 66,
                    column: 19
                },
                end: {
                    line: 66,
                    column: 75
                }
            },
            '30': {
                start: {
                    line: 67,
                    column: 8
                },
                end: {
                    line: 67,
                    column: 100
                }
            },
            '31': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 34
                }
            },
            '32': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 11
                }
            },
            '33': {
                start: {
                    line: 73,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 26
                }
            },
            '34': {
                start: {
                    line: 75,
                    column: 19
                },
                end: {
                    line: 75,
                    column: 75
                }
            },
            '35': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 97
                }
            },
            '36': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 11
                }
            },
            '37': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 81,
                    column: 70
                }
            },
            '38': {
                start: {
                    line: 86,
                    column: 0
                },
                end: {
                    line: 94,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 83
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 43
                    },
                    end: {
                        line: 10,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 49
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 14
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 62
                    },
                    end: {
                        line: 21,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 74
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 23,
                        column: 38
                    },
                    end: {
                        line: 23,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 48
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                line: 23
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 42
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 29
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 34,
                        column: 62
                    },
                    end: {
                        line: 34,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 72
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                line: 34
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 22
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 44
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 48,
                        column: 39
                    },
                    end: {
                        line: 48,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 45
                    },
                    end: {
                        line: 50,
                        column: 9
                    }
                },
                line: 48
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 17
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                line: 53
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 54,
                        column: 64
                    },
                    end: {
                        line: 54,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 70
                    },
                    end: {
                        line: 57,
                        column: 9
                    }
                },
                line: 54
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 60,
                        column: 4
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 21
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                line: 60
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 62,
                        column: 44
                    },
                    end: {
                        line: 62,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 52
                    },
                    end: {
                        line: 64,
                        column: 9
                    }
                },
                line: 62
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 70,
                        column: 4
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 19
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                line: 70
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 72,
                        column: 44
                    },
                    end: {
                        line: 72,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 52
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                },
                line: 72
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 79,
                        column: 4
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 17
                    },
                    end: {
                        line: 83,
                        column: 5
                    }
                },
                line: 79
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 80,
                        column: 32
                    },
                    end: {
                        line: 80,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 38
                    },
                    end: {
                        line: 82,
                        column: 9
                    }
                },
                line: 80
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                }, {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 13,
                        column: 13
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 11,
                        column: 16
                    },
                    end: {
                        line: 11,
                        column: 66
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 11,
                        column: 16
                    },
                    end: {
                        line: 11,
                        column: 50
                    }
                }, {
                    start: {
                        line: 11,
                        column: 54
                    },
                    end: {
                        line: 11,
                        column: 66
                    }
                }],
                line: 11
            },
            '2': {
                loc: {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }, {
                    start: {
                        line: 30,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }],
                line: 30
            },
            '3': {
                loc: {
                    start: {
                        line: 38,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 38,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                }, {
                    start: {
                        line: 38,
                        column: 12
                    },
                    end: {
                        line: 40,
                        column: 13
                    }
                }],
                line: 38
            },
            '4': {
                loc: {
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }, {
                    start: {
                        line: 45,
                        column: 8
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                }],
                line: 45
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UserDetailsController = function () {
    function UserDetailsController($mdToast, AuthService, $state, $scope, NotificationTemplatesTable) {
        var _this = this;

        _classCallCheck(this, UserDetailsController);

        cov_1poawcj75g.f[0]++;
        cov_1poawcj75g.s[0]++;

        this.$mdToast = $mdToast;
        cov_1poawcj75g.s[1]++;
        this.AuthService = AuthService;
        cov_1poawcj75g.s[2]++;
        this.$state = $state;
        cov_1poawcj75g.s[3]++;
        this.NotificationTemplatesTable = NotificationTemplatesTable;

        cov_1poawcj75g.s[4]++;
        this.notifications = {};

        cov_1poawcj75g.s[5]++;
        $scope.$watch('$ctrl.user.avatar', function () {
            cov_1poawcj75g.f[1]++;
            cov_1poawcj75g.s[6]++;

            if ((cov_1poawcj75g.b[1][0]++, angular.isString(_this.user.avatar)) && (cov_1poawcj75g.b[1][1]++, _this.user.id)) {
                cov_1poawcj75g.b[0][0]++;
                cov_1poawcj75g.s[7]++;

                _this.user.$save(['avatar']);
            } else {
                cov_1poawcj75g.b[0][1]++;
            }
        });
    }

    _createClass(UserDetailsController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this2 = this;

            cov_1poawcj75g.f[2]++;
            cov_1poawcj75g.s[8]++;

            /* eslint-disable camelcase */
            this.notificationTemplates = this.NotificationTemplatesTable.$search({ page_size: 999 });
            cov_1poawcj75g.s[9]++;
            this.user.$load('user_notifications').$load('processes');
            cov_1poawcj75g.s[10]++;
            this.user.$user_notifications.$on('after-fetch-many', function (response) {
                cov_1poawcj75g.f[3]++;
                cov_1poawcj75g.s[11]++;

                /* eslint-disable array-callback-return */
                response.data.results.map(function (result) {
                    cov_1poawcj75g.f[4]++;
                    cov_1poawcj75g.s[12]++;

                    _this2.notifications[result.notification_template] = true;
                });
            });
        }
    }, {
        key: 'updateUserNotifications',
        value: function updateUserNotifications(notification) {
            cov_1poawcj75g.f[5]++;
            cov_1poawcj75g.s[13]++;

            if (this.notifications[notification]) {
                cov_1poawcj75g.b[2][0]++;
                cov_1poawcj75g.s[14]++;

                /* eslint-disable camelcase */
                this.user.$user_notifications.$create({ user: this.user.id, notification_template: notification });
            } else {
                cov_1poawcj75g.b[2][1]++;

                var id = (cov_1poawcj75g.s[15]++, this.user.$user_notifications.$indexOf(function (record) {
                    cov_1poawcj75g.f[6]++;
                    cov_1poawcj75g.s[16]++;

                    return record.notificationTemplate === notification;
                }));

                cov_1poawcj75g.s[17]++;
                if (id !== -1) {
                    cov_1poawcj75g.b[3][0]++;
                    cov_1poawcj75g.s[18]++;

                    this.user.$user_notifications[id].$destroy();
                } else {
                    cov_1poawcj75g.b[3][1]++;
                }
            }
        }
    }, {
        key: 'changeInfo',
        value: function changeInfo(field) {
            var _this3 = this;

            cov_1poawcj75g.f[7]++;
            cov_1poawcj75g.s[19]++;

            if (!this.user.id) {
                cov_1poawcj75g.b[4][0]++;
                cov_1poawcj75g.s[20]++;

                return;
            } else {
                cov_1poawcj75g.b[4][1]++;
            }
            cov_1poawcj75g.s[21]++;
            this.user.$save([field]).$then(function () {
                cov_1poawcj75g.f[8]++;
                cov_1poawcj75g.s[22]++;

                _this3.$mdToast.show(_this3.$mdToast.simple().textContent('Dados de utilizador actualizados!'));
            });
        }
    }, {
        key: 'changePass',
        value: function changePass() {
            var _this4 = this;

            cov_1poawcj75g.f[9]++;
            cov_1poawcj75g.s[23]++;

            this.user.$save(['password', 'confirm_password']).$then(function () {
                cov_1poawcj75g.f[10]++;
                cov_1poawcj75g.s[24]++;

                _this4.$mdToast.show(_this4.$mdToast.simple().textContent('Password alterada!'));
                cov_1poawcj75g.s[25]++;
                _this4.user.password = _this4.user.confirm_password = null;
            });
        }
    }, {
        key: 'deactivateUser',
        value: function deactivateUser() {
            cov_1poawcj75g.f[11]++;
            cov_1poawcj75g.s[26]++;

            this.user.isActive = false;
            cov_1poawcj75g.s[27]++;
            this.user.$save(['isActive']).$then(function (user) {
                cov_1poawcj75g.f[12]++;
                cov_1poawcj75g.s[28]++;

                user.$fetch();
            });
            /* eslint-disable angular/document-service */
            var el = (cov_1poawcj75g.s[29]++, angular.element(document.getElementById('user-details')));
            cov_1poawcj75g.s[30]++;
            this.$mdToast.show(this.$mdToast.simple().parent(el).textContent('Utilizador desativado.'));
        }
    }, {
        key: 'activateUser',
        value: function activateUser() {
            cov_1poawcj75g.f[13]++;
            cov_1poawcj75g.s[31]++;

            this.user.isActive = true;
            cov_1poawcj75g.s[32]++;
            this.user.$save(['isActive']).$then(function (user) {
                cov_1poawcj75g.f[14]++;
                cov_1poawcj75g.s[33]++;

                user.$fetch();
            });
            var el = (cov_1poawcj75g.s[34]++, angular.element(document.getElementById('user-details')));
            cov_1poawcj75g.s[35]++;
            this.$mdToast.show(this.$mdToast.simple().parent(el).textContent('Utilizador ativado.'));
        }
    }, {
        key: 'createUser',
        value: function createUser() {
            var _this5 = this;

            cov_1poawcj75g.f[15]++;
            cov_1poawcj75g.s[36]++;

            this.user.$save().$then(function () {
                cov_1poawcj75g.f[16]++;
                cov_1poawcj75g.s[37]++;

                _this5.$mdToast.showSimple('Utilizador criado com sucesso');
            });
        }
    }]);

    return UserDetailsController;
}();

cov_1poawcj75g.s[38]++;


angular.module('app').component('userDetails', {
    templateUrl: 'app/components/users/userDetails/userDetails.html',
    controller: UserDetailsController,
    bindings: {
        user: '<'
    }
});