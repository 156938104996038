'use strict';

var cov_tnxdgl57c = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/priceCheck/currentCheck.js',
        hash = '511819f1028e6ae2ccae00af2098275c99f4fa28',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/priceCheck/currentCheck.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 71
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 93
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 0
                },
                end: {
                    line: 20,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 11
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 11
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 4,
                        column: 15
                    },
                    end: {
                        line: 4,
                        column: 70
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 4,
                        column: 47
                    },
                    end: {
                        line: 4,
                        column: 53
                    }
                }, {
                    start: {
                        line: 4,
                        column: 56
                    },
                    end: {
                        line: 4,
                        column: 70
                    }
                }],
                line: 4
            },
            '1': {
                loc: {
                    start: {
                        line: 8,
                        column: 15
                    },
                    end: {
                        line: 8,
                        column: 92
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 8,
                        column: 47
                    },
                    end: {
                        line: 8,
                        column: 67
                    }
                }, {
                    start: {
                        line: 8,
                        column: 70
                    },
                    end: {
                        line: 8,
                        column: 92
                    }
                }],
                line: 8
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CurrentCheckController = function () {
    function CurrentCheckController() {
        _classCallCheck(this, CurrentCheckController);
    }

    _createClass(CurrentCheckController, [{
        key: 'icon',
        value: function icon() {
            cov_tnxdgl57c.f[0]++;
            cov_tnxdgl57c.s[0]++;

            return this.check.timming === 'SYNC' ? (cov_tnxdgl57c.b[0][0]++, 'sync') : (cov_tnxdgl57c.b[0][1]++, 'sync_problem');
        }
    }, {
        key: 'name',
        value: function name() {
            cov_tnxdgl57c.f[1]++;
            cov_tnxdgl57c.s[1]++;

            return this.check.timming === 'SYNC' ? (cov_tnxdgl57c.b[1][0]++, 'Validação Síncrona') : (cov_tnxdgl57c.b[1][1]++, 'Validação Assíncrona');
        }
    }]);

    return CurrentCheckController;
}();

cov_tnxdgl57c.s[2]++;


angular.module('app').component('currentCheck', {
    templateUrl: 'app/components/priceCheck/currentCheck.html',
    controller: CurrentCheckController,
    bindings: {
        check: '<'
    }
});