'use strict';

var cov_17qm0xjxgy = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboard.controller.js',
        hash = '3ce6517e8a91fba212637d70b9a337d4afe7dc24',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboard.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 19,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 19
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 15
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 39
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 19,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 44
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 12,
                        column: 17
                    },
                    end: {
                        line: 12,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 28
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 14,
                        column: 32
                    },
                    end: {
                        line: 14,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 44
                    },
                    end: {
                        line: 16,
                        column: 13
                    }
                },
                line: 14
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_17qm0xjxgy.s[0]++;
(function () {
    'use strict';

    cov_17qm0xjxgy.f[0]++;
    cov_17qm0xjxgy.s[1]++;
    angular.module('app').controller('DashboardController', Controller);

    /* @ngInject */
    function Controller($rootScope, $state) {
        cov_17qm0xjxgy.f[1]++;
        cov_17qm0xjxgy.s[2]++;

        activate();

        function activate() {
            cov_17qm0xjxgy.f[2]++;
            cov_17qm0xjxgy.s[3]++;

            /* eslint-disable prefer-arrow-callback */
            $rootScope.me.$then(function () {
                cov_17qm0xjxgy.f[3]++;
                cov_17qm0xjxgy.s[4]++;

                $state.go('usersList');
            });
        }
    }
})();