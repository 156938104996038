'use strict';

var cov_2ek6ybamo0 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/bottomStickyNotifications/bottomStickyNotifications.js',
        hash = '5e5c896aadea6318896280571edcb1a707082c66',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/bottomStickyNotifications/bottomStickyNotifications.js',
        statementMap: {
            '0': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 11
                }
            },
            '1': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 26
                }
            },
            '2': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 40
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 40
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 53
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 35
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 81
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 52
                },
                end: {
                    line: 22,
                    column: 79
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 0
                },
                end: {
                    line: 32,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 59
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 12,
                        column: 18
                    },
                    end: {
                        line: 12,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 24
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                line: 12
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 19,
                        column: 4
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 29
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 19
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 35
                    },
                    end: {
                        line: 21,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 41
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 22,
                        column: 46
                    },
                    end: {
                        line: 22,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 52
                    },
                    end: {
                        line: 22,
                        column: 79
                    }
                },
                line: 22
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BottomStickyNotificationsController = function () {
    function BottomStickyNotificationsController(NotificationsTable, $interval, $rootScope) {
        var _this = this;

        _classCallCheck(this, BottomStickyNotificationsController);

        cov_2ek6ybamo0.f[0]++;
        cov_2ek6ybamo0.s[0]++;

        /* eslint-disable camelcase */
        this.notifications = $rootScope.bottomStickyNotifications = NotificationsTable.$search({
            kind: 'STICKY_BOTTOM_LEFT',
            page_size: 99999,
            ordering: '-created',
            is_read: false
        });

        cov_2ek6ybamo0.s[1]++;
        $interval(function () {
            cov_2ek6ybamo0.f[1]++;
            cov_2ek6ybamo0.s[2]++;

            _this.notifications.$clear();
            cov_2ek6ybamo0.s[3]++;
            _this.notifications.$fetch();
        }, 3 * 60 * 1000);
        cov_2ek6ybamo0.s[4]++;
        this.markAsRead = this.markAsRead.bind(this);
    }

    _createClass(BottomStickyNotificationsController, [{
        key: 'markAsRead',
        value: function markAsRead(notification) {
            var _this2 = this;

            cov_2ek6ybamo0.f[2]++;
            cov_2ek6ybamo0.s[5]++;

            notification.isRead = true;
            cov_2ek6ybamo0.s[6]++;
            notification.$save().$then(function () {
                cov_2ek6ybamo0.f[3]++;
                cov_2ek6ybamo0.s[7]++;

                _this2.notifications.$clear().$then(function () {
                    cov_2ek6ybamo0.f[4]++;
                    cov_2ek6ybamo0.s[8]++;
                    return _this2.notifications.$fetch();
                });
            });
        }
    }]);

    return BottomStickyNotificationsController;
}();

cov_2ek6ybamo0.s[9]++;


angular.module('app').component('bottomStickyNotifications', {
    templateUrl: 'app/components/bottomStickyNotifications/bottomStickyNotifications.html',
    controller: BottomStickyNotificationsController
});