"use strict";

var cov_2p0areg3mk = function () {
    var path = "/home/lobo/repos/mypedrosa-app/src/app/components/samplesListView/samplesListView.js",
        hash = "6c4ddac22f0e3bcb49388583c0fb55ca2c7a6960",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/mypedrosa-app/src/app/components/samplesListView/samplesListView.js",
        statementMap: {
            "0": {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 45
                }
            },
            "1": {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            "2": {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 10
                }
            },
            "3": {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 10
                }
            },
            "4": {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 10
                }
            },
            "5": {
                start: {
                    line: 77,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 10
                }
            },
            "6": {
                start: {
                    line: 97,
                    column: 8
                },
                end: {
                    line: 97,
                    column: 58
                }
            },
            "7": {
                start: {
                    line: 101,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 35
                }
            },
            "8": {
                start: {
                    line: 108,
                    column: 8
                },
                end: {
                    line: 108,
                    column: 33
                }
            },
            "9": {
                start: {
                    line: 109,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 29
                }
            },
            "10": {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 110,
                    column: 35
                }
            },
            "11": {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 41
                }
            },
            "12": {
                start: {
                    line: 112,
                    column: 8
                },
                end: {
                    line: 112,
                    column: 41
                }
            },
            "13": {
                start: {
                    line: 113,
                    column: 8
                },
                end: {
                    line: 113,
                    column: 51
                }
            },
            "14": {
                start: {
                    line: 114,
                    column: 8
                },
                end: {
                    line: 114,
                    column: 65
                }
            },
            "15": {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 115,
                    column: 35
                }
            },
            "16": {
                start: {
                    line: 116,
                    column: 8
                },
                end: {
                    line: 116,
                    column: 26
                }
            },
            "17": {
                start: {
                    line: 117,
                    column: 8
                },
                end: {
                    line: 117,
                    column: 31
                }
            },
            "18": {
                start: {
                    line: 118,
                    column: 8
                },
                end: {
                    line: 118,
                    column: 39
                }
            },
            "19": {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 119,
                    column: 45
                }
            },
            "20": {
                start: {
                    line: 120,
                    column: 8
                },
                end: {
                    line: 120,
                    column: 37
                }
            },
            "21": {
                start: {
                    line: 121,
                    column: 8
                },
                end: {
                    line: 121,
                    column: 45
                }
            },
            "22": {
                start: {
                    line: 122,
                    column: 8
                },
                end: {
                    line: 122,
                    column: 43
                }
            },
            "23": {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 33
                }
            },
            "24": {
                start: {
                    line: 125,
                    column: 8
                },
                end: {
                    line: 125,
                    column: 79
                }
            },
            "25": {
                start: {
                    line: 126,
                    column: 8
                },
                end: {
                    line: 126,
                    column: 59
                }
            },
            "26": {
                start: {
                    line: 128,
                    column: 8
                },
                end: {
                    line: 128,
                    column: 67
                }
            },
            "27": {
                start: {
                    line: 128,
                    column: 24
                },
                end: {
                    line: 128,
                    column: 50
                }
            },
            "28": {
                start: {
                    line: 132,
                    column: 8
                },
                end: {
                    line: 132,
                    column: 41
                }
            },
            "29": {
                start: {
                    line: 133,
                    column: 8
                },
                end: {
                    line: 133,
                    column: 34
                }
            },
            "30": {
                start: {
                    line: 136,
                    column: 8
                },
                end: {
                    line: 136,
                    column: 50
                }
            },
            "31": {
                start: {
                    line: 137,
                    column: 8
                },
                end: {
                    line: 137,
                    column: 51
                }
            },
            "32": {
                start: {
                    line: 138,
                    column: 8
                },
                end: {
                    line: 138,
                    column: 68
                }
            },
            "33": {
                start: {
                    line: 139,
                    column: 8
                },
                end: {
                    line: 139,
                    column: 67
                }
            },
            "34": {
                start: {
                    line: 140,
                    column: 8
                },
                end: {
                    line: 140,
                    column: 96
                }
            },
            "35": {
                start: {
                    line: 141,
                    column: 8
                },
                end: {
                    line: 141,
                    column: 78
                }
            },
            "36": {
                start: {
                    line: 142,
                    column: 8
                },
                end: {
                    line: 142,
                    column: 61
                }
            },
            "37": {
                start: {
                    line: 143,
                    column: 8
                },
                end: {
                    line: 143,
                    column: 62
                }
            },
            "38": {
                start: {
                    line: 144,
                    column: 8
                },
                end: {
                    line: 144,
                    column: 72
                }
            },
            "39": {
                start: {
                    line: 145,
                    column: 8
                },
                end: {
                    line: 145,
                    column: 69
                }
            },
            "40": {
                start: {
                    line: 146,
                    column: 8
                },
                end: {
                    line: 146,
                    column: 70
                }
            },
            "41": {
                start: {
                    line: 147,
                    column: 8
                },
                end: {
                    line: 147,
                    column: 69
                }
            },
            "42": {
                start: {
                    line: 148,
                    column: 8
                },
                end: {
                    line: 148,
                    column: 70
                }
            },
            "43": {
                start: {
                    line: 149,
                    column: 8
                },
                end: {
                    line: 149,
                    column: 56
                }
            },
            "44": {
                start: {
                    line: 150,
                    column: 8
                },
                end: {
                    line: 150,
                    column: 53
                }
            },
            "45": {
                start: {
                    line: 151,
                    column: 8
                },
                end: {
                    line: 151,
                    column: 62
                }
            },
            "46": {
                start: {
                    line: 152,
                    column: 8
                },
                end: {
                    line: 152,
                    column: 116
                }
            },
            "47": {
                start: {
                    line: 153,
                    column: 8
                },
                end: {
                    line: 153,
                    column: 119
                }
            },
            "48": {
                start: {
                    line: 154,
                    column: 8
                },
                end: {
                    line: 154,
                    column: 116
                }
            },
            "49": {
                start: {
                    line: 155,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 102
                }
            },
            "50": {
                start: {
                    line: 156,
                    column: 8
                },
                end: {
                    line: 156,
                    column: 60
                }
            },
            "51": {
                start: {
                    line: 157,
                    column: 8
                },
                end: {
                    line: 157,
                    column: 58
                }
            },
            "52": {
                start: {
                    line: 158,
                    column: 8
                },
                end: {
                    line: 158,
                    column: 78
                }
            },
            "53": {
                start: {
                    line: 159,
                    column: 8
                },
                end: {
                    line: 159,
                    column: 78
                }
            },
            "54": {
                start: {
                    line: 163,
                    column: 8
                },
                end: {
                    line: 169,
                    column: 9
                }
            },
            "55": {
                start: {
                    line: 164,
                    column: 12
                },
                end: {
                    line: 168,
                    column: 13
                }
            },
            "56": {
                start: {
                    line: 165,
                    column: 16
                },
                end: {
                    line: 165,
                    column: 66
                }
            },
            "57": {
                start: {
                    line: 167,
                    column: 16
                },
                end: {
                    line: 167,
                    column: 70
                }
            },
            "58": {
                start: {
                    line: 171,
                    column: 8
                },
                end: {
                    line: 171,
                    column: 67
                }
            },
            "59": {
                start: {
                    line: 172,
                    column: 8
                },
                end: {
                    line: 172,
                    column: 31
                }
            },
            "60": {
                start: {
                    line: 176,
                    column: 8
                },
                end: {
                    line: 178,
                    column: 9
                }
            },
            "61": {
                start: {
                    line: 176,
                    column: 75
                },
                end: {
                    line: 176,
                    column: 94
                }
            },
            "62": {
                start: {
                    line: 177,
                    column: 12
                },
                end: {
                    line: 177,
                    column: 19
                }
            },
            "63": {
                start: {
                    line: 179,
                    column: 8
                },
                end: {
                    line: 179,
                    column: 31
                }
            },
            "64": {
                start: {
                    line: 183,
                    column: 8
                },
                end: {
                    line: 188,
                    column: 9
                }
            },
            "65": {
                start: {
                    line: 185,
                    column: 12
                },
                end: {
                    line: 185,
                    column: 71
                }
            },
            "66": {
                start: {
                    line: 185,
                    column: 43
                },
                end: {
                    line: 185,
                    column: 69
                }
            },
            "67": {
                start: {
                    line: 187,
                    column: 12
                },
                end: {
                    line: 187,
                    column: 70
                }
            },
            "68": {
                start: {
                    line: 187,
                    column: 43
                },
                end: {
                    line: 187,
                    column: 68
                }
            },
            "69": {
                start: {
                    line: 192,
                    column: 8
                },
                end: {
                    line: 194,
                    column: 9
                }
            },
            "70": {
                start: {
                    line: 193,
                    column: 12
                },
                end: {
                    line: 193,
                    column: 64
                }
            },
            "71": {
                start: {
                    line: 196,
                    column: 8
                },
                end: {
                    line: 196,
                    column: 48
                }
            },
            "72": {
                start: {
                    line: 198,
                    column: 8
                },
                end: {
                    line: 198,
                    column: 29
                }
            },
            "73": {
                start: {
                    line: 202,
                    column: 8
                },
                end: {
                    line: 204,
                    column: 9
                }
            },
            "74": {
                start: {
                    line: 203,
                    column: 12
                },
                end: {
                    line: 203,
                    column: 36
                }
            },
            "75": {
                start: {
                    line: 206,
                    column: 8
                },
                end: {
                    line: 211,
                    column: 9
                }
            },
            "76": {
                start: {
                    line: 207,
                    column: 12
                },
                end: {
                    line: 207,
                    column: 40
                }
            },
            "77": {
                start: {
                    line: 208,
                    column: 12
                },
                end: {
                    line: 208,
                    column: 48
                }
            },
            "78": {
                start: {
                    line: 210,
                    column: 12
                },
                end: {
                    line: 210,
                    column: 41
                }
            },
            "79": {
                start: {
                    line: 213,
                    column: 8
                },
                end: {
                    line: 213,
                    column: 31
                }
            },
            "80": {
                start: {
                    line: 214,
                    column: 8
                },
                end: {
                    line: 214,
                    column: 41
                }
            },
            "81": {
                start: {
                    line: 215,
                    column: 8
                },
                end: {
                    line: 215,
                    column: 45
                }
            },
            "82": {
                start: {
                    line: 216,
                    column: 8
                },
                end: {
                    line: 216,
                    column: 31
                }
            },
            "83": {
                start: {
                    line: 222,
                    column: 8
                },
                end: {
                    line: 222,
                    column: 40
                }
            },
            "84": {
                start: {
                    line: 224,
                    column: 8
                },
                end: {
                    line: 228,
                    column: 9
                }
            },
            "85": {
                start: {
                    line: 225,
                    column: 12
                },
                end: {
                    line: 225,
                    column: 44
                }
            },
            "86": {
                start: {
                    line: 227,
                    column: 12
                },
                end: {
                    line: 227,
                    column: 45
                }
            },
            "87": {
                start: {
                    line: 230,
                    column: 8
                },
                end: {
                    line: 230,
                    column: 77
                }
            },
            "88": {
                start: {
                    line: 232,
                    column: 8
                },
                end: {
                    line: 234,
                    column: 9
                }
            },
            "89": {
                start: {
                    line: 233,
                    column: 12
                },
                end: {
                    line: 233,
                    column: 36
                }
            },
            "90": {
                start: {
                    line: 236,
                    column: 8
                },
                end: {
                    line: 236,
                    column: 44
                }
            },
            "91": {
                start: {
                    line: 240,
                    column: 8
                },
                end: {
                    line: 240,
                    column: 51
                }
            },
            "92": {
                start: {
                    line: 240,
                    column: 42
                },
                end: {
                    line: 240,
                    column: 49
                }
            },
            "93": {
                start: {
                    line: 241,
                    column: 8
                },
                end: {
                    line: 241,
                    column: 63
                }
            },
            "94": {
                start: {
                    line: 246,
                    column: 21
                },
                end: {
                    line: 246,
                    column: 108
                }
            },
            "95": {
                start: {
                    line: 247,
                    column: 8
                },
                end: {
                    line: 247,
                    column: 20
                }
            },
            "96": {
                start: {
                    line: 251,
                    column: 28
                },
                end: {
                    line: 251,
                    column: 75
                }
            },
            "97": {
                start: {
                    line: 251,
                    column: 65
                },
                end: {
                    line: 251,
                    column: 74
                }
            },
            "98": {
                start: {
                    line: 253,
                    column: 8
                },
                end: {
                    line: 260,
                    column: 11
                }
            },
            "99": {
                start: {
                    line: 259,
                    column: 12
                },
                end: {
                    line: 259,
                    column: 88
                }
            },
            "100": {
                start: {
                    line: 264,
                    column: 23
                },
                end: {
                    line: 264,
                    column: 25
                }
            },
            "101": {
                start: {
                    line: 265,
                    column: 21
                },
                end: {
                    line: 265,
                    column: 50
                }
            },
            "102": {
                start: {
                    line: 266,
                    column: 23
                },
                end: {
                    line: 266,
                    column: 24
                }
            },
            "103": {
                start: {
                    line: 267,
                    column: 8
                },
                end: {
                    line: 284,
                    column: 11
                }
            },
            "104": {
                start: {
                    line: 268,
                    column: 12
                },
                end: {
                    line: 268,
                    column: 34
                }
            },
            "105": {
                start: {
                    line: 269,
                    column: 12
                },
                end: {
                    line: 283,
                    column: 14
                }
            },
            "106": {
                start: {
                    line: 271,
                    column: 20
                },
                end: {
                    line: 271,
                    column: 47
                }
            },
            "107": {
                start: {
                    line: 272,
                    column: 20
                },
                end: {
                    line: 272,
                    column: 31
                }
            },
            "108": {
                start: {
                    line: 273,
                    column: 20
                },
                end: {
                    line: 273,
                    column: 61
                }
            },
            "109": {
                start: {
                    line: 274,
                    column: 20
                },
                end: {
                    line: 276,
                    column: 21
                }
            },
            "110": {
                start: {
                    line: 275,
                    column: 24
                },
                end: {
                    line: 275,
                    column: 49
                }
            },
            "111": {
                start: {
                    line: 279,
                    column: 20
                },
                end: {
                    line: 279,
                    column: 134
                }
            },
            "112": {
                start: {
                    line: 280,
                    column: 20
                },
                end: {
                    line: 280,
                    column: 31
                }
            },
            "113": {
                start: {
                    line: 281,
                    column: 20
                },
                end: {
                    line: 281,
                    column: 61
                }
            },
            "114": {
                start: {
                    line: 288,
                    column: 8
                },
                end: {
                    line: 288,
                    column: 42
                }
            },
            "115": {
                start: {
                    line: 289,
                    column: 8
                },
                end: {
                    line: 289,
                    column: 44
                }
            },
            "116": {
                start: {
                    line: 290,
                    column: 8
                },
                end: {
                    line: 290,
                    column: 36
                }
            },
            "117": {
                start: {
                    line: 291,
                    column: 8
                },
                end: {
                    line: 291,
                    column: 40
                }
            },
            "118": {
                start: {
                    line: 292,
                    column: 8
                },
                end: {
                    line: 292,
                    column: 45
                }
            },
            "119": {
                start: {
                    line: 296,
                    column: 23
                },
                end: {
                    line: 296,
                    column: 25
                }
            },
            "120": {
                start: {
                    line: 297,
                    column: 21
                },
                end: {
                    line: 297,
                    column: 50
                }
            },
            "121": {
                start: {
                    line: 298,
                    column: 23
                },
                end: {
                    line: 298,
                    column: 24
                }
            },
            "122": {
                start: {
                    line: 299,
                    column: 8
                },
                end: {
                    line: 317,
                    column: 11
                }
            },
            "123": {
                start: {
                    line: 300,
                    column: 12
                },
                end: {
                    line: 316,
                    column: 14
                }
            },
            "124": {
                start: {
                    line: 304,
                    column: 20
                },
                end: {
                    line: 304,
                    column: 47
                }
            },
            "125": {
                start: {
                    line: 305,
                    column: 20
                },
                end: {
                    line: 305,
                    column: 38
                }
            },
            "126": {
                start: {
                    line: 306,
                    column: 20
                },
                end: {
                    line: 306,
                    column: 31
                }
            },
            "127": {
                start: {
                    line: 307,
                    column: 20
                },
                end: {
                    line: 307,
                    column: 61
                }
            },
            "128": {
                start: {
                    line: 308,
                    column: 20
                },
                end: {
                    line: 308,
                    column: 49
                }
            },
            "129": {
                start: {
                    line: 311,
                    column: 20
                },
                end: {
                    line: 311,
                    column: 136
                }
            },
            "130": {
                start: {
                    line: 312,
                    column: 20
                },
                end: {
                    line: 312,
                    column: 31
                }
            },
            "131": {
                start: {
                    line: 313,
                    column: 20
                },
                end: {
                    line: 313,
                    column: 61
                }
            },
            "132": {
                start: {
                    line: 314,
                    column: 20
                },
                end: {
                    line: 314,
                    column: 49
                }
            },
            "133": {
                start: {
                    line: 321,
                    column: 8
                },
                end: {
                    line: 326,
                    column: 9
                }
            },
            "134": {
                start: {
                    line: 322,
                    column: 28
                },
                end: {
                    line: 322,
                    column: 93
                }
            },
            "135": {
                start: {
                    line: 322,
                    column: 68
                },
                end: {
                    line: 322,
                    column: 92
                }
            },
            "136": {
                start: {
                    line: 323,
                    column: 12
                },
                end: {
                    line: 323,
                    column: 58
                }
            },
            "137": {
                start: {
                    line: 325,
                    column: 12
                },
                end: {
                    line: 325,
                    column: 45
                }
            },
            "138": {
                start: {
                    line: 327,
                    column: 8
                },
                end: {
                    line: 327,
                    column: 25
                }
            },
            "139": {
                start: {
                    line: 331,
                    column: 24
                },
                end: {
                    line: 335,
                    column: 33
                }
            },
            "140": {
                start: {
                    line: 337,
                    column: 8
                },
                end: {
                    line: 366,
                    column: 11
                }
            },
            "141": {
                start: {
                    line: 338,
                    column: 27
                },
                end: {
                    line: 338,
                    column: 29
                }
            },
            "142": {
                start: {
                    line: 339,
                    column: 25
                },
                end: {
                    line: 339,
                    column: 54
                }
            },
            "143": {
                start: {
                    line: 340,
                    column: 27
                },
                end: {
                    line: 340,
                    column: 28
                }
            },
            "144": {
                start: {
                    line: 341,
                    column: 12
                },
                end: {
                    line: 365,
                    column: 15
                }
            },
            "145": {
                start: {
                    line: 342,
                    column: 16
                },
                end: {
                    line: 364,
                    column: 28
                }
            },
            "146": {
                start: {
                    line: 346,
                    column: 20
                },
                end: {
                    line: 348,
                    column: 23
                }
            },
            "147": {
                start: {
                    line: 347,
                    column: 24
                },
                end: {
                    line: 347,
                    column: 52
                }
            },
            "148": {
                start: {
                    line: 349,
                    column: 20
                },
                end: {
                    line: 349,
                    column: 47
                }
            },
            "149": {
                start: {
                    line: 350,
                    column: 20
                },
                end: {
                    line: 350,
                    column: 31
                }
            },
            "150": {
                start: {
                    line: 351,
                    column: 20
                },
                end: {
                    line: 351,
                    column: 61
                }
            },
            "151": {
                start: {
                    line: 352,
                    column: 20
                },
                end: {
                    line: 354,
                    column: 21
                }
            },
            "152": {
                start: {
                    line: 353,
                    column: 24
                },
                end: {
                    line: 353,
                    column: 47
                }
            },
            "153": {
                start: {
                    line: 357,
                    column: 41
                },
                end: {
                    line: 357,
                    column: 115
                }
            },
            "154": {
                start: {
                    line: 358,
                    column: 29
                },
                end: {
                    line: 358,
                    column: 124
                }
            },
            "155": {
                start: {
                    line: 359,
                    column: 29
                },
                end: {
                    line: 359,
                    column: 40
                }
            },
            "156": {
                start: {
                    line: 360,
                    column: 29
                },
                end: {
                    line: 360,
                    column: 70
                }
            },
            "157": {
                start: {
                    line: 361,
                    column: 29
                },
                end: {
                    line: 363,
                    column: 30
                }
            },
            "158": {
                start: {
                    line: 362,
                    column: 33
                },
                end: {
                    line: 362,
                    column: 56
                }
            },
            "159": {
                start: {
                    line: 370,
                    column: 24
                },
                end: {
                    line: 370,
                    column: 133
                }
            },
            "160": {
                start: {
                    line: 370,
                    column: 64
                },
                end: {
                    line: 370,
                    column: 132
                }
            },
            "161": {
                start: {
                    line: 371,
                    column: 8
                },
                end: {
                    line: 371,
                    column: 30
                }
            },
            "162": {
                start: {
                    line: 375,
                    column: 24
                },
                end: {
                    line: 378,
                    column: 23
                }
            },
            "163": {
                start: {
                    line: 379,
                    column: 8
                },
                end: {
                    line: 379,
                    column: 37
                }
            },
            "164": {
                start: {
                    line: 383,
                    column: 24
                },
                end: {
                    line: 383,
                    column: 46
                }
            },
            "165": {
                start: {
                    line: 384,
                    column: 8
                },
                end: {
                    line: 394,
                    column: 9
                }
            },
            "166": {
                start: {
                    line: 385,
                    column: 28
                },
                end: {
                    line: 385,
                    column: 92
                }
            },
            "167": {
                start: {
                    line: 386,
                    column: 12
                },
                end: {
                    line: 386,
                    column: 45
                }
            },
            "168": {
                start: {
                    line: 388,
                    column: 12
                },
                end: {
                    line: 393,
                    column: 15
                }
            },
            "169": {
                start: {
                    line: 389,
                    column: 16
                },
                end: {
                    line: 389,
                    column: 78
                }
            },
            "170": {
                start: {
                    line: 390,
                    column: 16
                },
                end: {
                    line: 392,
                    column: 85
                }
            },
            "171": {
                start: {
                    line: 391,
                    column: 26
                },
                end: {
                    line: 392,
                    column: 83
                }
            },
            "172": {
                start: {
                    line: 398,
                    column: 24
                },
                end: {
                    line: 398,
                    column: 46
                }
            },
            "173": {
                start: {
                    line: 399,
                    column: 8
                },
                end: {
                    line: 406,
                    column: 9
                }
            },
            "174": {
                start: {
                    line: 400,
                    column: 28
                },
                end: {
                    line: 400,
                    column: 92
                }
            },
            "175": {
                start: {
                    line: 401,
                    column: 12
                },
                end: {
                    line: 401,
                    column: 45
                }
            },
            "176": {
                start: {
                    line: 403,
                    column: 12
                },
                end: {
                    line: 405,
                    column: 15
                }
            },
            "177": {
                start: {
                    line: 404,
                    column: 16
                },
                end: {
                    line: 404,
                    column: 74
                }
            },
            "178": {
                start: {
                    line: 410,
                    column: 32
                },
                end: {
                    line: 410,
                    column: 99
                }
            },
            "179": {
                start: {
                    line: 410,
                    column: 80
                },
                end: {
                    line: 410,
                    column: 98
                }
            },
            "180": {
                start: {
                    line: 411,
                    column: 8
                },
                end: {
                    line: 413,
                    column: 11
                }
            },
            "181": {
                start: {
                    line: 412,
                    column: 12
                },
                end: {
                    line: 412,
                    column: 121
                }
            },
            "182": {
                start: {
                    line: 417,
                    column: 8
                },
                end: {
                    line: 419,
                    column: 9
                }
            },
            "183": {
                start: {
                    line: 418,
                    column: 12
                },
                end: {
                    line: 418,
                    column: 19
                }
            },
            "184": {
                start: {
                    line: 420,
                    column: 22
                },
                end: {
                    line: 420,
                    column: 93
                }
            },
            "185": {
                start: {
                    line: 421,
                    column: 8
                },
                end: {
                    line: 428,
                    column: 10
                }
            },
            "186": {
                start: {
                    line: 430,
                    column: 8
                },
                end: {
                    line: 430,
                    column: 53
                }
            },
            "187": {
                start: {
                    line: 434,
                    column: 24
                },
                end: {
                    line: 438,
                    column: 33
                }
            },
            "188": {
                start: {
                    line: 440,
                    column: 8
                },
                end: {
                    line: 458,
                    column: 11
                }
            },
            "189": {
                start: {
                    line: 441,
                    column: 27
                },
                end: {
                    line: 441,
                    column: 29
                }
            },
            "190": {
                start: {
                    line: 442,
                    column: 25
                },
                end: {
                    line: 442,
                    column: 54
                }
            },
            "191": {
                start: {
                    line: 443,
                    column: 27
                },
                end: {
                    line: 443,
                    column: 28
                }
            },
            "192": {
                start: {
                    line: 444,
                    column: 12
                },
                end: {
                    line: 457,
                    column: 15
                }
            },
            "193": {
                start: {
                    line: 445,
                    column: 16
                },
                end: {
                    line: 456,
                    column: 18
                }
            },
            "194": {
                start: {
                    line: 447,
                    column: 24
                },
                end: {
                    line: 447,
                    column: 51
                }
            },
            "195": {
                start: {
                    line: 448,
                    column: 24
                },
                end: {
                    line: 448,
                    column: 35
                }
            },
            "196": {
                start: {
                    line: 449,
                    column: 24
                },
                end: {
                    line: 449,
                    column: 87
                }
            },
            "197": {
                start: {
                    line: 452,
                    column: 24
                },
                end: {
                    line: 452,
                    column: 138
                }
            },
            "198": {
                start: {
                    line: 453,
                    column: 24
                },
                end: {
                    line: 453,
                    column: 35
                }
            },
            "199": {
                start: {
                    line: 454,
                    column: 24
                },
                end: {
                    line: 454,
                    column: 87
                }
            },
            "200": {
                start: {
                    line: 462,
                    column: 8
                },
                end: {
                    line: 479,
                    column: 11
                }
            },
            "201": {
                start: {
                    line: 474,
                    column: 12
                },
                end: {
                    line: 474,
                    column: 35
                }
            },
            "202": {
                start: {
                    line: 475,
                    column: 12
                },
                end: {
                    line: 475,
                    column: 59
                }
            },
            "203": {
                start: {
                    line: 476,
                    column: 12
                },
                end: {
                    line: 476,
                    column: 52
                }
            },
            "204": {
                start: {
                    line: 483,
                    column: 8
                },
                end: {
                    line: 483,
                    column: 70
                }
            },
            "205": {
                start: {
                    line: 484,
                    column: 8
                },
                end: {
                    line: 484,
                    column: 33
                }
            },
            "206": {
                start: {
                    line: 488,
                    column: 26
                },
                end: {
                    line: 488,
                    column: 57
                }
            },
            "207": {
                start: {
                    line: 490,
                    column: 8
                },
                end: {
                    line: 492,
                    column: 9
                }
            },
            "208": {
                start: {
                    line: 491,
                    column: 12
                },
                end: {
                    line: 491,
                    column: 24
                }
            },
            "209": {
                start: {
                    line: 494,
                    column: 8
                },
                end: {
                    line: 500,
                    column: 9
                }
            },
            "210": {
                start: {
                    line: 495,
                    column: 12
                },
                end: {
                    line: 495,
                    column: 46
                }
            },
            "211": {
                start: {
                    line: 497,
                    column: 12
                },
                end: {
                    line: 499,
                    column: 13
                }
            },
            "212": {
                start: {
                    line: 498,
                    column: 16
                },
                end: {
                    line: 498,
                    column: 28
                }
            },
            "213": {
                start: {
                    line: 501,
                    column: 8
                },
                end: {
                    line: 501,
                    column: 21
                }
            },
            "214": {
                start: {
                    line: 505,
                    column: 25
                },
                end: {
                    line: 505,
                    column: 48
                }
            },
            "215": {
                start: {
                    line: 506,
                    column: 25
                },
                end: {
                    line: 592,
                    column: 9
                }
            },
            "216": {
                start: {
                    line: 593,
                    column: 8
                },
                end: {
                    line: 593,
                    column: 34
                }
            },
            "217": {
                start: {
                    line: 597,
                    column: 28
                },
                end: {
                    line: 597,
                    column: 49
                }
            },
            "218": {
                start: {
                    line: 599,
                    column: 8
                },
                end: {
                    line: 603,
                    column: 11
                }
            },
            "219": {
                start: {
                    line: 600,
                    column: 12
                },
                end: {
                    line: 600,
                    column: 41
                }
            },
            "220": {
                start: {
                    line: 601,
                    column: 12
                },
                end: {
                    line: 601,
                    column: 48
                }
            },
            "221": {
                start: {
                    line: 602,
                    column: 12
                },
                end: {
                    line: 602,
                    column: 50
                }
            },
            "222": {
                start: {
                    line: 607,
                    column: 21
                },
                end: {
                    line: 607,
                    column: 42
                }
            },
            "223": {
                start: {
                    line: 609,
                    column: 8
                },
                end: {
                    line: 609,
                    column: 34
                }
            },
            "224": {
                start: {
                    line: 611,
                    column: 8
                },
                end: {
                    line: 614,
                    column: 11
                }
            },
            "225": {
                start: {
                    line: 612,
                    column: 12
                },
                end: {
                    line: 612,
                    column: 41
                }
            },
            "226": {
                start: {
                    line: 613,
                    column: 12
                },
                end: {
                    line: 613,
                    column: 48
                }
            },
            "227": {
                start: {
                    line: 618,
                    column: 8
                },
                end: {
                    line: 618,
                    column: 53
                }
            },
            "228": {
                start: {
                    line: 622,
                    column: 8
                },
                end: {
                    line: 622,
                    column: 35
                }
            },
            "229": {
                start: {
                    line: 623,
                    column: 8
                },
                end: {
                    line: 628,
                    column: 9
                }
            },
            "230": {
                start: {
                    line: 624,
                    column: 12
                },
                end: {
                    line: 624,
                    column: 50
                }
            },
            "231": {
                start: {
                    line: 626,
                    column: 12
                },
                end: {
                    line: 626,
                    column: 51
                }
            },
            "232": {
                start: {
                    line: 627,
                    column: 12
                },
                end: {
                    line: 627,
                    column: 40
                }
            },
            "233": {
                start: {
                    line: 630,
                    column: 8
                },
                end: {
                    line: 630,
                    column: 31
                }
            },
            "234": {
                start: {
                    line: 634,
                    column: 8
                },
                end: {
                    line: 652,
                    column: 11
                }
            },
            "235": {
                start: {
                    line: 640,
                    column: 12
                },
                end: {
                    line: 640,
                    column: 35
                }
            },
            "236": {
                start: {
                    line: 642,
                    column: 12
                },
                end: {
                    line: 651,
                    column: 13
                }
            },
            "237": {
                start: {
                    line: 643,
                    column: 30
                },
                end: {
                    line: 643,
                    column: 32
                }
            },
            "238": {
                start: {
                    line: 644,
                    column: 16
                },
                end: {
                    line: 648,
                    column: 17
                }
            },
            "239": {
                start: {
                    line: 645,
                    column: 20
                },
                end: {
                    line: 645,
                    column: 133
                }
            },
            "240": {
                start: {
                    line: 647,
                    column: 20
                },
                end: {
                    line: 647,
                    column: 141
                }
            },
            "241": {
                start: {
                    line: 649,
                    column: 30
                },
                end: {
                    line: 649,
                    column: 73
                }
            },
            "242": {
                start: {
                    line: 650,
                    column: 16
                },
                end: {
                    line: 650,
                    column: 42
                }
            },
            "243": {
                start: {
                    line: 656,
                    column: 8
                },
                end: {
                    line: 658,
                    column: 9
                }
            },
            "244": {
                start: {
                    line: 657,
                    column: 12
                },
                end: {
                    line: 657,
                    column: 25
                }
            },
            "245": {
                start: {
                    line: 659,
                    column: 25
                },
                end: {
                    line: 660,
                    column: 74
                }
            },
            "246": {
                start: {
                    line: 660,
                    column: 22
                },
                end: {
                    line: 660,
                    column: 73
                }
            },
            "247": {
                start: {
                    line: 661,
                    column: 8
                },
                end: {
                    line: 661,
                    column: 44
                }
            },
            "248": {
                start: {
                    line: 666,
                    column: 8
                },
                end: {
                    line: 668,
                    column: 9
                }
            },
            "249": {
                start: {
                    line: 667,
                    column: 12
                },
                end: {
                    line: 667,
                    column: 25
                }
            },
            "250": {
                start: {
                    line: 670,
                    column: 8
                },
                end: {
                    line: 675,
                    column: 9
                }
            },
            "251": {
                start: {
                    line: 671,
                    column: 12
                },
                end: {
                    line: 673,
                    column: 13
                }
            },
            "252": {
                start: {
                    line: 672,
                    column: 16
                },
                end: {
                    line: 672,
                    column: 43
                }
            },
            "253": {
                start: {
                    line: 674,
                    column: 12
                },
                end: {
                    line: 674,
                    column: 35
                }
            },
            "254": {
                start: {
                    line: 676,
                    column: 8
                },
                end: {
                    line: 676,
                    column: 20
                }
            },
            "255": {
                start: {
                    line: 682,
                    column: 0
                },
                end: {
                    line: 691,
                    column: 7
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 32
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                line: 3
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 96,
                        column: 4
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 96,
                        column: 23
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                line: 96
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 100,
                        column: 4
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 100,
                        column: 12
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                line: 100
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 106,
                        column: 4
                    },
                    end: {
                        line: 106,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 107,
                        column: 111
                    },
                    end: {
                        line: 129,
                        column: 5
                    }
                },
                line: 107
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 128,
                        column: 18
                    },
                    end: {
                        line: 128,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 128,
                        column: 24
                    },
                    end: {
                        line: 128,
                        column: 50
                    }
                },
                line: 128
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 131,
                        column: 4
                    },
                    end: {
                        line: 131,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 131,
                        column: 14
                    },
                    end: {
                        line: 173,
                        column: 5
                    }
                },
                line: 131
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 175,
                        column: 4
                    },
                    end: {
                        line: 175,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 175,
                        column: 26
                    },
                    end: {
                        line: 180,
                        column: 5
                    }
                },
                line: 175
            },
            "7": {
                name: "(anonymous_7)",
                decl: {
                    start: {
                        line: 176,
                        column: 65
                    },
                    end: {
                        line: 176,
                        column: 66
                    }
                },
                loc: {
                    start: {
                        line: 176,
                        column: 75
                    },
                    end: {
                        line: 176,
                        column: 94
                    }
                },
                line: 176
            },
            "8": {
                name: "(anonymous_8)",
                decl: {
                    start: {
                        line: 182,
                        column: 4
                    },
                    end: {
                        line: 182,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 182,
                        column: 18
                    },
                    end: {
                        line: 189,
                        column: 5
                    }
                },
                line: 182
            },
            "9": {
                name: "(anonymous_9)",
                decl: {
                    start: {
                        line: 185,
                        column: 33
                    },
                    end: {
                        line: 185,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 185,
                        column: 43
                    },
                    end: {
                        line: 185,
                        column: 69
                    }
                },
                line: 185
            },
            "10": {
                name: "(anonymous_10)",
                decl: {
                    start: {
                        line: 187,
                        column: 33
                    },
                    end: {
                        line: 187,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 187,
                        column: 43
                    },
                    end: {
                        line: 187,
                        column: 68
                    }
                },
                line: 187
            },
            "11": {
                name: "(anonymous_11)",
                decl: {
                    start: {
                        line: 191,
                        column: 4
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 25
                    },
                    end: {
                        line: 199,
                        column: 5
                    }
                },
                line: 191
            },
            "12": {
                name: "(anonymous_12)",
                decl: {
                    start: {
                        line: 201,
                        column: 4
                    },
                    end: {
                        line: 201,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 201,
                        column: 33
                    },
                    end: {
                        line: 217,
                        column: 5
                    }
                },
                line: 201
            },
            "13": {
                name: "(anonymous_13)",
                decl: {
                    start: {
                        line: 219,
                        column: 4
                    },
                    end: {
                        line: 219,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 219,
                        column: 22
                    },
                    end: {
                        line: 237,
                        column: 5
                    }
                },
                line: 219
            },
            "14": {
                name: "(anonymous_14)",
                decl: {
                    start: {
                        line: 239,
                        column: 4
                    },
                    end: {
                        line: 239,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 239,
                        column: 30
                    },
                    end: {
                        line: 242,
                        column: 5
                    }
                },
                line: 239
            },
            "15": {
                name: "(anonymous_15)",
                decl: {
                    start: {
                        line: 240,
                        column: 34
                    },
                    end: {
                        line: 240,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 240,
                        column: 42
                    },
                    end: {
                        line: 240,
                        column: 49
                    }
                },
                line: 240
            },
            "16": {
                name: "(anonymous_16)",
                decl: {
                    start: {
                        line: 244,
                        column: 4
                    },
                    end: {
                        line: 244,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 244,
                        column: 22
                    },
                    end: {
                        line: 248,
                        column: 5
                    }
                },
                line: 244
            },
            "17": {
                name: "(anonymous_17)",
                decl: {
                    start: {
                        line: 250,
                        column: 4
                    },
                    end: {
                        line: 250,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 250,
                        column: 15
                    },
                    end: {
                        line: 261,
                        column: 5
                    }
                },
                line: 250
            },
            "18": {
                name: "(anonymous_18)",
                decl: {
                    start: {
                        line: 251,
                        column: 55
                    },
                    end: {
                        line: 251,
                        column: 56
                    }
                },
                loc: {
                    start: {
                        line: 251,
                        column: 65
                    },
                    end: {
                        line: 251,
                        column: 74
                    }
                },
                line: 251
            },
            "19": {
                name: "(anonymous_19)",
                decl: {
                    start: {
                        line: 257,
                        column: 17
                    },
                    end: {
                        line: 257,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 257,
                        column: 25
                    },
                    end: {
                        line: 260,
                        column: 9
                    }
                },
                line: 257
            },
            "20": {
                name: "(anonymous_20)",
                decl: {
                    start: {
                        line: 263,
                        column: 4
                    },
                    end: {
                        line: 263,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 263,
                        column: 50
                    },
                    end: {
                        line: 285,
                        column: 5
                    }
                },
                line: 263
            },
            "21": {
                name: "(anonymous_21)",
                decl: {
                    start: {
                        line: 267,
                        column: 48
                    },
                    end: {
                        line: 267,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 267,
                        column: 58
                    },
                    end: {
                        line: 284,
                        column: 9
                    }
                },
                line: 267
            },
            "22": {
                name: "(anonymous_22)",
                decl: {
                    start: {
                        line: 270,
                        column: 16
                    },
                    end: {
                        line: 270,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 270,
                        column: 26
                    },
                    end: {
                        line: 277,
                        column: 17
                    }
                },
                line: 270
            },
            "23": {
                name: "(anonymous_23)",
                decl: {
                    start: {
                        line: 278,
                        column: 16
                    },
                    end: {
                        line: 278,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 278,
                        column: 26
                    },
                    end: {
                        line: 282,
                        column: 17
                    }
                },
                line: 278
            },
            "24": {
                name: "(anonymous_24)",
                decl: {
                    start: {
                        line: 287,
                        column: 4
                    },
                    end: {
                        line: 287,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 287,
                        column: 28
                    },
                    end: {
                        line: 293,
                        column: 5
                    }
                },
                line: 287
            },
            "25": {
                name: "(anonymous_25)",
                decl: {
                    start: {
                        line: 295,
                        column: 4
                    },
                    end: {
                        line: 295,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 295,
                        column: 71
                    },
                    end: {
                        line: 318,
                        column: 5
                    }
                },
                line: 295
            },
            "26": {
                name: "(anonymous_26)",
                decl: {
                    start: {
                        line: 299,
                        column: 48
                    },
                    end: {
                        line: 299,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 299,
                        column: 58
                    },
                    end: {
                        line: 317,
                        column: 9
                    }
                },
                line: 299
            },
            "27": {
                name: "(anonymous_27)",
                decl: {
                    start: {
                        line: 303,
                        column: 16
                    },
                    end: {
                        line: 303,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 303,
                        column: 22
                    },
                    end: {
                        line: 309,
                        column: 17
                    }
                },
                line: 303
            },
            "28": {
                name: "(anonymous_28)",
                decl: {
                    start: {
                        line: 310,
                        column: 16
                    },
                    end: {
                        line: 310,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 310,
                        column: 28
                    },
                    end: {
                        line: 315,
                        column: 17
                    }
                },
                line: 310
            },
            "29": {
                name: "(anonymous_29)",
                decl: {
                    start: {
                        line: 320,
                        column: 4
                    },
                    end: {
                        line: 320,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 320,
                        column: 28
                    },
                    end: {
                        line: 328,
                        column: 5
                    }
                },
                line: 320
            },
            "30": {
                name: "(anonymous_30)",
                decl: {
                    start: {
                        line: 322,
                        column: 58
                    },
                    end: {
                        line: 322,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 322,
                        column: 68
                    },
                    end: {
                        line: 322,
                        column: 92
                    }
                },
                line: 322
            },
            "31": {
                name: "(anonymous_31)",
                decl: {
                    start: {
                        line: 330,
                        column: 4
                    },
                    end: {
                        line: 330,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 330,
                        column: 31
                    },
                    end: {
                        line: 367,
                        column: 5
                    }
                },
                line: 330
            },
            "32": {
                name: "(anonymous_32)",
                decl: {
                    start: {
                        line: 337,
                        column: 42
                    },
                    end: {
                        line: 337,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 337,
                        column: 48
                    },
                    end: {
                        line: 366,
                        column: 9
                    }
                },
                line: 337
            },
            "33": {
                name: "(anonymous_33)",
                decl: {
                    start: {
                        line: 341,
                        column: 52
                    },
                    end: {
                        line: 341,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 341,
                        column: 62
                    },
                    end: {
                        line: 365,
                        column: 13
                    }
                },
                line: 341
            },
            "34": {
                name: "(anonymous_34)",
                decl: {
                    start: {
                        line: 345,
                        column: 25
                    },
                    end: {
                        line: 345,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 345,
                        column: 31
                    },
                    end: {
                        line: 355,
                        column: 17
                    }
                },
                line: 345
            },
            "35": {
                name: "(anonymous_35)",
                decl: {
                    start: {
                        line: 346,
                        column: 42
                    },
                    end: {
                        line: 346,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 346,
                        column: 52
                    },
                    end: {
                        line: 348,
                        column: 21
                    }
                },
                line: 346
            },
            "36": {
                name: "(anonymous_36)",
                decl: {
                    start: {
                        line: 356,
                        column: 25
                    },
                    end: {
                        line: 356,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 356,
                        column: 35
                    },
                    end: {
                        line: 364,
                        column: 26
                    }
                },
                line: 356
            },
            "37": {
                name: "(anonymous_37)",
                decl: {
                    start: {
                        line: 369,
                        column: 4
                    },
                    end: {
                        line: 369,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 369,
                        column: 22
                    },
                    end: {
                        line: 372,
                        column: 5
                    }
                },
                line: 369
            },
            "38": {
                name: "(anonymous_38)",
                decl: {
                    start: {
                        line: 370,
                        column: 54
                    },
                    end: {
                        line: 370,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 370,
                        column: 64
                    },
                    end: {
                        line: 370,
                        column: 132
                    }
                },
                line: 370
            },
            "39": {
                name: "(anonymous_39)",
                decl: {
                    start: {
                        line: 374,
                        column: 4
                    },
                    end: {
                        line: 374,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 374,
                        column: 32
                    },
                    end: {
                        line: 380,
                        column: 5
                    }
                },
                line: 374
            },
            "40": {
                name: "(anonymous_40)",
                decl: {
                    start: {
                        line: 382,
                        column: 4
                    },
                    end: {
                        line: 382,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 382,
                        column: 19
                    },
                    end: {
                        line: 395,
                        column: 5
                    }
                },
                line: 382
            },
            "41": {
                name: "(anonymous_41)",
                decl: {
                    start: {
                        line: 388,
                        column: 52
                    },
                    end: {
                        line: 388,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 388,
                        column: 62
                    },
                    end: {
                        line: 393,
                        column: 13
                    }
                },
                line: 388
            },
            "42": {
                name: "(anonymous_42)",
                decl: {
                    start: {
                        line: 391,
                        column: 20
                    },
                    end: {
                        line: 391,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 391,
                        column: 26
                    },
                    end: {
                        line: 392,
                        column: 83
                    }
                },
                line: 391
            },
            "43": {
                name: "(anonymous_43)",
                decl: {
                    start: {
                        line: 397,
                        column: 4
                    },
                    end: {
                        line: 397,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 397,
                        column: 15
                    },
                    end: {
                        line: 407,
                        column: 5
                    }
                },
                line: 397
            },
            "44": {
                name: "(anonymous_44)",
                decl: {
                    start: {
                        line: 403,
                        column: 52
                    },
                    end: {
                        line: 403,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 403,
                        column: 62
                    },
                    end: {
                        line: 405,
                        column: 13
                    }
                },
                line: 403
            },
            "45": {
                name: "(anonymous_45)",
                decl: {
                    start: {
                        line: 409,
                        column: 4
                    },
                    end: {
                        line: 409,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 409,
                        column: 30
                    },
                    end: {
                        line: 414,
                        column: 5
                    }
                },
                line: 409
            },
            "46": {
                name: "(anonymous_46)",
                decl: {
                    start: {
                        line: 410,
                        column: 72
                    },
                    end: {
                        line: 410,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 410,
                        column: 80
                    },
                    end: {
                        line: 410,
                        column: 98
                    }
                },
                line: 410
            },
            "47": {
                name: "(anonymous_47)",
                decl: {
                    start: {
                        line: 411,
                        column: 48
                    },
                    end: {
                        line: 411,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 411,
                        column: 58
                    },
                    end: {
                        line: 413,
                        column: 9
                    }
                },
                line: 411
            },
            "48": {
                name: "(anonymous_48)",
                decl: {
                    start: {
                        line: 416,
                        column: 4
                    },
                    end: {
                        line: 416,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 416,
                        column: 82
                    },
                    end: {
                        line: 431,
                        column: 5
                    }
                },
                line: 416
            },
            "49": {
                name: "(anonymous_49)",
                decl: {
                    start: {
                        line: 433,
                        column: 4
                    },
                    end: {
                        line: 433,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 433,
                        column: 21
                    },
                    end: {
                        line: 459,
                        column: 5
                    }
                },
                line: 433
            },
            "50": {
                name: "(anonymous_50)",
                decl: {
                    start: {
                        line: 440,
                        column: 42
                    },
                    end: {
                        line: 440,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 440,
                        column: 48
                    },
                    end: {
                        line: 458,
                        column: 9
                    }
                },
                line: 440
            },
            "51": {
                name: "(anonymous_51)",
                decl: {
                    start: {
                        line: 444,
                        column: 52
                    },
                    end: {
                        line: 444,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 444,
                        column: 62
                    },
                    end: {
                        line: 457,
                        column: 13
                    }
                },
                line: 444
            },
            "52": {
                name: "(anonymous_52)",
                decl: {
                    start: {
                        line: 446,
                        column: 20
                    },
                    end: {
                        line: 446,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 446,
                        column: 30
                    },
                    end: {
                        line: 450,
                        column: 21
                    }
                },
                line: 446
            },
            "53": {
                name: "(anonymous_53)",
                decl: {
                    start: {
                        line: 451,
                        column: 20
                    },
                    end: {
                        line: 451,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 451,
                        column: 30
                    },
                    end: {
                        line: 455,
                        column: 21
                    }
                },
                line: 451
            },
            "54": {
                name: "(anonymous_54)",
                decl: {
                    start: {
                        line: 461,
                        column: 4
                    },
                    end: {
                        line: 461,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 461,
                        column: 29
                    },
                    end: {
                        line: 480,
                        column: 5
                    }
                },
                line: 461
            },
            "55": {
                name: "(anonymous_55)",
                decl: {
                    start: {
                        line: 473,
                        column: 16
                    },
                    end: {
                        line: 473,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 473,
                        column: 22
                    },
                    end: {
                        line: 477,
                        column: 9
                    }
                },
                line: 473
            },
            "56": {
                name: "(anonymous_56)",
                decl: {
                    start: {
                        line: 477,
                        column: 17
                    },
                    end: {
                        line: 477,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 477,
                        column: 23
                    },
                    end: {
                        line: 479,
                        column: 9
                    }
                },
                line: 477
            },
            "57": {
                name: "(anonymous_57)",
                decl: {
                    start: {
                        line: 482,
                        column: 4
                    },
                    end: {
                        line: 482,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 482,
                        column: 20
                    },
                    end: {
                        line: 485,
                        column: 5
                    }
                },
                line: 482
            },
            "58": {
                name: "(anonymous_58)",
                decl: {
                    start: {
                        line: 487,
                        column: 4
                    },
                    end: {
                        line: 487,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 487,
                        column: 36
                    },
                    end: {
                        line: 502,
                        column: 5
                    }
                },
                line: 487
            },
            "59": {
                name: "(anonymous_59)",
                decl: {
                    start: {
                        line: 504,
                        column: 4
                    },
                    end: {
                        line: 504,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 504,
                        column: 21
                    },
                    end: {
                        line: 594,
                        column: 5
                    }
                },
                line: 504
            },
            "60": {
                name: "(anonymous_60)",
                decl: {
                    start: {
                        line: 596,
                        column: 4
                    },
                    end: {
                        line: 596,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 596,
                        column: 23
                    },
                    end: {
                        line: 604,
                        column: 5
                    }
                },
                line: 596
            },
            "61": {
                name: "(anonymous_61)",
                decl: {
                    start: {
                        line: 599,
                        column: 28
                    },
                    end: {
                        line: 599,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 599,
                        column: 35
                    },
                    end: {
                        line: 603,
                        column: 9
                    }
                },
                line: 599
            },
            "62": {
                name: "(anonymous_62)",
                decl: {
                    start: {
                        line: 606,
                        column: 4
                    },
                    end: {
                        line: 606,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 606,
                        column: 27
                    },
                    end: {
                        line: 615,
                        column: 5
                    }
                },
                line: 606
            },
            "63": {
                name: "(anonymous_63)",
                decl: {
                    start: {
                        line: 611,
                        column: 21
                    },
                    end: {
                        line: 611,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 611,
                        column: 28
                    },
                    end: {
                        line: 614,
                        column: 9
                    }
                },
                line: 611
            },
            "64": {
                name: "(anonymous_64)",
                decl: {
                    start: {
                        line: 617,
                        column: 4
                    },
                    end: {
                        line: 617,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 617,
                        column: 29
                    },
                    end: {
                        line: 619,
                        column: 5
                    }
                },
                line: 617
            },
            "65": {
                name: "(anonymous_65)",
                decl: {
                    start: {
                        line: 621,
                        column: 4
                    },
                    end: {
                        line: 621,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 621,
                        column: 26
                    },
                    end: {
                        line: 631,
                        column: 5
                    }
                },
                line: 621
            },
            "66": {
                name: "(anonymous_66)",
                decl: {
                    start: {
                        line: 633,
                        column: 4
                    },
                    end: {
                        line: 633,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 633,
                        column: 77
                    },
                    end: {
                        line: 653,
                        column: 5
                    }
                },
                line: 633
            },
            "67": {
                name: "(anonymous_67)",
                decl: {
                    start: {
                        line: 639,
                        column: 17
                    },
                    end: {
                        line: 639,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 639,
                        column: 23
                    },
                    end: {
                        line: 641,
                        column: 9
                    }
                },
                line: 639
            },
            "68": {
                name: "(anonymous_68)",
                decl: {
                    start: {
                        line: 641,
                        column: 20
                    },
                    end: {
                        line: 641,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 641,
                        column: 29
                    },
                    end: {
                        line: 652,
                        column: 9
                    }
                },
                line: 641
            },
            "69": {
                name: "(anonymous_69)",
                decl: {
                    start: {
                        line: 655,
                        column: 4
                    },
                    end: {
                        line: 655,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 655,
                        column: 25
                    },
                    end: {
                        line: 662,
                        column: 5
                    }
                },
                line: 655
            },
            "70": {
                name: "(anonymous_70)",
                decl: {
                    start: {
                        line: 660,
                        column: 12
                    },
                    end: {
                        line: 660,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 660,
                        column: 22
                    },
                    end: {
                        line: 660,
                        column: 73
                    }
                },
                line: 660
            },
            "71": {
                name: "(anonymous_71)",
                decl: {
                    start: {
                        line: 665,
                        column: 4
                    },
                    end: {
                        line: 665,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 665,
                        column: 32
                    },
                    end: {
                        line: 677,
                        column: 5
                    }
                },
                line: 665
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 97,
                        column: 15
                    },
                    end: {
                        line: 97,
                        column: 57
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 97,
                        column: 24
                    },
                    end: {
                        line: 97,
                        column: 41
                    }
                }, {
                    start: {
                        line: 97,
                        column: 44
                    },
                    end: {
                        line: 97,
                        column: 57
                    }
                }],
                line: 97
            },
            "1": {
                loc: {
                    start: {
                        line: 152,
                        column: 26
                    },
                    end: {
                        line: 152,
                        column: 115
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 152,
                        column: 64
                    },
                    end: {
                        line: 152,
                        column: 108
                    }
                }, {
                    start: {
                        line: 152,
                        column: 111
                    },
                    end: {
                        line: 152,
                        column: 115
                    }
                }],
                line: 152
            },
            "2": {
                loc: {
                    start: {
                        line: 153,
                        column: 31
                    },
                    end: {
                        line: 153,
                        column: 118
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 153,
                        column: 68
                    },
                    end: {
                        line: 153,
                        column: 111
                    }
                }, {
                    start: {
                        line: 153,
                        column: 114
                    },
                    end: {
                        line: 153,
                        column: 118
                    }
                }],
                line: 153
            },
            "3": {
                loc: {
                    start: {
                        line: 154,
                        column: 30
                    },
                    end: {
                        line: 154,
                        column: 115
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 154,
                        column: 66
                    },
                    end: {
                        line: 154,
                        column: 108
                    }
                }, {
                    start: {
                        line: 154,
                        column: 111
                    },
                    end: {
                        line: 154,
                        column: 115
                    }
                }],
                line: 154
            },
            "4": {
                loc: {
                    start: {
                        line: 155,
                        column: 24
                    },
                    end: {
                        line: 155,
                        column: 101
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 155,
                        column: 24
                    },
                    end: {
                        line: 155,
                        column: 50
                    }
                }, {
                    start: {
                        line: 155,
                        column: 54
                    },
                    end: {
                        line: 155,
                        column: 91
                    }
                }, {
                    start: {
                        line: 155,
                        column: 95
                    },
                    end: {
                        line: 155,
                        column: 101
                    }
                }],
                line: 155
            },
            "5": {
                loc: {
                    start: {
                        line: 163,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 163,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                }, {
                    start: {
                        line: 163,
                        column: 8
                    },
                    end: {
                        line: 169,
                        column: 9
                    }
                }],
                line: 163
            },
            "6": {
                loc: {
                    start: {
                        line: 164,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 164,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                }, {
                    start: {
                        line: 164,
                        column: 12
                    },
                    end: {
                        line: 168,
                        column: 13
                    }
                }],
                line: 164
            },
            "7": {
                loc: {
                    start: {
                        line: 176,
                        column: 8
                    },
                    end: {
                        line: 178,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 176,
                        column: 8
                    },
                    end: {
                        line: 178,
                        column: 9
                    }
                }, {
                    start: {
                        line: 176,
                        column: 8
                    },
                    end: {
                        line: 178,
                        column: 9
                    }
                }],
                line: 176
            },
            "8": {
                loc: {
                    start: {
                        line: 176,
                        column: 12
                    },
                    end: {
                        line: 176,
                        column: 106
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 176,
                        column: 12
                    },
                    end: {
                        line: 176,
                        column: 41
                    }
                }, {
                    start: {
                        line: 176,
                        column: 45
                    },
                    end: {
                        line: 176,
                        column: 106
                    }
                }],
                line: 176
            },
            "9": {
                loc: {
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 188,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 188,
                        column: 9
                    }
                }, {
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 188,
                        column: 9
                    }
                }],
                line: 183
            },
            "10": {
                loc: {
                    start: {
                        line: 192,
                        column: 8
                    },
                    end: {
                        line: 194,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 192,
                        column: 8
                    },
                    end: {
                        line: 194,
                        column: 9
                    }
                }, {
                    start: {
                        line: 192,
                        column: 8
                    },
                    end: {
                        line: 194,
                        column: 9
                    }
                }],
                line: 192
            },
            "11": {
                loc: {
                    start: {
                        line: 193,
                        column: 19
                    },
                    end: {
                        line: 193,
                        column: 63
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 193,
                        column: 48
                    },
                    end: {
                        line: 193,
                        column: 54
                    }
                }, {
                    start: {
                        line: 193,
                        column: 57
                    },
                    end: {
                        line: 193,
                        column: 63
                    }
                }],
                line: 193
            },
            "12": {
                loc: {
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                }, {
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                }],
                line: 202
            },
            "13": {
                loc: {
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 211,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 211,
                        column: 9
                    }
                }, {
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 211,
                        column: 9
                    }
                }],
                line: 206
            },
            "14": {
                loc: {
                    start: {
                        line: 224,
                        column: 8
                    },
                    end: {
                        line: 228,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 224,
                        column: 8
                    },
                    end: {
                        line: 228,
                        column: 9
                    }
                }, {
                    start: {
                        line: 224,
                        column: 8
                    },
                    end: {
                        line: 228,
                        column: 9
                    }
                }],
                line: 224
            },
            "15": {
                loc: {
                    start: {
                        line: 224,
                        column: 12
                    },
                    end: {
                        line: 224,
                        column: 123
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 224,
                        column: 12
                    },
                    end: {
                        line: 224,
                        column: 39
                    }
                }, {
                    start: {
                        line: 224,
                        column: 43
                    },
                    end: {
                        line: 224,
                        column: 123
                    }
                }],
                line: 224
            },
            "16": {
                loc: {
                    start: {
                        line: 232,
                        column: 8
                    },
                    end: {
                        line: 234,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 232,
                        column: 8
                    },
                    end: {
                        line: 234,
                        column: 9
                    }
                }, {
                    start: {
                        line: 232,
                        column: 8
                    },
                    end: {
                        line: 234,
                        column: 9
                    }
                }],
                line: 232
            },
            "17": {
                loc: {
                    start: {
                        line: 246,
                        column: 21
                    },
                    end: {
                        line: 246,
                        column: 108
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 246,
                        column: 41
                    },
                    end: {
                        line: 246,
                        column: 87
                    }
                }, {
                    start: {
                        line: 246,
                        column: 90
                    },
                    end: {
                        line: 246,
                        column: 108
                    }
                }],
                line: 246
            },
            "18": {
                loc: {
                    start: {
                        line: 255,
                        column: 18
                    },
                    end: {
                        line: 255,
                        column: 64
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 255,
                        column: 47
                    },
                    end: {
                        line: 255,
                        column: 55
                    }
                }, {
                    start: {
                        line: 255,
                        column: 58
                    },
                    end: {
                        line: 255,
                        column: 64
                    }
                }],
                line: 255
            },
            "19": {
                loc: {
                    start: {
                        line: 263,
                        column: 29
                    },
                    end: {
                        line: 263,
                        column: 48
                    }
                },
                type: "default-arg",
                locations: [{
                    start: {
                        line: 263,
                        column: 43
                    },
                    end: {
                        line: 263,
                        column: 48
                    }
                }],
                line: 263
            },
            "20": {
                loc: {
                    start: {
                        line: 274,
                        column: 20
                    },
                    end: {
                        line: 276,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 274,
                        column: 20
                    },
                    end: {
                        line: 276,
                        column: 21
                    }
                }, {
                    start: {
                        line: 274,
                        column: 20
                    },
                    end: {
                        line: 276,
                        column: 21
                    }
                }],
                line: 274
            },
            "21": {
                loc: {
                    start: {
                        line: 321,
                        column: 8
                    },
                    end: {
                        line: 326,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 321,
                        column: 8
                    },
                    end: {
                        line: 326,
                        column: 9
                    }
                }, {
                    start: {
                        line: 321,
                        column: 8
                    },
                    end: {
                        line: 326,
                        column: 9
                    }
                }],
                line: 321
            },
            "22": {
                loc: {
                    start: {
                        line: 352,
                        column: 20
                    },
                    end: {
                        line: 354,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 352,
                        column: 20
                    },
                    end: {
                        line: 354,
                        column: 21
                    }
                }, {
                    start: {
                        line: 352,
                        column: 20
                    },
                    end: {
                        line: 354,
                        column: 21
                    }
                }],
                line: 352
            },
            "23": {
                loc: {
                    start: {
                        line: 357,
                        column: 41
                    },
                    end: {
                        line: 357,
                        column: 115
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 357,
                        column: 67
                    },
                    end: {
                        line: 357,
                        column: 94
                    }
                }, {
                    start: {
                        line: 357,
                        column: 97
                    },
                    end: {
                        line: 357,
                        column: 115
                    }
                }],
                line: 357
            },
            "24": {
                loc: {
                    start: {
                        line: 361,
                        column: 29
                    },
                    end: {
                        line: 363,
                        column: 30
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 361,
                        column: 29
                    },
                    end: {
                        line: 363,
                        column: 30
                    }
                }, {
                    start: {
                        line: 361,
                        column: 29
                    },
                    end: {
                        line: 363,
                        column: 30
                    }
                }],
                line: 361
            },
            "25": {
                loc: {
                    start: {
                        line: 384,
                        column: 8
                    },
                    end: {
                        line: 394,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 384,
                        column: 8
                    },
                    end: {
                        line: 394,
                        column: 9
                    }
                }, {
                    start: {
                        line: 384,
                        column: 8
                    },
                    end: {
                        line: 394,
                        column: 9
                    }
                }],
                line: 384
            },
            "26": {
                loc: {
                    start: {
                        line: 399,
                        column: 8
                    },
                    end: {
                        line: 406,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 399,
                        column: 8
                    },
                    end: {
                        line: 406,
                        column: 9
                    }
                }, {
                    start: {
                        line: 399,
                        column: 8
                    },
                    end: {
                        line: 406,
                        column: 9
                    }
                }],
                line: 399
            },
            "27": {
                loc: {
                    start: {
                        line: 416,
                        column: 32
                    },
                    end: {
                        line: 416,
                        column: 43
                    }
                },
                type: "default-arg",
                locations: [{
                    start: {
                        line: 416,
                        column: 41
                    },
                    end: {
                        line: 416,
                        column: 43
                    }
                }],
                line: 416
            },
            "28": {
                loc: {
                    start: {
                        line: 416,
                        column: 45
                    },
                    end: {
                        line: 416,
                        column: 80
                    }
                },
                type: "default-arg",
                locations: [{
                    start: {
                        line: 416,
                        column: 58
                    },
                    end: {
                        line: 416,
                        column: 80
                    }
                }],
                line: 416
            },
            "29": {
                loc: {
                    start: {
                        line: 417,
                        column: 8
                    },
                    end: {
                        line: 419,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 417,
                        column: 8
                    },
                    end: {
                        line: 419,
                        column: 9
                    }
                }, {
                    start: {
                        line: 417,
                        column: 8
                    },
                    end: {
                        line: 419,
                        column: 9
                    }
                }],
                line: 417
            },
            "30": {
                loc: {
                    start: {
                        line: 420,
                        column: 22
                    },
                    end: {
                        line: 420,
                        column: 93
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 420,
                        column: 40
                    },
                    end: {
                        line: 420,
                        column: 80
                    }
                }, {
                    start: {
                        line: 420,
                        column: 83
                    },
                    end: {
                        line: 420,
                        column: 93
                    }
                }],
                line: 420
            },
            "31": {
                loc: {
                    start: {
                        line: 490,
                        column: 8
                    },
                    end: {
                        line: 492,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 490,
                        column: 8
                    },
                    end: {
                        line: 492,
                        column: 9
                    }
                }, {
                    start: {
                        line: 490,
                        column: 8
                    },
                    end: {
                        line: 492,
                        column: 9
                    }
                }],
                line: 490
            },
            "32": {
                loc: {
                    start: {
                        line: 494,
                        column: 8
                    },
                    end: {
                        line: 500,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 494,
                        column: 8
                    },
                    end: {
                        line: 500,
                        column: 9
                    }
                }, {
                    start: {
                        line: 494,
                        column: 8
                    },
                    end: {
                        line: 500,
                        column: 9
                    }
                }],
                line: 494
            },
            "33": {
                loc: {
                    start: {
                        line: 497,
                        column: 12
                    },
                    end: {
                        line: 499,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 497,
                        column: 12
                    },
                    end: {
                        line: 499,
                        column: 13
                    }
                }, {
                    start: {
                        line: 497,
                        column: 12
                    },
                    end: {
                        line: 499,
                        column: 13
                    }
                }],
                line: 497
            },
            "34": {
                loc: {
                    start: {
                        line: 623,
                        column: 8
                    },
                    end: {
                        line: 628,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 623,
                        column: 8
                    },
                    end: {
                        line: 628,
                        column: 9
                    }
                }, {
                    start: {
                        line: 623,
                        column: 8
                    },
                    end: {
                        line: 628,
                        column: 9
                    }
                }],
                line: 623
            },
            "35": {
                loc: {
                    start: {
                        line: 633,
                        column: 45
                    },
                    end: {
                        line: 633,
                        column: 58
                    }
                },
                type: "default-arg",
                locations: [{
                    start: {
                        line: 633,
                        column: 54
                    },
                    end: {
                        line: 633,
                        column: 58
                    }
                }],
                line: 633
            },
            "36": {
                loc: {
                    start: {
                        line: 633,
                        column: 60
                    },
                    end: {
                        line: 633,
                        column: 75
                    }
                },
                type: "default-arg",
                locations: [{
                    start: {
                        line: 633,
                        column: 71
                    },
                    end: {
                        line: 633,
                        column: 75
                    }
                }],
                line: 633
            },
            "37": {
                loc: {
                    start: {
                        line: 642,
                        column: 12
                    },
                    end: {
                        line: 651,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 642,
                        column: 12
                    },
                    end: {
                        line: 651,
                        column: 13
                    }
                }, {
                    start: {
                        line: 642,
                        column: 12
                    },
                    end: {
                        line: 651,
                        column: 13
                    }
                }],
                line: 642
            },
            "38": {
                loc: {
                    start: {
                        line: 644,
                        column: 16
                    },
                    end: {
                        line: 648,
                        column: 17
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 644,
                        column: 16
                    },
                    end: {
                        line: 648,
                        column: 17
                    }
                }, {
                    start: {
                        line: 644,
                        column: 16
                    },
                    end: {
                        line: 648,
                        column: 17
                    }
                }],
                line: 644
            },
            "39": {
                loc: {
                    start: {
                        line: 656,
                        column: 8
                    },
                    end: {
                        line: 658,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 656,
                        column: 8
                    },
                    end: {
                        line: 658,
                        column: 9
                    }
                }, {
                    start: {
                        line: 656,
                        column: 8
                    },
                    end: {
                        line: 658,
                        column: 9
                    }
                }],
                line: 656
            },
            "40": {
                loc: {
                    start: {
                        line: 666,
                        column: 8
                    },
                    end: {
                        line: 668,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 666,
                        column: 8
                    },
                    end: {
                        line: 668,
                        column: 9
                    }
                }, {
                    start: {
                        line: 666,
                        column: 8
                    },
                    end: {
                        line: 668,
                        column: 9
                    }
                }],
                line: 666
            },
            "41": {
                loc: {
                    start: {
                        line: 670,
                        column: 8
                    },
                    end: {
                        line: 675,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 670,
                        column: 8
                    },
                    end: {
                        line: 675,
                        column: 9
                    }
                }, {
                    start: {
                        line: 670,
                        column: 8
                    },
                    end: {
                        line: 675,
                        column: 9
                    }
                }],
                line: 670
            },
            "42": {
                loc: {
                    start: {
                        line: 671,
                        column: 12
                    },
                    end: {
                        line: 673,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 671,
                        column: 12
                    },
                    end: {
                        line: 673,
                        column: 13
                    }
                }, {
                    start: {
                        line: 671,
                        column: 12
                    },
                    end: {
                        line: 673,
                        column: 13
                    }
                }],
                line: 671
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0,
            "61": 0,
            "62": 0,
            "63": 0,
            "64": 0,
            "65": 0,
            "66": 0,
            "67": 0,
            "68": 0,
            "69": 0,
            "70": 0,
            "71": 0,
            "72": 0,
            "73": 0,
            "74": 0,
            "75": 0,
            "76": 0,
            "77": 0,
            "78": 0,
            "79": 0,
            "80": 0,
            "81": 0,
            "82": 0,
            "83": 0,
            "84": 0,
            "85": 0,
            "86": 0,
            "87": 0,
            "88": 0,
            "89": 0,
            "90": 0,
            "91": 0,
            "92": 0,
            "93": 0,
            "94": 0,
            "95": 0,
            "96": 0,
            "97": 0,
            "98": 0,
            "99": 0,
            "100": 0,
            "101": 0,
            "102": 0,
            "103": 0,
            "104": 0,
            "105": 0,
            "106": 0,
            "107": 0,
            "108": 0,
            "109": 0,
            "110": 0,
            "111": 0,
            "112": 0,
            "113": 0,
            "114": 0,
            "115": 0,
            "116": 0,
            "117": 0,
            "118": 0,
            "119": 0,
            "120": 0,
            "121": 0,
            "122": 0,
            "123": 0,
            "124": 0,
            "125": 0,
            "126": 0,
            "127": 0,
            "128": 0,
            "129": 0,
            "130": 0,
            "131": 0,
            "132": 0,
            "133": 0,
            "134": 0,
            "135": 0,
            "136": 0,
            "137": 0,
            "138": 0,
            "139": 0,
            "140": 0,
            "141": 0,
            "142": 0,
            "143": 0,
            "144": 0,
            "145": 0,
            "146": 0,
            "147": 0,
            "148": 0,
            "149": 0,
            "150": 0,
            "151": 0,
            "152": 0,
            "153": 0,
            "154": 0,
            "155": 0,
            "156": 0,
            "157": 0,
            "158": 0,
            "159": 0,
            "160": 0,
            "161": 0,
            "162": 0,
            "163": 0,
            "164": 0,
            "165": 0,
            "166": 0,
            "167": 0,
            "168": 0,
            "169": 0,
            "170": 0,
            "171": 0,
            "172": 0,
            "173": 0,
            "174": 0,
            "175": 0,
            "176": 0,
            "177": 0,
            "178": 0,
            "179": 0,
            "180": 0,
            "181": 0,
            "182": 0,
            "183": 0,
            "184": 0,
            "185": 0,
            "186": 0,
            "187": 0,
            "188": 0,
            "189": 0,
            "190": 0,
            "191": 0,
            "192": 0,
            "193": 0,
            "194": 0,
            "195": 0,
            "196": 0,
            "197": 0,
            "198": 0,
            "199": 0,
            "200": 0,
            "201": 0,
            "202": 0,
            "203": 0,
            "204": 0,
            "205": 0,
            "206": 0,
            "207": 0,
            "208": 0,
            "209": 0,
            "210": 0,
            "211": 0,
            "212": 0,
            "213": 0,
            "214": 0,
            "215": 0,
            "216": 0,
            "217": 0,
            "218": 0,
            "219": 0,
            "220": 0,
            "221": 0,
            "222": 0,
            "223": 0,
            "224": 0,
            "225": 0,
            "226": 0,
            "227": 0,
            "228": 0,
            "229": 0,
            "230": 0,
            "231": 0,
            "232": 0,
            "233": 0,
            "234": 0,
            "235": 0,
            "236": 0,
            "237": 0,
            "238": 0,
            "239": 0,
            "240": 0,
            "241": 0,
            "242": 0,
            "243": 0,
            "244": 0,
            "245": 0,
            "246": 0,
            "247": 0,
            "248": 0,
            "249": 0,
            "250": 0,
            "251": 0,
            "252": 0,
            "253": 0,
            "254": 0,
            "255": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0,
            "61": 0,
            "62": 0,
            "63": 0,
            "64": 0,
            "65": 0,
            "66": 0,
            "67": 0,
            "68": 0,
            "69": 0,
            "70": 0,
            "71": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0],
            "4": [0, 0, 0],
            "5": [0, 0],
            "6": [0, 0],
            "7": [0, 0],
            "8": [0, 0],
            "9": [0, 0],
            "10": [0, 0],
            "11": [0, 0],
            "12": [0, 0],
            "13": [0, 0],
            "14": [0, 0],
            "15": [0, 0],
            "16": [0, 0],
            "17": [0, 0],
            "18": [0, 0],
            "19": [0],
            "20": [0, 0],
            "21": [0, 0],
            "22": [0, 0],
            "23": [0, 0],
            "24": [0, 0],
            "25": [0, 0],
            "26": [0, 0],
            "27": [0],
            "28": [0],
            "29": [0, 0],
            "30": [0, 0],
            "31": [0, 0],
            "32": [0, 0],
            "33": [0, 0],
            "34": [0, 0],
            "35": [0],
            "36": [0],
            "37": [0, 0],
            "38": [0, 0],
            "39": [0, 0],
            "40": [0, 0],
            "41": [0, 0],
            "42": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var WorkspaceEditorController = function () {
    function WorkspaceEditorController($mdBottomSheet) {
        _classCallCheck(this, WorkspaceEditorController);

        cov_2p0areg3mk.f[0]++;
        cov_2p0areg3mk.s[0]++;

        this.$mdBottomSheet = $mdBottomSheet;

        cov_2p0areg3mk.s[1]++;
        this.options = {
            filters: false,
            listFields: false,
            bulk: false,
            pdf: false,
            other: false
        };

        cov_2p0areg3mk.s[2]++;
        this.filtersConfigs = [{ name: "Referências", model: "search" }, { name: "Malha", model: "hasMesh" }, { name: "Tipo Malha", model: "meshType" }, { name: "Marca", model: "brand" }, { name: "Comercial", model: "brandCommercial" }, { name: "Comercial Acessórios", model: "brandAccessoriesCommercialUser" }, { name: "Modelista", model: "brandStylistUser" }, { name: "Encarregada", model: "confectionOverseer" }, { name: "Ornamentos", model: "decorationIn" }, { name: "Acabamentos", model: "finishingIn" }, { name: "Estação", model: "season" }, { name: "Tipo Amostra", model: "sampleTypeIn" }, { name: "Estado", model: "workflowState" }, { name: "Data Pretendida", model: "intendedDate" }, { name: "Data Prevista", model: "foreseenDate" }, { name: "Data Envio", model: "shippingDate" }, { name: "Fast Track", model: "fastTrack" }, { name: "Posição Ornamento", model: "decorationPosition" }];

        cov_2p0areg3mk.s[3]++;
        this.bulkConfigs = [{ name: "Encarregada", model: "confectionOverseer" }, { name: "Quantidade", model: "quantity" }, { name: "Data Pretendida", model: "intendedDate" }, { name: "Data Prevista", model: "foreseenDate" }, { name: "Data Prevista Ornamentos", model: "decorationsForeseenDate" }, { name: "Malha Disponível", model: "hasMesh" }, { name: "Data Prevista Malha", model: "meshForeseenDate" }, { name: "Data Prevista Acabamentos", model: "finishingsForeseenDate" }, { name: "Fast Track", model: "fastTrack" }, { name: "Data Prevista Acessórios Confeção", model: "confectionAccessoriesForeseenDate" }, { name: "Acessórios Conf. Disp.", model: "hasConfectionAccessories" }, { name: "Data Prevista Acessórios Embalagem", model: "packingAccessoriesForeseenDate" }, { name: "Acessórios Emb. Disp.", model: "hasPackingAccessories" }, { name: "Redirecionar Amostra", model: "redirectSample" }, { name: "Data Entregue Ornamentos", model: "decorationsSentAt" }, { name: "Data Recebida Ornamentos", model: "decorationsReceivedAt" }, { name: "Expedir", model: "markedShipped" }, { name: "Retificar", model: "rectify" }, { name: "Remover Amostras", model: "deleteSamples" }];

        cov_2p0areg3mk.s[4]++;
        this.listFieldsConfigs = [{ name: "Marca", model: "brand" }, { name: "Ref. Cliente", model: "clientReference" }, { name: "Ref. Interna", model: "orderReference" }, { name: "Comercial", model: "brandCommercialName" }, { name: "Encarregada", model: "confectionOverseerName" }, { name: "Malha", model: "hasMesh" }, { name: "Acessórios Confeção", model: "hasConfectionAccessories" }, { name: "Acessórios Embalagem", model: "hasPackingAccessories" }, { name: "Ornamentos", model: "decoration" }, { name: "Acabamento", model: "finishingType" }, { name: "Tipo de amostra", model: "sampleType" }, { name: "Quantidade", model: "quantity" }, { name: "Data pretendida", model: "intendedDate" }, { name: "Data prevista", model: "foreseenDate" }, { name: "Data envio", model: "shippingDate" }, { name: "Estado", model: "currentStateSampleStateType" }, { name: "Observações", model: "observations" }];

        cov_2p0areg3mk.s[5]++;
        this.pdfConfigs = [{ name: "Marca", model: "brand" }, { name: "Ref. Cliente", model: "clientReference" }, { name: "Ref. Interna", model: "orderReference" }, { name: "Comercial", model: "brandCommercialName" }, { name: "Malha", model: "hasMesh" }, { name: "Acessórios Confeção", model: "hasConfectionAccessories" }, { name: "Acessórios Embalagem", model: "hasPackingAccessories" }, { name: "Ornamentos", model: "decoration" }, { name: "Acabamento", model: "finishingType" }, { name: "Tipo de amostra", model: "sampleType" }, { name: "Quantidade", model: "quantity" }, { name: "Data pretendida", model: "intendedDate" }, { name: "Data prevista", model: "foreseenDate" }, { name: "Estado Amostra", model: "currentStateSampleStateType" }, { name: "Observações", model: "observations" }];
    }

    _createClass(WorkspaceEditorController, [{
        key: "optionIcon",
        value: function optionIcon(option) {
            cov_2p0areg3mk.f[1]++;
            cov_2p0areg3mk.s[6]++;

            return option ? (cov_2p0areg3mk.b[0][0]++, 'arrow_drop_down') : (cov_2p0areg3mk.b[0][1]++, 'arrow_right');
        }
    }, {
        key: "close",
        value: function close() {
            cov_2p0areg3mk.f[2]++;
            cov_2p0areg3mk.s[7]++;

            this.$mdBottomSheet.hide();
        }
    }]);

    return WorkspaceEditorController;
}();

var SamplesListViewController = function () {
    function SamplesListViewController($element, SamplesTable, $mdDialog, $state, $stateParams, $location, $window, SampleStatesTable, $interval, AppSettings, OverviewsTable, $rootScope, $mdBottomSheet, sampleRouting, $mdToast, ForeseenDateChangesTable) {
        var _this = this;

        _classCallCheck(this, SamplesListViewController);

        cov_2p0areg3mk.f[3]++;
        cov_2p0areg3mk.s[8]++;

        this.$element = $element;
        cov_2p0areg3mk.s[9]++;
        this.$state = $state;
        cov_2p0areg3mk.s[10]++;
        this.$location = $location;
        cov_2p0areg3mk.s[11]++;
        this.$stateParams = $stateParams;
        cov_2p0areg3mk.s[12]++;
        this.SamplesTable = SamplesTable;
        cov_2p0areg3mk.s[13]++;
        this.SampleStatesTable = SampleStatesTable;
        cov_2p0areg3mk.s[14]++;
        this.ForeseenDateChangesTable = ForeseenDateChangesTable;
        cov_2p0areg3mk.s[15]++;
        this.$mdDialog = $mdDialog;
        cov_2p0areg3mk.s[16]++;
        this.filters = {};
        cov_2p0areg3mk.s[17]++;
        this.$window = $window;
        cov_2p0areg3mk.s[18]++;
        this.AppSettings = AppSettings;
        cov_2p0areg3mk.s[19]++;
        this.OverviewsTable = OverviewsTable;
        cov_2p0areg3mk.s[20]++;
        this.$rootScope = $rootScope;
        cov_2p0areg3mk.s[21]++;
        this.$mdBottomSheet = $mdBottomSheet;
        cov_2p0areg3mk.s[22]++;
        this.sampleRouting = sampleRouting;
        cov_2p0areg3mk.s[23]++;
        this.$mdToast = $mdToast;

        cov_2p0areg3mk.s[24]++;
        this.hasBulkActionPermission = this.hasBulkActionPermission.bind(this);
        cov_2p0areg3mk.s[25]++;
        this.checkWorkItem = this.checkWorkItem.bind(this);

        cov_2p0areg3mk.s[26]++;
        $interval(function () {
            cov_2p0areg3mk.f[4]++;
            cov_2p0areg3mk.s[27]++;
            return _this._autoRefreshSamples();
        }, 5 * 60 * 1000);
    }

    _createClass(SamplesListViewController, [{
        key: "$onInit",
        value: function $onInit() {
            cov_2p0areg3mk.f[5]++;
            cov_2p0areg3mk.s[28]++;

            this.filters = this.$stateParams;
            cov_2p0areg3mk.s[29]++;
            this.disabledFilters = [];

            // loading filter state from location
            cov_2p0areg3mk.s[30]++;
            this.refSearch = this.$stateParams.search;
            cov_2p0areg3mk.s[31]++;
            this.brandFilter = this.$stateParams.brand;
            cov_2p0areg3mk.s[32]++;
            this.commercialFilter = this.$stateParams.brand__commercial;
            cov_2p0areg3mk.s[33]++;
            this.stylistFilter = this.$stateParams.brand__stylist_user;
            cov_2p0areg3mk.s[34]++;
            this.accessoriesCommercialFilter = this.$stateParams.brand__accessories_commercial_user;
            cov_2p0areg3mk.s[35]++;
            this.sampleState = this.$stateParams.current_state__sample_state_type;
            cov_2p0areg3mk.s[36]++;
            this.fromDate = this.$stateParams.foreseen_date__gte;
            cov_2p0areg3mk.s[37]++;
            this.untilDate = this.$stateParams.foreseen_date__lte;
            cov_2p0areg3mk.s[38]++;
            this.foreseenDateNull = this.$stateParams.foreseen_date__isnull;
            cov_2p0areg3mk.s[39]++;
            this.fromIntendedDate = this.$stateParams.intended_date__gte;
            cov_2p0areg3mk.s[40]++;
            this.untilIntendedDate = this.$stateParams.intended_date__lte;
            cov_2p0areg3mk.s[41]++;
            this.fromShippingDate = this.$stateParams.shipping_date__gte;
            cov_2p0areg3mk.s[42]++;
            this.untilShippingDate = this.$stateParams.shipping_date__lte;
            cov_2p0areg3mk.s[43]++;
            this.hasMeshFilter = this.$stateParams.has_mesh;
            cov_2p0areg3mk.s[44]++;
            this.seasonFilter = this.$stateParams.season;
            cov_2p0areg3mk.s[45]++;
            this.workflowState = this.$stateParams.workflow_state;
            cov_2p0areg3mk.s[46]++;
            this.sampleType = this.$stateParams.sample_type__in ? (cov_2p0areg3mk.b[1][0]++, this.$stateParams.sample_type__in.split(',')) : (cov_2p0areg3mk.b[1][1]++, null);
            cov_2p0areg3mk.s[47]++;
            this.decorationTypes = this.$stateParams.decoration__in ? (cov_2p0areg3mk.b[2][0]++, this.$stateParams.decoration__in.split(',')) : (cov_2p0areg3mk.b[2][1]++, null);
            cov_2p0areg3mk.s[48]++;
            this.finishingTypes = this.$stateParams.finishing__in ? (cov_2p0areg3mk.b[3][0]++, this.$stateParams.finishing__in.split(',')) : (cov_2p0areg3mk.b[3][1]++, null);
            cov_2p0areg3mk.s[49]++;
            this.viewType = (cov_2p0areg3mk.b[4][0]++, this.$stateParams.viewType) || (cov_2p0areg3mk.b[4][1]++, this.$rootScope.me.workspace.viewType) || (cov_2p0areg3mk.b[4][2]++, 'GRID');
            cov_2p0areg3mk.s[50]++;
            this.fastTrackFilter = this.$stateParams.fast_track;
            cov_2p0areg3mk.s[51]++;
            this.meshTypeFilter = this.$stateParams.mesh_type;
            cov_2p0areg3mk.s[52]++;
            this.decorationPositionFilter = this.$stateParams.decoration_position;
            cov_2p0areg3mk.s[53]++;
            this.confectionOverseerFilter = this.$stateParams.confection_overseer;

            // loads my work filter configuration from workspace
            // and overrides value if location param differs
            cov_2p0areg3mk.s[54]++;
            if (angular.isDefined(this.$rootScope.me.workspace.myWork)) {
                cov_2p0areg3mk.b[5][0]++;
                cov_2p0areg3mk.s[55]++;

                if (angular.isUndefined(this.$stateParams.myWork)) {
                    cov_2p0areg3mk.b[6][0]++;
                    cov_2p0areg3mk.s[56]++;

                    this.myWork = this.$rootScope.me.workspace.myWork;
                } else {
                    cov_2p0areg3mk.b[6][1]++;
                    cov_2p0areg3mk.s[57]++;

                    this.myWork = !(this.$stateParams.myWork === 'false');
                }
            } else {
                cov_2p0areg3mk.b[5][1]++;
            }

            cov_2p0areg3mk.s[58]++;
            this.routingRules = this.sampleRouting(this.$rootScope.me);
            cov_2p0areg3mk.s[59]++;
            this._refreshSamples();
        }
    }, {
        key: "_autoRefreshSamples",
        value: function _autoRefreshSamples() {
            cov_2p0areg3mk.f[6]++;
            cov_2p0areg3mk.s[60]++;

            if ((cov_2p0areg3mk.b[8][0]++, this.samples.$selected.length) || (cov_2p0areg3mk.b[8][1]++, this.samples.filter(function (sample) {
                cov_2p0areg3mk.f[7]++;
                cov_2p0areg3mk.s[61]++;
                return sample.$notesActive;
            }).length > 0)) {
                cov_2p0areg3mk.b[7][0]++;
                cov_2p0areg3mk.s[62]++;

                return;
            } else {
                cov_2p0areg3mk.b[7][1]++;
            }
            cov_2p0areg3mk.s[63]++;
            this._refreshSamples();
        }
    }, {
        key: "allSelector",
        value: function allSelector() {
            cov_2p0areg3mk.f[8]++;
            cov_2p0areg3mk.s[64]++;

            if (this.samples.length === this.samples.$selected.length) {
                cov_2p0areg3mk.b[9][0]++;
                cov_2p0areg3mk.s[65]++;

                /* eslint-disable no-return-assign */
                this.samples.forEach(function (sample) {
                    cov_2p0areg3mk.f[9]++;
                    cov_2p0areg3mk.s[66]++;
                    return sample.$isSelected = false;
                });
            } else {
                cov_2p0areg3mk.b[9][1]++;
                cov_2p0areg3mk.s[67]++;

                this.samples.forEach(function (sample) {
                    cov_2p0areg3mk.f[10]++;
                    cov_2p0areg3mk.s[68]++;
                    return sample.$isSelected = true;
                });
            }
        }
    }, {
        key: "changeViewType",
        value: function changeViewType(type) {
            cov_2p0areg3mk.f[11]++;
            cov_2p0areg3mk.s[69]++;

            if (!type) {
                cov_2p0areg3mk.b[10][0]++;
                cov_2p0areg3mk.s[70]++;

                type = this.viewType === 'LIST' ? (cov_2p0areg3mk.b[11][0]++, 'GRID') : (cov_2p0areg3mk.b[11][1]++, 'LIST');
            } else {
                cov_2p0areg3mk.b[10][1]++;
            }

            cov_2p0areg3mk.s[71]++;
            this.$location.search('viewType', type);

            cov_2p0areg3mk.s[72]++;
            this.viewType = type;
        }
    }, {
        key: "changeFilters",
        value: function changeFilters(filter, value) {
            cov_2p0areg3mk.f[12]++;
            cov_2p0areg3mk.s[73]++;

            if (angular.isArray(value)) {
                cov_2p0areg3mk.b[12][0]++;
                cov_2p0areg3mk.s[74]++;

                value = value.join(',');
            } else {
                cov_2p0areg3mk.b[12][1]++;
            }

            cov_2p0areg3mk.s[75]++;
            if (value === null) {
                cov_2p0areg3mk.b[13][0]++;
                cov_2p0areg3mk.s[76]++;

                delete this.filters[filter];
                cov_2p0areg3mk.s[77]++;
                delete this.samples.$params[filter];
            } else {
                cov_2p0areg3mk.b[13][1]++;
                cov_2p0areg3mk.s[78]++;

                this.filters[filter] = value;
            }

            cov_2p0areg3mk.s[79]++;
            this.samples.$page = 1;
            cov_2p0areg3mk.s[80]++;
            this.$location.search('page', 1);
            cov_2p0areg3mk.s[81]++;
            this.$location.search(filter, value);
            cov_2p0areg3mk.s[82]++;
            this._refreshSamples();
        }
    }, {
        key: "_refreshSamples",
        value: function _refreshSamples() {
            cov_2p0areg3mk.f[13]++;
            cov_2p0areg3mk.s[83]++;

            // do not show virtual samples in planning view
            /* eslint-disable camelcase */
            this.filters.is_virtual = false;

            cov_2p0areg3mk.s[84]++;
            if ((cov_2p0areg3mk.b[15][0]++, this.filters.workflow_state) && (cov_2p0areg3mk.b[15][1]++, this.$rootScope.FINAL_STATES.includes(parseInt(this.filters.workflow_state, 10)))) {
                cov_2p0areg3mk.b[14][0]++;
                cov_2p0areg3mk.s[85]++;

                this.filters.is_archived = true;
            } else {
                cov_2p0areg3mk.b[14][1]++;
                cov_2p0areg3mk.s[86]++;

                this.filters.is_archived = false;
            }

            cov_2p0areg3mk.s[87]++;
            this.filters.page_size = this.$rootScope.me.workspace.samplesPerPage;

            cov_2p0areg3mk.s[88]++;
            if (this.myWork) {
                cov_2p0areg3mk.b[16][0]++;
                cov_2p0areg3mk.s[89]++;

                this.applyWorkFilters();
            } else {
                cov_2p0areg3mk.b[16][1]++;
            }

            cov_2p0areg3mk.s[90]++;
            this.samples.$refresh(this.filters);
        }
    }, {
        key: "selectAllSampleTypes",
        value: function selectAllSampleTypes(all) {
            cov_2p0areg3mk.f[14]++;
            cov_2p0areg3mk.s[91]++;

            this.sampleType = all.map(function (type) {
                cov_2p0areg3mk.f[15]++;
                cov_2p0areg3mk.s[92]++;
                return type.id;
            });
            cov_2p0areg3mk.s[93]++;
            this.changeFilters('sample_type__in', this.sampleType);
        }
    }, {
        key: "getSelectedText",
        value: function getSelectedText() {
            cov_2p0areg3mk.f[16]++;

            /* eslint-disable prefer-template */
            var size = (cov_2p0areg3mk.s[94]++, this.sampleType ? (cov_2p0areg3mk.b[17][0]++, this.sampleType.length + ' tipos selecionados') : (cov_2p0areg3mk.b[17][1]++, 'Tipo de amostras'));
            cov_2p0areg3mk.s[95]++;
            return size;
        }
    }, {
        key: "overview",
        value: function overview() {
            var _this2 = this;

            cov_2p0areg3mk.f[17]++;

            var selectedIds = (cov_2p0areg3mk.s[96]++, this.samples.$selected.map(function (sample) {
                cov_2p0areg3mk.f[18]++;
                cov_2p0areg3mk.s[97]++;
                return sample.id;
            }));

            cov_2p0areg3mk.s[98]++;
            this.exporting = this.OverviewsTable.$create({
                ids: selectedIds.join(','),
                type: this.viewType === 'GRID' ? (cov_2p0areg3mk.b[18][0]++, 'MATRIX') : (cov_2p0areg3mk.b[18][1]++, 'LIST'),
                ordering: this.samples.$params.ordering
            }).$then(function (resp) {
                cov_2p0areg3mk.f[19]++;
                cov_2p0areg3mk.s[99]++;

                /* eslint-disable angular/window-service */
                window.open(_this2.AppSettings.mediaUrl + '/' + resp.file, 'overview-export');
            });
        }
    }, {
        key: "bulkChange",
        value: function bulkChange(field, value) {
            var _this3 = this;

            var clear_field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_2p0areg3mk.b[19][0]++, false);
            cov_2p0areg3mk.f[20]++;

            var errors = (cov_2p0areg3mk.s[100]++, []);
            var size = (cov_2p0areg3mk.s[101]++, this.samples.$selected.length);
            var finished = (cov_2p0areg3mk.s[102]++, 0);
            cov_2p0areg3mk.s[103]++;
            angular.forEach(this.samples.$selected, function (sample) {
                cov_2p0areg3mk.f[21]++;
                cov_2p0areg3mk.s[104]++;

                sample[field] = value;
                cov_2p0areg3mk.s[105]++;
                sample.$save([field]).$then(function (sample) {
                    cov_2p0areg3mk.f[22]++;
                    cov_2p0areg3mk.s[106]++;

                    sample.$isSelected = false;
                    cov_2p0areg3mk.s[107]++;
                    finished++;
                    cov_2p0areg3mk.s[108]++;
                    _this3._finishBulk(size, finished, errors);
                    cov_2p0areg3mk.s[109]++;
                    if (clear_field) {
                        cov_2p0areg3mk.b[20][0]++;
                        cov_2p0areg3mk.s[110]++;

                        _this3[clear_field] = null;
                    } else {
                        cov_2p0areg3mk.b[20][1]++;
                    }
                }, function (sample) {
                    cov_2p0areg3mk.f[23]++;
                    cov_2p0areg3mk.s[111]++;

                    errors.push('Amostra <b>#' + sample.orderReference + '</b>: <small>' + sample.$response.data.detail + '</small>');
                    cov_2p0areg3mk.s[112]++;
                    finished++;
                    cov_2p0areg3mk.s[113]++;
                    _this3._finishBulk(size, finished, errors);
                });
            });
        }
    }, {
        key: "clearForeseenDateData",
        value: function clearForeseenDateData() {
            cov_2p0areg3mk.f[24]++;
            cov_2p0areg3mk.s[114]++;

            this.bulkForeseenDate = undefined;
            cov_2p0areg3mk.s[115]++;
            this.bulkReasonCategory = undefined;
            cov_2p0areg3mk.s[116]++;
            this.bulkReason = undefined;
            cov_2p0areg3mk.s[117]++;
            this.bulkReasonText = undefined;
            cov_2p0areg3mk.s[118]++;
            this.askForJustification = undefined;
        }
    }, {
        key: "bulkChangeForeseenDate",
        value: function bulkChangeForeseenDate(toDate, reasonCategory, reason, reasonText) {
            var _this4 = this;

            cov_2p0areg3mk.f[25]++;

            var errors = (cov_2p0areg3mk.s[119]++, []);
            var size = (cov_2p0areg3mk.s[120]++, this.samples.$selected.length);
            var finished = (cov_2p0areg3mk.s[121]++, 0);
            cov_2p0areg3mk.s[122]++;
            angular.forEach(this.samples.$selected, function (sample) {
                cov_2p0areg3mk.f[26]++;
                cov_2p0areg3mk.s[123]++;

                _this4.ForeseenDateChangesTable.$create({
                    sample: sample.id, toDate: toDate, reasonCategory: reasonCategory, reason: reason, reasonText: reasonText
                }).$then(function () {
                    cov_2p0areg3mk.f[27]++;
                    cov_2p0areg3mk.s[124]++;

                    sample.$isSelected = false;
                    cov_2p0areg3mk.s[125]++;
                    sample.$refresh();
                    cov_2p0areg3mk.s[126]++;
                    finished++;
                    cov_2p0areg3mk.s[127]++;
                    _this4._finishBulk(size, finished, errors);
                    cov_2p0areg3mk.s[128]++;
                    _this4.clearForeseenDateData();
                }, function (response) {
                    cov_2p0areg3mk.f[28]++;
                    cov_2p0areg3mk.s[129]++;

                    errors.push('Amostra <b>#' + sample.orderReference + '</b>: <small>' + response.$response.data.detail + '</small>');
                    cov_2p0areg3mk.s[130]++;
                    finished++;
                    cov_2p0areg3mk.s[131]++;
                    _this4._finishBulk(size, finished, errors);
                    cov_2p0areg3mk.s[132]++;
                    _this4.clearForeseenDateData();
                });
            });
        }
    }, {
        key: "beforeOpen",
        value: function beforeOpen(event, menu) {
            cov_2p0areg3mk.f[29]++;
            cov_2p0areg3mk.s[133]++;

            if (this.$rootScope.FORESEEN_UPDATED.value === "1") {
                cov_2p0areg3mk.b[21][0]++;

                var results = (cov_2p0areg3mk.s[134]++, this.samples.$selected.filter(function (sample) {
                    cov_2p0areg3mk.f[30]++;
                    cov_2p0areg3mk.s[135]++;
                    return sample.firstForeseenDate;
                }));
                cov_2p0areg3mk.s[136]++;
                this.askForJustification = results.length > 0;
            } else {
                cov_2p0areg3mk.b[21][1]++;
                cov_2p0areg3mk.s[137]++;

                this.askForJustification = false;
            }
            cov_2p0areg3mk.s[138]++;
            menu.open(event);
        }
    }, {
        key: "bulkChangeState",
        value: function bulkChangeState(stateType) {
            var _this5 = this;

            cov_2p0areg3mk.f[31]++;

            var confirm = (cov_2p0areg3mk.s[139]++, this.$mdDialog.confirm().title('Tem certeza que deseja alterar o estado de ' + this.samples.$selected.length + ' amostras?').textContent('Essa ação é irreversível!').ok('Alterar ' + this.samples.$selected.length + ' amostras').cancel('Cancelar'));

            cov_2p0areg3mk.s[140]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_2p0areg3mk.f[32]++;

                var errors = (cov_2p0areg3mk.s[141]++, []);
                var size = (cov_2p0areg3mk.s[142]++, _this5.samples.$selected.length);
                var finished = (cov_2p0areg3mk.s[143]++, 0);
                cov_2p0areg3mk.s[144]++;
                angular.forEach(_this5.samples.$selected, function (sample) {
                    cov_2p0areg3mk.f[33]++;
                    cov_2p0areg3mk.s[145]++;

                    _this5.SampleStatesTable.$create({
                        sample: sample.id,
                        sample_state_type: stateType
                    }).$then(function () {
                        cov_2p0areg3mk.f[34]++;
                        cov_2p0areg3mk.s[146]++;

                        sample.$fetch().$then(function (sample) {
                            cov_2p0areg3mk.f[35]++;
                            cov_2p0areg3mk.s[147]++;

                            sample.$currentState = null;
                        });
                        cov_2p0areg3mk.s[148]++;
                        sample.$isSelected = false;
                        cov_2p0areg3mk.s[149]++;
                        finished++;
                        cov_2p0areg3mk.s[150]++;
                        _this5._finishBulk(size, finished, errors);
                        cov_2p0areg3mk.s[151]++;
                        if (finished === size) {
                            cov_2p0areg3mk.b[22][0]++;
                            cov_2p0areg3mk.s[152]++;

                            _this5._refreshSamples();
                        } else {
                            cov_2p0areg3mk.b[22][1]++;
                        }
                    }, function (sample) {
                        cov_2p0areg3mk.f[36]++;

                        var ref = (cov_2p0areg3mk.s[153]++, sample.orderReference ? (cov_2p0areg3mk.b[23][0]++, '#' + sample.orderReference) : (cov_2p0areg3mk.b[23][1]++, '(Sem referência)'));
                        cov_2p0areg3mk.s[154]++;
                        errors.push('Amostra <b>' + ref + '</b>: <small>' + sample.$response.data.detail + '</small>');
                        cov_2p0areg3mk.s[155]++;
                        finished++;
                        cov_2p0areg3mk.s[156]++;
                        _this5._finishBulk(size, finished, errors);
                        cov_2p0areg3mk.s[157]++;
                        if (finished === size) {
                            cov_2p0areg3mk.b[24][0]++;
                            cov_2p0areg3mk.s[158]++;

                            _this5._refreshSamples();
                        } else {
                            cov_2p0areg3mk.b[24][1]++;
                        }
                    });
                });
            });
        }
    }, {
        key: "includesWorking",
        value: function includesWorking() {
            var _this6 = this;

            cov_2p0areg3mk.f[37]++;

            var working = (cov_2p0areg3mk.s[159]++, this.samples.$selected.filter(function (sample) {
                cov_2p0areg3mk.f[38]++;
                cov_2p0areg3mk.s[160]++;
                return sample.$currentState.$stateType.id !== _this6.$rootScope.WAITING_STATE;
            }));
            cov_2p0areg3mk.s[161]++;
            return working.length;
        }
    }, {
        key: "showCannotCheckout",
        value: function showCannotCheckout(message) {
            cov_2p0areg3mk.f[39]++;

            var confirm = (cov_2p0areg3mk.s[162]++, this.$mdDialog.confirm().title('Erro!!!').textContent(message).ok('Ok'));
            cov_2p0areg3mk.s[163]++;
            this.$mdDialog.show(confirm);
        }
    }, {
        key: "bulkRedirect",
        value: function bulkRedirect() {
            var _this7 = this;

            cov_2p0areg3mk.f[40]++;

            var working = (cov_2p0areg3mk.s[164]++, this.includesWorking());
            cov_2p0areg3mk.s[165]++;
            if (working) {
                cov_2p0areg3mk.b[25][0]++;

                var message = (cov_2p0areg3mk.s[166]++, 'Não é possível expedir amostras que estejam a ser trabalhadas.');
                cov_2p0areg3mk.s[167]++;
                this.showCannotCheckout(message);
            } else {
                cov_2p0areg3mk.b[25][1]++;
                cov_2p0areg3mk.s[168]++;

                angular.forEach(this.samples.$selected, function (sample) {
                    cov_2p0areg3mk.f[41]++;
                    cov_2p0areg3mk.s[169]++;

                    sample.$currentState.observations = _this7.redirectObservations;
                    cov_2p0areg3mk.s[170]++;
                    sample.$currentState.$save().$then(function () {
                        cov_2p0areg3mk.f[42]++;
                        cov_2p0areg3mk.s[171]++;
                        return _this7.checkWorkItem(sample, _this7.$rootScope.WAITING_STATE, _this7.redirectRouted);
                    });
                });
            }
        }
    }, {
        key: "bulkShip",
        value: function bulkShip() {
            var _this8 = this;

            cov_2p0areg3mk.f[43]++;

            var working = (cov_2p0areg3mk.s[172]++, this.includesWorking());
            cov_2p0areg3mk.s[173]++;
            if (working) {
                cov_2p0areg3mk.b[26][0]++;

                var message = (cov_2p0areg3mk.s[174]++, 'Não é possível expedir amostras que estejam a ser trabalhadas.');
                cov_2p0areg3mk.s[175]++;
                this.showCannotCheckout(message);
            } else {
                cov_2p0areg3mk.b[26][1]++;
                cov_2p0areg3mk.s[176]++;

                angular.forEach(this.samples.$selected, function (sample) {
                    cov_2p0areg3mk.f[44]++;
                    cov_2p0areg3mk.s[177]++;

                    _this8.checkWorkItem(sample, _this8.$rootScope.SHIPPED_STATE);
                });
            }
        }
    }, {
        key: "bulkTypifiedRouting",
        value: function bulkTypifiedRouting(name) {
            var _this9 = this;

            cov_2p0areg3mk.f[45]++;

            var typifiedRouting = (cov_2p0areg3mk.s[178]++, this.$rootScope.typifiedRoutings.filter(function (item) {
                cov_2p0areg3mk.f[46]++;
                cov_2p0areg3mk.s[179]++;
                return item.name === name;
            }));
            cov_2p0areg3mk.s[180]++;
            angular.forEach(this.samples.$selected, function (sample) {
                cov_2p0areg3mk.f[47]++;
                cov_2p0areg3mk.s[181]++;

                _this9.checkWorkItem(sample, _this9.$rootScope.WAITING_STATE, typifiedRouting[0].stateTo, typifiedRouting[0].id);
            });
        }
    }, {
        key: "_finishBulk",
        value: function _finishBulk(size, finished) {
            var errors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_2p0areg3mk.b[27][0]++, []);
            var successMsg = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (cov_2p0areg3mk.b[28][0]++, 'Alterações guardadas');
            cov_2p0areg3mk.f[48]++;
            cov_2p0areg3mk.s[182]++;

            if (size !== finished) {
                cov_2p0areg3mk.b[29][0]++;
                cov_2p0areg3mk.s[183]++;

                return;
            } else {
                cov_2p0areg3mk.b[29][1]++;
            }
            var title = (cov_2p0areg3mk.s[184]++, errors.length ? (cov_2p0areg3mk.b[30][0]++, 'Algumas alterações não foram guardadas') : (cov_2p0areg3mk.b[30][1]++, successMsg));
            cov_2p0areg3mk.s[185]++;
            this.$mdDialog.show(this.$mdDialog.alert().clickOutsideToClose(true).title(title).htmlContent(errors.join('<br />')).ariaLabel('Alert Dialog Demo').ok('Ok'));

            cov_2p0areg3mk.s[186]++;
            this.$rootScope.$broadcast('bulk_completed');
        }
    }, {
        key: "deleteSelected",
        value: function deleteSelected() {
            var _this10 = this;

            cov_2p0areg3mk.f[49]++;

            var confirm = (cov_2p0areg3mk.s[187]++, this.$mdDialog.confirm().title('Tem certeza que deseja eliminar ' + this.samples.$selected.length + ' amostras?').textContent('Essa ação é irreversível. Todos os dados relativo as amostras serão eliminados!').ok('Eliminar ' + this.samples.$selected.length + ' amostras').cancel('Cancelar'));

            cov_2p0areg3mk.s[188]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_2p0areg3mk.f[50]++;

                var errors = (cov_2p0areg3mk.s[189]++, []);
                var size = (cov_2p0areg3mk.s[190]++, _this10.samples.$selected.length);
                var finished = (cov_2p0areg3mk.s[191]++, 0);
                cov_2p0areg3mk.s[192]++;
                angular.forEach(_this10.samples.$selected, function (sample) {
                    cov_2p0areg3mk.f[51]++;
                    cov_2p0areg3mk.s[193]++;

                    sample.$destroy().$then(function (sample) {
                        cov_2p0areg3mk.f[52]++;
                        cov_2p0areg3mk.s[194]++;

                        sample.$isSelected = false;
                        cov_2p0areg3mk.s[195]++;
                        finished++;
                        cov_2p0areg3mk.s[196]++;
                        _this10._finishBulk(size, finished, errors, 'Amostras removidas');
                    }, function (sample) {
                        cov_2p0areg3mk.f[53]++;
                        cov_2p0areg3mk.s[197]++;

                        errors.push('Amostra <b>#' + sample.orderReference + '</b>: <small>' + sample.$response.data.detail + '</small>');
                        cov_2p0areg3mk.s[198]++;
                        finished++;
                        cov_2p0areg3mk.s[199]++;
                        _this10._finishBulk(size, finished, errors, 'Amostras removidas');
                    });
                });
            });
        }
    }, {
        key: "displayWorkspaceEditor",
        value: function displayWorkspaceEditor() {
            var _this11 = this;

            cov_2p0areg3mk.f[54]++;
            cov_2p0areg3mk.s[200]++;

            this.$mdBottomSheet.show({
                templateUrl: 'app/components/samplesListView/workspaceEditor.html',
                /** @ngInject */
                controller: WorkspaceEditorController,
                controllerAs: '$ctrl',
                clickOutsideToClose: false,
                escapeToClose: false,
                bindToController: true,
                locals: {
                    checkBulkActionPermission: this.hasBulkActionPermission
                }
            }).then(function () {
                cov_2p0areg3mk.f[55]++;
                cov_2p0areg3mk.s[201]++;

                _this11._refreshSamples();
                cov_2p0areg3mk.s[202]++;
                _this11.$rootScope.me.workspace.version = '2.0.0';
                cov_2p0areg3mk.s[203]++;
                _this11.$rootScope.me.$save(['workspace']);
            }).catch(function () {
                // User clicked outside or hit escape

                cov_2p0areg3mk.f[56]++;
            });
        }
    }, {
        key: "showAllStates",
        value: function showAllStates() {
            cov_2p0areg3mk.f[57]++;
            cov_2p0areg3mk.s[204]++;

            this.changeFilters('current_state__sample_state_type', 'all');
            cov_2p0areg3mk.s[205]++;
            this.sampleState = 'all';
        }
    }, {
        key: "hasBulkActionPermission",
        value: function hasBulkActionPermission(action) {
            cov_2p0areg3mk.f[58]++;

            var bulkActions = (cov_2p0areg3mk.s[206]++, this.$rootScope.planBulkActions);

            cov_2p0areg3mk.s[207]++;
            if (this.$rootScope.me.isSupervisor) {
                cov_2p0areg3mk.b[31][0]++;
                cov_2p0areg3mk.s[208]++;

                return true;
            } else {
                cov_2p0areg3mk.b[31][1]++;
            }

            cov_2p0areg3mk.s[209]++;
            if (bulkActions.hasOwnProperty(action)) {
                cov_2p0areg3mk.b[32][0]++;
                cov_2p0areg3mk.s[210]++;

                bulkActions = bulkActions[action];

                cov_2p0areg3mk.s[211]++;
                if (bulkActions.indexOf(this.$rootScope.me.type) !== -1) {
                    cov_2p0areg3mk.b[33][0]++;
                    cov_2p0areg3mk.s[212]++;

                    return true;
                } else {
                    cov_2p0areg3mk.b[33][1]++;
                }
            } else {
                cov_2p0areg3mk.b[32][1]++;
            }
            cov_2p0areg3mk.s[213]++;
            return false;
        }
    }, {
        key: "getWorkFilters",
        value: function getWorkFilters() {
            cov_2p0areg3mk.f[59]++;

            var userType = (cov_2p0areg3mk.s[214]++, this.$rootScope.me.type);
            var settings = (cov_2p0areg3mk.s[215]++, {
                COMMERCIAL: [{
                    filter: 'commercialFilter',
                    field: 'brand__commercial',
                    value: this.$rootScope.me.id,
                    clear: null
                }],
                CAD: [{
                    filter: 'workflowState',
                    field: 'workflow_state',
                    value: 2,
                    clear: null
                }, {
                    filter: 'stylistFilter',
                    field: 'brand__stylist_user',
                    value: this.$rootScope.me.id,
                    clear: null
                }],
                CUTTING: [{
                    filter: 'workflowState',
                    field: 'workflow_state',
                    value: 3,
                    clear: null
                }],
                CONFECTION: [{
                    filter: 'confectionOverseerFilter',
                    field: 'confection_overseer',
                    value: this.$rootScope.me.id,
                    clear: null
                }, {
                    filter: 'workflowState',
                    field: 'workflow_state',
                    value: 4,
                    clear: null
                }],
                ACCESSORIES: [{
                    filter: 'accessoriesCommercialFilter',
                    field: 'brand__accessories_commercial_user',
                    value: this.$rootScope.me.id,
                    clear: null
                }],
                CONTROL: [{
                    filter: 'workflowState',
                    field: 'workflow_state',
                    value: 7,
                    clear: null
                }],
                FINISHINGS: [{
                    filter: 'workflowState',
                    field: 'workflow_state',
                    value: 6,
                    clear: null
                }],
                ORNAMENTS: [{
                    filter: 'decorationTypes',
                    field: 'decoration__in',
                    value: "1,2,3",
                    clear: null
                }],
                WAREHOUSE: [{
                    filter: 'hasMeshFilter',
                    field: 'has_mesh',
                    value: "false",
                    clear: null
                }]

            });
            cov_2p0areg3mk.s[216]++;
            return settings[userType];
        }
    }, {
        key: "applyWorkFilters",
        value: function applyWorkFilters() {
            var _this12 = this;

            cov_2p0areg3mk.f[60]++;

            var workFilters = (cov_2p0areg3mk.s[217]++, this.getWorkFilters());

            cov_2p0areg3mk.s[218]++;
            workFilters.forEach(function (obj) {
                cov_2p0areg3mk.f[61]++;
                cov_2p0areg3mk.s[219]++;

                _this12[obj.filter] = obj.value;
                cov_2p0areg3mk.s[220]++;
                _this12.filters[obj.field] = obj.value;
                cov_2p0areg3mk.s[221]++;
                _this12.disabledFilters.push(obj.filter);
            });
        }
    }, {
        key: "clearMultipleFilters",
        value: function clearMultipleFilters() {
            var _this13 = this;

            cov_2p0areg3mk.f[62]++;

            var data = (cov_2p0areg3mk.s[222]++, this.getWorkFilters());

            cov_2p0areg3mk.s[223]++;
            this.disabledFilters = [];

            cov_2p0areg3mk.s[224]++;
            data.forEach(function (obj) {
                cov_2p0areg3mk.f[63]++;
                cov_2p0areg3mk.s[225]++;

                _this13[obj.filter] = obj.clear;
                cov_2p0areg3mk.s[226]++;
                _this13.filters[obj.field] = obj.clear;
            });
        }
    }, {
        key: "isFilterDisabled",
        value: function isFilterDisabled(filter) {
            cov_2p0areg3mk.f[64]++;
            cov_2p0areg3mk.s[227]++;

            return this.disabledFilters.includes(filter);
        }
    }, {
        key: "toogleMyWorkFilters",
        value: function toogleMyWorkFilters() {
            cov_2p0areg3mk.f[65]++;
            cov_2p0areg3mk.s[228]++;

            this.myWork = !this.myWork;
            cov_2p0areg3mk.s[229]++;
            if (this.myWork) {
                cov_2p0areg3mk.b[34][0]++;
                cov_2p0areg3mk.s[230]++;

                this.$location.search('myWork', true);
            } else {
                cov_2p0areg3mk.b[34][1]++;
                cov_2p0areg3mk.s[231]++;

                this.$location.search('myWork', false);
                cov_2p0areg3mk.s[232]++;
                this.clearMultipleFilters();
            }

            cov_2p0areg3mk.s[233]++;
            this._refreshSamples();
        }
    }, {
        key: "checkWorkItem",
        value: function checkWorkItem(sample, sample_state_type) {
            var _this14 = this;

            var routed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_2p0areg3mk.b[35][0]++, null);
            var typified = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (cov_2p0areg3mk.b[36][0]++, null);
            cov_2p0areg3mk.f[66]++;
            cov_2p0areg3mk.s[234]++;

            return this.SampleStatesTable.$create({
                sample: sample.id,
                sample_state_type: sample_state_type,
                routed: routed,
                typified: typified
            }).$then(function () {
                cov_2p0areg3mk.f[67]++;
                cov_2p0areg3mk.s[235]++;

                _this14._refreshSamples();
            }).$finally(function (error) {
                cov_2p0areg3mk.f[68]++;
                cov_2p0areg3mk.s[236]++;

                if (error.$response.data.non_field_errors) {
                    cov_2p0areg3mk.b[37][0]++;

                    var message = (cov_2p0areg3mk.s[237]++, '');
                    cov_2p0areg3mk.s[238]++;
                    if (error.sample_state_type === 11) {
                        cov_2p0areg3mk.b[38][0]++;
                        cov_2p0areg3mk.s[239]++;

                        message = 'Não é possível parar amostra com o id ' + error.sample + '. Verifique se amostra não está em espera.';
                    } else {
                        cov_2p0areg3mk.b[38][1]++;
                        cov_2p0areg3mk.s[240]++;

                        message = 'Não é possível iniciar amostra com o id ' + error.sample + '. Verifique se amostra não está a ser trabalhada';
                    }
                    var toast = (cov_2p0areg3mk.s[241]++, _this14.$mdToast.simple().textContent(message));
                    cov_2p0areg3mk.s[242]++;
                    _this14.$mdToast.show(toast);
                } else {
                    cov_2p0areg3mk.b[37][1]++;
                }
            });
        }
    }, {
        key: "textReasonRequired",
        value: function textReasonRequired() {
            var _this15 = this;

            cov_2p0areg3mk.f[69]++;
            cov_2p0areg3mk.s[243]++;

            if (!this.bulkReasonCategory) {
                cov_2p0areg3mk.b[39][0]++;
                cov_2p0areg3mk.s[244]++;

                return false;
            } else {
                cov_2p0areg3mk.b[39][1]++;
            }
            var selected = (cov_2p0areg3mk.s[245]++, this.$rootScope.foreseenDateReasonCategories.$response.data.results.find(function (record) {
                cov_2p0areg3mk.f[70]++;
                cov_2p0areg3mk.s[246]++;
                return record.id === parseInt(_this15.bulkReasonCategory, 10);
            }));
            cov_2p0areg3mk.s[247]++;
            return selected.require_reason_text;
        }

        /* return true if conditions are met */

    }, {
        key: "foreseenBulkConditionsMet",
        value: function foreseenBulkConditionsMet() {
            cov_2p0areg3mk.f[71]++;
            cov_2p0areg3mk.s[248]++;

            if (!this.bulkForeseenDate) {
                cov_2p0areg3mk.b[40][0]++;
                cov_2p0areg3mk.s[249]++;

                return false;
            } else {
                cov_2p0areg3mk.b[40][1]++;
            }
            // if a sample already has a first foreseen date
            cov_2p0areg3mk.s[250]++;
            if (this.askForJustification) {
                cov_2p0areg3mk.b[41][0]++;
                cov_2p0areg3mk.s[251]++;

                if (this.textReasonRequired()) {
                    cov_2p0areg3mk.b[42][0]++;
                    cov_2p0areg3mk.s[252]++;

                    return this.bulkReasonText;
                } else {
                    cov_2p0areg3mk.b[42][1]++;
                }
                cov_2p0areg3mk.s[253]++;
                return this.bulkReason;
            } else {
                cov_2p0areg3mk.b[41][1]++;
            }
            cov_2p0areg3mk.s[254]++;
            return true;
        }
    }]);

    return SamplesListViewController;
}();

/* eslint-disable angular/controller-name */


cov_2p0areg3mk.s[255]++;
angular.module('app').component('samplesListView', {
    templateUrl: 'app/components/samplesListView/samplesListView.html',
    controller: SamplesListViewController,
    bindings: {
        samples: '<',
        archivedState: '<'
    }
});