'use strict';

var cov_1jhsuxj791 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/users/usersList/usersList.js',
        hash = 'df49724e1df75a096cca578fa0a712b3a3247b68',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/users/usersList/usersList.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 27
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 27
                }
            },
            '4': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 31
                }
            },
            '5': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 43
                }
            },
            '6': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 35
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 36
                }
            },
            '9': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 26
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 19
                }
            },
            '12': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 33
                }
            },
            '13': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 49
                }
            },
            '14': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 29
                }
            },
            '15': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 22
                }
            },
            '16': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 27
                }
            },
            '17': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 46
                }
            },
            '18': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 9
                }
            },
            '19': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 37
                }
            },
            '21': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 70
                }
            },
            '22': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 43
                }
            },
            '23': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 29
                }
            },
            '24': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 50
                }
            },
            '25': {
                start: {
                    line: 52,
                    column: 22
                },
                end: {
                    line: 52,
                    column: 23
                }
            },
            '26': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 11
                }
            },
            '27': {
                start: {
                    line: 54,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 13
                }
            },
            '28': {
                start: {
                    line: 55,
                    column: 16
                },
                end: {
                    line: 55,
                    column: 38
                }
            },
            '29': {
                start: {
                    line: 56,
                    column: 16
                },
                end: {
                    line: 58,
                    column: 19
                }
            },
            '30': {
                start: {
                    line: 57,
                    column: 20
                },
                end: {
                    line: 57,
                    column: 34
                }
            },
            '31': {
                start: {
                    line: 59,
                    column: 16
                },
                end: {
                    line: 59,
                    column: 29
                }
            },
            '32': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 27
                }
            },
            '33': {
                start: {
                    line: 63,
                    column: 22
                },
                end: {
                    line: 63,
                    column: 40
                }
            },
            '34': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 9
                }
            },
            '35': {
                start: {
                    line: 65,
                    column: 12
                },
                end: {
                    line: 65,
                    column: 49
                }
            },
            '36': {
                start: {
                    line: 67,
                    column: 12
                },
                end: {
                    line: 67,
                    column: 52
                }
            },
            '37': {
                start: {
                    line: 70,
                    column: 19
                },
                end: {
                    line: 70,
                    column: 73
                }
            },
            '38': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 83
                }
            },
            '39': {
                start: {
                    line: 75,
                    column: 22
                },
                end: {
                    line: 75,
                    column: 23
                }
            },
            '40': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 84,
                    column: 11
                }
            },
            '41': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 13
                }
            },
            '42': {
                start: {
                    line: 78,
                    column: 16
                },
                end: {
                    line: 78,
                    column: 37
                }
            },
            '43': {
                start: {
                    line: 79,
                    column: 16
                },
                end: {
                    line: 81,
                    column: 19
                }
            },
            '44': {
                start: {
                    line: 80,
                    column: 20
                },
                end: {
                    line: 80,
                    column: 34
                }
            },
            '45': {
                start: {
                    line: 82,
                    column: 16
                },
                end: {
                    line: 82,
                    column: 29
                }
            },
            '46': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 27
                }
            },
            '47': {
                start: {
                    line: 86,
                    column: 22
                },
                end: {
                    line: 86,
                    column: 40
                }
            },
            '48': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 9
                }
            },
            '49': {
                start: {
                    line: 88,
                    column: 12
                },
                end: {
                    line: 88,
                    column: 46
                }
            },
            '50': {
                start: {
                    line: 90,
                    column: 12
                },
                end: {
                    line: 90,
                    column: 49
                }
            },
            '51': {
                start: {
                    line: 92,
                    column: 19
                },
                end: {
                    line: 92,
                    column: 73
                }
            },
            '52': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 83
                }
            },
            '53': {
                start: {
                    line: 97,
                    column: 0
                },
                end: {
                    line: 105,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 73
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 12,
                        column: 38
                    },
                    end: {
                        line: 12,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 55
                    },
                    end: {
                        line: 15,
                        column: 9
                    }
                },
                line: 12
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 26
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 18
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 13
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 28
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 33
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 41,
                        column: 4
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 17
                    },
                    end: {
                        line: 45,
                        column: 5
                    }
                },
                line: 41
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 47,
                        column: 4
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 21
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 47
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 51,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 22
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 51
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 53,
                        column: 30
                    },
                    end: {
                        line: 53,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 38
                    },
                    end: {
                        line: 61,
                        column: 9
                    }
                },
                line: 53
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 56,
                        column: 47
                    },
                    end: {
                        line: 56,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 55
                    },
                    end: {
                        line: 58,
                        column: 17
                    }
                },
                line: 56
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 20
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                line: 74
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 76,
                        column: 30
                    },
                    end: {
                        line: 76,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 38
                    },
                    end: {
                        line: 84,
                        column: 9
                    }
                },
                line: 76
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 79,
                        column: 47
                    },
                    end: {
                        line: 79,
                        column: 48
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 55
                    },
                    end: {
                        line: 81,
                        column: 17
                    }
                },
                line: 79
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                }, {
                    start: {
                        line: 19,
                        column: 8
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                }],
                line: 19
            },
            '1': {
                loc: {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }, {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }],
                line: 34
            },
            '2': {
                loc: {
                    start: {
                        line: 54,
                        column: 12
                    },
                    end: {
                        line: 60,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 54,
                        column: 12
                    },
                    end: {
                        line: 60,
                        column: 13
                    }
                }, {
                    start: {
                        line: 54,
                        column: 12
                    },
                    end: {
                        line: 60,
                        column: 13
                    }
                }],
                line: 54
            },
            '3': {
                loc: {
                    start: {
                        line: 64,
                        column: 8
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 64,
                        column: 8
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                }, {
                    start: {
                        line: 64,
                        column: 8
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                }],
                line: 64
            },
            '4': {
                loc: {
                    start: {
                        line: 77,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 77,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                }, {
                    start: {
                        line: 77,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                }],
                line: 77
            },
            '5': {
                loc: {
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 91,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 91,
                        column: 9
                    }
                }, {
                    start: {
                        line: 87,
                        column: 8
                    },
                    end: {
                        line: 91,
                        column: 9
                    }
                }],
                line: 87
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var UsersListController = function () {
    function UsersListController($rootScope, $mdToast, $window, $location, $anchorScroll) {
        var _this = this;

        _classCallCheck(this, UsersListController);

        cov_1jhsuxj791.f[0]++;
        cov_1jhsuxj791.s[0]++;

        this.selected = [];
        cov_1jhsuxj791.s[1]++;
        this.$mdToast = $mdToast;
        cov_1jhsuxj791.s[2]++;
        this.$rootScope = $rootScope;
        cov_1jhsuxj791.s[3]++;
        this.userType = "";
        cov_1jhsuxj791.s[4]++;
        this.$window = $window;
        cov_1jhsuxj791.s[5]++;
        this.$anchorScroll = $anchorScroll;
        cov_1jhsuxj791.s[6]++;
        this.$location = $location;

        /* eslint-disable angular/on-watch */
        cov_1jhsuxj791.s[7]++;
        $rootScope.$on('pageChanged', function (event, data) {
            cov_1jhsuxj791.f[1]++;
            cov_1jhsuxj791.s[8]++;

            _this.users.$page = data;
            cov_1jhsuxj791.s[9]++;
            _this.update();
        });
    }

    _createClass(UsersListController, [{
        key: 'filterUsers',
        value: function filterUsers(userType) {
            cov_1jhsuxj791.f[2]++;
            cov_1jhsuxj791.s[10]++;

            if (userType === this.userType) {
                cov_1jhsuxj791.b[0][0]++;
                cov_1jhsuxj791.s[11]++;

                return;
            } else {
                cov_1jhsuxj791.b[0][1]++;
            }
            cov_1jhsuxj791.s[12]++;
            this.userType = userType;
            cov_1jhsuxj791.s[13]++;
            this.requestData = { type: this.userType };
            cov_1jhsuxj791.s[14]++;
            this.users.$page = 1;
            cov_1jhsuxj791.s[15]++;
            this.update();
        }
    }, {
        key: 'update',
        value: function update() {
            cov_1jhsuxj791.f[3]++;
            cov_1jhsuxj791.s[16]++;

            this.selected = [];
            cov_1jhsuxj791.s[17]++;
            this.users.$refresh(this.requestData);
        }
    }, {
        key: 'checkUser',
        value: function checkUser(user) {
            cov_1jhsuxj791.f[4]++;
            cov_1jhsuxj791.s[18]++;

            if (this.selected.indexOf(user) > -1) {
                cov_1jhsuxj791.b[1][0]++;
                cov_1jhsuxj791.s[19]++;

                this.selected.splice(this.selected.indexOf(user), 1);
            } else {
                cov_1jhsuxj791.b[1][1]++;
                cov_1jhsuxj791.s[20]++;

                this.selected.push(user);
            }
        }
    }, {
        key: 'focusInput',
        value: function focusInput() {
            cov_1jhsuxj791.f[5]++;
            cov_1jhsuxj791.s[21]++;

            this.$window.document.getElementById('newInputFocus').focus();
            cov_1jhsuxj791.s[22]++;
            this.$location.hash('newUserArea');
            cov_1jhsuxj791.s[23]++;
            this.$anchorScroll();
        }
    }, {
        key: 'userInList',
        value: function userInList(user) {
            cov_1jhsuxj791.f[6]++;
            cov_1jhsuxj791.s[24]++;

            return this.selected.indexOf(user) > -1;
        }
    }, {
        key: 'deactivateUsers',
        value: function deactivateUsers() {
            cov_1jhsuxj791.f[7]++;

            var counter = (cov_1jhsuxj791.s[25]++, 0);
            cov_1jhsuxj791.s[26]++;
            this.selected.forEach(function (user) {
                cov_1jhsuxj791.f[8]++;
                cov_1jhsuxj791.s[27]++;

                if (user.isActive) {
                    cov_1jhsuxj791.b[2][0]++;
                    cov_1jhsuxj791.s[28]++;

                    user.isActive = false;
                    cov_1jhsuxj791.s[29]++;
                    user.$save(['isActive']).$then(function (user) {
                        cov_1jhsuxj791.f[9]++;
                        cov_1jhsuxj791.s[30]++;

                        user.$fetch();
                    });
                    cov_1jhsuxj791.s[31]++;
                    counter += 1;
                } else {
                    cov_1jhsuxj791.b[2][1]++;
                }
            });
            cov_1jhsuxj791.s[32]++;
            this.selected = [];
            var message = (cov_1jhsuxj791.s[33]++, counter.toString());
            cov_1jhsuxj791.s[34]++;
            if (counter === 1) {
                cov_1jhsuxj791.b[3][0]++;
                cov_1jhsuxj791.s[35]++;

                message += ' utilizador desativado.';
            } else {
                cov_1jhsuxj791.b[3][1]++;
                cov_1jhsuxj791.s[36]++;

                message += ' utilizadores desativados.';
            }
            /* eslint-disable angular/document-service */
            var el = (cov_1jhsuxj791.s[37]++, angular.element(document.getElementById('list-users')));
            cov_1jhsuxj791.s[38]++;
            this.$mdToast.show(this.$mdToast.simple().parent(el).textContent(message));
        }
    }, {
        key: 'activateUsers',
        value: function activateUsers() {
            cov_1jhsuxj791.f[10]++;

            var counter = (cov_1jhsuxj791.s[39]++, 0);
            cov_1jhsuxj791.s[40]++;
            this.selected.forEach(function (user) {
                cov_1jhsuxj791.f[11]++;
                cov_1jhsuxj791.s[41]++;

                if (!user.isActive) {
                    cov_1jhsuxj791.b[4][0]++;
                    cov_1jhsuxj791.s[42]++;

                    user.isActive = true;
                    cov_1jhsuxj791.s[43]++;
                    user.$save(['isActive']).$then(function (user) {
                        cov_1jhsuxj791.f[12]++;
                        cov_1jhsuxj791.s[44]++;

                        user.$fetch();
                    });
                    cov_1jhsuxj791.s[45]++;
                    counter += 1;
                } else {
                    cov_1jhsuxj791.b[4][1]++;
                }
            });
            cov_1jhsuxj791.s[46]++;
            this.selected = [];
            var message = (cov_1jhsuxj791.s[47]++, counter.toString());
            cov_1jhsuxj791.s[48]++;
            if (counter === 1) {
                cov_1jhsuxj791.b[5][0]++;
                cov_1jhsuxj791.s[49]++;

                message += ' utilizador ativado.';
            } else {
                cov_1jhsuxj791.b[5][1]++;
                cov_1jhsuxj791.s[50]++;

                message += ' utilizadores ativados.';
            }
            var el = (cov_1jhsuxj791.s[51]++, angular.element(document.getElementById('list-users')));
            cov_1jhsuxj791.s[52]++;
            this.$mdToast.show(this.$mdToast.simple().parent(el).textContent(message));
        }
    }]);

    return UsersListController;
}();

cov_1jhsuxj791.s[53]++;


angular.module('app').component('usersList', {
    templateUrl: 'app/components/users/usersList/usersList.html',
    controller: UsersListController,
    bindings: {
        users: '<'
    }
});