'use strict';

var cov_1yxxirlly8 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/strings.js',
        hash = '685b07a42e5dfa549cff96d4e2d4453c518e0b60',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/strings.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 0
                },
                end: {
                    line: 6,
                    column: 2
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 4
                },
                end: {
                    line: 5,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 0
                },
                end: {
                    line: 14,
                    column: 2
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 4
                },
                end: {
                    line: 13,
                    column: 7
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 49
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 0
                },
                end: {
                    line: 21,
                    column: 2
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 4
                },
                end: {
                    line: 20,
                    column: 7
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 28,
                    column: 2
                }
            },
            '9': {
                start: {
                    line: 25,
                    column: 4
                },
                end: {
                    line: 27,
                    column: 7
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 38
                }
            },
            '11': {
                start: {
                    line: 30,
                    column: 0
                },
                end: {
                    line: 42,
                    column: 2
                }
            },
            '12': {
                start: {
                    line: 31,
                    column: 19
                },
                end: {
                    line: 31,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 32,
                    column: 16
                },
                end: {
                    line: 32,
                    column: 41
                }
            },
            '14': {
                start: {
                    line: 33,
                    column: 18
                },
                end: {
                    line: 33,
                    column: 60
                }
            },
            '15': {
                start: {
                    line: 35,
                    column: 4
                },
                end: {
                    line: 37,
                    column: 5
                }
            },
            '16': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 28
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 4
                },
                end: {
                    line: 40,
                    column: 5
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 32
                }
            },
            '19': {
                start: {
                    line: 41,
                    column: 4
                },
                end: {
                    line: 41,
                    column: 33
                }
            },
            '20': {
                start: {
                    line: 44,
                    column: 0
                },
                end: {
                    line: 60,
                    column: 2
                }
            },
            '21': {
                start: {
                    line: 45,
                    column: 19
                },
                end: {
                    line: 45,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 46,
                    column: 16
                },
                end: {
                    line: 46,
                    column: 41
                }
            },
            '23': {
                start: {
                    line: 47,
                    column: 18
                },
                end: {
                    line: 47,
                    column: 60
                }
            },
            '24': {
                start: {
                    line: 48,
                    column: 18
                },
                end: {
                    line: 48,
                    column: 58
                }
            },
            '25': {
                start: {
                    line: 50,
                    column: 4
                },
                end: {
                    line: 52,
                    column: 5
                }
            },
            '26': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 28
                }
            },
            '27': {
                start: {
                    line: 53,
                    column: 4
                },
                end: {
                    line: 55,
                    column: 5
                }
            },
            '28': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 32
                }
            },
            '29': {
                start: {
                    line: 56,
                    column: 4
                },
                end: {
                    line: 58,
                    column: 5
                }
            },
            '30': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 32
                }
            },
            '31': {
                start: {
                    line: 59,
                    column: 4
                },
                end: {
                    line: 59,
                    column: 49
                }
            },
            '32': {
                start: {
                    line: 62,
                    column: 0
                },
                end: {
                    line: 69,
                    column: 2
                }
            },
            '33': {
                start: {
                    line: 63,
                    column: 19
                },
                end: {
                    line: 63,
                    column: 37
                }
            },
            '34': {
                start: {
                    line: 64,
                    column: 18
                },
                end: {
                    line: 64,
                    column: 43
                }
            },
            '35': {
                start: {
                    line: 65,
                    column: 20
                },
                end: {
                    line: 65,
                    column: 62
                }
            },
            '36': {
                start: {
                    line: 66,
                    column: 20
                },
                end: {
                    line: 66,
                    column: 60
                }
            },
            '37': {
                start: {
                    line: 68,
                    column: 4
                },
                end: {
                    line: 68,
                    column: 99
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 4,
                        column: 24
                    },
                    end: {
                        line: 4,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 4,
                        column: 36
                    },
                    end: {
                        line: 6,
                        column: 1
                    }
                },
                line: 4
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 27
                    },
                    end: {
                        line: 9,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 39
                    },
                    end: {
                        line: 14,
                        column: 1
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 38
                    },
                    end: {
                        line: 11,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 52
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 26
                    },
                    end: {
                        line: 17,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 38
                    },
                    end: {
                        line: 21,
                        column: 1
                    }
                },
                line: 17
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 36
                    },
                    end: {
                        line: 18,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 50
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 24,
                        column: 32
                    },
                    end: {
                        line: 24,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 44
                    },
                    end: {
                        line: 28,
                        column: 1
                    }
                },
                line: 24
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 25,
                        column: 36
                    },
                    end: {
                        line: 25,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 50
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 25
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 30,
                        column: 26
                    },
                    end: {
                        line: 30,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 38
                    },
                    end: {
                        line: 42,
                        column: 1
                    }
                },
                line: 30
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 44,
                        column: 28
                    },
                    end: {
                        line: 44,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 40
                    },
                    end: {
                        line: 60,
                        column: 1
                    }
                },
                line: 44
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 62,
                        column: 29
                    },
                    end: {
                        line: 62,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 41
                    },
                    end: {
                        line: 69,
                        column: 1
                    }
                },
                line: 62
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                }, {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                }],
                line: 35
            },
            '1': {
                loc: {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                }, {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                }],
                line: 38
            },
            '2': {
                loc: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                }, {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                }],
                line: 50
            },
            '3': {
                loc: {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                }, {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                }],
                line: 53
            },
            '4': {
                loc: {
                    start: {
                        line: 56,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                }, {
                    start: {
                        line: 56,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                }],
                line: 56
            },
            '5': {
                loc: {
                    start: {
                        line: 68,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 43
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 68,
                        column: 26
                    },
                    end: {
                        line: 68,
                        column: 38
                    }
                }, {
                    start: {
                        line: 68,
                        column: 41
                    },
                    end: {
                        line: 68,
                        column: 43
                    }
                }],
                line: 68
            },
            '6': {
                loc: {
                    start: {
                        line: 68,
                        column: 65
                    },
                    end: {
                        line: 68,
                        column: 97
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 68,
                        column: 79
                    },
                    end: {
                        line: 68,
                        column: 81
                    }
                }, {
                    start: {
                        line: 68,
                        column: 84
                    },
                    end: {
                        line: 68,
                        column: 97
                    }
                }],
                line: 68
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1yxxirlly8.s[0]++;
/* eslint-disable no-extend-native*/
/* eslint-disable prefer-template */

String.prototype.trim = function () {
    cov_1yxxirlly8.f[0]++;
    cov_1yxxirlly8.s[1]++;

    return this.replace(/^\s+|\s+$/g, "");
};

// To Camel Case
cov_1yxxirlly8.s[2]++;
String.prototype.toCamel = function () {
    cov_1yxxirlly8.f[1]++;
    cov_1yxxirlly8.s[3]++;

    /* eslint-disable prefer-arrow-callback */
    return this.replace(/(\-[a-z])/g, function ($1) {
        cov_1yxxirlly8.f[2]++;
        cov_1yxxirlly8.s[4]++;

        return $1.toUpperCase().replace('-', '');
    });
};

// To Dashed from Camel Case
cov_1yxxirlly8.s[5]++;
String.prototype.toDash = function () {
    cov_1yxxirlly8.f[3]++;
    cov_1yxxirlly8.s[6]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_1yxxirlly8.f[4]++;
        cov_1yxxirlly8.s[7]++;

        return "-" + $1.toLowerCase();
    });
};

// To Underscore from Camel Case
cov_1yxxirlly8.s[8]++;
String.prototype.toUnderscore = function () {
    cov_1yxxirlly8.f[5]++;
    cov_1yxxirlly8.s[9]++;

    return this.replace(/([A-Z])/g, function ($1) {
        cov_1yxxirlly8.f[6]++;
        cov_1yxxirlly8.s[10]++;

        return "_" + $1.toLowerCase();
    });
};

cov_1yxxirlly8.s[11]++;
String.prototype.toHHMM = function () {
    cov_1yxxirlly8.f[7]++;

    var secNum = (cov_1yxxirlly8.s[12]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_1yxxirlly8.s[13]++, Math.floor(secNum / 3600));
    var minutes = (cov_1yxxirlly8.s[14]++, Math.floor((secNum - hours * 3600) / 60));

    cov_1yxxirlly8.s[15]++;
    if (hours < 10) {
        cov_1yxxirlly8.b[0][0]++;
        cov_1yxxirlly8.s[16]++;

        hours = "0" + hours;
    } else {
        cov_1yxxirlly8.b[0][1]++;
    }
    cov_1yxxirlly8.s[17]++;
    if (minutes < 10) {
        cov_1yxxirlly8.b[1][0]++;
        cov_1yxxirlly8.s[18]++;

        minutes = "0" + minutes;
    } else {
        cov_1yxxirlly8.b[1][1]++;
    }
    cov_1yxxirlly8.s[19]++;
    return hours + ':' + minutes;
};

cov_1yxxirlly8.s[20]++;
String.prototype.toHHMMSS = function () {
    cov_1yxxirlly8.f[8]++;

    var secNum = (cov_1yxxirlly8.s[21]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_1yxxirlly8.s[22]++, Math.floor(secNum / 3600));
    var minutes = (cov_1yxxirlly8.s[23]++, Math.floor((secNum - hours * 3600) / 60));
    var seconds = (cov_1yxxirlly8.s[24]++, secNum - hours * 3600 - minutes * 60);

    cov_1yxxirlly8.s[25]++;
    if (hours < 10) {
        cov_1yxxirlly8.b[2][0]++;
        cov_1yxxirlly8.s[26]++;

        hours = "0" + hours;
    } else {
        cov_1yxxirlly8.b[2][1]++;
    }
    cov_1yxxirlly8.s[27]++;
    if (minutes < 10) {
        cov_1yxxirlly8.b[3][0]++;
        cov_1yxxirlly8.s[28]++;

        minutes = "0" + minutes;
    } else {
        cov_1yxxirlly8.b[3][1]++;
    }
    cov_1yxxirlly8.s[29]++;
    if (seconds < 10) {
        cov_1yxxirlly8.b[4][0]++;
        cov_1yxxirlly8.s[30]++;

        seconds = "0" + seconds;
    } else {
        cov_1yxxirlly8.b[4][1]++;
    }
    cov_1yxxirlly8.s[31]++;
    return hours + ':' + minutes + ':' + seconds;
};

cov_1yxxirlly8.s[32]++;
String.prototype.toHhMmSSs = function () {
    cov_1yxxirlly8.f[9]++;

    var secNum = (cov_1yxxirlly8.s[33]++, parseInt(this, 10)); // don't forget the second param
    var hours = (cov_1yxxirlly8.s[34]++, Math.floor(secNum / 3600));
    var minutes = (cov_1yxxirlly8.s[35]++, Math.floor((secNum - hours * 3600) / 60));
    var seconds = (cov_1yxxirlly8.s[36]++, secNum - hours * 3600 - minutes * 60);

    cov_1yxxirlly8.s[37]++;
    return (hours > 0 ? (cov_1yxxirlly8.b[5][0]++, hours + 'h ') : (cov_1yxxirlly8.b[5][1]++, '')) + minutes + 'm ' + (hours > 0 ? (cov_1yxxirlly8.b[6][0]++, '') : (cov_1yxxirlly8.b[6][1]++, seconds + 's'));
};