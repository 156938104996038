'use strict';

var cov_1tt8y2z22l = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/sampleStateFilter.js',
        hash = 'b1e36c403ee2b4b812b7829ed095da13c9c6ef9b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/sampleStateFilter.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 13
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 13,
                    column: 43
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 43
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 19,
                    column: 43
                }
            },
            '10': {
                start: {
                    line: 21,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 13
                }
            },
            '11': {
                start: {
                    line: 22,
                    column: 16
                },
                end: {
                    line: 22,
                    column: 43
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 32
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 52
                }
            },
            '14': {
                start: {
                    line: 34,
                    column: 0
                },
                end: {
                    line: 42,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 8,
                        column: 33
                    },
                    end: {
                        line: 8,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 45
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 8
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 19
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 28
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }],
                line: 9
            },
            '1': {
                loc: {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }, {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }],
                line: 12
            },
            '2': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 15
            },
            '3': {
                loc: {
                    start: {
                        line: 18,
                        column: 12
                    },
                    end: {
                        line: 20,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 18,
                        column: 12
                    },
                    end: {
                        line: 20,
                        column: 13
                    }
                }, {
                    start: {
                        line: 18,
                        column: 12
                    },
                    end: {
                        line: 20,
                        column: 13
                    }
                }],
                line: 18
            },
            '4': {
                loc: {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                }, {
                    start: {
                        line: 21,
                        column: 12
                    },
                    end: {
                        line: 23,
                        column: 13
                    }
                }],
                line: 21
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleStateFilterController = function () {
    function SampleStateFilterController($rootScope) {
        _classCallCheck(this, SampleStateFilterController);

        cov_1tt8y2z22l.f[0]++;
        cov_1tt8y2z22l.s[0]++;

        this.$rootScope = $rootScope;
    }

    _createClass(SampleStateFilterController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_1tt8y2z22l.f[1]++;
            cov_1tt8y2z22l.s[1]++;

            this.$rootScope.me.$then(function (response) {
                cov_1tt8y2z22l.f[2]++;
                cov_1tt8y2z22l.s[2]++;

                if (response.type === 'CONTROL') {
                    cov_1tt8y2z22l.b[0][0]++;
                    cov_1tt8y2z22l.s[3]++;

                    _this.sampleStateFilter = 7;
                } else {
                    cov_1tt8y2z22l.b[0][1]++;
                }
                cov_1tt8y2z22l.s[4]++;
                if (response.type === 'CAD') {
                    cov_1tt8y2z22l.b[1][0]++;
                    cov_1tt8y2z22l.s[5]++;

                    _this.sampleStateFilter = 2;
                } else {
                    cov_1tt8y2z22l.b[1][1]++;
                }
                cov_1tt8y2z22l.s[6]++;
                if (response.type === 'CUTTING') {
                    cov_1tt8y2z22l.b[2][0]++;
                    cov_1tt8y2z22l.s[7]++;

                    _this.sampleStateFilter = 3;
                } else {
                    cov_1tt8y2z22l.b[2][1]++;
                }
                cov_1tt8y2z22l.s[8]++;
                if (response.type === 'ORNAMENTS') {
                    cov_1tt8y2z22l.b[3][0]++;
                    cov_1tt8y2z22l.s[9]++;

                    _this.sampleStateFilter = 5;
                } else {
                    cov_1tt8y2z22l.b[3][1]++;
                }
                cov_1tt8y2z22l.s[10]++;
                if (response.type === 'WAREHOUSE') {
                    cov_1tt8y2z22l.b[4][0]++;
                    cov_1tt8y2z22l.s[11]++;

                    _this.sampleStateFilter = 8;
                } else {
                    cov_1tt8y2z22l.b[4][1]++;
                }
                cov_1tt8y2z22l.s[12]++;
                _this.changeFilter();
            });
        }
    }, {
        key: 'changeFilter',
        value: function changeFilter() {
            cov_1tt8y2z22l.f[3]++;
            cov_1tt8y2z22l.s[13]++;

            /* eslint-disable camelcase */
            this.changed({ val: this.sampleStateFilter });
        }
    }]);

    return SampleStateFilterController;
}();

cov_1tt8y2z22l.s[14]++;


angular.module('app').component('sampleStateFilter', {
    templateUrl: 'app/components/dashboard/sampleStateFilter.html',
    controller: SampleStateFilterController,
    bindings: {
        changed: '&'
    }
});