'use strict';

var cov_2ok7imf1ki = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/samplesDisplayGrid/samplesDisplayGrid.js',
        hash = '2eaf84bac67608def8bbb1cee3067fc8586e3b37',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/samplesDisplayGrid/samplesDisplayGrid.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 39
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 35
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 29
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 43
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 45
                }
            },
            '7': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 45
                }
            },
            '8': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 29
                }
            },
            '9': {
                start: {
                    line: 19,
                    column: 24
                },
                end: {
                    line: 19,
                    column: 44
                }
            },
            '10': {
                start: {
                    line: 20,
                    column: 21
                },
                end: {
                    line: 20,
                    column: 44
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 101
                }
            },
            '12': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 80
                }
            },
            '14': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 73
                }
            },
            '15': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 27
                }
            },
            '16': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 11
                }
            },
            '17': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 55
                }
            },
            '18': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 72
                }
            },
            '19': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 73
                }
            },
            '20': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 32
                }
            },
            '21': {
                start: {
                    line: 43,
                    column: 21
                },
                end: {
                    line: 43,
                    column: 25
                }
            },
            '22': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 9
                }
            },
            '23': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 13
                }
            },
            '24': {
                start: {
                    line: 47,
                    column: 16
                },
                end: {
                    line: 47,
                    column: 48
                }
            },
            '25': {
                start: {
                    line: 49,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 13
                }
            },
            '26': {
                start: {
                    line: 50,
                    column: 16
                },
                end: {
                    line: 50,
                    column: 29
                }
            },
            '27': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 9
                }
            },
            '28': {
                start: {
                    line: 55,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 13
                }
            },
            '29': {
                start: {
                    line: 56,
                    column: 16
                },
                end: {
                    line: 56,
                    column: 28
                }
            },
            '30': {
                start: {
                    line: 58,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 25
                }
            },
            '31': {
                start: {
                    line: 63,
                    column: 0
                },
                end: {
                    line: 74,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 105
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 14
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 17
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 31,
                        column: 42
                    },
                    end: {
                        line: 31,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 48
                    },
                    end: {
                        line: 33,
                        column: 9
                    }
                },
                line: 31
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 18
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 36
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 20
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 42
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 24,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 24,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }, {
                    start: {
                        line: 24,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }],
                line: 24
            },
            '1': {
                loc: {
                    start: {
                        line: 25,
                        column: 29
                    },
                    end: {
                        line: 25,
                        column: 79
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 25,
                        column: 71
                    },
                    end: {
                        line: 25,
                        column: 74
                    }
                }, {
                    start: {
                        line: 25,
                        column: 77
                    },
                    end: {
                        line: 25,
                        column: 79
                    }
                }],
                line: 25
            },
            '2': {
                loc: {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                }, {
                    start: {
                        line: 46,
                        column: 12
                    },
                    end: {
                        line: 48,
                        column: 13
                    }
                }],
                line: 46
            },
            '3': {
                loc: {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }, {
                    start: {
                        line: 49,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 13
                    }
                }],
                line: 49
            },
            '4': {
                loc: {
                    start: {
                        line: 54,
                        column: 8
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 54,
                        column: 8
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                }, {
                    start: {
                        line: 54,
                        column: 8
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                }],
                line: 54
            },
            '5': {
                loc: {
                    start: {
                        line: 55,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 55,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                }, {
                    start: {
                        line: 55,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                }],
                line: 55
            },
            '6': {
                loc: {
                    start: {
                        line: 55,
                        column: 16
                    },
                    end: {
                        line: 55,
                        column: 80
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 55,
                        column: 16
                    },
                    end: {
                        line: 55,
                        column: 45
                    }
                }, {
                    start: {
                        line: 55,
                        column: 49
                    },
                    end: {
                        line: 55,
                        column: 80
                    }
                }],
                line: 55
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SamplesDisplayGridController = function () {
    function SamplesDisplayGridController($location, $stateParams, OverviewsTable, AppSettings, $mdDialog, WorkloadsTable, $scope) {
        _classCallCheck(this, SamplesDisplayGridController);

        cov_2ok7imf1ki.f[0]++;
        cov_2ok7imf1ki.s[0]++;

        this.$location = $location;
        cov_2ok7imf1ki.s[1]++;
        this.$stateParams = $stateParams;
        cov_2ok7imf1ki.s[2]++;
        this.AppSettings = AppSettings;
        cov_2ok7imf1ki.s[3]++;
        this.$mdDialog = $mdDialog;

        cov_2ok7imf1ki.s[4]++;
        this.direction = '-';
        cov_2ok7imf1ki.s[5]++;
        this.sortField = 'order_reference';
        cov_2ok7imf1ki.s[6]++;
        this.OverviewsTable = OverviewsTable;

        cov_2ok7imf1ki.s[7]++;
        this.WorkloadsTable = WorkloadsTable;

        cov_2ok7imf1ki.s[8]++;
        this.$scope = $scope;
    }

    _createClass(SamplesDisplayGridController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_2ok7imf1ki.f[1]++;

            /* eslint-disable no-undef */
            var isoweek = (cov_2ok7imf1ki.s[9]++, moment().format('W'));
            var year = (cov_2ok7imf1ki.s[10]++, moment().format('YYYY'));
            /* eslint-disable camelcase */
            /* eslint-disable babel/object-shorthand */
            cov_2ok7imf1ki.s[11]++;
            this.workloads = this.WorkloadsTable.$search({ page_size: 3, week__gte: isoweek, year: year });
            cov_2ok7imf1ki.s[12]++;
            if (this.$stateParams.ordering) {
                cov_2ok7imf1ki.b[0][0]++;
                cov_2ok7imf1ki.s[13]++;

                this.direction = this.$stateParams.ordering[0] === '-' ? (cov_2ok7imf1ki.b[1][0]++, '-') : (cov_2ok7imf1ki.b[1][1]++, '');
                cov_2ok7imf1ki.s[14]++;
                this.sortField = this.$stateParams.ordering.replace('-', '');
            } else {
                cov_2ok7imf1ki.b[0][1]++;
            }

            cov_2ok7imf1ki.s[15]++;
            this.changeOrder();

            cov_2ok7imf1ki.s[16]++;
            this.$scope.$on('bulk_completed', function () {
                cov_2ok7imf1ki.f[2]++;
                cov_2ok7imf1ki.s[17]++;

                _this.workloads = _this.workloads.$refresh();
            });
        }
    }, {
        key: 'changeOrder',
        value: function changeOrder() {
            cov_2ok7imf1ki.f[3]++;
            cov_2ok7imf1ki.s[18]++;

            this.samples.$params.ordering = this.direction + this.sortField;
            cov_2ok7imf1ki.s[19]++;
            this.$location.search('ordering', this.samples.$params.ordering);
            cov_2ok7imf1ki.s[20]++;
            this.samples.$refresh();
        }
    }, {
        key: 'samplesSeason',
        value: function samplesSeason() {
            cov_2ok7imf1ki.f[4]++;

            var season = (cov_2ok7imf1ki.s[21]++, null);
            var i = void 0;
            cov_2ok7imf1ki.s[22]++;
            for (i = 0; i < this.samples.length; i++) {
                cov_2ok7imf1ki.s[23]++;

                if (i === 0) {
                    cov_2ok7imf1ki.b[2][0]++;
                    cov_2ok7imf1ki.s[24]++;

                    season = this.samples[i].season;
                } else {
                    cov_2ok7imf1ki.b[2][1]++;
                }
                cov_2ok7imf1ki.s[25]++;
                if (this.samples[i].season !== season) {
                    cov_2ok7imf1ki.b[3][0]++;
                    cov_2ok7imf1ki.s[26]++;

                    return false;
                } else {
                    cov_2ok7imf1ki.b[3][1]++;
                }
            }
            /* eslint-disable angular/definedundefined */
            cov_2ok7imf1ki.s[27]++;
            if (i === this.samples.length) {
                cov_2ok7imf1ki.b[4][0]++;
                cov_2ok7imf1ki.s[28]++;

                if ((cov_2ok7imf1ki.b[6][0]++, this.samples[0] !== undefined) && (cov_2ok7imf1ki.b[6][1]++, this.samples[0].season !== null)) {
                    cov_2ok7imf1ki.b[5][0]++;
                    cov_2ok7imf1ki.s[29]++;

                    return true;
                } else {
                    cov_2ok7imf1ki.b[5][1]++;
                }
                cov_2ok7imf1ki.s[30]++;
                return false;
            } else {
                cov_2ok7imf1ki.b[4][1]++;
            }
        }
    }]);

    return SamplesDisplayGridController;
}();

cov_2ok7imf1ki.s[31]++;


angular.module('app').component('samplesDisplayGrid', {
    templateUrl: 'app/components/samplesDisplayGrid/samplesDisplayGrid.html',
    controller: SamplesDisplayGridController,
    bindings: {
        samples: '<',
        brand: '=',
        workloads: '<',
        checkWorkItem: '<'
    }
});