'use strict';

var cov_6r81qn36g = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardSampleStates.js',
        hash = '0cadb988c99eed9f0025e863005476583e79b358',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardSampleStates.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 41
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 55
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 23
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 24
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 37
                }
            },
            '8': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 44
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 29
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 60
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 20
                }
            },
            '12': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 45
                }
            },
            '14': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 46
                }
            },
            '15': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 20
                }
            },
            '16': {
                start: {
                    line: 45,
                    column: 26
                },
                end: {
                    line: 45,
                    column: 62
                }
            },
            '17': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 45
                }
            },
            '18': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 52
                }
            },
            '19': {
                start: {
                    line: 55,
                    column: 0
                },
                end: {
                    line: 60,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 49
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 16,
                        column: 18
                    },
                    end: {
                        line: 16,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 24
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 16
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 20
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 11
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 25
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 30
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                line: 30
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 35,
                        column: 4
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 35,
                        column: 24
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 35
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 44,
                        column: 4
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 44,
                        column: 32
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                line: 44
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 22
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 49
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                }, {
                    start: {
                        line: 36,
                        column: 8
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                }],
                line: 36
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardSampleStatesController = function () {
    function DashboardSampleStatesController(SamplesTable, $interval, $state) {
        var _this = this;

        _classCallCheck(this, DashboardSampleStatesController);

        cov_6r81qn36g.f[0]++;
        cov_6r81qn36g.s[0]++;

        this.SamplesTable = SamplesTable;
        cov_6r81qn36g.s[1]++;
        this.$state = $state;
        cov_6r81qn36g.s[2]++;
        this.now = new Date();
        /* eslint-disable camelcase */
        cov_6r81qn36g.s[3]++;
        this.filters = {
            page_size: 15,
            is_archived: false,
            related_only: false,
            ordering: '-current_state__sample_state_type'
        };
        cov_6r81qn36g.s[4]++;
        this.samples = this.SamplesTable.$collection();

        cov_6r81qn36g.s[5]++;
        $interval(function () {
            cov_6r81qn36g.f[1]++;
            cov_6r81qn36g.s[6]++;

            _this.load();
        }, 600 * 1000);
    }

    _createClass(DashboardSampleStatesController, [{
        key: 'setLastUpdate',
        value: function setLastUpdate() {
            cov_6r81qn36g.f[2]++;
            cov_6r81qn36g.s[7]++;

            this.lastUpdate = new Date();
        }
    }, {
        key: 'load',
        value: function load() {
            cov_6r81qn36g.f[3]++;
            cov_6r81qn36g.s[8]++;

            this.samples.$refresh(this.filters);
            cov_6r81qn36g.s[9]++;
            this.setLastUpdate();
        }
    }, {
        key: 'setSampleStateFilter',
        value: function setSampleStateFilter(val) {
            cov_6r81qn36g.f[4]++;
            cov_6r81qn36g.s[10]++;

            this.filters.current_state__sample_state_type = val;
            cov_6r81qn36g.s[11]++;
            this.load();
        }
    }, {
        key: 'setBrandFilter',
        value: function setBrandFilter(val) {
            cov_6r81qn36g.f[5]++;
            cov_6r81qn36g.s[12]++;

            if (val === 'mine') {
                cov_6r81qn36g.b[0][0]++;
                cov_6r81qn36g.s[13]++;

                this.filters.related_only = true;
            } else {
                cov_6r81qn36g.b[0][1]++;
                cov_6r81qn36g.s[14]++;

                this.filters.related_only = false;
            }
            cov_6r81qn36g.s[15]++;
            this.load();
        }
    }, {
        key: 'showSimpleBrandFilter',
        value: function showSimpleBrandFilter(user) {
            cov_6r81qn36g.f[6]++;

            var relatable = (cov_6r81qn36g.s[16]++, ['COMMERCIAL', 'ACCESSORIES', 'CAD']);
            cov_6r81qn36g.s[17]++;
            return relatable.includes(user.type);
        }
    }, {
        key: 'openSamplesList',
        value: function openSamplesList() {
            cov_6r81qn36g.f[7]++;
            cov_6r81qn36g.s[18]++;

            this.$state.go('samplesList', this.filters);
        }
    }]);

    return DashboardSampleStatesController;
}();

cov_6r81qn36g.s[19]++;


angular.module('app').component('dashboardSampleStates', {
    templateUrl: 'app/components/dashboard/dashboardSampleStates.html',
    controller: DashboardSampleStatesController
});