'use strict';

var cov_1ekedaiuiu = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/workHandler/workHandler.js',
        hash = '6b7f0ae4ffd03ed1b5d8bebe77463eed59dad6bd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/workHandler/workHandler.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 42
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 48
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 23
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 25
                },
                end: {
                    line: 19,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 31
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 38
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 37
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 33
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 66
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 29
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 43
                }
            },
            '15': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 29
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 51
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 47
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 59
                }
            },
            '19': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 40
                }
            },
            '20': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 24
                }
            },
            '21': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 69
                }
            },
            '22': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 51
                }
            },
            '23': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 47
                }
            },
            '24': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 40
                }
            },
            '25': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 37
                }
            },
            '26': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 67
                }
            },
            '27': {
                start: {
                    line: 51,
                    column: 22
                },
                end: {
                    line: 51,
                    column: 38
                }
            },
            '28': {
                start: {
                    line: 53,
                    column: 23
                },
                end: {
                    line: 53,
                    column: 66
                }
            },
            '29': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 15
                }
            },
            '30': {
                start: {
                    line: 57,
                    column: 16
                },
                end: {
                    line: 57,
                    column: 62
                }
            },
            '31': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 42
                }
            },
            '32': {
                start: {
                    line: 64,
                    column: 25
                },
                end: {
                    line: 64,
                    column: 41
                }
            },
            '33': {
                start: {
                    line: 66,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 11
                }
            },
            '34': {
                start: {
                    line: 67,
                    column: 12
                },
                end: {
                    line: 67,
                    column: 31
                }
            },
            '35': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 9
                }
            },
            '36': {
                start: {
                    line: 73,
                    column: 12
                },
                end: {
                    line: 73,
                    column: 24
                }
            },
            '37': {
                start: {
                    line: 75,
                    column: 24
                },
                end: {
                    line: 75,
                    column: 71
                }
            },
            '38': {
                start: {
                    line: 75,
                    column: 66
                },
                end: {
                    line: 75,
                    column: 70
                }
            },
            '39': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 63
                }
            },
            '40': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 9
                }
            },
            '41': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 13
                }
            },
            '42': {
                start: {
                    line: 82,
                    column: 16
                },
                end: {
                    line: 82,
                    column: 28
                }
            },
            '43': {
                start: {
                    line: 84,
                    column: 12
                },
                end: {
                    line: 84,
                    column: 46
                }
            },
            '44': {
                start: {
                    line: 86,
                    column: 8
                },
                end: {
                    line: 88,
                    column: 9
                }
            },
            '45': {
                start: {
                    line: 87,
                    column: 12
                },
                end: {
                    line: 87,
                    column: 25
                }
            },
            '46': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 50
                }
            },
            '47': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 32
                }
            },
            '48': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 37
                }
            },
            '49': {
                start: {
                    line: 98,
                    column: 8
                },
                end: {
                    line: 98,
                    column: 50
                }
            },
            '50': {
                start: {
                    line: 99,
                    column: 28
                },
                end: {
                    line: 99,
                    column: 67
                }
            },
            '51': {
                start: {
                    line: 100,
                    column: 8
                },
                end: {
                    line: 100,
                    column: 78
                }
            },
            '52': {
                start: {
                    line: 101,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 27
                }
            },
            '53': {
                start: {
                    line: 105,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 11
                }
            },
            '54': {
                start: {
                    line: 106,
                    column: 12
                },
                end: {
                    line: 108,
                    column: 13
                }
            },
            '55': {
                start: {
                    line: 107,
                    column: 16
                },
                end: {
                    line: 107,
                    column: 106
                }
            },
            '56': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 110,
                    column: 52
                }
            },
            '57': {
                start: {
                    line: 114,
                    column: 8
                },
                end: {
                    line: 114,
                    column: 37
                }
            },
            '58': {
                start: {
                    line: 117,
                    column: 8
                },
                end: {
                    line: 119,
                    column: 9
                }
            },
            '59': {
                start: {
                    line: 118,
                    column: 12
                },
                end: {
                    line: 118,
                    column: 19
                }
            },
            '60': {
                start: {
                    line: 121,
                    column: 26
                },
                end: {
                    line: 121,
                    column: 28
                }
            },
            '61': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 11
                }
            },
            '62': {
                start: {
                    line: 124,
                    column: 12
                },
                end: {
                    line: 130,
                    column: 13
                }
            },
            '63': {
                start: {
                    line: 125,
                    column: 29
                },
                end: {
                    line: 125,
                    column: 84
                }
            },
            '64': {
                start: {
                    line: 126,
                    column: 16
                },
                end: {
                    line: 128,
                    column: 54
                }
            },
            '65': {
                start: {
                    line: 129,
                    column: 16
                },
                end: {
                    line: 129,
                    column: 40
                }
            },
            '66': {
                start: {
                    line: 133,
                    column: 8
                },
                end: {
                    line: 138,
                    column: 9
                }
            },
            '67': {
                start: {
                    line: 134,
                    column: 12
                },
                end: {
                    line: 137,
                    column: 15
                }
            },
            '68': {
                start: {
                    line: 135,
                    column: 16
                },
                end: {
                    line: 135,
                    column: 47
                }
            },
            '69': {
                start: {
                    line: 136,
                    column: 16
                },
                end: {
                    line: 136,
                    column: 96
                }
            },
            '70': {
                start: {
                    line: 142,
                    column: 8
                },
                end: {
                    line: 142,
                    column: 39
                }
            },
            '71': {
                start: {
                    line: 146,
                    column: 21
                },
                end: {
                    line: 146,
                    column: 23
                }
            },
            '72': {
                start: {
                    line: 147,
                    column: 8
                },
                end: {
                    line: 149,
                    column: 9
                }
            },
            '73': {
                start: {
                    line: 148,
                    column: 12
                },
                end: {
                    line: 148,
                    column: 65
                }
            },
            '74': {
                start: {
                    line: 150,
                    column: 20
                },
                end: {
                    line: 150,
                    column: 56
                }
            },
            '75': {
                start: {
                    line: 151,
                    column: 8
                },
                end: {
                    line: 151,
                    column: 68
                }
            },
            '76': {
                start: {
                    line: 155,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 42
                }
            },
            '77': {
                start: {
                    line: 159,
                    column: 8
                },
                end: {
                    line: 159,
                    column: 46
                }
            },
            '78': {
                start: {
                    line: 160,
                    column: 8
                },
                end: {
                    line: 160,
                    column: 116
                }
            },
            '79': {
                start: {
                    line: 160,
                    column: 27
                },
                end: {
                    line: 160,
                    column: 114
                }
            },
            '80': {
                start: {
                    line: 160,
                    column: 77
                },
                end: {
                    line: 160,
                    column: 113
                }
            },
            '81': {
                start: {
                    line: 164,
                    column: 8
                },
                end: {
                    line: 164,
                    column: 36
                }
            },
            '82': {
                start: {
                    line: 168,
                    column: 8
                },
                end: {
                    line: 173,
                    column: 9
                }
            },
            '83': {
                start: {
                    line: 169,
                    column: 12
                },
                end: {
                    line: 169,
                    column: 31
                }
            },
            '84': {
                start: {
                    line: 170,
                    column: 12
                },
                end: {
                    line: 170,
                    column: 37
                }
            },
            '85': {
                start: {
                    line: 172,
                    column: 12
                },
                end: {
                    line: 172,
                    column: 48
                }
            },
            '86': {
                start: {
                    line: 177,
                    column: 20
                },
                end: {
                    line: 177,
                    column: 30
                }
            },
            '87': {
                start: {
                    line: 178,
                    column: 8
                },
                end: {
                    line: 178,
                    column: 62
                }
            },
            '88': {
                start: {
                    line: 182,
                    column: 23
                },
                end: {
                    line: 182,
                    column: 74
                }
            },
            '89': {
                start: {
                    line: 183,
                    column: 8
                },
                end: {
                    line: 189,
                    column: 9
                }
            },
            '90': {
                start: {
                    line: 184,
                    column: 12
                },
                end: {
                    line: 187,
                    column: 13
                }
            },
            '91': {
                start: {
                    line: 185,
                    column: 33
                },
                end: {
                    line: 185,
                    column: 57
                }
            },
            '92': {
                start: {
                    line: 186,
                    column: 16
                },
                end: {
                    line: 186,
                    column: 61
                }
            },
            '93': {
                start: {
                    line: 188,
                    column: 12
                },
                end: {
                    line: 188,
                    column: 24
                }
            },
            '94': {
                start: {
                    line: 190,
                    column: 8
                },
                end: {
                    line: 190,
                    column: 21
                }
            },
            '95': {
                start: {
                    line: 199,
                    column: 8
                },
                end: {
                    line: 201,
                    column: 9
                }
            },
            '96': {
                start: {
                    line: 200,
                    column: 12
                },
                end: {
                    line: 200,
                    column: 33
                }
            },
            '97': {
                start: {
                    line: 202,
                    column: 8
                },
                end: {
                    line: 204,
                    column: 9
                }
            },
            '98': {
                start: {
                    line: 203,
                    column: 12
                },
                end: {
                    line: 203,
                    column: 33
                }
            },
            '99': {
                start: {
                    line: 206,
                    column: 8
                },
                end: {
                    line: 206,
                    column: 21
                }
            },
            '100': {
                start: {
                    line: 214,
                    column: 8
                },
                end: {
                    line: 214,
                    column: 33
                }
            },
            '101': {
                start: {
                    line: 215,
                    column: 8
                },
                end: {
                    line: 215,
                    column: 53
                }
            },
            '102': {
                start: {
                    line: 216,
                    column: 8
                },
                end: {
                    line: 216,
                    column: 37
                }
            },
            '103': {
                start: {
                    line: 217,
                    column: 8
                },
                end: {
                    line: 217,
                    column: 23
                }
            },
            '104': {
                start: {
                    line: 219,
                    column: 8
                },
                end: {
                    line: 219,
                    column: 51
                }
            },
            '105': {
                start: {
                    line: 220,
                    column: 8
                },
                end: {
                    line: 220,
                    column: 53
                }
            },
            '106': {
                start: {
                    line: 221,
                    column: 8
                },
                end: {
                    line: 221,
                    column: 59
                }
            },
            '107': {
                start: {
                    line: 222,
                    column: 8
                },
                end: {
                    line: 222,
                    column: 61
                }
            },
            '108': {
                start: {
                    line: 224,
                    column: 8
                },
                end: {
                    line: 224,
                    column: 59
                }
            },
            '109': {
                start: {
                    line: 227,
                    column: 8
                },
                end: {
                    line: 227,
                    column: 52
                }
            },
            '110': {
                start: {
                    line: 231,
                    column: 8
                },
                end: {
                    line: 231,
                    column: 42
                }
            },
            '111': {
                start: {
                    line: 232,
                    column: 8
                },
                end: {
                    line: 232,
                    column: 87
                }
            },
            '112': {
                start: {
                    line: 232,
                    column: 56
                },
                end: {
                    line: 232,
                    column: 85
                }
            },
            '113': {
                start: {
                    line: 236,
                    column: 8
                },
                end: {
                    line: 236,
                    column: 87
                }
            },
            '114': {
                start: {
                    line: 236,
                    column: 56
                },
                end: {
                    line: 236,
                    column: 85
                }
            },
            '115': {
                start: {
                    line: 241,
                    column: 8
                },
                end: {
                    line: 241,
                    column: 43
                }
            },
            '116': {
                start: {
                    line: 245,
                    column: 8
                },
                end: {
                    line: 245,
                    column: 62
                }
            },
            '117': {
                start: {
                    line: 246,
                    column: 8
                },
                end: {
                    line: 247,
                    column: 77
                }
            },
            '118': {
                start: {
                    line: 246,
                    column: 26
                },
                end: {
                    line: 247,
                    column: 75
                }
            },
            '119': {
                start: {
                    line: 248,
                    column: 8
                },
                end: {
                    line: 249,
                    column: 119
                }
            },
            '120': {
                start: {
                    line: 249,
                    column: 18
                },
                end: {
                    line: 249,
                    column: 117
                }
            },
            '121': {
                start: {
                    line: 249,
                    column: 99
                },
                end: {
                    line: 249,
                    column: 116
                }
            },
            '122': {
                start: {
                    line: 253,
                    column: 25
                },
                end: {
                    line: 253,
                    column: 77
                }
            },
            '123': {
                start: {
                    line: 254,
                    column: 23
                },
                end: {
                    line: 270,
                    column: 9
                }
            },
            '124': {
                start: {
                    line: 271,
                    column: 8
                },
                end: {
                    line: 271,
                    column: 35
                }
            },
            '125': {
                start: {
                    line: 275,
                    column: 25
                },
                end: {
                    line: 275,
                    column: 77
                }
            },
            '126': {
                start: {
                    line: 276,
                    column: 23
                },
                end: {
                    line: 292,
                    column: 9
                }
            },
            '127': {
                start: {
                    line: 293,
                    column: 8
                },
                end: {
                    line: 293,
                    column: 35
                }
            },
            '128': {
                start: {
                    line: 297,
                    column: 8
                },
                end: {
                    line: 297,
                    column: 33
                }
            },
            '129': {
                start: {
                    line: 300,
                    column: 8
                },
                end: {
                    line: 314,
                    column: 9
                }
            },
            '130': {
                start: {
                    line: 302,
                    column: 12
                },
                end: {
                    line: 313,
                    column: 13
                }
            },
            '131': {
                start: {
                    line: 304,
                    column: 16
                },
                end: {
                    line: 308,
                    column: 17
                }
            },
            '132': {
                start: {
                    line: 305,
                    column: 20
                },
                end: {
                    line: 305,
                    column: 46
                }
            },
            '133': {
                start: {
                    line: 307,
                    column: 20
                },
                end: {
                    line: 307,
                    column: 79
                }
            },
            '134': {
                start: {
                    line: 311,
                    column: 34
                },
                end: {
                    line: 311,
                    column: 65
                }
            },
            '135': {
                start: {
                    line: 312,
                    column: 16
                },
                end: {
                    line: 312,
                    column: 59
                }
            },
            '136': {
                start: {
                    line: 316,
                    column: 8
                },
                end: {
                    line: 318,
                    column: 9
                }
            },
            '137': {
                start: {
                    line: 317,
                    column: 12
                },
                end: {
                    line: 317,
                    column: 37
                }
            },
            '138': {
                start: {
                    line: 322,
                    column: 24
                },
                end: {
                    line: 322,
                    column: 52
                }
            },
            '139': {
                start: {
                    line: 323,
                    column: 8
                },
                end: {
                    line: 337,
                    column: 9
                }
            },
            '140': {
                start: {
                    line: 324,
                    column: 12
                },
                end: {
                    line: 324,
                    column: 36
                }
            },
            '141': {
                start: {
                    line: 325,
                    column: 12
                },
                end: {
                    line: 325,
                    column: 36
                }
            },
            '142': {
                start: {
                    line: 326,
                    column: 12
                },
                end: {
                    line: 326,
                    column: 39
                }
            },
            '143': {
                start: {
                    line: 327,
                    column: 15
                },
                end: {
                    line: 337,
                    column: 9
                }
            },
            '144': {
                start: {
                    line: 328,
                    column: 12
                },
                end: {
                    line: 328,
                    column: 42
                }
            },
            '145': {
                start: {
                    line: 329,
                    column: 12
                },
                end: {
                    line: 329,
                    column: 38
                }
            },
            '146': {
                start: {
                    line: 330,
                    column: 12
                },
                end: {
                    line: 330,
                    column: 39
                }
            },
            '147': {
                start: {
                    line: 331,
                    column: 15
                },
                end: {
                    line: 337,
                    column: 9
                }
            },
            '148': {
                start: {
                    line: 332,
                    column: 12
                },
                end: {
                    line: 332,
                    column: 27
                }
            },
            '149': {
                start: {
                    line: 334,
                    column: 12
                },
                end: {
                    line: 334,
                    column: 36
                }
            },
            '150': {
                start: {
                    line: 335,
                    column: 12
                },
                end: {
                    line: 335,
                    column: 37
                }
            },
            '151': {
                start: {
                    line: 336,
                    column: 12
                },
                end: {
                    line: 336,
                    column: 38
                }
            },
            '152': {
                start: {
                    line: 341,
                    column: 0
                },
                end: {
                    line: 350,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 40
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 14
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 4
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 19
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 18
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 21,
                        column: 30
                    },
                    end: {
                        line: 21,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 36
                    },
                    end: {
                        line: 23,
                        column: 9
                    }
                },
                line: 21
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 123
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                line: 29
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 14
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                line: 49
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 56,
                        column: 12
                    },
                    end: {
                        line: 56,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 26
                    },
                    end: {
                        line: 58,
                        column: 13
                    }
                },
                line: 56
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 63,
                        column: 4
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 18
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 63
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 66,
                        column: 30
                    },
                    end: {
                        line: 66,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 36
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                },
                line: 66
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 71,
                        column: 4
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 25
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                line: 71
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 75,
                        column: 58
                    },
                    end: {
                        line: 75,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 75,
                        column: 66
                    },
                    end: {
                        line: 75,
                        column: 70
                    }
                },
                line: 75
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 79,
                        column: 4
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 79,
                        column: 20
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                line: 79
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 92,
                        column: 4
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 20
                    },
                    end: {
                        line: 95,
                        column: 5
                    }
                },
                line: 92
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 97,
                        column: 4
                    },
                    end: {
                        line: 97,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 97,
                        column: 18
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                line: 97
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 104,
                        column: 4
                    },
                    end: {
                        line: 104,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 27
                    },
                    end: {
                        line: 111,
                        column: 5
                    }
                },
                line: 104
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 105,
                        column: 42
                    },
                    end: {
                        line: 105,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 105,
                        column: 56
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                },
                line: 105
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 113,
                        column: 4
                    },
                    end: {
                        line: 113,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 113,
                        column: 14
                    },
                    end: {
                        line: 139,
                        column: 5
                    }
                },
                line: 113
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 123,
                        column: 42
                    },
                    end: {
                        line: 123,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 123,
                        column: 56
                    },
                    end: {
                        line: 131,
                        column: 9
                    }
                },
                line: 123
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 134,
                        column: 40
                    },
                    end: {
                        line: 134,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 46
                    },
                    end: {
                        line: 137,
                        column: 13
                    }
                },
                line: 134
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 141,
                        column: 4
                    },
                    end: {
                        line: 141,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 141,
                        column: 36
                    },
                    end: {
                        line: 143,
                        column: 5
                    }
                },
                line: 141
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 145,
                        column: 4
                    },
                    end: {
                        line: 145,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 20
                    },
                    end: {
                        line: 152,
                        column: 5
                    }
                },
                line: 145
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 154,
                        column: 4
                    },
                    end: {
                        line: 154,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 154,
                        column: 37
                    },
                    end: {
                        line: 156,
                        column: 5
                    }
                },
                line: 154
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 158,
                        column: 4
                    },
                    end: {
                        line: 158,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 158,
                        column: 39
                    },
                    end: {
                        line: 161,
                        column: 5
                    }
                },
                line: 158
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 160,
                        column: 19
                    },
                    end: {
                        line: 160,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 160,
                        column: 27
                    },
                    end: {
                        line: 160,
                        column: 114
                    }
                },
                line: 160
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 160,
                        column: 68
                    },
                    end: {
                        line: 160,
                        column: 69
                    }
                },
                loc: {
                    start: {
                        line: 160,
                        column: 77
                    },
                    end: {
                        line: 160,
                        column: 113
                    }
                },
                line: 160
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 163,
                        column: 4
                    },
                    end: {
                        line: 163,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 163,
                        column: 22
                    },
                    end: {
                        line: 165,
                        column: 5
                    }
                },
                line: 163
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 167,
                        column: 4
                    },
                    end: {
                        line: 167,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 167,
                        column: 20
                    },
                    end: {
                        line: 174,
                        column: 5
                    }
                },
                line: 167
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 176,
                        column: 4
                    },
                    end: {
                        line: 176,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 176,
                        column: 37
                    },
                    end: {
                        line: 179,
                        column: 5
                    }
                },
                line: 176
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 181,
                        column: 4
                    },
                    end: {
                        line: 181,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 181,
                        column: 24
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                line: 181
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 198,
                        column: 4
                    },
                    end: {
                        line: 198,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 198,
                        column: 33
                    },
                    end: {
                        line: 207,
                        column: 5
                    }
                },
                line: 198
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 213,
                        column: 4
                    },
                    end: {
                        line: 213,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 213,
                        column: 79
                    },
                    end: {
                        line: 228,
                        column: 5
                    }
                },
                line: 213
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 230,
                        column: 4
                    },
                    end: {
                        line: 230,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 230,
                        column: 14
                    },
                    end: {
                        line: 233,
                        column: 5
                    }
                },
                line: 230
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 232,
                        column: 40
                    },
                    end: {
                        line: 232,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 232,
                        column: 56
                    },
                    end: {
                        line: 232,
                        column: 85
                    }
                },
                line: 232
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 235,
                        column: 4
                    },
                    end: {
                        line: 235,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 235,
                        column: 17
                    },
                    end: {
                        line: 237,
                        column: 5
                    }
                },
                line: 235
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 236,
                        column: 40
                    },
                    end: {
                        line: 236,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 236,
                        column: 56
                    },
                    end: {
                        line: 236,
                        column: 85
                    }
                },
                line: 236
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 239,
                        column: 4
                    },
                    end: {
                        line: 239,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 239,
                        column: 21
                    },
                    end: {
                        line: 242,
                        column: 5
                    }
                },
                line: 239
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 244,
                        column: 4
                    },
                    end: {
                        line: 244,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 244,
                        column: 57
                    },
                    end: {
                        line: 250,
                        column: 5
                    }
                },
                line: 244
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 246,
                        column: 18
                    },
                    end: {
                        line: 246,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 246,
                        column: 26
                    },
                    end: {
                        line: 247,
                        column: 75
                    }
                },
                line: 246
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 249,
                        column: 12
                    },
                    end: {
                        line: 249,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 249,
                        column: 18
                    },
                    end: {
                        line: 249,
                        column: 117
                    }
                },
                line: 249
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 249,
                        column: 93
                    },
                    end: {
                        line: 249,
                        column: 94
                    }
                },
                loc: {
                    start: {
                        line: 249,
                        column: 99
                    },
                    end: {
                        line: 249,
                        column: 116
                    }
                },
                line: 249
            },
            '41': {
                name: '(anonymous_41)',
                decl: {
                    start: {
                        line: 252,
                        column: 4
                    },
                    end: {
                        line: 252,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 252,
                        column: 25
                    },
                    end: {
                        line: 272,
                        column: 5
                    }
                },
                line: 252
            },
            '42': {
                name: '(anonymous_42)',
                decl: {
                    start: {
                        line: 274,
                        column: 4
                    },
                    end: {
                        line: 274,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 274,
                        column: 24
                    },
                    end: {
                        line: 294,
                        column: 5
                    }
                },
                line: 274
            },
            '43': {
                name: '(anonymous_43)',
                decl: {
                    start: {
                        line: 296,
                        column: 4
                    },
                    end: {
                        line: 296,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 296,
                        column: 22
                    },
                    end: {
                        line: 319,
                        column: 5
                    }
                },
                line: 296
            },
            '44': {
                name: '(anonymous_44)',
                decl: {
                    start: {
                        line: 321,
                        column: 4
                    },
                    end: {
                        line: 321,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 321,
                        column: 29
                    },
                    end: {
                        line: 338,
                        column: 5
                    }
                },
                line: 321
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 72,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 72,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                }, {
                    start: {
                        line: 72,
                        column: 8
                    },
                    end: {
                        line: 74,
                        column: 9
                    }
                }],
                line: 72
            },
            '1': {
                loc: {
                    start: {
                        line: 72,
                        column: 12
                    },
                    end: {
                        line: 72,
                        column: 43
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 72,
                        column: 12
                    },
                    end: {
                        line: 72,
                        column: 27
                    }
                }, {
                    start: {
                        line: 72,
                        column: 31
                    },
                    end: {
                        line: 72,
                        column: 43
                    }
                }],
                line: 72
            },
            '2': {
                loc: {
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 85,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 85,
                        column: 9
                    }
                }, {
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 85,
                        column: 9
                    }
                }],
                line: 80
            },
            '3': {
                loc: {
                    start: {
                        line: 81,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 81,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                }, {
                    start: {
                        line: 81,
                        column: 12
                    },
                    end: {
                        line: 83,
                        column: 13
                    }
                }],
                line: 81
            },
            '4': {
                loc: {
                    start: {
                        line: 81,
                        column: 16
                    },
                    end: {
                        line: 81,
                        column: 83
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 81,
                        column: 16
                    },
                    end: {
                        line: 81,
                        column: 40
                    }
                }, {
                    start: {
                        line: 81,
                        column: 44
                    },
                    end: {
                        line: 81,
                        column: 83
                    }
                }],
                line: 81
            },
            '5': {
                loc: {
                    start: {
                        line: 86,
                        column: 8
                    },
                    end: {
                        line: 88,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 86,
                        column: 8
                    },
                    end: {
                        line: 88,
                        column: 9
                    }
                }, {
                    start: {
                        line: 86,
                        column: 8
                    },
                    end: {
                        line: 88,
                        column: 9
                    }
                }],
                line: 86
            },
            '6': {
                loc: {
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 50
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 25
                    }
                }, {
                    start: {
                        line: 86,
                        column: 29
                    },
                    end: {
                        line: 86,
                        column: 50
                    }
                }],
                line: 86
            },
            '7': {
                loc: {
                    start: {
                        line: 89,
                        column: 15
                    },
                    end: {
                        line: 89,
                        column: 49
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 89,
                        column: 15
                    },
                    end: {
                        line: 89,
                        column: 27
                    }
                }, {
                    start: {
                        line: 89,
                        column: 31
                    },
                    end: {
                        line: 89,
                        column: 49
                    }
                }],
                line: 89
            },
            '8': {
                loc: {
                    start: {
                        line: 99,
                        column: 28
                    },
                    end: {
                        line: 99,
                        column: 67
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 99,
                        column: 44
                    },
                    end: {
                        line: 99,
                        column: 60
                    }
                }, {
                    start: {
                        line: 99,
                        column: 63
                    },
                    end: {
                        line: 99,
                        column: 67
                    }
                }],
                line: 99
            },
            '9': {
                loc: {
                    start: {
                        line: 106,
                        column: 12
                    },
                    end: {
                        line: 108,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 106,
                        column: 12
                    },
                    end: {
                        line: 108,
                        column: 13
                    }
                }, {
                    start: {
                        line: 106,
                        column: 12
                    },
                    end: {
                        line: 108,
                        column: 13
                    }
                }],
                line: 106
            },
            '10': {
                loc: {
                    start: {
                        line: 117,
                        column: 8
                    },
                    end: {
                        line: 119,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 117,
                        column: 8
                    },
                    end: {
                        line: 119,
                        column: 9
                    }
                }, {
                    start: {
                        line: 117,
                        column: 8
                    },
                    end: {
                        line: 119,
                        column: 9
                    }
                }],
                line: 117
            },
            '11': {
                loc: {
                    start: {
                        line: 124,
                        column: 12
                    },
                    end: {
                        line: 130,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 124,
                        column: 12
                    },
                    end: {
                        line: 130,
                        column: 13
                    }
                }, {
                    start: {
                        line: 124,
                        column: 12
                    },
                    end: {
                        line: 130,
                        column: 13
                    }
                }],
                line: 124
            },
            '12': {
                loc: {
                    start: {
                        line: 125,
                        column: 29
                    },
                    end: {
                        line: 125,
                        column: 84
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 125,
                        column: 71
                    },
                    end: {
                        line: 125,
                        column: 76
                    }
                }, {
                    start: {
                        line: 125,
                        column: 79
                    },
                    end: {
                        line: 125,
                        column: 84
                    }
                }],
                line: 125
            },
            '13': {
                loc: {
                    start: {
                        line: 133,
                        column: 8
                    },
                    end: {
                        line: 138,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 133,
                        column: 8
                    },
                    end: {
                        line: 138,
                        column: 9
                    }
                }, {
                    start: {
                        line: 133,
                        column: 8
                    },
                    end: {
                        line: 138,
                        column: 9
                    }
                }],
                line: 133
            },
            '14': {
                loc: {
                    start: {
                        line: 147,
                        column: 8
                    },
                    end: {
                        line: 149,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 147,
                        column: 8
                    },
                    end: {
                        line: 149,
                        column: 9
                    }
                }, {
                    start: {
                        line: 147,
                        column: 8
                    },
                    end: {
                        line: 149,
                        column: 9
                    }
                }],
                line: 147
            },
            '15': {
                loc: {
                    start: {
                        line: 168,
                        column: 8
                    },
                    end: {
                        line: 173,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 168,
                        column: 8
                    },
                    end: {
                        line: 173,
                        column: 9
                    }
                }, {
                    start: {
                        line: 168,
                        column: 8
                    },
                    end: {
                        line: 173,
                        column: 9
                    }
                }],
                line: 168
            },
            '16': {
                loc: {
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 189,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 189,
                        column: 9
                    }
                }, {
                    start: {
                        line: 183,
                        column: 8
                    },
                    end: {
                        line: 189,
                        column: 9
                    }
                }],
                line: 183
            },
            '17': {
                loc: {
                    start: {
                        line: 183,
                        column: 12
                    },
                    end: {
                        line: 183,
                        column: 75
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 183,
                        column: 12
                    },
                    end: {
                        line: 183,
                        column: 24
                    }
                }, {
                    start: {
                        line: 183,
                        column: 28
                    },
                    end: {
                        line: 183,
                        column: 75
                    }
                }],
                line: 183
            },
            '18': {
                loc: {
                    start: {
                        line: 184,
                        column: 12
                    },
                    end: {
                        line: 187,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 184,
                        column: 12
                    },
                    end: {
                        line: 187,
                        column: 13
                    }
                }, {
                    start: {
                        line: 184,
                        column: 12
                    },
                    end: {
                        line: 187,
                        column: 13
                    }
                }],
                line: 184
            },
            '19': {
                loc: {
                    start: {
                        line: 199,
                        column: 8
                    },
                    end: {
                        line: 201,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 199,
                        column: 8
                    },
                    end: {
                        line: 201,
                        column: 9
                    }
                }, {
                    start: {
                        line: 199,
                        column: 8
                    },
                    end: {
                        line: 201,
                        column: 9
                    }
                }],
                line: 199
            },
            '20': {
                loc: {
                    start: {
                        line: 199,
                        column: 12
                    },
                    end: {
                        line: 199,
                        column: 152
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 199,
                        column: 12
                    },
                    end: {
                        line: 199,
                        column: 48
                    }
                }, {
                    start: {
                        line: 199,
                        column: 52
                    },
                    end: {
                        line: 199,
                        column: 101
                    }
                }, {
                    start: {
                        line: 199,
                        column: 105
                    },
                    end: {
                        line: 199,
                        column: 152
                    }
                }],
                line: 199
            },
            '21': {
                loc: {
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                }, {
                    start: {
                        line: 202,
                        column: 8
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                }],
                line: 202
            },
            '22': {
                loc: {
                    start: {
                        line: 202,
                        column: 12
                    },
                    end: {
                        line: 202,
                        column: 97
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 202,
                        column: 12
                    },
                    end: {
                        line: 202,
                        column: 46
                    }
                }, {
                    start: {
                        line: 202,
                        column: 50
                    },
                    end: {
                        line: 202,
                        column: 97
                    }
                }],
                line: 202
            },
            '23': {
                loc: {
                    start: {
                        line: 300,
                        column: 8
                    },
                    end: {
                        line: 314,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 300,
                        column: 8
                    },
                    end: {
                        line: 314,
                        column: 9
                    }
                }, {
                    start: {
                        line: 300,
                        column: 8
                    },
                    end: {
                        line: 314,
                        column: 9
                    }
                }],
                line: 300
            },
            '24': {
                loc: {
                    start: {
                        line: 302,
                        column: 12
                    },
                    end: {
                        line: 313,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 302,
                        column: 12
                    },
                    end: {
                        line: 313,
                        column: 13
                    }
                }, {
                    start: {
                        line: 302,
                        column: 12
                    },
                    end: {
                        line: 313,
                        column: 13
                    }
                }],
                line: 302
            },
            '25': {
                loc: {
                    start: {
                        line: 304,
                        column: 16
                    },
                    end: {
                        line: 308,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 304,
                        column: 16
                    },
                    end: {
                        line: 308,
                        column: 17
                    }
                }, {
                    start: {
                        line: 304,
                        column: 16
                    },
                    end: {
                        line: 308,
                        column: 17
                    }
                }],
                line: 304
            },
            '26': {
                loc: {
                    start: {
                        line: 316,
                        column: 8
                    },
                    end: {
                        line: 318,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 316,
                        column: 8
                    },
                    end: {
                        line: 318,
                        column: 9
                    }
                }, {
                    start: {
                        line: 316,
                        column: 8
                    },
                    end: {
                        line: 318,
                        column: 9
                    }
                }],
                line: 316
            },
            '27': {
                loc: {
                    start: {
                        line: 323,
                        column: 8
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 323,
                        column: 8
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }, {
                    start: {
                        line: 323,
                        column: 8
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }],
                line: 323
            },
            '28': {
                loc: {
                    start: {
                        line: 327,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 327,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }, {
                    start: {
                        line: 327,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }],
                line: 327
            },
            '29': {
                loc: {
                    start: {
                        line: 327,
                        column: 19
                    },
                    end: {
                        line: 327,
                        column: 116
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 327,
                        column: 19
                    },
                    end: {
                        line: 327,
                        column: 48
                    }
                }, {
                    start: {
                        line: 327,
                        column: 52
                    },
                    end: {
                        line: 327,
                        column: 116
                    }
                }],
                line: 327
            },
            '30': {
                loc: {
                    start: {
                        line: 331,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 331,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }, {
                    start: {
                        line: 331,
                        column: 15
                    },
                    end: {
                        line: 337,
                        column: 9
                    }
                }],
                line: 331
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0,
            '94': 0,
            '95': 0,
            '96': 0,
            '97': 0,
            '98': 0,
            '99': 0,
            '100': 0,
            '101': 0,
            '102': 0,
            '103': 0,
            '104': 0,
            '105': 0,
            '106': 0,
            '107': 0,
            '108': 0,
            '109': 0,
            '110': 0,
            '111': 0,
            '112': 0,
            '113': 0,
            '114': 0,
            '115': 0,
            '116': 0,
            '117': 0,
            '118': 0,
            '119': 0,
            '120': 0,
            '121': 0,
            '122': 0,
            '123': 0,
            '124': 0,
            '125': 0,
            '126': 0,
            '127': 0,
            '128': 0,
            '129': 0,
            '130': 0,
            '131': 0,
            '132': 0,
            '133': 0,
            '134': 0,
            '135': 0,
            '136': 0,
            '137': 0,
            '138': 0,
            '139': 0,
            '140': 0,
            '141': 0,
            '142': 0,
            '143': 0,
            '144': 0,
            '145': 0,
            '146': 0,
            '147': 0,
            '148': 0,
            '149': 0,
            '150': 0,
            '151': 0,
            '152': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0],
            '15': [0, 0],
            '16': [0, 0],
            '17': [0, 0],
            '18': [0, 0],
            '19': [0, 0],
            '20': [0, 0, 0],
            '21': [0, 0],
            '22': [0, 0],
            '23': [0, 0],
            '24': [0, 0],
            '25': [0, 0],
            '26': [0, 0],
            '27': [0, 0],
            '28': [0, 0],
            '29': [0, 0],
            '30': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckoutDialogController = function () {
    function CheckoutDialogController(mdPanelRef, $rootScope) {
        _classCallCheck(this, CheckoutDialogController);

        cov_1ekedaiuiu.f[0]++;
        cov_1ekedaiuiu.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_1ekedaiuiu.s[1]++;
        this.$rootScope = $rootScope;
    }

    _createClass(CheckoutDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1ekedaiuiu.f[1]++;
            cov_1ekedaiuiu.s[2]++;

            this.sample.$load('sampleMeshes');
            cov_1ekedaiuiu.s[3]++;
            this.sample.$sampleMeshes.$load('mesh');
        }
    }, {
        key: 'checkoutWork',
        value: function checkoutWork() {
            cov_1ekedaiuiu.f[2]++;
            cov_1ekedaiuiu.s[4]++;

            this.handler();
            cov_1ekedaiuiu.s[5]++;
            this.closeDialog();
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_1ekedaiuiu.f[3]++;

            var panelRef = (cov_1ekedaiuiu.s[6]++, this._mdPanelRef);

            cov_1ekedaiuiu.s[7]++;
            panelRef.close().then(function () {
                cov_1ekedaiuiu.f[4]++;
                cov_1ekedaiuiu.s[8]++;

                panelRef.destroy();
            });
        }
    }]);

    return CheckoutDialogController;
}();

var CheckinDialogController = function () {
    function CheckinDialogController(mdPanelRef, $rootScope, sampleRouting, ChecklistsTable, $mdToast, typifiedRoutings, MeshConsumptionsTable) {
        _classCallCheck(this, CheckinDialogController);

        cov_1ekedaiuiu.f[5]++;
        cov_1ekedaiuiu.s[9]++;

        this._mdPanelRef = mdPanelRef;
        cov_1ekedaiuiu.s[10]++;
        this.$rootScope = $rootScope;
        cov_1ekedaiuiu.s[11]++;
        this.$mdToast = $mdToast;
        cov_1ekedaiuiu.s[12]++;
        this.acceptFiles = "image/jpeg,image/png,application/pdf";
        cov_1ekedaiuiu.s[13]++;
        this.maxSize = "5MB";
        cov_1ekedaiuiu.s[14]++;
        this.sampleRouting = sampleRouting;
        cov_1ekedaiuiu.s[15]++;
        this.routing = false;
        cov_1ekedaiuiu.s[16]++;
        this.typifiedRoutings = typifiedRoutings();
        cov_1ekedaiuiu.s[17]++;
        this.ChecklistsTable = ChecklistsTable;
        cov_1ekedaiuiu.s[18]++;
        this.MeshConsumptionsTable = MeshConsumptionsTable;
        cov_1ekedaiuiu.s[19]++;
        this.checklistItemsChecked = [];
        cov_1ekedaiuiu.s[20]++;
        this.files = [];
        cov_1ekedaiuiu.s[21]++;
        this.updateFilesDisplay = this.updateFilesDisplay.bind(this);
        cov_1ekedaiuiu.s[22]++;
        this.showToast = this.showToast.bind(this);
        cov_1ekedaiuiu.s[23]++;
        this.consume = this.consume.bind(this);
        cov_1ekedaiuiu.s[24]++;
        this.consumptionsLogged = false;
        cov_1ekedaiuiu.s[25]++;
        this.consumptionsErrors = [];
    }

    _createClass(CheckinDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_1ekedaiuiu.f[6]++;
            cov_1ekedaiuiu.s[26]++;

            this.routingRules = this.sampleRouting(this.$rootScope.me);
            var event = (cov_1ekedaiuiu.s[27]++, 'SAMPLE_CHECKIN');
            /* eslint-disable camelcase */
            var params = (cov_1ekedaiuiu.s[28]++, { event: event, user_type: this.$rootScope.me.type });

            cov_1ekedaiuiu.s[29]++;
            this.ChecklistsTable.$search(params).$then(function (checklists) {
                cov_1ekedaiuiu.f[7]++;
                cov_1ekedaiuiu.s[30]++;

                _this.checklist = checklists[0].$load('items');
            });

            cov_1ekedaiuiu.s[31]++;
            this.sample.$load('sampleMeshes');
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_1ekedaiuiu.f[8]++;

            var panelRef = (cov_1ekedaiuiu.s[32]++, this._mdPanelRef);

            cov_1ekedaiuiu.s[33]++;
            panelRef.close().then(function () {
                cov_1ekedaiuiu.f[9]++;
                cov_1ekedaiuiu.s[34]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'checklistCompleted',
        value: function checklistCompleted() {
            cov_1ekedaiuiu.f[10]++;
            cov_1ekedaiuiu.s[35]++;

            if ((cov_1ekedaiuiu.b[1][0]++, !this.checklist) || (cov_1ekedaiuiu.b[1][1]++, this.routing)) {
                cov_1ekedaiuiu.b[0][0]++;
                cov_1ekedaiuiu.s[36]++;

                return true;
            } else {
                cov_1ekedaiuiu.b[0][1]++;
            }
            var checked = (cov_1ekedaiuiu.s[37]++, this.checklistItemsChecked.filter(function (item) {
                cov_1ekedaiuiu.f[11]++;
                cov_1ekedaiuiu.s[38]++;
                return item;
            }));
            cov_1ekedaiuiu.s[39]++;
            return checked.length === this.checklist.$items.length;
        }
    }, {
        key: 'disableSubmit',
        value: function disableSubmit() {
            cov_1ekedaiuiu.f[12]++;
            cov_1ekedaiuiu.s[40]++;

            if (!this.routing) {
                cov_1ekedaiuiu.b[2][0]++;
                cov_1ekedaiuiu.s[41]++;

                if ((cov_1ekedaiuiu.b[4][0]++, this.askExceededReason()) && (cov_1ekedaiuiu.b[4][1]++, !this.sample.foreseenDateExceededReason)) {
                    cov_1ekedaiuiu.b[3][0]++;
                    cov_1ekedaiuiu.s[42]++;

                    return true;
                } else {
                    cov_1ekedaiuiu.b[3][1]++;
                }
                cov_1ekedaiuiu.s[43]++;
                return !this.checklistCompleted();
            } else {
                cov_1ekedaiuiu.b[2][1]++;
            }
            cov_1ekedaiuiu.s[44]++;
            if ((cov_1ekedaiuiu.b[6][0]++, this.typified) && (cov_1ekedaiuiu.b[6][1]++, this.typified !== ' ')) {
                cov_1ekedaiuiu.b[5][0]++;
                cov_1ekedaiuiu.s[45]++;

                return false;
            } else {
                cov_1ekedaiuiu.b[5][1]++;
            }
            cov_1ekedaiuiu.s[46]++;
            return (cov_1ekedaiuiu.b[7][0]++, !this.routed) || (cov_1ekedaiuiu.b[7][1]++, !this.observations);
        }
    }, {
        key: 'toogleRouting',
        value: function toogleRouting() {
            cov_1ekedaiuiu.f[13]++;
            cov_1ekedaiuiu.s[47]++;

            this.routed = undefined;
            cov_1ekedaiuiu.s[48]++;
            this.routing = !this.routing;
        }
    }, {
        key: 'checkinWork',
        value: function checkinWork() {
            cov_1ekedaiuiu.f[14]++;
            cov_1ekedaiuiu.s[49]++;

            this.sample.$save(['decorationPosition']);
            var typified_id = (cov_1ekedaiuiu.s[50]++, this.typified ? (cov_1ekedaiuiu.b[8][0]++, this.typified.id) : (cov_1ekedaiuiu.b[8][1]++, null));
            cov_1ekedaiuiu.s[51]++;
            this.handler(this.observations, this.routed, this.files, typified_id);
            cov_1ekedaiuiu.s[52]++;
            this.closeDialog();
        }
    }, {
        key: 'validateConsumptions',
        value: function validateConsumptions() {
            var _this2 = this;

            cov_1ekedaiuiu.f[15]++;
            cov_1ekedaiuiu.s[53]++;

            this.sample.$sampleMeshes.forEach(function (sampleMesh) {
                cov_1ekedaiuiu.f[16]++;
                cov_1ekedaiuiu.s[54]++;

                if (parseFloat(sampleMesh.$mesh.consume) > parseFloat(sampleMesh.$mesh.currentQuantity)) {
                    cov_1ekedaiuiu.b[9][0]++;
                    cov_1ekedaiuiu.s[55]++;

                    _this2.consumptionsErrors.push('Quantidade insuficiente da malha M#' + sampleMesh.$mesh.id);
                } else {
                    cov_1ekedaiuiu.b[9][1]++;
                }
            });
            cov_1ekedaiuiu.s[56]++;
            return this.consumptionsErrors.length === 0;
        }
    }, {
        key: 'consume',
        value: function consume() {
            var _this3 = this;

            cov_1ekedaiuiu.f[17]++;
            cov_1ekedaiuiu.s[57]++;

            this.consumptionsErrors = [];

            // validação antes de prosseguir
            cov_1ekedaiuiu.s[58]++;
            if (!this.validateConsumptions()) {
                cov_1ekedaiuiu.b[10][0]++;
                cov_1ekedaiuiu.s[59]++;

                return;
            } else {
                cov_1ekedaiuiu.b[10][1]++;
            }

            var promisses = (cov_1ekedaiuiu.s[60]++, []);
            var promise = void 0;
            cov_1ekedaiuiu.s[61]++;
            this.sample.$sampleMeshes.forEach(function (sampleMesh) {
                cov_1ekedaiuiu.f[18]++;
                cov_1ekedaiuiu.s[62]++;

                if (sampleMesh.$mesh.consume) {
                    cov_1ekedaiuiu.b[11][0]++;

                    var unit = (cov_1ekedaiuiu.s[63]++, sampleMesh.$mesh.quantityUnit === 'PCS' ? (cov_1ekedaiuiu.b[12][0]++, 'PCS') : (cov_1ekedaiuiu.b[12][1]++, 'MTS'));
                    cov_1ekedaiuiu.s[64]++;
                    promise = _this3.MeshConsumptionsTable.$create({
                        mesh: sampleMesh.$mesh.id, quantity: sampleMesh.$mesh.consume,
                        purpose: "corte amostras", unit: unit });
                    cov_1ekedaiuiu.s[65]++;
                    promisses.push(promise);
                } else {
                    cov_1ekedaiuiu.b[11][1]++;
                }
            });

            cov_1ekedaiuiu.s[66]++;
            if (promisses.length > 0) {
                cov_1ekedaiuiu.b[13][0]++;
                cov_1ekedaiuiu.s[67]++;

                Promise.all(promisses).then(function () {
                    cov_1ekedaiuiu.f[19]++;
                    cov_1ekedaiuiu.s[68]++;

                    _this3.consumptionsLogged = true;
                    cov_1ekedaiuiu.s[69]++;
                    _this3.$mdToast.show(_this3.$mdToast.simple().textContent("Consumos Adicionados."));
                });
            } else {
                cov_1ekedaiuiu.b[13][1]++;
            }
        }
    }, {
        key: 'updateFilesDisplay',
        value: function updateFilesDisplay(file, media) {
            cov_1ekedaiuiu.f[20]++;
            cov_1ekedaiuiu.s[70]++;

            this.files.push({ file: file, media: media });
        }
    }, {
        key: 'showToast',
        value: function showToast(file) {
            cov_1ekedaiuiu.f[21]++;

            var reason = (cov_1ekedaiuiu.s[71]++, '');
            cov_1ekedaiuiu.s[72]++;
            if (file.$error === 'maxSize') {
                cov_1ekedaiuiu.b[14][0]++;
                cov_1ekedaiuiu.s[73]++;

                reason = ' .Tamanho excedido (m\xE1x ' + this.maxSize + ').';
            } else {
                cov_1ekedaiuiu.b[14][1]++;
            }
            var msg = (cov_1ekedaiuiu.s[74]++, 'Erro imagem ' + file.name + ' ' + reason);
            cov_1ekedaiuiu.s[75]++;
            this.$mdToast.show(this.$mdToast.simple().textContent(msg));
        }
    }, {
        key: 'alertInvalidFiles',
        value: function alertInvalidFiles($invalidFiles) {
            cov_1ekedaiuiu.f[22]++;
            cov_1ekedaiuiu.s[76]++;

            $invalidFiles.map(this.showToast);
        }
    }, {
        key: 'uploadFiles',
        value: function uploadFiles($files, $invalidFiles) {
            var _this4 = this;

            cov_1ekedaiuiu.f[23]++;
            cov_1ekedaiuiu.s[77]++;

            this.alertInvalidFiles($invalidFiles);
            cov_1ekedaiuiu.s[78]++;
            $files.map(function (file) {
                cov_1ekedaiuiu.f[24]++;
                cov_1ekedaiuiu.s[79]++;
                return _this4.$rootScope.$Media.upload(file).then(function (media) {
                    cov_1ekedaiuiu.f[25]++;
                    cov_1ekedaiuiu.s[80]++;
                    return _this4.updateFilesDisplay(file, media);
                });
            });
        }
    }, {
        key: 'removeFile',
        value: function removeFile(index) {
            cov_1ekedaiuiu.f[26]++;
            cov_1ekedaiuiu.s[81]++;

            this.files.splice(index, 1);
        }
    }, {
        key: 'updateRouting',
        value: function updateRouting() {
            cov_1ekedaiuiu.f[27]++;
            cov_1ekedaiuiu.s[82]++;

            if (this.typified === 'OTHER') {
                cov_1ekedaiuiu.b[15][0]++;
                cov_1ekedaiuiu.s[83]++;

                this.routed = null;
                cov_1ekedaiuiu.s[84]++;
                this.observations = null;
            } else {
                cov_1ekedaiuiu.b[15][1]++;
                cov_1ekedaiuiu.s[85]++;

                this.routed = this.typified.stateTo;
            }
        }
    }, {
        key: 'leewayExceeded',
        value: function leewayExceeded(foreseen, leeway) {
            cov_1ekedaiuiu.f[28]++;

            var now = (cov_1ekedaiuiu.s[86]++, new Date());
            cov_1ekedaiuiu.s[87]++;
            return now.setTime(now.getTime() - leeway) > foreseen;
        }
    }, {
        key: 'askExceededReason',
        value: function askExceededReason() {
            cov_1ekedaiuiu.f[29]++;

            var leeway = (cov_1ekedaiuiu.s[88]++, parseInt(this.$rootScope.FORESEEN_REASON.value, 10));
            cov_1ekedaiuiu.s[89]++;
            if ((cov_1ekedaiuiu.b[17][0]++, leeway !== 0) && (cov_1ekedaiuiu.b[17][1]++, this.sample.$currentState.sampleStateType === 7)) {
                cov_1ekedaiuiu.b[16][0]++;
                cov_1ekedaiuiu.s[90]++;

                if (this.sample.foreseenDate) {
                    cov_1ekedaiuiu.b[18][0]++;

                    var foreseen = (cov_1ekedaiuiu.s[91]++, this.sample.foreseenDate);
                    cov_1ekedaiuiu.s[92]++;
                    return this.leewayExceeded(foreseen, leeway);
                } else {
                    cov_1ekedaiuiu.b[18][1]++;
                }
                cov_1ekedaiuiu.s[93]++;
                return true;
            } else {
                cov_1ekedaiuiu.b[16][1]++;
            }
            cov_1ekedaiuiu.s[94]++;
            return false;
        }

        /**
         * should a transiton action be required from the user
         * return boolean value
         *
         */

    }, {
        key: 'transitionAction',
        value: function transitionAction(actionName) {
            cov_1ekedaiuiu.f[30]++;
            cov_1ekedaiuiu.s[95]++;

            if ((cov_1ekedaiuiu.b[20][0]++, actionName === 'decoration_position') && (cov_1ekedaiuiu.b[20][1]++, this.$rootScope.CAD_ATTACH_POSITION.value === '1') && (cov_1ekedaiuiu.b[20][2]++, this.sample.$currentState.sampleStateType === 2)) {
                cov_1ekedaiuiu.b[19][0]++;
                cov_1ekedaiuiu.s[96]++;

                return !this.routing;
            } else {
                cov_1ekedaiuiu.b[19][1]++;
            }
            cov_1ekedaiuiu.s[97]++;
            if ((cov_1ekedaiuiu.b[22][0]++, actionName === 'mesh_consumptions') && (cov_1ekedaiuiu.b[22][1]++, this.sample.$currentState.sampleStateType === 3)) {
                cov_1ekedaiuiu.b[21][0]++;
                cov_1ekedaiuiu.s[98]++;

                return !this.routing;
            } else {
                cov_1ekedaiuiu.b[21][1]++;
            }

            cov_1ekedaiuiu.s[99]++;
            return false;
        }
    }]);

    return CheckinDialogController;
}();

var WorkHandlerController = function () {
    function WorkHandlerController($rootScope, $mdPanel, UserWorkingState, SampleStateFilesTable) {
        _classCallCheck(this, WorkHandlerController);

        cov_1ekedaiuiu.f[31]++;
        cov_1ekedaiuiu.s[100]++;

        this.$mdPanel = $mdPanel;
        cov_1ekedaiuiu.s[101]++;
        this.WaitingState = $rootScope.WAITING_STATE;
        cov_1ekedaiuiu.s[102]++;
        this.$rootScope = $rootScope;
        cov_1ekedaiuiu.s[103]++;
        this.work = {};

        cov_1ekedaiuiu.s[104]++;
        this.checkWork = this.checkWork.bind(this);
        cov_1ekedaiuiu.s[105]++;
        this.defineWork = this.defineWork.bind(this);
        cov_1ekedaiuiu.s[106]++;
        this.handleCheckin = this.handleCheckin.bind(this);
        cov_1ekedaiuiu.s[107]++;
        this.handleCheckout = this.handleCheckout.bind(this);

        cov_1ekedaiuiu.s[108]++;
        this.SampleStateFilesTable = SampleStateFilesTable;

        /* eslint-disable  babel/new-cap */
        cov_1ekedaiuiu.s[109]++;
        this.userWorkingStates = UserWorkingState();
    }

    _createClass(WorkHandlerController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this5 = this;

            cov_1ekedaiuiu.f[32]++;
            cov_1ekedaiuiu.s[110]++;

            this.sample.$load('currentState');
            cov_1ekedaiuiu.s[111]++;
            this.sample.$currentState.$then(function (currentState) {
                cov_1ekedaiuiu.f[33]++;
                cov_1ekedaiuiu.s[112]++;
                return _this5.defineWork(currentState);
            });
        }
    }, {
        key: '$onChanges',
        value: function $onChanges() {
            var _this6 = this;

            cov_1ekedaiuiu.f[34]++;
            cov_1ekedaiuiu.s[113]++;

            this.sample.$currentState.$then(function (currentState) {
                cov_1ekedaiuiu.f[35]++;
                cov_1ekedaiuiu.s[114]++;
                return _this6.defineWork(currentState);
            });
        }
    }, {
        key: 'handleCheckout',
        value: function handleCheckout() {
            cov_1ekedaiuiu.f[36]++;
            cov_1ekedaiuiu.s[115]++;

            // só está a ser usado pelo corte
            this.checkWorkItem(this.sample, 3);
        }
    }, {
        key: 'handleCheckin',
        value: function handleCheckin(observations, routed, files, typified) {
            var _this7 = this;

            cov_1ekedaiuiu.f[37]++;
            cov_1ekedaiuiu.s[116]++;

            this.sample.$currentState.observations = observations;
            cov_1ekedaiuiu.s[117]++;
            files.map(function (file) {
                cov_1ekedaiuiu.f[38]++;
                cov_1ekedaiuiu.s[118]++;
                return _this7.SampleStateFilesTable.$create({ sample_state: _this7.sample.$currentState.id, file: file.media });
            });
            cov_1ekedaiuiu.s[119]++;
            this.sample.$currentState.$save().$then(function () {
                cov_1ekedaiuiu.f[39]++;
                cov_1ekedaiuiu.s[120]++;
                return _this7.checkWorkItem(_this7.sample, _this7.WaitingState, routed, typified).$then(function () {
                    cov_1ekedaiuiu.f[40]++;
                    cov_1ekedaiuiu.s[121]++;
                    return _this7.defineWork();
                });
            });
        }
    }, {
        key: 'openCheckoutDialog',
        value: function openCheckoutDialog() {
            cov_1ekedaiuiu.f[41]++;

            var position = (cov_1ekedaiuiu.s[122]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_1ekedaiuiu.s[123]++, {
                /** @ngInject */
                controller: CheckoutDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/workHandler/checkoutDialog.html',
                hasBackdrop: true,
                panelClass: 'transition-modal',
                position: position,
                trapFocus: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    sample: this.sample,
                    handler: this.handleCheckout
                }
            });
            cov_1ekedaiuiu.s[124]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'openCheckinDialog',
        value: function openCheckinDialog() {
            cov_1ekedaiuiu.f[42]++;

            var position = (cov_1ekedaiuiu.s[125]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_1ekedaiuiu.s[126]++, {
                /** @ngInject */
                controller: CheckinDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/workHandler/checkinDialog.html',
                hasBackdrop: true,
                panelClass: 'transition-modal',
                position: position,
                trapFocus: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    sample: this.sample,
                    handler: this.handleCheckin
                }
            });
            cov_1ekedaiuiu.s[127]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'checkWork',
        value: function checkWork($event) {
            cov_1ekedaiuiu.f[43]++;
            cov_1ekedaiuiu.s[128]++;

            $event.stopPropagation();

            // user is making a checkout request
            cov_1ekedaiuiu.s[129]++;
            if (this.sample.$currentState.sampleStateType === this.WaitingState) {
                cov_1ekedaiuiu.b[23][0]++;
                cov_1ekedaiuiu.s[130]++;

                // o tipo de utilizador só tem um estado associado
                if (this.userWorkingStates.length === 1) {
                    cov_1ekedaiuiu.b[24][0]++;
                    cov_1ekedaiuiu.s[131]++;

                    // o utilizador do corte tem checkout dialog
                    if (this.$rootScope.me.type === 'CUTTING') {
                        cov_1ekedaiuiu.b[25][0]++;
                        cov_1ekedaiuiu.s[132]++;

                        this.openCheckoutDialog();
                    } else {
                        cov_1ekedaiuiu.b[25][1]++;
                        cov_1ekedaiuiu.s[133]++;

                        this.checkWorkItem(this.sample, this.userWorkingStates[0]);
                    }
                } else {
                    cov_1ekedaiuiu.b[24][1]++;

                    // este tipo de utilizador tem mais do que um estado associado
                    var nextState = (cov_1ekedaiuiu.s[134]++, this.sample.nextSampleStateType);
                    cov_1ekedaiuiu.s[135]++;
                    this.checkWorkItem(this.sample, nextState);
                }
            } else {
                cov_1ekedaiuiu.b[23][1]++;
            }

            cov_1ekedaiuiu.s[136]++;
            if (this.userWorkingStates.includes(this.sample.$currentState.sampleStateType)) {
                cov_1ekedaiuiu.b[26][0]++;
                cov_1ekedaiuiu.s[137]++;

                this.openCheckinDialog();
            } else {
                cov_1ekedaiuiu.b[26][1]++;
            }
        }
    }, {
        key: 'defineWork',
        value: function defineWork(currentState) {
            cov_1ekedaiuiu.f[44]++;

            var current = (cov_1ekedaiuiu.s[138]++, currentState.sampleStateType);
            cov_1ekedaiuiu.s[139]++;
            if (this.userWorkingStates.includes(current)) {
                cov_1ekedaiuiu.b[27][0]++;
                cov_1ekedaiuiu.s[140]++;

                this.work.icon = 'stop';
                cov_1ekedaiuiu.s[141]++;
                this.work.color = 'red';
                cov_1ekedaiuiu.s[142]++;
                this.work.disabled = false;
            } else {
                    cov_1ekedaiuiu.b[27][1]++;
                    cov_1ekedaiuiu.s[143]++;
                    if ((cov_1ekedaiuiu.b[29][0]++, current === this.WaitingState) && (cov_1ekedaiuiu.b[29][1]++, this.userWorkingStates.includes(this.sample.nextSampleStateType))) {
                        cov_1ekedaiuiu.b[28][0]++;
                        cov_1ekedaiuiu.s[144]++;

                        this.work.icon = 'play_arrow';
                        cov_1ekedaiuiu.s[145]++;
                        this.work.color = 'green';
                        cov_1ekedaiuiu.s[146]++;
                        this.work.disabled = false;
                    } else {
                            cov_1ekedaiuiu.b[28][1]++;
                            cov_1ekedaiuiu.s[147]++;
                            if ([9, 10].includes(current)) {
                                cov_1ekedaiuiu.b[30][0]++;
                                cov_1ekedaiuiu.s[148]++;

                                this.work = {};
                            } else {
                                cov_1ekedaiuiu.b[30][1]++;
                                cov_1ekedaiuiu.s[149]++;

                                this.work.icon = 'loop';
                                cov_1ekedaiuiu.s[150]++;
                                this.work.color = 'gray';
                                cov_1ekedaiuiu.s[151]++;
                                this.work.disabled = true;
                            }
                        }
                }
        }
    }]);

    return WorkHandlerController;
}();

cov_1ekedaiuiu.s[152]++;


angular.module('app').component('workHandler', {
    templateUrl: 'app/components/workHandler/workHandler.html',
    controller: WorkHandlerController,
    bindings: {
        sample: '<',
        checkWorkItem: '<'
    }
});