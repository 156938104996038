'use strict';

var cov_fd4ws32hd = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/announcementsList/announcementsList.js',
        hash = '4f5c2f3dec4da029b6ebf6af8364cb43879af6e7',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/announcementsList/announcementsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 26
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 49
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 10
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 37
                }
            },
            '8': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 36
                }
            },
            '9': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 50
                }
            },
            '10': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 40
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 54
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 41
                }
            },
            '16': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 37
                }
            },
            '17': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 41
                }
            },
            '18': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 45
                }
            },
            '19': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 37
                }
            },
            '20': {
                start: {
                    line: 48,
                    column: 0
                },
                end: {
                    line: 56,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 53
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 28
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 22
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 33
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 28
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                }, {
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                }],
                line: 29
            },
            '1': {
                loc: {
                    start: {
                        line: 33,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 33,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }, {
                    start: {
                        line: 33,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }],
                line: 33
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AnnouncementsListController = function () {
    function AnnouncementsListController($location, $rootScope, $stateParams) {
        _classCallCheck(this, AnnouncementsListController);

        cov_fd4ws32hd.f[0]++;
        cov_fd4ws32hd.s[0]++;

        this.$location = $location;
        cov_fd4ws32hd.s[1]++;
        this.$rootScope = $rootScope;
        cov_fd4ws32hd.s[2]++;
        this.$stateParams = $stateParams;
        cov_fd4ws32hd.s[3]++;
        this.filters = {};
    }

    _createClass(AnnouncementsListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_fd4ws32hd.f[1]++;
            cov_fd4ws32hd.s[4]++;

            this.filters = this.$stateParams;
            cov_fd4ws32hd.s[5]++;
            this.userFilter = this.$stateParams.user;
            cov_fd4ws32hd.s[6]++;
            this.announcements.$params = {
                /* eslint-disable camelcase */
                page_size: 25,
                ordering: '-id'
            };

            cov_fd4ws32hd.s[7]++;
            this._refreshAnnouncements();
        }
    }, {
        key: '_refreshAnnouncements',
        value: function _refreshAnnouncements() {
            cov_fd4ws32hd.f[2]++;
            cov_fd4ws32hd.s[8]++;

            /* eslint-disable camelcase */
            this.filters.page_size = 25;
            cov_fd4ws32hd.s[9]++;
            this.announcements.$refresh(this.filters);
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_fd4ws32hd.f[3]++;
            cov_fd4ws32hd.s[10]++;

            if (angular.isArray(value)) {
                cov_fd4ws32hd.b[0][0]++;
                cov_fd4ws32hd.s[11]++;

                value = value.join(',');
            } else {
                cov_fd4ws32hd.b[0][1]++;
            }

            cov_fd4ws32hd.s[12]++;
            if (value === null) {
                cov_fd4ws32hd.b[1][0]++;
                cov_fd4ws32hd.s[13]++;

                delete this.filters[filter];
                cov_fd4ws32hd.s[14]++;
                delete this.announcements.$params[filter];
            } else {
                cov_fd4ws32hd.b[1][1]++;
                cov_fd4ws32hd.s[15]++;

                this.filters[filter] = value;
            }

            cov_fd4ws32hd.s[16]++;
            this.announcements.$page = 1;
            cov_fd4ws32hd.s[17]++;
            this.$location.search('page', 1);
            cov_fd4ws32hd.s[18]++;
            this.$location.search(filter, value);
            cov_fd4ws32hd.s[19]++;
            this._refreshAnnouncements();
        }
    }]);

    return AnnouncementsListController;
}();

cov_fd4ws32hd.s[20]++;


angular.module('app').component('announcementsList', {
    templateUrl: 'app/components/announcementsList/announcementsList.html',
    controller: AnnouncementsListController,
    bindings: {
        announcements: '<'
    }
});