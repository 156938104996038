'use strict';

var cov_24co93wawf = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/auth/auth.login.controller.js',
        hash = 'dad3fe4afa7373756cfac7273f3d34534bc6b896',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/auth/auth.login.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 51,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 19
                },
                end: {
                    line: 10,
                    column: 23
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 25
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 30
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 39
                }
            },
            '9': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 15
                }
            },
            '10': {
                start: {
                    line: 27,
                    column: 16
                },
                end: {
                    line: 27,
                    column: 29
                }
            },
            '11': {
                start: {
                    line: 29,
                    column: 16
                },
                end: {
                    line: 29,
                    column: 34
                }
            },
            '12': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 15
                }
            },
            '13': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 29
                }
            },
            '14': {
                start: {
                    line: 37,
                    column: 16
                },
                end: {
                    line: 37,
                    column: 34
                }
            },
            '15': {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 43,
                    column: 33
                }
            },
            '16': {
                start: {
                    line: 47,
                    column: 12
                },
                end: {
                    line: 47,
                    column: 40
                }
            },
            '17': {
                start: {
                    line: 48,
                    column: 12
                },
                end: {
                    line: 48,
                    column: 37
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 51,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 57
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 18,
                        column: 17
                    },
                    end: {
                        line: 18,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 28
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                line: 18
            },
            '3': {
                name: 'login',
                decl: {
                    start: {
                        line: 24,
                        column: 17
                    },
                    end: {
                        line: 24,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 25
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                },
                line: 24
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 58
                    },
                    end: {
                        line: 26,
                        column: 59
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 70
                    },
                    end: {
                        line: 28,
                        column: 13
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 28,
                        column: 15
                    },
                    end: {
                        line: 28,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 32
                    },
                    end: {
                        line: 30,
                        column: 13
                    }
                },
                line: 28
            },
            '6': {
                name: 'loginFacebook',
                decl: {
                    start: {
                        line: 33,
                        column: 17
                    },
                    end: {
                        line: 33,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 33
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                line: 33
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 34,
                        column: 45
                    },
                    end: {
                        line: 34,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 57
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                line: 34
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 36,
                        column: 15
                    },
                    end: {
                        line: 36,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 32
                    },
                    end: {
                        line: 38,
                        column: 13
                    }
                },
                line: 36
            },
            '9': {
                name: 'logout',
                decl: {
                    start: {
                        line: 42,
                        column: 17
                    },
                    end: {
                        line: 42,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 26
                    },
                    end: {
                        line: 44,
                        column: 9
                    }
                },
                line: 42
            },
            '10': {
                name: 'afterLogin',
                decl: {
                    start: {
                        line: 46,
                        column: 17
                    },
                    end: {
                        line: 46,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 30
                    },
                    end: {
                        line: 49,
                        column: 9
                    }
                },
                line: 46
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }, {
                    start: {
                        line: 19,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }],
                line: 19
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_24co93wawf.s[0]++;
(function () {
    'use strict';

    cov_24co93wawf.f[0]++;
    cov_24co93wawf.s[1]++;
    angular.module('app').controller('AuthLoginController', Controller);

    /* @ngInject */
    function Controller($state, AuthService, $rootScope) {
        cov_24co93wawf.f[1]++;

        var vm = (cov_24co93wawf.s[2]++, this);
        cov_24co93wawf.s[3]++;
        vm.login = login;
        cov_24co93wawf.s[4]++;
        vm.loginFacebook = loginFacebook;

        cov_24co93wawf.s[5]++;
        vm.authenticated = {};

        cov_24co93wawf.s[6]++;
        activate();

        function activate() {
            cov_24co93wawf.f[2]++;
            cov_24co93wawf.s[7]++;

            if ($rootScope.me) {
                cov_24co93wawf.b[0][0]++;
                cov_24co93wawf.s[8]++;

                $state.go('usersList');
            } else {
                cov_24co93wawf.b[0][1]++;
            }
        }

        function login() {
            cov_24co93wawf.f[3]++;
            cov_24co93wawf.s[9]++;

            /* eslint-disable prefer-arrow-callback */
            AuthService.login(vm.email, vm.password).then(function () {
                cov_24co93wawf.f[4]++;
                cov_24co93wawf.s[10]++;

                afterLogin();
            }, function (error) {
                cov_24co93wawf.f[5]++;
                cov_24co93wawf.s[11]++;

                vm.member = error;
            });
        }

        function loginFacebook() {
            cov_24co93wawf.f[6]++;
            cov_24co93wawf.s[12]++;

            AuthService.loginFacebook().then(function () {
                cov_24co93wawf.f[7]++;
                cov_24co93wawf.s[13]++;

                afterLogin();
            }, function (error) {
                cov_24co93wawf.f[8]++;
                cov_24co93wawf.s[14]++;

                vm.member = error;
            });
        }

        /* eslint-disable no-unused-vars */
        function logout() {
            cov_24co93wawf.f[9]++;
            cov_24co93wawf.s[15]++;

            AuthService.logout();
        }

        function afterLogin() {
            cov_24co93wawf.f[10]++;
            cov_24co93wawf.s[16]++;

            $rootScope.initStaticData();
            cov_24co93wawf.s[17]++;
            $state.go('samplesList');
        }
    }
})();