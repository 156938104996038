'use strict';

var cov_142kxtzhem = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/routingRules.filter.js',
        hash = '0bf1ccf0b8ffccee88e38811763c733d9981c70d',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/routingRules.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 14,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 46
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 62
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 38
                },
                end: {
                    line: 11,
                    column: 60
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 14,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'routingRules',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 28
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 38
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 11,
                        column: 31
                    },
                    end: {
                        line: 11,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 38
                    },
                    end: {
                        line: 11,
                        column: 60
                    }
                },
                line: 11
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_142kxtzhem.s[0]++;
(function () {
    'use strict';

    cov_142kxtzhem.f[0]++;
    cov_142kxtzhem.s[1]++;
    angular.module('app').filter('routingRules', routingRules);

    /* @ngInject */
    function routingRules() {
        cov_142kxtzhem.f[1]++;
        cov_142kxtzhem.s[2]++;

        return function (list, rules) {
            cov_142kxtzhem.f[2]++;
            cov_142kxtzhem.s[3]++;

            return list.filter(function (obj) {
                cov_142kxtzhem.f[3]++;
                cov_142kxtzhem.s[4]++;
                return rules.includes(obj.id);
            });
        };
    }
})();