'use strict';

var cov_1au11bf44n = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/auth/auth.logout.controller.js',
        hash = 'fc243b095f24a65e578abca959013e1716f18860',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/auth/auth.logout.controller.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 28,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 56
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 19
                },
                end: {
                    line: 10,
                    column: 23
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 28
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 27
                }
            },
            '5': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 24
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 15
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 16
                },
                end: {
                    line: 23,
                    column: 57
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 16
                },
                end: {
                    line: 24,
                    column: 35
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 28,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Controller',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 57
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'activate',
                decl: {
                    start: {
                        line: 16,
                        column: 17
                    },
                    end: {
                        line: 16,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 28
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 16
            },
            '3': {
                name: 'logout',
                decl: {
                    start: {
                        line: 20,
                        column: 17
                    },
                    end: {
                        line: 20,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 26
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 22,
                        column: 38
                    },
                    end: {
                        line: 22,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 50
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                line: 22
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1au11bf44n.s[0]++;
(function () {
    'use strict';

    cov_1au11bf44n.f[0]++;
    cov_1au11bf44n.s[1]++;
    angular.module('app').controller('AuthLogoutController', Controller);

    /* @ngInject */
    function Controller($rootScope, $state, AuthService) {
        cov_1au11bf44n.f[1]++;

        var vm = (cov_1au11bf44n.s[2]++, this);
        cov_1au11bf44n.s[3]++;
        vm.title = 'Logout';
        cov_1au11bf44n.s[4]++;
        vm.logout = logout;

        cov_1au11bf44n.s[5]++;
        activate();

        function activate() {
            cov_1au11bf44n.f[2]++;
            cov_1au11bf44n.s[6]++;

            vm.logout();
        }

        function logout() {
            cov_1au11bf44n.f[3]++;
            cov_1au11bf44n.s[7]++;

            /* eslint-disable prefer-arrow-callback */
            AuthService.logout().then(function () {
                cov_1au11bf44n.f[4]++;
                cov_1au11bf44n.s[8]++;

                $rootScope.profileMenuPageActive = false;
                cov_1au11bf44n.s[9]++;
                $state.go('login');
            });
        }
    }
})();