'use strict';

var cov_29ty970zlv = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/selectAndEdit/selectAndEdit.js',
        hash = '4094521b444f5f9d0e3df69c6d442ac920e334e7',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/selectAndEdit/selectAndEdit.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 12,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_29ty970zlv.s[0]++;
angular.module('app').component('selectAndEdit', {
    templateUrl: 'app/components/selectAndEdit/selectAndEdit.html',
    bindings: {
        collection: '<',
        newItemText: '@',
        selectItemText: '@',
        searchPlaceholder: '@',
        searchOnAPI: '<'
    }
});