'use strict';

var cov_baupg7jej = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.mixins.containable.factory.js',
        hash = '884952324c0348c920087bfa74677e22974077c8',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.mixins.containable.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 201,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 53
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 56
                }
            },
            '3': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 49
                }
            },
            '4': {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 52,
                    column: 19
                }
            },
            '5': {
                start: {
                    line: 18,
                    column: 32
                },
                end: {
                    line: 18,
                    column: 37
                }
            },
            '6': {
                start: {
                    line: 20,
                    column: 20
                },
                end: {
                    line: 45,
                    column: 21
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 24
                },
                end: {
                    line: 22,
                    column: 85
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 24
                },
                end: {
                    line: 25,
                    column: 25
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 28
                },
                end: {
                    line: 24,
                    column: 54
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 24
                },
                end: {
                    line: 26,
                    column: 30
                }
            },
            '11': {
                start: {
                    line: 28,
                    column: 24
                },
                end: {
                    line: 28,
                    column: 87
                }
            },
            '12': {
                start: {
                    line: 29,
                    column: 24
                },
                end: {
                    line: 31,
                    column: 25
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 28
                },
                end: {
                    line: 30,
                    column: 54
                }
            },
            '14': {
                start: {
                    line: 32,
                    column: 24
                },
                end: {
                    line: 32,
                    column: 30
                }
            },
            '15': {
                start: {
                    line: 34,
                    column: 24
                },
                end: {
                    line: 34,
                    column: 87
                }
            },
            '16': {
                start: {
                    line: 35,
                    column: 24
                },
                end: {
                    line: 37,
                    column: 25
                }
            },
            '17': {
                start: {
                    line: 36,
                    column: 28
                },
                end: {
                    line: 36,
                    column: 54
                }
            },
            '18': {
                start: {
                    line: 38,
                    column: 24
                },
                end: {
                    line: 38,
                    column: 30
                }
            },
            '19': {
                start: {
                    line: 40,
                    column: 24
                },
                end: {
                    line: 40,
                    column: 92
                }
            },
            '20': {
                start: {
                    line: 41,
                    column: 24
                },
                end: {
                    line: 43,
                    column: 25
                }
            },
            '21': {
                start: {
                    line: 42,
                    column: 28
                },
                end: {
                    line: 42,
                    column: 54
                }
            },
            '22': {
                start: {
                    line: 44,
                    column: 24
                },
                end: {
                    line: 44,
                    column: 30
                }
            },
            '23': {
                start: {
                    line: 46,
                    column: 20
                },
                end: {
                    line: 51,
                    column: 21
                }
            },
            '24': {
                start: {
                    line: 47,
                    column: 24
                },
                end: {
                    line: 47,
                    column: 74
                }
            },
            '25': {
                start: {
                    line: 48,
                    column: 24
                },
                end: {
                    line: 50,
                    column: 25
                }
            },
            '26': {
                start: {
                    line: 49,
                    column: 28
                },
                end: {
                    line: 49,
                    column: 55
                }
            },
            '27': {
                start: {
                    line: 56,
                    column: 16
                },
                end: {
                    line: 56,
                    column: 73
                }
            },
            '28': {
                start: {
                    line: 57,
                    column: 16
                },
                end: {
                    line: 62,
                    column: 19
                }
            },
            '29': {
                start: {
                    line: 58,
                    column: 20
                },
                end: {
                    line: 61,
                    column: 21
                }
            },
            '30': {
                start: {
                    line: 59,
                    column: 24
                },
                end: {
                    line: 59,
                    column: 82
                }
            },
            '31': {
                start: {
                    line: 60,
                    column: 24
                },
                end: {
                    line: 60,
                    column: 51
                }
            },
            '32': {
                start: {
                    line: 65,
                    column: 25
                },
                end: {
                    line: 65,
                    column: 30
                }
            },
            '33': {
                start: {
                    line: 66,
                    column: 12
                },
                end: {
                    line: 75,
                    column: 13
                }
            },
            '34': {
                start: {
                    line: 68,
                    column: 16
                },
                end: {
                    line: 68,
                    column: 45
                }
            },
            '35': {
                start: {
                    line: 69,
                    column: 16
                },
                end: {
                    line: 69,
                    column: 22
                }
            },
            '36': {
                start: {
                    line: 71,
                    column: 16
                },
                end: {
                    line: 71,
                    column: 43
                }
            },
            '37': {
                start: {
                    line: 72,
                    column: 16
                },
                end: {
                    line: 72,
                    column: 22
                }
            },
            '38': {
                start: {
                    line: 74,
                    column: 16
                },
                end: {
                    line: 74,
                    column: 99
                }
            },
            '39': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 77,
                    column: 44
                }
            },
            '40': {
                start: {
                    line: 78,
                    column: 12
                },
                end: {
                    line: 78,
                    column: 37
                }
            },
            '41': {
                start: {
                    line: 80,
                    column: 12
                },
                end: {
                    line: 80,
                    column: 26
                }
            },
            '42': {
                start: {
                    line: 84,
                    column: 12
                },
                end: {
                    line: 84,
                    column: 56
                }
            },
            '43': {
                start: {
                    line: 87,
                    column: 16
                },
                end: {
                    line: 109,
                    column: 19
                }
            },
            '44': {
                start: {
                    line: 88,
                    column: 33
                },
                end: {
                    line: 90,
                    column: 22
                }
            },
            '45': {
                start: {
                    line: 89,
                    column: 24
                },
                end: {
                    line: 89,
                    column: 54
                }
            },
            '46': {
                start: {
                    line: 93,
                    column: 36
                },
                end: {
                    line: 104,
                    column: 22
                }
            },
            '47': {
                start: {
                    line: 96,
                    column: 41
                },
                end: {
                    line: 96,
                    column: 43
                }
            },
            '48': {
                start: {
                    line: 97,
                    column: 24
                },
                end: {
                    line: 99,
                    column: 27
                }
            },
            '49': {
                start: {
                    line: 98,
                    column: 28
                },
                end: {
                    line: 98,
                    column: 60
                }
            },
            '50': {
                start: {
                    line: 101,
                    column: 24
                },
                end: {
                    line: 103,
                    column: 27
                }
            },
            '51': {
                start: {
                    line: 102,
                    column: 28
                },
                end: {
                    line: 102,
                    column: 80
                }
            },
            '52': {
                start: {
                    line: 105,
                    column: 20
                },
                end: {
                    line: 107,
                    column: 23
                }
            },
            '53': {
                start: {
                    line: 106,
                    column: 24
                },
                end: {
                    line: 106,
                    column: 50
                }
            },
            '54': {
                start: {
                    line: 108,
                    column: 20
                },
                end: {
                    line: 108,
                    column: 52
                }
            },
            '55': {
                start: {
                    line: 113,
                    column: 16
                },
                end: {
                    line: 113,
                    column: 80
                }
            },
            '56': {
                start: {
                    line: 116,
                    column: 25
                },
                end: {
                    line: 116,
                    column: 30
                }
            },
            '57': {
                start: {
                    line: 117,
                    column: 12
                },
                end: {
                    line: 126,
                    column: 13
                }
            },
            '58': {
                start: {
                    line: 119,
                    column: 16
                },
                end: {
                    line: 119,
                    column: 49
                }
            },
            '59': {
                start: {
                    line: 120,
                    column: 16
                },
                end: {
                    line: 120,
                    column: 22
                }
            },
            '60': {
                start: {
                    line: 122,
                    column: 16
                },
                end: {
                    line: 122,
                    column: 47
                }
            },
            '61': {
                start: {
                    line: 123,
                    column: 16
                },
                end: {
                    line: 123,
                    column: 22
                }
            },
            '62': {
                start: {
                    line: 125,
                    column: 16
                },
                end: {
                    line: 125,
                    column: 99
                }
            },
            '63': {
                start: {
                    line: 128,
                    column: 12
                },
                end: {
                    line: 128,
                    column: 44
                }
            },
            '64': {
                start: {
                    line: 129,
                    column: 12
                },
                end: {
                    line: 129,
                    column: 37
                }
            },
            '65': {
                start: {
                    line: 131,
                    column: 12
                },
                end: {
                    line: 131,
                    column: 30
                }
            },
            '66': {
                start: {
                    line: 135,
                    column: 29
                },
                end: {
                    line: 135,
                    column: 64
                }
            },
            '67': {
                start: {
                    line: 136,
                    column: 12
                },
                end: {
                    line: 149,
                    column: 13
                }
            },
            '68': {
                start: {
                    line: 137,
                    column: 31
                },
                end: {
                    line: 137,
                    column: 67
                }
            },
            '69': {
                start: {
                    line: 139,
                    column: 16
                },
                end: {
                    line: 139,
                    column: 60
                }
            },
            '70': {
                start: {
                    line: 140,
                    column: 16
                },
                end: {
                    line: 144,
                    column: 19
                }
            },
            '71': {
                start: {
                    line: 141,
                    column: 20
                },
                end: {
                    line: 143,
                    column: 21
                }
            },
            '72': {
                start: {
                    line: 142,
                    column: 24
                },
                end: {
                    line: 142,
                    column: 54
                }
            },
            '73': {
                start: {
                    line: 145,
                    column: 16
                },
                end: {
                    line: 145,
                    column: 30
                }
            },
            '74': {
                start: {
                    line: 148,
                    column: 16
                },
                end: {
                    line: 148,
                    column: 92
                }
            },
            '75': {
                start: {
                    line: 152,
                    column: 8
                },
                end: {
                    line: 199,
                    column: 11
                }
            },
            '76': {
                start: {
                    line: 158,
                    column: 40
                },
                end: {
                    line: 158,
                    column: 75
                }
            },
            '77': {
                start: {
                    line: 159,
                    column: 24
                },
                end: {
                    line: 159,
                    column: 95
                }
            },
            '78': {
                start: {
                    line: 160,
                    column: 24
                },
                end: {
                    line: 160,
                    column: 94
                }
            },
            '79': {
                start: {
                    line: 164,
                    column: 24
                },
                end: {
                    line: 171,
                    column: 25
                }
            },
            '80': {
                start: {
                    line: 165,
                    column: 28
                },
                end: {
                    line: 170,
                    column: 29
                }
            },
            '81': {
                start: {
                    line: 166,
                    column: 32
                },
                end: {
                    line: 168,
                    column: 33
                }
            },
            '82': {
                start: {
                    line: 167,
                    column: 36
                },
                end: {
                    line: 167,
                    column: 51
                }
            },
            '83': {
                start: {
                    line: 172,
                    column: 24
                },
                end: {
                    line: 172,
                    column: 36
                }
            },
            '84': {
                start: {
                    line: 177,
                    column: 40
                },
                end: {
                    line: 177,
                    column: 75
                }
            },
            '85': {
                start: {
                    line: 178,
                    column: 24
                },
                end: {
                    line: 178,
                    column: 95
                }
            },
            '86': {
                start: {
                    line: 179,
                    column: 24
                },
                end: {
                    line: 179,
                    column: 90
                }
            },
            '87': {
                start: {
                    line: 182,
                    column: 37
                },
                end: {
                    line: 182,
                    column: 55
                }
            },
            '88': {
                start: {
                    line: 183,
                    column: 24
                },
                end: {
                    line: 185,
                    column: 25
                }
            },
            '89': {
                start: {
                    line: 184,
                    column: 28
                },
                end: {
                    line: 184,
                    column: 53
                }
            },
            '90': {
                start: {
                    line: 186,
                    column: 24
                },
                end: {
                    line: 186,
                    column: 42
                }
            },
            '91': {
                start: {
                    line: 193,
                    column: 20
                },
                end: {
                    line: 195,
                    column: 21
                }
            },
            '92': {
                start: {
                    line: 194,
                    column: 24
                },
                end: {
                    line: 194,
                    column: 41
                }
            },
            '93': {
                start: {
                    line: 196,
                    column: 20
                },
                end: {
                    line: 196,
                    column: 93
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 201,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 60
                    },
                    end: {
                        line: 200,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: 'recordLoad',
                decl: {
                    start: {
                        line: 10,
                        column: 17
                    },
                    end: {
                        line: 10,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 68
                    },
                    end: {
                        line: 81,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: 'recordLoadBelongsTo',
                decl: {
                    start: {
                        line: 13,
                        column: 21
                    },
                    end: {
                        line: 13,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 48
                    },
                    end: {
                        line: 53,
                        column: 13
                    }
                },
                line: 13
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 17,
                        column: 29
                    },
                    end: {
                        line: 17,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 41
                    },
                    end: {
                        line: 52,
                        column: 17
                    }
                },
                line: 17
            },
            '5': {
                name: 'recordLoadHasMany',
                decl: {
                    start: {
                        line: 55,
                        column: 21
                    },
                    end: {
                        line: 55,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 46
                    },
                    end: {
                        line: 63,
                        column: 13
                    }
                },
                line: 55
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 57,
                        column: 29
                    },
                    end: {
                        line: 57,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 41
                    },
                    end: {
                        line: 62,
                        column: 17
                    }
                },
                line: 57
            },
            '7': {
                name: 'collectionLoad',
                decl: {
                    start: {
                        line: 83,
                        column: 17
                    },
                    end: {
                        line: 83,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 76
                    },
                    end: {
                        line: 132,
                        column: 9
                    }
                },
                line: 83
            },
            '8': {
                name: 'collectionLoadBelongsTo',
                decl: {
                    start: {
                        line: 86,
                        column: 21
                    },
                    end: {
                        line: 86,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 52
                    },
                    end: {
                        line: 110,
                        column: 13
                    }
                },
                line: 86
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 87,
                        column: 33
                    },
                    end: {
                        line: 87,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 87,
                        column: 45
                    },
                    end: {
                        line: 109,
                        column: 17
                    }
                },
                line: 87
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 88,
                        column: 48
                    },
                    end: {
                        line: 88,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 63
                    },
                    end: {
                        line: 90,
                        column: 21
                    }
                },
                line: 88
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 95,
                        column: 29
                    },
                    end: {
                        line: 95,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 95,
                        column: 45
                    },
                    end: {
                        line: 104,
                        column: 21
                    }
                },
                line: 95
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 97,
                        column: 46
                    },
                    end: {
                        line: 97,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 97,
                        column: 65
                    },
                    end: {
                        line: 99,
                        column: 25
                    }
                },
                line: 97
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 101,
                        column: 52
                    },
                    end: {
                        line: 101,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 101,
                        column: 68
                    },
                    end: {
                        line: 103,
                        column: 25
                    }
                },
                line: 101
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 105,
                        column: 48
                    },
                    end: {
                        line: 105,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 105,
                        column: 64
                    },
                    end: {
                        line: 107,
                        column: 21
                    }
                },
                line: 105
            },
            '15': {
                name: 'collectionLoadHasMany',
                decl: {
                    start: {
                        line: 112,
                        column: 21
                    },
                    end: {
                        line: 112,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 45
                    },
                    end: {
                        line: 114,
                        column: 13
                    }
                },
                line: 112
            },
            '16': {
                name: 'getModelConfigs',
                decl: {
                    start: {
                        line: 134,
                        column: 17
                    },
                    end: {
                        line: 134,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 54
                    },
                    end: {
                        line: 150,
                        column: 9
                    }
                },
                line: 134
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 140,
                        column: 48
                    },
                    end: {
                        line: 140,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 140,
                        column: 70
                    },
                    end: {
                        line: 144,
                        column: 17
                    }
                },
                line: 140
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 157,
                        column: 27
                    },
                    end: {
                        line: 157,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 70
                    },
                    end: {
                        line: 161,
                        column: 21
                    }
                },
                line: 157
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 162,
                        column: 26
                    },
                    end: {
                        line: 162,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 162,
                        column: 40
                    },
                    end: {
                        line: 173,
                        column: 21
                    }
                },
                line: 162
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 176,
                        column: 27
                    },
                    end: {
                        line: 176,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 176,
                        column: 70
                    },
                    end: {
                        line: 180,
                        column: 21
                    }
                },
                line: 176
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 181,
                        column: 24
                    },
                    end: {
                        line: 181,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 181,
                        column: 48
                    },
                    end: {
                        line: 187,
                        column: 21
                    }
                },
                line: 181
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 191,
                        column: 37
                    },
                    end: {
                        line: 191,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 53
                    },
                    end: {
                        line: 197,
                        column: 17
                    }
                },
                line: 191
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 32
                    },
                    end: {
                        line: 11,
                        column: 55
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 11,
                        column: 32
                    },
                    end: {
                        line: 11,
                        column: 49
                    }
                }, {
                    start: {
                        line: 11,
                        column: 53
                    },
                    end: {
                        line: 11,
                        column: 55
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 20,
                        column: 20
                    },
                    end: {
                        line: 45,
                        column: 21
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 21,
                        column: 20
                    },
                    end: {
                        line: 26,
                        column: 30
                    }
                }, {
                    start: {
                        line: 27,
                        column: 20
                    },
                    end: {
                        line: 32,
                        column: 30
                    }
                }, {
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 38,
                        column: 30
                    }
                }, {
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 44,
                        column: 30
                    }
                }],
                line: 20
            },
            '2': {
                loc: {
                    start: {
                        line: 23,
                        column: 24
                    },
                    end: {
                        line: 25,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 23,
                        column: 24
                    },
                    end: {
                        line: 25,
                        column: 25
                    }
                }, {
                    start: {
                        line: 23,
                        column: 24
                    },
                    end: {
                        line: 25,
                        column: 25
                    }
                }],
                line: 23
            },
            '3': {
                loc: {
                    start: {
                        line: 29,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 29,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                }, {
                    start: {
                        line: 29,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                }],
                line: 29
            },
            '4': {
                loc: {
                    start: {
                        line: 35,
                        column: 24
                    },
                    end: {
                        line: 37,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 35,
                        column: 24
                    },
                    end: {
                        line: 37,
                        column: 25
                    }
                }, {
                    start: {
                        line: 35,
                        column: 24
                    },
                    end: {
                        line: 37,
                        column: 25
                    }
                }],
                line: 35
            },
            '5': {
                loc: {
                    start: {
                        line: 41,
                        column: 24
                    },
                    end: {
                        line: 43,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 41,
                        column: 24
                    },
                    end: {
                        line: 43,
                        column: 25
                    }
                }, {
                    start: {
                        line: 41,
                        column: 24
                    },
                    end: {
                        line: 43,
                        column: 25
                    }
                }],
                line: 41
            },
            '6': {
                loc: {
                    start: {
                        line: 46,
                        column: 20
                    },
                    end: {
                        line: 51,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 46,
                        column: 20
                    },
                    end: {
                        line: 51,
                        column: 21
                    }
                }, {
                    start: {
                        line: 46,
                        column: 20
                    },
                    end: {
                        line: 51,
                        column: 21
                    }
                }],
                line: 46
            },
            '7': {
                loc: {
                    start: {
                        line: 48,
                        column: 24
                    },
                    end: {
                        line: 50,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 48,
                        column: 24
                    },
                    end: {
                        line: 50,
                        column: 25
                    }
                }, {
                    start: {
                        line: 48,
                        column: 24
                    },
                    end: {
                        line: 50,
                        column: 25
                    }
                }],
                line: 48
            },
            '8': {
                loc: {
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                }, {
                    start: {
                        line: 58,
                        column: 20
                    },
                    end: {
                        line: 61,
                        column: 21
                    }
                }],
                line: 58
            },
            '9': {
                loc: {
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 75,
                        column: 13
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 67,
                        column: 12
                    },
                    end: {
                        line: 69,
                        column: 22
                    }
                }, {
                    start: {
                        line: 70,
                        column: 12
                    },
                    end: {
                        line: 72,
                        column: 22
                    }
                }, {
                    start: {
                        line: 73,
                        column: 12
                    },
                    end: {
                        line: 74,
                        column: 99
                    }
                }],
                line: 66
            },
            '10': {
                loc: {
                    start: {
                        line: 84,
                        column: 32
                    },
                    end: {
                        line: 84,
                        column: 55
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 84,
                        column: 32
                    },
                    end: {
                        line: 84,
                        column: 49
                    }
                }, {
                    start: {
                        line: 84,
                        column: 53
                    },
                    end: {
                        line: 84,
                        column: 55
                    }
                }],
                line: 84
            },
            '11': {
                loc: {
                    start: {
                        line: 117,
                        column: 12
                    },
                    end: {
                        line: 126,
                        column: 13
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 118,
                        column: 12
                    },
                    end: {
                        line: 120,
                        column: 22
                    }
                }, {
                    start: {
                        line: 121,
                        column: 12
                    },
                    end: {
                        line: 123,
                        column: 22
                    }
                }, {
                    start: {
                        line: 124,
                        column: 12
                    },
                    end: {
                        line: 125,
                        column: 99
                    }
                }],
                line: 117
            },
            '12': {
                loc: {
                    start: {
                        line: 136,
                        column: 12
                    },
                    end: {
                        line: 149,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 136,
                        column: 12
                    },
                    end: {
                        line: 149,
                        column: 13
                    }
                }, {
                    start: {
                        line: 136,
                        column: 12
                    },
                    end: {
                        line: 149,
                        column: 13
                    }
                }],
                line: 136
            },
            '13': {
                loc: {
                    start: {
                        line: 136,
                        column: 16
                    },
                    end: {
                        line: 136,
                        column: 50
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 136,
                        column: 16
                    },
                    end: {
                        line: 136,
                        column: 24
                    }
                }, {
                    start: {
                        line: 136,
                        column: 28
                    },
                    end: {
                        line: 136,
                        column: 50
                    }
                }],
                line: 136
            },
            '14': {
                loc: {
                    start: {
                        line: 139,
                        column: 36
                    },
                    end: {
                        line: 139,
                        column: 59
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 139,
                        column: 36
                    },
                    end: {
                        line: 139,
                        column: 53
                    }
                }, {
                    start: {
                        line: 139,
                        column: 57
                    },
                    end: {
                        line: 139,
                        column: 59
                    }
                }],
                line: 139
            },
            '15': {
                loc: {
                    start: {
                        line: 141,
                        column: 20
                    },
                    end: {
                        line: 143,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 141,
                        column: 20
                    },
                    end: {
                        line: 143,
                        column: 21
                    }
                }, {
                    start: {
                        line: 141,
                        column: 20
                    },
                    end: {
                        line: 143,
                        column: 21
                    }
                }],
                line: 141
            },
            '16': {
                loc: {
                    start: {
                        line: 166,
                        column: 32
                    },
                    end: {
                        line: 168,
                        column: 33
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 166,
                        column: 32
                    },
                    end: {
                        line: 168,
                        column: 33
                    }
                }, {
                    start: {
                        line: 166,
                        column: 32
                    },
                    end: {
                        line: 168,
                        column: 33
                    }
                }],
                line: 166
            },
            '17': {
                loc: {
                    start: {
                        line: 183,
                        column: 24
                    },
                    end: {
                        line: 185,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 183,
                        column: 24
                    },
                    end: {
                        line: 185,
                        column: 25
                    }
                }, {
                    start: {
                        line: 183,
                        column: 24
                    },
                    end: {
                        line: 185,
                        column: 25
                    }
                }],
                line: 183
            },
            '18': {
                loc: {
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 195,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 195,
                        column: 21
                    }
                }, {
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 195,
                        column: 21
                    }
                }],
                line: 193
            },
            '19': {
                loc: {
                    start: {
                        line: 196,
                        column: 47
                    },
                    end: {
                        line: 196,
                        column: 92
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 196,
                        column: 47
                    },
                    end: {
                        line: 196,
                        column: 71
                    }
                }, {
                    start: {
                        line: 196,
                        column: 75
                    },
                    end: {
                        line: 196,
                        column: 86
                    }
                }, {
                    start: {
                        line: 196,
                        column: 90
                    },
                    end: {
                        line: 196,
                        column: 92
                    }
                }],
                line: 196
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0, 0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0, 0],
            '10': [0, 0],
            '11': [0, 0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0],
            '15': [0, 0],
            '16': [0, 0],
            '17': [0, 0],
            '18': [0, 0],
            '19': [0, 0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_baupg7jej.s[0]++;
(function () {
    'use strict';

    cov_baupg7jej.f[0]++;
    cov_baupg7jej.s[1]++;
    angular.module('app').factory('MixinContainable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod, $injector, $rootScope) {
        cov_baupg7jej.f[1]++;

        function recordLoad(record, key, config, additionalFilters) {
            cov_baupg7jej.f[2]++;
            cov_baupg7jej.s[2]++;

            additionalFilters = (cov_baupg7jej.b[0][0]++, additionalFilters) || (cov_baupg7jej.b[0][1]++, {});

            function recordLoadBelongsTo(table) {
                cov_baupg7jej.f[3]++;
                cov_baupg7jej.s[3]++;

                /* eslint-disable prefer-template */
                record['$' + key] = table.$new();
                /* eslint-disable prefer-arrow-callback */
                cov_baupg7jej.s[4]++;
                record.$then(function () {
                    cov_baupg7jej.f[4]++;

                    var found = (cov_baupg7jej.s[5]++, false);
                    /* eslint-disable default-case */
                    cov_baupg7jej.s[6]++;
                    switch (config.table) {
                        case 'BrandsTable':
                            cov_baupg7jej.b[1][0]++;
                            cov_baupg7jej.s[7]++;

                            found = $rootScope.allBrands.$get(record[config.foreignKey]);
                            cov_baupg7jej.s[8]++;
                            if (found) {
                                cov_baupg7jej.b[2][0]++;
                                cov_baupg7jej.s[9]++;

                                record['$' + key] = found;
                            } else {
                                cov_baupg7jej.b[2][1]++;
                            }
                            cov_baupg7jej.s[10]++;
                            break;
                        case 'UsersTable':
                            cov_baupg7jej.b[1][1]++;
                            cov_baupg7jej.s[11]++;

                            found = $rootScope.commercials.$get(record[config.foreignKey]);
                            cov_baupg7jej.s[12]++;
                            if (found) {
                                cov_baupg7jej.b[3][0]++;
                                cov_baupg7jej.s[13]++;

                                record['$' + key] = found;
                            } else {
                                cov_baupg7jej.b[3][1]++;
                            }
                            cov_baupg7jej.s[14]++;
                            break;
                        case 'SampleTypesTable':
                            cov_baupg7jej.b[1][2]++;
                            cov_baupg7jej.s[15]++;

                            found = $rootScope.sampleTypes.$get(record[config.foreignKey]);
                            cov_baupg7jej.s[16]++;
                            if (found) {
                                cov_baupg7jej.b[4][0]++;
                                cov_baupg7jej.s[17]++;

                                record['$' + key] = found;
                            } else {
                                cov_baupg7jej.b[4][1]++;
                            }
                            cov_baupg7jej.s[18]++;
                            break;
                        case 'SampleStateTypesTable':
                            cov_baupg7jej.b[1][3]++;
                            cov_baupg7jej.s[19]++;

                            found = $rootScope.sampleStateTypes.$get(record[config.foreignKey]);
                            cov_baupg7jej.s[20]++;
                            if (found) {
                                cov_baupg7jej.b[5][0]++;
                                cov_baupg7jej.s[21]++;

                                record['$' + key] = found;
                            } else {
                                cov_baupg7jej.b[5][1]++;
                            }
                            cov_baupg7jej.s[22]++;
                            break;
                    }
                    cov_baupg7jej.s[23]++;
                    if (!found) {
                        cov_baupg7jej.b[6][0]++;
                        cov_baupg7jej.s[24]++;

                        record['$' + key].$pk = record[config.foreignKey];
                        cov_baupg7jej.s[25]++;
                        if (record[config.foreignKey]) {
                            cov_baupg7jej.b[7][0]++;
                            cov_baupg7jej.s[26]++;

                            record['$' + key].$fetch();
                        } else {
                            cov_baupg7jej.b[7][1]++;
                        }
                    } else {
                        cov_baupg7jej.b[6][1]++;
                    }
                });
            }

            function recordLoadHasMany(table) {
                cov_baupg7jej.f[5]++;
                cov_baupg7jej.s[27]++;

                record['$' + key] = table.$collection(additionalFilters);
                cov_baupg7jej.s[28]++;
                record.$then(function () {
                    cov_baupg7jej.f[6]++;
                    cov_baupg7jej.s[29]++;

                    if (record.$pk) {
                        cov_baupg7jej.b[8][0]++;
                        cov_baupg7jej.s[30]++;

                        record['$' + key].$params[config.foreignKey] = record.$pk;
                        cov_baupg7jej.s[31]++;
                        record['$' + key].$fetch();
                    } else {
                        cov_baupg7jej.b[8][1]++;
                    }
                });
            }

            var loader = (cov_baupg7jej.s[32]++, false);
            cov_baupg7jej.s[33]++;
            switch (config.type) {
                case 'belongsTo':
                    cov_baupg7jej.b[9][0]++;
                    cov_baupg7jej.s[34]++;

                    loader = recordLoadBelongsTo;
                    cov_baupg7jej.s[35]++;
                    break;
                case 'hasMany':
                    cov_baupg7jej.b[9][1]++;
                    cov_baupg7jej.s[36]++;

                    loader = recordLoadHasMany;
                    cov_baupg7jej.s[37]++;
                    break;
                default:
                    cov_baupg7jej.b[9][2]++;
                    cov_baupg7jej.s[38]++;

                    throw new Error('Unknown contain type "' + config.type + '" for MixinContainable');
            }

            cov_baupg7jej.s[39]++;
            loader.$inject = [config.table];
            cov_baupg7jej.s[40]++;
            $injector.invoke(loader);

            cov_baupg7jej.s[41]++;
            return record;
        }

        function collectionLoad(collection, key, config, additionalFilters) {
            cov_baupg7jej.f[7]++;
            cov_baupg7jej.s[42]++;

            additionalFilters = (cov_baupg7jej.b[10][0]++, additionalFilters) || (cov_baupg7jej.b[10][1]++, {});

            function collectionLoadBelongsTo(table) {
                cov_baupg7jej.f[8]++;
                cov_baupg7jej.s[43]++;

                collection.$then(function () {
                    cov_baupg7jej.f[9]++;

                    var keys = (cov_baupg7jej.s[44]++, collection.map(function (obj) {
                        cov_baupg7jej.f[10]++;
                        cov_baupg7jej.s[45]++;

                        return obj[config.foreignKey];
                    }));

                    /* eslint-disable camelcase */
                    var request = (cov_baupg7jej.s[46]++, table.$search({
                        id__in: keys.join(',')
                    }).$then(function (data) {
                        cov_baupg7jej.f[11]++;

                        var objsById = (cov_baupg7jej.s[47]++, {});
                        cov_baupg7jej.s[48]++;
                        angular.forEach(data, function (respObj) {
                            cov_baupg7jej.f[12]++;
                            cov_baupg7jej.s[49]++;

                            objsById[respObj.$pk] = respObj;
                        });

                        cov_baupg7jej.s[50]++;
                        angular.forEach(collection, function (item) {
                            cov_baupg7jej.f[13]++;
                            cov_baupg7jej.s[51]++;

                            item['$' + key] = objsById[item[config.foreignKey]];
                        });
                    }));
                    cov_baupg7jej.s[52]++;
                    angular.forEach(collection, function (item) {
                        cov_baupg7jej.f[14]++;
                        cov_baupg7jej.s[53]++;

                        item['$' + key] = request;
                    });
                    cov_baupg7jej.s[54]++;
                    collection['$' + key] = request;
                });
            }

            function collectionLoadHasMany() {
                cov_baupg7jej.f[15]++;
                cov_baupg7jej.s[55]++;

                throw new Error('Collection load has many Not implemented yet');
            }

            var loader = (cov_baupg7jej.s[56]++, false);
            cov_baupg7jej.s[57]++;
            switch (config.type) {
                case 'belongsTo':
                    cov_baupg7jej.b[11][0]++;
                    cov_baupg7jej.s[58]++;

                    loader = collectionLoadBelongsTo;
                    cov_baupg7jej.s[59]++;
                    break;
                case 'hasMany':
                    cov_baupg7jej.b[11][1]++;
                    cov_baupg7jej.s[60]++;

                    loader = collectionLoadHasMany;
                    cov_baupg7jej.s[61]++;
                    break;
                default:
                    cov_baupg7jej.b[11][2]++;
                    cov_baupg7jej.s[62]++;

                    throw new Error('Unknown contain type "' + config.type + '" for MixinContainable');
            }

            cov_baupg7jej.s[63]++;
            loader.$inject = [config.table];
            cov_baupg7jej.s[64]++;
            $injector.invoke(loader);

            cov_baupg7jej.s[65]++;
            return collection;
        }

        function getModelConfigs(model, relatedModel) {
            cov_baupg7jej.f[16]++;

            var contains = (cov_baupg7jej.s[66]++, model.$type.getProperty('contains'));
            cov_baupg7jej.s[67]++;
            if ((cov_baupg7jej.b[13][0]++, contains) && (cov_baupg7jej.b[13][1]++, contains[relatedModel])) {
                cov_baupg7jej.b[12][0]++;

                var config = (cov_baupg7jej.s[68]++, angular.copy(contains[relatedModel]));
                /* eslint-disable dot-notation */
                cov_baupg7jej.s[69]++;
                config['filters'] = (cov_baupg7jej.b[14][0]++, config['filters']) || (cov_baupg7jej.b[14][1]++, {});
                cov_baupg7jej.s[70]++;
                angular.forEach(config.filters, function (value, key) {
                    cov_baupg7jej.f[17]++;
                    cov_baupg7jej.s[71]++;

                    if (angular.isFunction(config.filters[key])) {
                        cov_baupg7jej.b[15][0]++;
                        cov_baupg7jej.s[72]++;

                        config.filters[key] = value();
                    } else {
                        cov_baupg7jej.b[15][1]++;
                    }
                });
                cov_baupg7jej.s[73]++;
                return config;
                /* eslint-disable no-else-return */
            } else {
                cov_baupg7jej.b[12][1]++;
                cov_baupg7jej.s[74]++;

                throw new Error('Cannot find MixinContainable configs for ' + relatedModel);
            }
        }

        cov_baupg7jej.s[75]++;
        return restmod.mixin({
            $extend: {
                Collection: {
                    $limit: 25,
                    /* eslint-disable babel/object-shorthand */
                    $load: function $load(relatedModel, additionalFilters) {
                        cov_baupg7jej.f[18]++;

                        var configs = (cov_baupg7jej.s[76]++, getModelConfigs(this, relatedModel));
                        cov_baupg7jej.s[77]++;
                        additionalFilters = angular.extend(configs.filters, additionalFilters);
                        cov_baupg7jej.s[78]++;
                        return collectionLoad(this, relatedModel, configs, additionalFilters);
                    },
                    $get: function $get(id) {
                        cov_baupg7jej.f[19]++;
                        cov_baupg7jej.s[79]++;

                        /* eslint-disable guard-for-in */
                        for (var i in this) {
                            cov_baupg7jej.s[80]++;

                            try {
                                cov_baupg7jej.s[81]++;

                                if (this[i].id === id) {
                                    cov_baupg7jej.b[16][0]++;
                                    cov_baupg7jej.s[82]++;

                                    return this[i];
                                } else {
                                    cov_baupg7jej.b[16][1]++;
                                }
                            } catch (E) {}
                        }
                        cov_baupg7jej.s[83]++;
                        return null;
                    }
                },
                Record: {
                    $load: function $load(relatedModel, additionalFilters) {
                        cov_baupg7jej.f[20]++;

                        var configs = (cov_baupg7jej.s[84]++, getModelConfigs(this, relatedModel));
                        cov_baupg7jej.s[85]++;
                        additionalFilters = angular.extend(configs.filters, additionalFilters);
                        cov_baupg7jej.s[86]++;
                        return recordLoad(this, relatedModel, configs, additionalFilters);
                    },
                    $_: function $_(relatedModel) {
                        cov_baupg7jej.f[21]++;

                        var name = (cov_baupg7jej.s[87]++, '$' + relatedModel);
                        cov_baupg7jej.s[88]++;
                        if (!this[name]) {
                            cov_baupg7jej.b[17][0]++;
                            cov_baupg7jej.s[89]++;

                            this.$load(relatedModel);
                        } else {
                            cov_baupg7jej.b[17][1]++;
                        }
                        cov_baupg7jej.s[90]++;
                        return this[name];
                    }
                }
            },
            $hooks: {
                'before-fetch-many': function beforeFetchMany(_req) {
                    cov_baupg7jej.f[22]++;
                    cov_baupg7jej.s[91]++;

                    /* eslint-disable no-undef */
                    if (_.isUndefined(_req.params)) {
                        cov_baupg7jej.b[18][0]++;
                        cov_baupg7jej.s[92]++;

                        _req.params = {};
                    } else {
                        cov_baupg7jej.b[18][1]++;
                    }
                    cov_baupg7jej.s[93]++;
                    _req.params['page_size'] = (cov_baupg7jej.b[19][0]++, _req.params['page_size']) || (cov_baupg7jej.b[19][1]++, this.$limit) || (cov_baupg7jej.b[19][2]++, 25);
                }
            }
        });
    }
})();