'use strict';

var cov_2a6ca27lsi = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/announcements/announcements.js',
        hash = '3a09fe4e691bf2b2bbdec7cf417b91a0ead4cf36',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/announcements/announcements.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 34
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 31
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 48
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 46
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 37
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 35
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 16
                },
                end: {
                    line: 20,
                    column: 41
                }
            },
            '10': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 37
                }
            },
            '11': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 72
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 52
                },
                end: {
                    line: 27,
                    column: 70
                }
            },
            '13': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 36
                }
            },
            '14': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 109
                }
            },
            '15': {
                start: {
                    line: 33,
                    column: 52
                },
                end: {
                    line: 33,
                    column: 107
                }
            },
            '16': {
                start: {
                    line: 37,
                    column: 25
                },
                end: {
                    line: 37,
                    column: 41
                }
            },
            '17': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 11
                }
            },
            '18': {
                start: {
                    line: 39,
                    column: 12
                },
                end: {
                    line: 39,
                    column: 31
                }
            },
            '19': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 33
                }
            },
            '20': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 53
                }
            },
            '21': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 77
                }
            },
            '23': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 69
                }
            },
            '24': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 58,
                    column: 11
                }
            },
            '25': {
                start: {
                    line: 56,
                    column: 12
                },
                end: {
                    line: 56,
                    column: 29
                }
            },
            '26': {
                start: {
                    line: 57,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 38
                }
            },
            '27': {
                start: {
                    line: 63,
                    column: 21
                },
                end: {
                    line: 63,
                    column: 81
                }
            },
            '28': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 15
                }
            },
            '29': {
                start: {
                    line: 66,
                    column: 16
                },
                end: {
                    line: 68,
                    column: 17
                }
            },
            '30': {
                start: {
                    line: 67,
                    column: 20
                },
                end: {
                    line: 67,
                    column: 55
                }
            },
            '31': {
                start: {
                    line: 73,
                    column: 23
                },
                end: {
                    line: 90,
                    column: 9
                }
            },
            '32': {
                start: {
                    line: 91,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 35
                }
            },
            '33': {
                start: {
                    line: 95,
                    column: 29
                },
                end: {
                    line: 95,
                    column: 45
                }
            },
            '34': {
                start: {
                    line: 96,
                    column: 8
                },
                end: {
                    line: 96,
                    column: 46
                }
            },
            '35': {
                start: {
                    line: 100,
                    column: 0
                },
                end: {
                    line: 104,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 37
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 17
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 16,
                        column: 46
                    },
                    end: {
                        line: 16,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 52
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                line: 16
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 25,
                        column: 4
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 25
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 27,
                        column: 46
                    },
                    end: {
                        line: 27,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 52
                    },
                    end: {
                        line: 27,
                        column: 70
                    }
                },
                line: 27
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 15
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 30
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 33,
                        column: 46
                    },
                    end: {
                        line: 33,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 52
                    },
                    end: {
                        line: 33,
                        column: 107
                    }
                },
                line: 33
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 18
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 36
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 38,
                        column: 30
                    },
                    end: {
                        line: 38,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 36
                    },
                    end: {
                        line: 40,
                        column: 9
                    }
                },
                line: 38
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 58
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                line: 46
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 54,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 14
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                line: 54
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 55,
                        column: 33
                    },
                    end: {
                        line: 55,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 41
                    },
                    end: {
                        line: 58,
                        column: 9
                    }
                },
                line: 55
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 61,
                        column: 4
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 61,
                        column: 25
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                line: 61
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 65,
                        column: 12
                    },
                    end: {
                        line: 65,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 23
                    },
                    end: {
                        line: 69,
                        column: 13
                    }
                },
                line: 65
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 72,
                        column: 4
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 37
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                line: 72
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 94,
                        column: 4
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 40
                    },
                    end: {
                        line: 97,
                        column: 5
                    }
                },
                line: 94
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }, {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 21,
                        column: 13
                    }
                }],
                line: 17
            },
            '1': {
                loc: {
                    start: {
                        line: 66,
                        column: 16
                    },
                    end: {
                        line: 68,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 66,
                        column: 16
                    },
                    end: {
                        line: 68,
                        column: 17
                    }
                }, {
                    start: {
                        line: 66,
                        column: 16
                    },
                    end: {
                        line: 68,
                        column: 17
                    }
                }],
                line: 66
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AnnouncementsDialogController = function () {
    function AnnouncementsDialogController(mdPanelRef, $window) {
        _classCallCheck(this, AnnouncementsDialogController);

        cov_2a6ca27lsi.f[0]++;
        cov_2a6ca27lsi.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_2a6ca27lsi.s[1]++;
        this.instructions = false;
        cov_2a6ca27lsi.s[2]++;
        this.$window = $window;
    }

    _createClass(AnnouncementsDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_2a6ca27lsi.f[1]++;
            cov_2a6ca27lsi.s[3]++;

            this.announcement.showedAt = new Date();
            cov_2a6ca27lsi.s[4]++;
            this.announcement.$save(['showedAt']);
        }
    }, {
        key: 'setok',
        value: function setok(value) {
            var _this = this;

            cov_2a6ca27lsi.f[2]++;
            cov_2a6ca27lsi.s[5]++;

            this.announcement.ok = value;
            cov_2a6ca27lsi.s[6]++;
            this.announcement.$save(['ok']).$then(function () {
                cov_2a6ca27lsi.f[3]++;
                cov_2a6ca27lsi.s[7]++;

                if (value) {
                    cov_2a6ca27lsi.b[0][0]++;
                    cov_2a6ca27lsi.s[8]++;

                    _this.closeDialog();
                } else {
                    cov_2a6ca27lsi.b[0][1]++;
                    cov_2a6ca27lsi.s[9]++;

                    _this.instructions = true;
                }
            });
        }
    }, {
        key: 'later',
        value: function later() {
            var _this2 = this;

            cov_2a6ca27lsi.f[4]++;
            cov_2a6ca27lsi.s[10]++;

            this.announcement.ok = false;
            cov_2a6ca27lsi.s[11]++;
            this.announcement.$save(['ok']).$then(function () {
                cov_2a6ca27lsi.f[5]++;
                cov_2a6ca27lsi.s[12]++;
                return _this2.closeDialog();
            });
        }
    }, {
        key: 'redirect',
        value: function redirect() {
            var _this3 = this;

            cov_2a6ca27lsi.f[6]++;
            cov_2a6ca27lsi.s[13]++;

            this.announcement.ok = true;
            /* eslint-disable no-return-assign */
            cov_2a6ca27lsi.s[14]++;
            this.announcement.$save(['ok']).$then(function () {
                cov_2a6ca27lsi.f[7]++;
                cov_2a6ca27lsi.s[15]++;
                return _this3.$window.location.href = _this3.announcement.redirect;
            });
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_2a6ca27lsi.f[8]++;

            var panelRef = (cov_2a6ca27lsi.s[16]++, this._mdPanelRef);
            cov_2a6ca27lsi.s[17]++;
            panelRef.close().then(function () {
                cov_2a6ca27lsi.f[9]++;
                cov_2a6ca27lsi.s[18]++;

                panelRef.destroy();
            });
        }
    }]);

    return AnnouncementsDialogController;
}();

var AnnouncementsController = function () {
    function AnnouncementsController($mdPanel, AnnouncementsTable, $rootScope) {
        _classCallCheck(this, AnnouncementsController);

        cov_2a6ca27lsi.f[10]++;
        cov_2a6ca27lsi.s[19]++;

        this.$mdPanel = $mdPanel;
        cov_2a6ca27lsi.s[20]++;
        this.AnnouncementsTable = AnnouncementsTable;
        cov_2a6ca27lsi.s[21]++;
        this.$rootScope = $rootScope;
        cov_2a6ca27lsi.s[22]++;
        this.position = this.$mdPanel.newPanelPosition().absolute().center();
        cov_2a6ca27lsi.s[23]++;
        this.fetchAnnouncements = this.fetchAnnouncements.bind(this);
    }

    _createClass(AnnouncementsController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this4 = this;

            cov_2a6ca27lsi.f[11]++;
            cov_2a6ca27lsi.s[24]++;

            this.$rootScope.me.$then(function (user) {
                cov_2a6ca27lsi.f[12]++;
                cov_2a6ca27lsi.s[25]++;

                _this4.user = user;
                cov_2a6ca27lsi.s[26]++;
                _this4.fetchAnnouncements();
            });
        }
    }, {
        key: 'fetchAnnouncements',
        value: function fetchAnnouncements() {
            var _this5 = this;

            cov_2a6ca27lsi.f[13]++;

            /* eslint-disable camelcase */
            var data = (cov_2a6ca27lsi.s[27]++, { read_at__isnull: true, user: this.user.id, ordering: '-id' });
            cov_2a6ca27lsi.s[28]++;
            this.AnnouncementsTable.$search(data).$then(function (results) {
                cov_2a6ca27lsi.f[14]++;
                cov_2a6ca27lsi.s[29]++;

                if (results.length > 0) {
                    cov_2a6ca27lsi.b[1][0]++;
                    cov_2a6ca27lsi.s[30]++;

                    _this5.displayAnnouncements(results);
                } else {
                    cov_2a6ca27lsi.b[1][1]++;
                }
            });
        }
    }, {
        key: 'createAnnouncement',
        value: function createAnnouncement(announcement) {
            cov_2a6ca27lsi.f[15]++;

            var config = (cov_2a6ca27lsi.s[31]++, {
                /** @ngInject */
                controller: AnnouncementsDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/announcements/announcements.html',
                hasBackdrop: true,
                panelClass: 'announcement-dialog',
                position: this.position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                onRemoving: this.fetchAnnouncements,
                locals: {
                    announcement: announcement
                }
            });
            cov_2a6ca27lsi.s[32]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'displayAnnouncements',
        value: function displayAnnouncements(announcements) {
            cov_2a6ca27lsi.f[16]++;

            var announcement = (cov_2a6ca27lsi.s[33]++, announcements[0]);
            cov_2a6ca27lsi.s[34]++;
            this.createAnnouncement(announcement);
        }
    }]);

    return AnnouncementsController;
}();

cov_2a6ca27lsi.s[35]++;


angular.module('app').component('announcements', {
    controller: AnnouncementsController
});