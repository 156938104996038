'use strict';

var cov_qq7i9311j = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/simpleBrandFilter.js',
        hash = '263c8e3c633c9d1a6e4619fcc3d15f7778177db7',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/simpleBrandFilter.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 9,
                    column: 44
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 32
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 52
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 0
                },
                end: {
                    line: 28,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 8,
                        column: 33
                    },
                    end: {
                        line: 8,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 39
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                line: 8
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 19
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 14
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SimpleBrandFilterController = function () {
    function SimpleBrandFilterController($rootScope) {
        _classCallCheck(this, SimpleBrandFilterController);

        cov_qq7i9311j.f[0]++;
        cov_qq7i9311j.s[0]++;

        this.$rootScope = $rootScope;
    }

    _createClass(SimpleBrandFilterController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_qq7i9311j.f[1]++;
            cov_qq7i9311j.s[1]++;

            this.$rootScope.me.$then(function () {
                cov_qq7i9311j.f[2]++;
                cov_qq7i9311j.s[2]++;

                _this.sampleStateFilter = 'mine';
                cov_qq7i9311j.s[3]++;
                _this.changeFilter();
            });
        }
    }, {
        key: 'changeFilter',
        value: function changeFilter() {
            cov_qq7i9311j.f[3]++;
            cov_qq7i9311j.s[4]++;

            /* eslint-disable camelcase */
            this.changed({ val: this.sampleStateFilter });
        }
    }]);

    return SimpleBrandFilterController;
}();

cov_qq7i9311j.s[5]++;


angular.module('app').component('simpleBrandFilter', {
    templateUrl: 'app/components/dashboard/simpleBrandFilter.html',
    controller: SimpleBrandFilterController,
    bindings: {
        changed: '&'
    }
});