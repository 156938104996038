'use strict';

var cov_2qnfv4ecti = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentCycleTable/sampleDevelopmentCycleTable.js',
        hash = '2af9d992614d176276a681aed2b41c7c213e198c',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentCycleTable/sampleDevelopmentCycleTable.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 38
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 103
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 93
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 64
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 129
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 61
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 25
                },
                end: {
                    line: 22,
                    column: 41
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '10': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 31
                }
            },
            '11': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 44
                }
            },
            '12': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 35
                }
            },
            '14': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 33
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 24
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 41
                }
            },
            '17': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 32
                }
            },
            '18': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 33
                }
            },
            '19': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 44
                }
            },
            '20': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 62
                }
            },
            '21': {
                start: {
                    line: 50,
                    column: 24
                },
                end: {
                    line: 54,
                    column: 38
                }
            },
            '22': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 25
                }
            },
            '23': {
                start: {
                    line: 58,
                    column: 16
                },
                end: {
                    line: 58,
                    column: 50
                }
            },
            '24': {
                start: {
                    line: 63,
                    column: 25
                },
                end: {
                    line: 63,
                    column: 77
                }
            },
            '25': {
                start: {
                    line: 64,
                    column: 23
                },
                end: {
                    line: 80,
                    column: 9
                }
            },
            '26': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 35
                }
            },
            '27': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 98
                }
            },
            '28': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 84
                }
            },
            '29': {
                start: {
                    line: 90,
                    column: 21
                },
                end: {
                    line: 98,
                    column: 9
                }
            },
            '30': {
                start: {
                    line: 99,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 86
                }
            },
            '31': {
                start: {
                    line: 99,
                    column: 63
                },
                end: {
                    line: 99,
                    column: 84
                }
            },
            '32': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 9
                }
            },
            '33': {
                start: {
                    line: 104,
                    column: 12
                },
                end: {
                    line: 104,
                    column: 57
                }
            },
            '34': {
                start: {
                    line: 105,
                    column: 12
                },
                end: {
                    line: 105,
                    column: 72
                }
            },
            '35': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 108
                }
            },
            '36': {
                start: {
                    line: 111,
                    column: 85
                },
                end: {
                    line: 111,
                    column: 106
                }
            },
            '37': {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 118,
                    column: 9
                }
            },
            '38': {
                start: {
                    line: 116,
                    column: 12
                },
                end: {
                    line: 116,
                    column: 73
                }
            },
            '39': {
                start: {
                    line: 117,
                    column: 12
                },
                end: {
                    line: 117,
                    column: 52
                }
            },
            '40': {
                start: {
                    line: 122,
                    column: 8
                },
                end: {
                    line: 122,
                    column: 73
                }
            },
            '41': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 50
                }
            },
            '42': {
                start: {
                    line: 128,
                    column: 0
                },
                end: {
                    line: 138,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 25
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 16,
                        column: 4
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 22
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 16
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 18
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 21
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 30
                    },
                    end: {
                        line: 24,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 36
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 63
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 32
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 41,
                        column: 4
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 21
                    },
                    end: {
                        line: 47,
                        column: 5
                    }
                },
                line: 41
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 40
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 49
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 57,
                        column: 12
                    },
                    end: {
                        line: 57,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 18
                    },
                    end: {
                        line: 59,
                        column: 13
                    }
                },
                line: 57
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 59,
                        column: 15
                    },
                    end: {
                        line: 59,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 21
                    },
                    end: {
                        line: 59,
                        column: 23
                    }
                },
                line: 59
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 33
                    },
                    end: {
                        line: 82,
                        column: 5
                    }
                },
                line: 62
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 84,
                        column: 4
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 23
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                line: 84
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 88,
                        column: 4
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 18
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 88
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 99,
                        column: 57
                    },
                    end: {
                        line: 99,
                        column: 58
                    }
                },
                loc: {
                    start: {
                        line: 99,
                        column: 63
                    },
                    end: {
                        line: 99,
                        column: 84
                    }
                },
                line: 99
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 19
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                line: 102
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 109,
                        column: 4
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 23
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                line: 109
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 111,
                        column: 73
                    },
                    end: {
                        line: 111,
                        column: 74
                    }
                },
                loc: {
                    start: {
                        line: 111,
                        column: 85
                    },
                    end: {
                        line: 111,
                        column: 106
                    }
                },
                line: 111
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 114,
                        column: 4
                    },
                    end: {
                        line: 114,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 40
                    },
                    end: {
                        line: 119,
                        column: 5
                    }
                },
                line: 114
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 121,
                        column: 4
                    },
                    end: {
                        line: 121,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 121,
                        column: 42
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                line: 121
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                }, {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 13,
                        column: 9
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 130
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 11,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 52
                    }
                }, {
                    start: {
                        line: 11,
                        column: 56
                    },
                    end: {
                        line: 11,
                        column: 130
                    }
                }],
                line: 11
            },
            '2': {
                loc: {
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 106,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 106,
                        column: 9
                    }
                }, {
                    start: {
                        line: 103,
                        column: 8
                    },
                    end: {
                        line: 106,
                        column: 9
                    }
                }],
                line: 103
            },
            '3': {
                loc: {
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 118,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 118,
                        column: 9
                    }
                }, {
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 118,
                        column: 9
                    }
                }],
                line: 115
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleDevelopmentObservationsController = function () {
    function SampleDevelopmentObservationsController(mdPanelRef) {
        _classCallCheck(this, SampleDevelopmentObservationsController);

        cov_2qnfv4ecti.f[0]++;
        cov_2qnfv4ecti.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_2qnfv4ecti.s[1]++;
        this.observationsMaxLen = 300;
        cov_2qnfv4ecti.s[2]++;
        this.sampleDevelopmentCycle.hours = Math.floor(this.sampleDevelopmentCycle.executionTime / 60);
        cov_2qnfv4ecti.s[3]++;
        this.sampleDevelopmentCycle.minutes = this.sampleDevelopmentCycle.executionTime % 60;
    }

    _createClass(SampleDevelopmentObservationsController, [{
        key: 'updateObservations',
        value: function updateObservations() {
            cov_2qnfv4ecti.f[1]++;
            cov_2qnfv4ecti.s[4]++;

            if ((cov_2qnfv4ecti.b[1][0]++, this.sampleDevelopmentCycle.observations) && (cov_2qnfv4ecti.b[1][1]++, this.sampleDevelopmentCycle.observations.length <= this.observationsMaxLen)) {
                cov_2qnfv4ecti.b[0][0]++;
                cov_2qnfv4ecti.s[5]++;

                this.sampleDevelopmentCycle.$save(['observations']);
            } else {
                cov_2qnfv4ecti.b[0][1]++;
            }
        }
    }, {
        key: 'updateExecution',
        value: function updateExecution() {
            cov_2qnfv4ecti.f[2]++;
            cov_2qnfv4ecti.s[6]++;

            this.sampleDevelopmentCycle.executionTime = this.sampleDevelopmentCycle.hours * 60 + this.sampleDevelopmentCycle.minutes;
            cov_2qnfv4ecti.s[7]++;
            this.sampleDevelopmentCycle.$save(['executionTime']);
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_2qnfv4ecti.f[3]++;

            var panelRef = (cov_2qnfv4ecti.s[8]++, this._mdPanelRef);

            cov_2qnfv4ecti.s[9]++;
            panelRef.close().then(function () {
                cov_2qnfv4ecti.f[4]++;
                cov_2qnfv4ecti.s[10]++;

                panelRef.destroy();
            });
        }
    }]);

    return SampleDevelopmentObservationsController;
}();

var SampleDevelopmentCycleTableController = function () {
    function SampleDevelopmentCycleTableController($rootScope, SamplesTable, $mdDialog, $mdPanel) {
        _classCallCheck(this, SampleDevelopmentCycleTableController);

        cov_2qnfv4ecti.f[5]++;
        cov_2qnfv4ecti.s[11]++;

        this.newSampleDevelopmentCycle = {};
        cov_2qnfv4ecti.s[12]++;
        this.$rootScope = $rootScope;
        cov_2qnfv4ecti.s[13]++;
        this.$mdDialog = $mdDialog;
        cov_2qnfv4ecti.s[14]++;
        this.$mdPanel = $mdPanel;
        cov_2qnfv4ecti.s[15]++;
        this.items = [];
        cov_2qnfv4ecti.s[16]++;
        this.SamplesTable = SamplesTable;
    }

    _createClass(SampleDevelopmentCycleTableController, [{
        key: 'resetEntryLine',
        value: function resetEntryLine() {
            cov_2qnfv4ecti.f[6]++;
            cov_2qnfv4ecti.s[17]++;

            this.xxx.$setPristine(); // fix
            cov_2qnfv4ecti.s[18]++;
            this.xxx.$setUntouched(); // fix
            cov_2qnfv4ecti.s[19]++;
            this.newSampleDevelopmentCycle = {};
            /* eslint-disable angular/document-service */
            cov_2qnfv4ecti.s[20]++;
            document.querySelector('#seamstressSelector').focus();
        }
    }, {
        key: 'deleteEntry',
        value: function deleteEntry(sampleDevelopmentCycle) {
            cov_2qnfv4ecti.f[7]++;

            var confirm = (cov_2qnfv4ecti.s[21]++, this.$mdDialog.confirm().title('Apagar Registo?').textContent('Esta ação é irreversível. Quer prosseguir?').ok('Sim').cancel('Não, quero rever.'));

            cov_2qnfv4ecti.s[22]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_2qnfv4ecti.f[8]++;
                cov_2qnfv4ecti.s[23]++;

                sampleDevelopmentCycle.$destroy();
            }, function () {
                cov_2qnfv4ecti.f[9]++;
            });
        }
    }, {
        key: 'edit',
        value: function edit(sampleDevelopmentCycle) {
            cov_2qnfv4ecti.f[10]++;

            var position = (cov_2qnfv4ecti.s[24]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_2qnfv4ecti.s[25]++, {
                /** @ngInject */
                controller: SampleDevelopmentObservationsController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/sampleDevelopmentCycleTable/editing.tmpl.html',
                hasBackdrop: true,
                panelClass: 'sample-price-dialog',
                position: position,
                trapFocus: true,
                clickOutsideToClose: false,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    sampleDevelopmentCycle: sampleDevelopmentCycle,
                    editable: this.editable
                }
            });
            cov_2qnfv4ecti.s[26]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'getExecutionTime',
        value: function getExecutionTime() {
            cov_2qnfv4ecti.f[11]++;
            cov_2qnfv4ecti.s[27]++;

            return this.newSampleDevelopmentCycle.hours * 60 + this.newSampleDevelopmentCycle.minutes;
        }
    }, {
        key: 'createEntry',
        value: function createEntry() {
            var _this = this;

            cov_2qnfv4ecti.f[12]++;
            cov_2qnfv4ecti.s[28]++;

            this.newSampleDevelopmentCycle.sample.$save(['confectionDifficultyDegree']);
            var data = (cov_2qnfv4ecti.s[29]++, {
                /* eslint-disable camelcase */
                sample_development_log: this.sampleDevelopmentLog.id,
                seamstress: this.newSampleDevelopmentCycle.seamstress,
                sample: this.newSampleDevelopmentCycle.sample.id,
                quantity: this.newSampleDevelopmentCycle.quantity,
                progress: this.newSampleDevelopmentCycle.progress,
                execution_time: this.getExecutionTime()
            });
            cov_2qnfv4ecti.s[30]++;
            this.sampleDevelopmentCycles.$create(data).$then(function () {
                cov_2qnfv4ecti.f[13]++;
                cov_2qnfv4ecti.s[31]++;
                return _this.resetEntryLine();
            });
        }
    }, {
        key: 'selected',
        value: function selected(item) {
            cov_2qnfv4ecti.f[14]++;
            cov_2qnfv4ecti.s[32]++;

            if (item) {
                cov_2qnfv4ecti.b[2][0]++;
                cov_2qnfv4ecti.s[33]++;

                this.newSampleDevelopmentCycle.sample = item;
                cov_2qnfv4ecti.s[34]++;
                this.newSampleDevelopmentCycle.sampleType = item.sampleType;
            } else {
                cov_2qnfv4ecti.b[2][1]++;
            }
        }
    }, {
        key: 'searchItems',
        value: function searchItems(query) {
            var _this2 = this;

            cov_2qnfv4ecti.f[15]++;
            cov_2qnfv4ecti.s[35]++;

            /* eslint-disable no-return-assign */
            return this.SamplesTable.$search({ order_reference: query }).$then(function (response) {
                cov_2qnfv4ecti.f[16]++;
                cov_2qnfv4ecti.s[36]++;
                return _this2.items = response;
            });
        }
    }, {
        key: 'toggleIsFix',
        value: function toggleIsFix(sampleDevelopmentCycle) {
            cov_2qnfv4ecti.f[17]++;
            cov_2qnfv4ecti.s[37]++;

            if (this.editable) {
                cov_2qnfv4ecti.b[3][0]++;
                cov_2qnfv4ecti.s[38]++;

                sampleDevelopmentCycle.isFix = !sampleDevelopmentCycle.isFix;
                cov_2qnfv4ecti.s[39]++;
                sampleDevelopmentCycle.$save(['isFix']);
            } else {
                cov_2qnfv4ecti.b[3][1]++;
            }
        }
    }, {
        key: 'toggleIsFinal',
        value: function toggleIsFinal(sampleDevelopmentCycle) {
            cov_2qnfv4ecti.f[18]++;
            cov_2qnfv4ecti.s[40]++;

            sampleDevelopmentCycle.isFinal = !sampleDevelopmentCycle.isFinal;
            cov_2qnfv4ecti.s[41]++;
            sampleDevelopmentCycle.$save(['isFinal']);
        }
    }]);

    return SampleDevelopmentCycleTableController;
}();

cov_2qnfv4ecti.s[42]++;


angular.module('app').component('sampleDevelopmentCycleTable', {
    templateUrl: 'app/components/sampleDevelopmentCycleTable/sampleDevelopmentCycleTable.html',
    controller: SampleDevelopmentCycleTableController,
    bindings: {
        sampleDevelopmentLog: '<',
        sampleDevelopmentCycles: '<',
        editable: '<'
    }
});