'use strict';

var cov_iotkjocqf = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.foreseenDateChanges.factory.js',
        hash = 'a6150d91fd94ad5cd86aa69a4b9f0e4da75311f0',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.foreseenDateChanges.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 47,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 61
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 33,
                    column: 20
                },
                end: {
                    line: 35,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 34,
                    column: 24
                },
                end: {
                    line: 34,
                    column: 65
                }
            },
            '5': {
                start: {
                    line: 36,
                    column: 20
                },
                end: {
                    line: 36,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 39,
                    column: 20
                },
                end: {
                    line: 41,
                    column: 21
                }
            },
            '7': {
                start: {
                    line: 40,
                    column: 24
                },
                end: {
                    line: 40,
                    column: 46
                }
            },
            '8': {
                start: {
                    line: 42,
                    column: 20
                },
                end: {
                    line: 42,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 47,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 31,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 32
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                line: 31
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 38,
                        column: 24
                    },
                    end: {
                        line: 38,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 32
                    },
                    end: {
                        line: 43,
                        column: 17
                    }
                },
                line: 38
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                }, {
                    start: {
                        line: 33,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                }],
                line: 33
            },
            '1': {
                loc: {
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                }, {
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                }],
                line: 39
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_iotkjocqf.s[0]++;
(function () {
    'use strict';

    cov_iotkjocqf.f[0]++;
    cov_iotkjocqf.s[1]++;
    angular.module('app').factory('ForeseenDateChangesTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_iotkjocqf.f[1]++;
        cov_iotkjocqf.s[2]++;

        return restmod.model('/foreseen-date-changes').mix('MixinContainable', {
            $config: {
                contains: {
                    user: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'user'
                    },
                    reason: {
                        type: 'belongsTo',
                        table: 'ForeseenDateReasonsTable',
                        foreignKey: 'reason'
                    },
                    category: {
                        type: 'belongsTo',
                        table: 'ForeseenDateReasonCategoriesTable',
                        foreignKey: 'reasonCategory'
                    }
                }
            },
            toDate: {
                encode: function encode(date) {
                    cov_iotkjocqf.f[2]++;
                    cov_iotkjocqf.s[3]++;

                    /* eslint-disable no-undef */
                    if (date) {
                        cov_iotkjocqf.b[0][0]++;
                        cov_iotkjocqf.s[4]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_iotkjocqf.b[0][1]++;
                    }
                    cov_iotkjocqf.s[5]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_iotkjocqf.f[3]++;
                    cov_iotkjocqf.s[6]++;

                    if (date) {
                        cov_iotkjocqf.b[1][0]++;
                        cov_iotkjocqf.s[7]++;

                        return new Date(date);
                    } else {
                        cov_iotkjocqf.b[1][1]++;
                    }
                    cov_iotkjocqf.s[8]++;
                    return null;
                }
            }
        });
    }
})();