'use strict';

var cov_fjuvphcd6 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardStats.js',
        hash = '5ee712a5fecbdec1bddfbd9f3c653e80675abbdd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardStats.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 69
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 24
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 48
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 50
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 35
                }
            },
            '7': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 34
                }
            },
            '8': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 75
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 23
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 24
                }
            },
            '12': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 29
                }
            },
            '13': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 37
                }
            },
            '14': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 42
                }
            },
            '15': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 29
                }
            },
            '16': {
                start: {
                    line: 39,
                    column: 22
                },
                end: {
                    line: 39,
                    column: 63
                }
            },
            '17': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 32
                }
            },
            '18': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 33
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 64
                }
            },
            '20': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 41
                }
            },
            '21': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 34
                }
            },
            '22': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 20
                }
            },
            '23': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 33
                }
            },
            '24': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 20
                }
            },
            '25': {
                start: {
                    line: 58,
                    column: 0
                },
                end: {
                    line: 63,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 63
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 22,
                        column: 18
                    },
                    end: {
                        line: 22,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 24
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                },
                line: 22
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 29,
                        column: 4
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 20
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 29
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 11
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                line: 33
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 29
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 38
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 15
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 46
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 51,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 11
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                line: 51
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 39,
                        column: 22
                    },
                    end: {
                        line: 39,
                        column: 63
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 39,
                        column: 34
                    },
                    end: {
                        line: 39,
                        column: 45
                    }
                }, {
                    start: {
                        line: 39,
                        column: 48
                    },
                    end: {
                        line: 39,
                        column: 63
                    }
                }],
                line: 39
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardStatsController = function () {
    function DashboardStatsController(PerformanceIndicatorsTable, $interval, $state) {
        var _this = this;

        _classCallCheck(this, DashboardStatsController);

        cov_fjuvphcd6.f[0]++;
        cov_fjuvphcd6.s[0]++;

        this.PerformanceIndicatorsTable = PerformanceIndicatorsTable;
        cov_fjuvphcd6.s[1]++;
        this.$state = $state;
        cov_fjuvphcd6.s[2]++;
        this.now = new Date();
        cov_fjuvphcd6.s[3]++;
        this.months = 3;
        cov_fjuvphcd6.s[4]++;
        this.currentMonth = this.now.getMonth();
        cov_fjuvphcd6.s[5]++;
        this.currentYear = this.now.getFullYear();
        cov_fjuvphcd6.s[6]++;
        this.x = this.currentMonth;
        cov_fjuvphcd6.s[7]++;
        this.y = this.currentYear;
        /* eslint-disable camelcase */
        cov_fjuvphcd6.s[8]++;
        this.filters = {
            year: this.y,
            month__gt: this.x - this.months,
            month__lte: this.x,
            page_size: 9999
        };

        cov_fjuvphcd6.s[9]++;
        this.stats = this.PerformanceIndicatorsTable.$search(this.filters);

        cov_fjuvphcd6.s[10]++;
        $interval(function () {
            cov_fjuvphcd6.f[1]++;
            cov_fjuvphcd6.s[11]++;

            _this.load();
        }, 600 * 1000);

        cov_fjuvphcd6.s[12]++;
        this.setLastUpdate();
    }

    _createClass(DashboardStatsController, [{
        key: 'setLastUpdate',
        value: function setLastUpdate() {
            cov_fjuvphcd6.f[2]++;
            cov_fjuvphcd6.s[13]++;

            this.lastUpdate = new Date();
        }
    }, {
        key: 'load',
        value: function load() {
            cov_fjuvphcd6.f[3]++;
            cov_fjuvphcd6.s[14]++;

            this.stats.$refresh(this.filters);
            cov_fjuvphcd6.s[15]++;
            this.setLastUpdate();
        }
    }, {
        key: 'updateFilters',
        value: function updateFilters(increment) {
            cov_fjuvphcd6.f[4]++;

            var delta = (cov_fjuvphcd6.s[16]++, increment ? (cov_fjuvphcd6.b[0][0]++, this.months) : (cov_fjuvphcd6.b[0][1]++, 0 - this.months));
            cov_fjuvphcd6.s[17]++;
            this.x = this.x + delta;
            cov_fjuvphcd6.s[18]++;
            this.filters.year = 2019;
            cov_fjuvphcd6.s[19]++;
            this.filters.month__gt = this.filters.month__gt + delta;
            cov_fjuvphcd6.s[20]++;
            this.filters.month__lte = this.x;
        }
    }, {
        key: 'previous',
        value: function previous() {
            cov_fjuvphcd6.f[5]++;
            cov_fjuvphcd6.s[21]++;

            this.updateFilters(false);
            cov_fjuvphcd6.s[22]++;
            this.load();
        }
    }, {
        key: 'next',
        value: function next() {
            cov_fjuvphcd6.f[6]++;
            cov_fjuvphcd6.s[23]++;

            this.updateFilters(true);
            cov_fjuvphcd6.s[24]++;
            this.load();
        }
    }]);

    return DashboardStatsController;
}();

cov_fjuvphcd6.s[25]++;


angular.module('app').component('dashboardStats', {
    templateUrl: 'app/components/dashboard/dashboardStats.html',
    controller: DashboardStatsController
});