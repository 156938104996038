'use strict';

var cov_kelxfyu3n = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/decorationTypeDetailsView/decorationTypeDetailsView.js',
        hash = 'a695f453790c52874f0f4491deda39e08980cd37',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/decorationTypeDetailsView/decorationTypeDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 29
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '3': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 9
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 47
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 11
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 65
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 24
                },
                end: {
                    line: 26,
                    column: 31
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 38,
                    column: 14
                }
            },
            '10': {
                start: {
                    line: 31,
                    column: 20
                },
                end: {
                    line: 31,
                    column: 67
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 20
                },
                end: {
                    line: 32,
                    column: 40
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 20
                },
                end: {
                    line: 35,
                    column: 65
                }
            },
            '13': {
                start: {
                    line: 36,
                    column: 20
                },
                end: {
                    line: 36,
                    column: 32
                }
            },
            '14': {
                start: {
                    line: 43,
                    column: 0
                },
                end: {
                    line: 51,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 45
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 20
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 13
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 42
                    },
                    end: {
                        line: 15,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 60
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 13
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 20
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 28,
                        column: 42
                    },
                    end: {
                        line: 28,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 48
                    },
                    end: {
                        line: 39,
                        column: 9
                    }
                },
                line: 28
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 30,
                        column: 16
                    },
                    end: {
                        line: 30,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 22
                    },
                    end: {
                        line: 33,
                        column: 17
                    }
                },
                line: 30
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 34,
                        column: 16
                    },
                    end: {
                        line: 34,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 23
                    },
                    end: {
                        line: 37,
                        column: 17
                    }
                },
                line: 34
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }, {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }],
                line: 9
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DecorationTypeDetailsViewController = function () {
    function DecorationTypeDetailsViewController($state, $mdToast, $mdDialog) {
        _classCallCheck(this, DecorationTypeDetailsViewController);

        cov_kelxfyu3n.f[0]++;
        cov_kelxfyu3n.s[0]++;

        this.$state = $state;
        cov_kelxfyu3n.s[1]++;
        this.$mdToast = $mdToast;
        cov_kelxfyu3n.s[2]++;
        this.$mdDialog = $mdDialog;
    }

    _createClass(DecorationTypeDetailsViewController, [{
        key: 'autosave',
        value: function autosave(field) {
            cov_kelxfyu3n.f[1]++;
            cov_kelxfyu3n.s[3]++;

            if (this.decorationType.id) {
                cov_kelxfyu3n.b[0][0]++;
                cov_kelxfyu3n.s[4]++;

                this.decorationType.$save([field]);
            } else {
                cov_kelxfyu3n.b[0][1]++;
            }
        }
    }, {
        key: 'create',
        value: function create() {
            var _this = this;

            cov_kelxfyu3n.f[2]++;
            cov_kelxfyu3n.s[5]++;

            this.decorationType.$save().$then(function (decorationType) {
                cov_kelxfyu3n.f[3]++;
                cov_kelxfyu3n.s[6]++;

                _this.$state.go('^.details', { id: decorationType.id });
            });
        }
    }, {
        key: 'delete',
        value: function _delete() {
            var _this2 = this;

            cov_kelxfyu3n.f[4]++;

            /* eslint-disable prefer-template */
            var confirm = (cov_kelxfyu3n.s[7]++, this.$mdDialog.confirm().title('Tem certeza que deseja remover o Ornamento ' + this.decorationType.name).textContent('Essa ação é irreversível.').ok('Remover').cancel('Cancelar'));

            cov_kelxfyu3n.s[8]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_kelxfyu3n.f[5]++;
                cov_kelxfyu3n.s[9]++;

                _this2.decorationType.$destroy().$then(function () {
                    cov_kelxfyu3n.f[6]++;
                    cov_kelxfyu3n.s[10]++;

                    _this2.$mdToast.showSimple('Ornamento removido');
                    cov_kelxfyu3n.s[11]++;
                    _this2.$state.go('^');
                }, function (err) {
                    cov_kelxfyu3n.f[7]++;
                    cov_kelxfyu3n.s[12]++;

                    _this2.$mdToast.showSimple(err.$response.data);
                    cov_kelxfyu3n.s[13]++;
                    return null;
                });
            });
        }
    }]);

    return DecorationTypeDetailsViewController;
}();

cov_kelxfyu3n.s[14]++;


angular.module('app').component('decorationTypeDetailsView', {
    templateUrl: 'app/components/decorationTypeDetailsView/decorationTypeDetailsView.html',
    controller: DecorationTypeDetailsViewController,
    bindings: {
        decorationType: '<'
    }
});