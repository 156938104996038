'use strict';

var cov_2i9z79syx3 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.sampleItemPrices.factory.js',
        hash = '8d923f64c41fec04314ca97e71dc2f04e9bc7396',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.sampleItemPrices.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 64,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 58
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 62,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 32,
                    column: 20
                },
                end: {
                    line: 34,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 33,
                    column: 24
                },
                end: {
                    line: 33,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 35,
                    column: 20
                },
                end: {
                    line: 35,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 40,
                    column: 20
                },
                end: {
                    line: 42,
                    column: 21
                }
            },
            '7': {
                start: {
                    line: 41,
                    column: 24
                },
                end: {
                    line: 41,
                    column: 41
                }
            },
            '8': {
                start: {
                    line: 43,
                    column: 20
                },
                end: {
                    line: 43,
                    column: 32
                }
            },
            '9': {
                start: {
                    line: 48,
                    column: 20
                },
                end: {
                    line: 50,
                    column: 21
                }
            },
            '10': {
                start: {
                    line: 49,
                    column: 24
                },
                end: {
                    line: 49,
                    column: 41
                }
            },
            '11': {
                start: {
                    line: 51,
                    column: 20
                },
                end: {
                    line: 51,
                    column: 32
                }
            },
            '12': {
                start: {
                    line: 56,
                    column: 20
                },
                end: {
                    line: 58,
                    column: 21
                }
            },
            '13': {
                start: {
                    line: 57,
                    column: 24
                },
                end: {
                    line: 57,
                    column: 33
                }
            },
            '14': {
                start: {
                    line: 59,
                    column: 20
                },
                end: {
                    line: 59,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 64,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 31,
                        column: 24
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 29
                    },
                    end: {
                        line: 36,
                        column: 17
                    }
                },
                line: 31
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 39,
                        column: 24
                    },
                    end: {
                        line: 39,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 29
                    },
                    end: {
                        line: 44,
                        column: 17
                    }
                },
                line: 39
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 47,
                        column: 24
                    },
                    end: {
                        line: 47,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 29
                    },
                    end: {
                        line: 52,
                        column: 17
                    }
                },
                line: 47
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 55,
                        column: 24
                    },
                    end: {
                        line: 55,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 29
                    },
                    end: {
                        line: 60,
                        column: 17
                    }
                },
                line: 55
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 32,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 32,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                }, {
                    start: {
                        line: 32,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                }],
                line: 32
            },
            '1': {
                loc: {
                    start: {
                        line: 40,
                        column: 20
                    },
                    end: {
                        line: 42,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 40,
                        column: 20
                    },
                    end: {
                        line: 42,
                        column: 21
                    }
                }, {
                    start: {
                        line: 40,
                        column: 20
                    },
                    end: {
                        line: 42,
                        column: 21
                    }
                }],
                line: 40
            },
            '2': {
                loc: {
                    start: {
                        line: 48,
                        column: 20
                    },
                    end: {
                        line: 50,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 48,
                        column: 20
                    },
                    end: {
                        line: 50,
                        column: 21
                    }
                }, {
                    start: {
                        line: 48,
                        column: 20
                    },
                    end: {
                        line: 50,
                        column: 21
                    }
                }],
                line: 48
            },
            '3': {
                loc: {
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                }, {
                    start: {
                        line: 56,
                        column: 20
                    },
                    end: {
                        line: 58,
                        column: 21
                    }
                }],
                line: 56
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_2i9z79syx3.s[0]++;
(function () {
    'use strict';

    cov_2i9z79syx3.f[0]++;
    cov_2i9z79syx3.s[1]++;
    angular.module('app').factory('SampleItemPricesTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_2i9z79syx3.f[1]++;
        cov_2i9z79syx3.s[2]++;

        return restmod.model('/sample-item-prices').mix('MixinContainable', {
            $config: {
                contains: {
                    sampleItemPriceType: {
                        type: 'belongsTo',
                        table: 'SampleItemPriceTypesTable',
                        foreignKey: 'sampleItemPriceType'
                    },
                    createdBy: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'createdBy'
                    },
                    updatedBy: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'updatedBy'
                    }
                }
            },
            length: {
                decode: function decode(n) {
                    cov_2i9z79syx3.f[2]++;
                    cov_2i9z79syx3.s[3]++;

                    if (n) {
                        cov_2i9z79syx3.b[0][0]++;
                        cov_2i9z79syx3.s[4]++;

                        return Number(n);
                    } else {
                        cov_2i9z79syx3.b[0][1]++;
                    }
                    cov_2i9z79syx3.s[5]++;
                    return null;
                }
            },
            height: {
                decode: function decode(n) {
                    cov_2i9z79syx3.f[3]++;
                    cov_2i9z79syx3.s[6]++;

                    if (n) {
                        cov_2i9z79syx3.b[1][0]++;
                        cov_2i9z79syx3.s[7]++;

                        return Number(n);
                    } else {
                        cov_2i9z79syx3.b[1][1]++;
                    }
                    cov_2i9z79syx3.s[8]++;
                    return null;
                }
            },
            price: {
                decode: function decode(n) {
                    cov_2i9z79syx3.f[4]++;
                    cov_2i9z79syx3.s[9]++;

                    if (n) {
                        cov_2i9z79syx3.b[2][0]++;
                        cov_2i9z79syx3.s[10]++;

                        return Number(n);
                    } else {
                        cov_2i9z79syx3.b[2][1]++;
                    }
                    cov_2i9z79syx3.s[11]++;
                    return null;
                }
            },
            observations: {
                encode: function encode(n) {
                    cov_2i9z79syx3.f[5]++;
                    cov_2i9z79syx3.s[12]++;

                    if (n) {
                        cov_2i9z79syx3.b[3][0]++;
                        cov_2i9z79syx3.s[13]++;

                        return n;
                    } else {
                        cov_2i9z79syx3.b[3][1]++;
                    }
                    cov_2i9z79syx3.s[14]++;
                    return null;
                }
            }
        });
    }
})();