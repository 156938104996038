'use strict';

var cov_1o857ewbn4 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardNotifications.js',
        hash = '9e1cf6b2f91a9732491b84f36bbd9763b6fff358',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardNotifications.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardNotificationsController = function DashboardNotificationsController() {
    _classCallCheck(this, DashboardNotificationsController);
};

cov_1o857ewbn4.s[0]++;


angular.module('app').component('dashboardNotifications', {
    templateUrl: 'app/components/dashboard/dashboardNotifications.html',
    controller: DashboardNotificationsController
});