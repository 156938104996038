'use strict';

var cov_oxoc3kgqc = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/media/media.directive.js',
        hash = '162dc4e7b2d0469750eb2a1749107096cedb525f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/media/media.directive.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 39,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 31
                },
                end: {
                    line: 14,
                    column: 57
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 31
                },
                end: {
                    line: 15,
                    column: 44
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 31
                },
                end: {
                    line: 16,
                    column: 36
                }
            },
            '6': {
                start: {
                    line: 19,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 19
                }
            },
            '7': {
                start: {
                    line: 20,
                    column: 33
                },
                end: {
                    line: 20,
                    column: 47
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 20
                },
                end: {
                    line: 34,
                    column: 21
                }
            },
            '9': {
                start: {
                    line: 27,
                    column: 40
                },
                end: {
                    line: 27,
                    column: 72
                }
            },
            '10': {
                start: {
                    line: 28,
                    column: 24
                },
                end: {
                    line: 28,
                    column: 48
                }
            },
            '11': {
                start: {
                    line: 29,
                    column: 24
                },
                end: {
                    line: 33,
                    column: 27
                }
            },
            '12': {
                start: {
                    line: 30,
                    column: 28
                },
                end: {
                    line: 30,
                    column: 49
                }
            },
            '13': {
                start: {
                    line: 32,
                    column: 28
                },
                end: {
                    line: 32,
                    column: 49
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 39,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'MediaDirective',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 36
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 13,
                        column: 18
                    },
                    end: {
                        line: 13,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 56
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 19,
                        column: 50
                    },
                    end: {
                        line: 19,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 62
                    },
                    end: {
                        line: 35,
                        column: 17
                    }
                },
                line: 19
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 29,
                        column: 37
                    },
                    end: {
                        line: 29,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 53
                    },
                    end: {
                        line: 31,
                        column: 25
                    }
                },
                line: 29
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 31,
                        column: 27
                    },
                    end: {
                        line: 31,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 43
                    },
                    end: {
                        line: 33,
                        column: 25
                    }
                },
                line: 31
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                }, {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 34,
                        column: 21
                    }
                }],
                line: 22
            },
            '1': {
                loc: {
                    start: {
                        line: 23,
                        column: 24
                    },
                    end: {
                        line: 25,
                        column: 70
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 23,
                        column: 24
                    },
                    end: {
                        line: 23,
                        column: 28
                    }
                }, {
                    start: {
                        line: 24,
                        column: 24
                    },
                    end: {
                        line: 24,
                        column: 48
                    }
                }, {
                    start: {
                        line: 25,
                        column: 25
                    },
                    end: {
                        line: 25,
                        column: 45
                    }
                }, {
                    start: {
                        line: 25,
                        column: 49
                    },
                    end: {
                        line: 25,
                        column: 69
                    }
                }],
                line: 23
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0, 0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

cov_oxoc3kgqc.s[0]++;
(function () {
    'use strict';

    cov_oxoc3kgqc.f[0]++;
    cov_oxoc3kgqc.s[1]++;
    angular.module('app').directive('mediaAutoUpload', MediaDirective);

    /* @ngInject */
    function MediaDirective($parse) {
        cov_oxoc3kgqc.f[1]++;
        cov_oxoc3kgqc.s[2]++;

        return {
            require: '?ngModel',
            /* eslint-disable babel/object-shorthand */
            link: function link(scope, element, attributes) {
                cov_oxoc3kgqc.f[2]++;

                var getter = (cov_oxoc3kgqc.s[3]++, $parse(attributes.ngModel));
                var setter = (cov_oxoc3kgqc.s[4]++, getter.assign);
                var $scope = (cov_oxoc3kgqc.s[5]++, scope);

                /* eslint-disable prefer-arrow-callback */
                cov_oxoc3kgqc.s[6]++;
                $scope.$watch(attributes.ngModel, function () {
                    cov_oxoc3kgqc.f[3]++;

                    var file = (cov_oxoc3kgqc.s[7]++, getter($scope));
                    /* eslint-disable angular/typecheck-object */
                    cov_oxoc3kgqc.s[8]++;
                    if ((cov_oxoc3kgqc.b[1][0]++, file) && (cov_oxoc3kgqc.b[1][1]++, (typeof file === 'undefined' ? 'undefined' : _typeof(file)) === 'object') && ((cov_oxoc3kgqc.b[1][2]++, file instanceof File) || (cov_oxoc3kgqc.b[1][3]++, file instanceof Blob))) {
                        cov_oxoc3kgqc.b[0][0]++;

                        var promise = (cov_oxoc3kgqc.s[9]++, $scope.$root.$Media.upload(file));
                        cov_oxoc3kgqc.s[10]++;
                        setter($scope, promise);
                        cov_oxoc3kgqc.s[11]++;
                        promise.then(function (data) {
                            cov_oxoc3kgqc.f[4]++;
                            cov_oxoc3kgqc.s[12]++;

                            setter($scope, data);
                        }, function (data) {
                            cov_oxoc3kgqc.f[5]++;
                            cov_oxoc3kgqc.s[13]++;

                            setter($scope, data);
                        });
                    } else {
                        cov_oxoc3kgqc.b[0][1]++;
                    }
                });
            }
        };
    }
})();