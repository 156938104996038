'use strict';

var cov_14t318mpoq = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/userWorkingState.js',
        hash = '6ff9bc7ecd639627070cf574f5ad90fc8e77b583',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/userWorkingState.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 23,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 55
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 29
                },
                end: {
                    line: 11,
                    column: 47
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 28
                },
                end: {
                    line: 19,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 37
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 23,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'UserWorkingState',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 42
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 27
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_14t318mpoq.s[0]++;
(function () {
    'use strict';

    cov_14t318mpoq.f[0]++;
    cov_14t318mpoq.s[1]++;
    angular.module('app').factory('UserWorkingState', UserWorkingState);

    /* @ngInject */
    function UserWorkingState($rootScope) {
        cov_14t318mpoq.f[1]++;
        cov_14t318mpoq.s[2]++;

        return function () {
            cov_14t318mpoq.f[2]++;

            var userType = (cov_14t318mpoq.s[3]++, $rootScope.me.type);
            var mapping = (cov_14t318mpoq.s[4]++, {
                COMMERCIAL: [1],
                CAD: [2],
                CUTTING: [3],
                CONFECTION: [4],
                FINISHINGS: [6],
                CONTROL: [6, 7, 8]
            });
            cov_14t318mpoq.s[5]++;
            return mapping[userType];
        };
    }
})();