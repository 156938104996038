'use strict';

var cov_26braw75y6 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/brandDetailsView/brandDetailsView.js',
        hash = '131807fdd1e584eaff5026aeda25a432b6f3dc38',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/brandDetailsView/brandDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 29
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            '2': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '3': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 67
                }
            },
            '4': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 39
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 35
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 49
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 46
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 26
                },
                end: {
                    line: 18,
                    column: 44
                }
            },
            '10': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 52,
                    column: 13
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 26
                },
                end: {
                    line: 27,
                    column: 72
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 16
                },
                end: {
                    line: 36,
                    column: 19
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 34
                },
                end: {
                    line: 29,
                    column: 77
                }
            },
            '15': {
                start: {
                    line: 31,
                    column: 20
                },
                end: {
                    line: 35,
                    column: 21
                }
            },
            '16': {
                start: {
                    line: 32,
                    column: 24
                },
                end: {
                    line: 32,
                    column: 42
                }
            },
            '17': {
                start: {
                    line: 34,
                    column: 24
                },
                end: {
                    line: 34,
                    column: 87
                }
            },
            '18': {
                start: {
                    line: 38,
                    column: 16
                },
                end: {
                    line: 47,
                    column: 19
                }
            },
            '19': {
                start: {
                    line: 39,
                    column: 20
                },
                end: {
                    line: 41,
                    column: 21
                }
            },
            '20': {
                start: {
                    line: 40,
                    column: 24
                },
                end: {
                    line: 40,
                    column: 31
                }
            },
            '21': {
                start: {
                    line: 42,
                    column: 20
                },
                end: {
                    line: 46,
                    column: 55
                }
            },
            '22': {
                start: {
                    line: 49,
                    column: 16
                },
                end: {
                    line: 49,
                    column: 22
                }
            },
            '23': {
                start: {
                    line: 51,
                    column: 16
                },
                end: {
                    line: 51,
                    column: 42
                }
            },
            '24': {
                start: {
                    line: 57,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 11
                }
            },
            '25': {
                start: {
                    line: 58,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 56
                }
            },
            '26': {
                start: {
                    line: 63,
                    column: 21
                },
                end: {
                    line: 63,
                    column: 68
                }
            },
            '27': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 61
                }
            },
            '28': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 32
                }
            },
            '29': {
                start: {
                    line: 70,
                    column: 24
                },
                end: {
                    line: 74,
                    column: 31
                }
            },
            '30': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 11
                }
            },
            '31': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 86,
                    column: 14
                }
            },
            '32': {
                start: {
                    line: 79,
                    column: 20
                },
                end: {
                    line: 79,
                    column: 63
                }
            },
            '33': {
                start: {
                    line: 80,
                    column: 20
                },
                end: {
                    line: 80,
                    column: 40
                }
            },
            '34': {
                start: {
                    line: 83,
                    column: 20
                },
                end: {
                    line: 83,
                    column: 65
                }
            },
            '35': {
                start: {
                    line: 84,
                    column: 20
                },
                end: {
                    line: 84,
                    column: 32
                }
            },
            '36': {
                start: {
                    line: 91,
                    column: 0
                },
                end: {
                    line: 99,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 72
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 9,
                        column: 4
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 26
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 16,
                        column: 65
                    },
                    end: {
                        line: 16,
                        column: 66
                    }
                },
                loc: {
                    start: {
                        line: 16,
                        column: 89
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 16
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 16
                    },
                    end: {
                        line: 18,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 26
                    },
                    end: {
                        line: 18,
                        column: 44
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 20
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                line: 22
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 28,
                        column: 66
                    },
                    end: {
                        line: 28,
                        column: 67
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 89
                    },
                    end: {
                        line: 36,
                        column: 17
                    }
                },
                line: 28
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 38,
                        column: 37
                    },
                    end: {
                        line: 38,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 53
                    },
                    end: {
                        line: 47,
                        column: 17
                    }
                },
                line: 38
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 56,
                        column: 4
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 13
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 56
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 57,
                        column: 33
                    },
                    end: {
                        line: 57,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 42
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                },
                line: 57
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 23
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 62
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 13
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                line: 68
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 76,
                        column: 42
                    },
                    end: {
                        line: 76,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 48
                    },
                    end: {
                        line: 87,
                        column: 9
                    }
                },
                line: 76
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 78,
                        column: 16
                    },
                    end: {
                        line: 78,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 22
                    },
                    end: {
                        line: 81,
                        column: 17
                    }
                },
                line: 78
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 82,
                        column: 16
                    },
                    end: {
                        line: 82,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 82,
                        column: 23
                    },
                    end: {
                        line: 85,
                        column: 17
                    }
                },
                line: 82
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 53,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 53,
                        column: 9
                    }
                }, {
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 53,
                        column: 9
                    }
                }],
                line: 23
            },
            '1': {
                loc: {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 52,
                        column: 13
                    }
                },
                type: 'switch',
                locations: [{
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 49,
                        column: 22
                    }
                }, {
                    start: {
                        line: 50,
                        column: 12
                    },
                    end: {
                        line: 51,
                        column: 42
                    }
                }],
                line: 24
            },
            '2': {
                loc: {
                    start: {
                        line: 31,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                }, {
                    start: {
                        line: 31,
                        column: 20
                    },
                    end: {
                        line: 35,
                        column: 21
                    }
                }],
                line: 31
            },
            '3': {
                loc: {
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                }, {
                    start: {
                        line: 39,
                        column: 20
                    },
                    end: {
                        line: 41,
                        column: 21
                    }
                }],
                line: 39
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BrandDetailsViewController = function () {
    function BrandDetailsViewController($state, $mdToast, $mdDialog, BrandPriceReferencesTable) {
        _classCallCheck(this, BrandDetailsViewController);

        cov_26braw75y6.f[0]++;
        cov_26braw75y6.s[0]++;

        this.$state = $state;
        cov_26braw75y6.s[1]++;
        this.$mdToast = $mdToast;
        cov_26braw75y6.s[2]++;
        this.$mdDialog = $mdDialog;
        cov_26braw75y6.s[3]++;
        this.BrandPriceReferencesTable = BrandPriceReferencesTable;
    }

    _createClass(BrandDetailsViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_26braw75y6.f[1]++;
            cov_26braw75y6.s[4]++;

            this.brand.$load('brandAlias');
            cov_26braw75y6.s[5]++;
            this.loadPriceReferences();
        }
    }, {
        key: 'loadPriceReferences',
        value: function loadPriceReferences() {
            var _this = this;

            cov_26braw75y6.f[2]++;
            cov_26braw75y6.s[6]++;

            this.brand.$load('brandPriceReferences');
            cov_26braw75y6.s[7]++;
            this.brand.$brandPriceReferences.$on('after-fetch-many', function (brandPriceReferences) {
                cov_26braw75y6.f[3]++;
                cov_26braw75y6.s[8]++;

                _this.brand.$brandPriceReferencesModel = brandPriceReferences.data.results.map(function (result) {
                    cov_26braw75y6.f[4]++;
                    cov_26braw75y6.s[9]++;
                    return result.sample_type;
                });
            });
        }
    }, {
        key: 'autosave',
        value: function autosave(field) {
            var _this2 = this;

            cov_26braw75y6.f[5]++;
            cov_26braw75y6.s[10]++;

            if (this.brand.id) {
                cov_26braw75y6.b[0][0]++;
                cov_26braw75y6.s[11]++;

                switch (field) {
                    case 'brandPriceReferences':
                        cov_26braw75y6.b[1][0]++;

                        /* eslint-disable no-var */
                        var ids = (cov_26braw75y6.s[12]++, this.brand.$brandPriceReferencesModel.slice(0));
                        cov_26braw75y6.s[13]++;
                        angular.forEach(this.brand.$brandPriceReferences, function (brandPriceReference) {
                            cov_26braw75y6.f[6]++;

                            var index = (cov_26braw75y6.s[14]++, ids.indexOf(brandPriceReference.sampleType));
                            /* eslint-disable no-negated-condition */
                            cov_26braw75y6.s[15]++;
                            if (index !== -1) {
                                cov_26braw75y6.b[2][0]++;
                                cov_26braw75y6.s[16]++;

                                delete ids[index];
                            } else {
                                cov_26braw75y6.b[2][1]++;
                                cov_26braw75y6.s[17]++;

                                brandPriceReference.$destroy().$then(_this2.loadPriceReferences);
                            }
                        });

                        cov_26braw75y6.s[18]++;
                        angular.forEach(ids, function (sampleTypeId) {
                            cov_26braw75y6.f[7]++;
                            cov_26braw75y6.s[19]++;

                            if (!sampleTypeId) {
                                cov_26braw75y6.b[3][0]++;
                                cov_26braw75y6.s[20]++;

                                return;
                            } else {
                                cov_26braw75y6.b[3][1]++;
                            }
                            cov_26braw75y6.s[21]++;
                            _this2.BrandPriceReferencesTable.$create({
                                brand: _this2.brand.id,
                                /* eslint-disable camelcase */
                                sample_type: sampleTypeId
                            }).$then(_this2.loadPriceReferences);
                        });

                        cov_26braw75y6.s[22]++;
                        break;
                    default:
                        cov_26braw75y6.b[1][1]++;
                        cov_26braw75y6.s[23]++;

                        this.brand.$save([field]);
                }
            } else {
                cov_26braw75y6.b[0][1]++;
            }
        }
    }, {
        key: 'create',
        value: function create() {
            var _this3 = this;

            cov_26braw75y6.f[8]++;
            cov_26braw75y6.s[24]++;

            this.brand.$save().$then(function (brand) {
                cov_26braw75y6.f[9]++;
                cov_26braw75y6.s[25]++;

                _this3.$state.go('^.details', { id: brand.id });
            });
        }
    }, {
        key: 'createBrandAlias',
        value: function createBrandAlias() {
            cov_26braw75y6.f[10]++;

            var data = (cov_26braw75y6.s[26]++, { key: this.brand.id, alias: this.newBrandAlias });
            cov_26braw75y6.s[27]++;
            this.newAlias = this.brand.$brandAlias.$create(data);
            cov_26braw75y6.s[28]++;
            this.newBrandAlias = '';
        }
    }, {
        key: 'delete',
        value: function _delete() {
            var _this4 = this;

            cov_26braw75y6.f[11]++;

            /* eslint-disable prefer-template */
            var confirm = (cov_26braw75y6.s[29]++, this.$mdDialog.confirm().title('Tem certeza que deseja remover a marca ' + this.brand.name).textContent('Essa ação é irreversível.').ok('Remover').cancel('Cancelar'));

            cov_26braw75y6.s[30]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_26braw75y6.f[12]++;
                cov_26braw75y6.s[31]++;

                _this4.brand.$destroy().$then(function () {
                    cov_26braw75y6.f[13]++;
                    cov_26braw75y6.s[32]++;

                    _this4.$mdToast.showSimple('Marca removida');
                    cov_26braw75y6.s[33]++;
                    _this4.$state.go('^');
                }, function (err) {
                    cov_26braw75y6.f[14]++;
                    cov_26braw75y6.s[34]++;

                    _this4.$mdToast.showSimple(err.$response.data);
                    cov_26braw75y6.s[35]++;
                    return null;
                });
            });
        }
    }]);

    return BrandDetailsViewController;
}();

cov_26braw75y6.s[36]++;


angular.module('app').component('brandDetailsView', {
    templateUrl: 'app/components/brandDetailsView/brandDetailsView.html',
    controller: BrandDetailsViewController,
    bindings: {
        brand: '<'
    }
});