'use strict';

var cov_2ri02b0mzx = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/priceCheck/priceCheck.js',
        hash = '82db60b4b8d612e4f96b5e3c03749a97b493c5e7',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/priceCheck/priceCheck.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 25
                },
                end: {
                    line: 8,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 37
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 39
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 33
                }
            },
            '7': {
                start: {
                    line: 22,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 33
                }
            },
            '9': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 55
                }
            },
            '10': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 43
                }
            },
            '11': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 69
                }
            },
            '12': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 69
                }
            },
            '13': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 69
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 61
                }
            },
            '15': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 35
                }
            },
            '16': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 30
                }
            },
            '17': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 10
                }
            },
            '18': {
                start: {
                    line: 43,
                    column: 24
                },
                end: {
                    line: 47,
                    column: 37
                }
            },
            '19': {
                start: {
                    line: 48,
                    column: 8
                },
                end: {
                    line: 48,
                    column: 69
                }
            },
            '20': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 34
                }
            },
            '21': {
                start: {
                    line: 62,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 11
                }
            },
            '22': {
                start: {
                    line: 64,
                    column: 12
                },
                end: {
                    line: 64,
                    column: 31
                }
            },
            '23': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 52
                }
            },
            '24': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 106
                }
            },
            '25': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 38
                }
            },
            '26': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 72,
                    column: 47
                }
            },
            '27': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 57
                }
            },
            '28': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 80,
                    column: 9
                }
            },
            '29': {
                start: {
                    line: 79,
                    column: 12
                },
                end: {
                    line: 79,
                    column: 72
                }
            },
            '30': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 28
                }
            },
            '31': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 9
                }
            },
            '32': {
                start: {
                    line: 86,
                    column: 12
                },
                end: {
                    line: 88,
                    column: 13
                }
            },
            '33': {
                start: {
                    line: 87,
                    column: 16
                },
                end: {
                    line: 87,
                    column: 47
                }
            },
            '34': {
                start: {
                    line: 89,
                    column: 12
                },
                end: {
                    line: 89,
                    column: 75
                }
            },
            '35': {
                start: {
                    line: 91,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 25
                }
            },
            '36': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 63
                }
            },
            '37': {
                start: {
                    line: 99,
                    column: 8
                },
                end: {
                    line: 99,
                    column: 45
                }
            },
            '38': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 45
                }
            },
            '39': {
                start: {
                    line: 107,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 9
                }
            },
            '40': {
                start: {
                    line: 108,
                    column: 12
                },
                end: {
                    line: 108,
                    column: 32
                }
            },
            '41': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 30
                }
            },
            '42': {
                start: {
                    line: 115,
                    column: 25
                },
                end: {
                    line: 115,
                    column: 77
                }
            },
            '43': {
                start: {
                    line: 117,
                    column: 23
                },
                end: {
                    line: 133,
                    column: 9
                }
            },
            '44': {
                start: {
                    line: 134,
                    column: 8
                },
                end: {
                    line: 134,
                    column: 35
                }
            },
            '45': {
                start: {
                    line: 138,
                    column: 23
                },
                end: {
                    line: 141,
                    column: 9
                }
            },
            '46': {
                start: {
                    line: 142,
                    column: 8
                },
                end: {
                    line: 143,
                    column: 30
                }
            },
            '47': {
                start: {
                    line: 147,
                    column: 8
                },
                end: {
                    line: 148,
                    column: 33
                }
            },
            '48': {
                start: {
                    line: 148,
                    column: 18
                },
                end: {
                    line: 148,
                    column: 31
                }
            },
            '49': {
                start: {
                    line: 152,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 11
                }
            },
            '50': {
                start: {
                    line: 153,
                    column: 12
                },
                end: {
                    line: 153,
                    column: 33
                }
            },
            '51': {
                start: {
                    line: 154,
                    column: 12
                },
                end: {
                    line: 154,
                    column: 51
                }
            },
            '52': {
                start: {
                    line: 154,
                    column: 36
                },
                end: {
                    line: 154,
                    column: 49
                }
            },
            '53': {
                start: {
                    line: 159,
                    column: 8
                },
                end: {
                    line: 159,
                    column: 52
                }
            },
            '54': {
                start: {
                    line: 160,
                    column: 8
                },
                end: {
                    line: 161,
                    column: 57
                }
            },
            '55': {
                start: {
                    line: 165,
                    column: 8
                },
                end: {
                    line: 165,
                    column: 51
                }
            },
            '56': {
                start: {
                    line: 166,
                    column: 8
                },
                end: {
                    line: 167,
                    column: 57
                }
            },
            '57': {
                start: {
                    line: 171,
                    column: 0
                },
                end: {
                    line: 184,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 18
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 30
                    },
                    end: {
                        line: 10,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 36
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 107
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 18
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 53
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                line: 42
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 48,
                        column: 59
                    },
                    end: {
                        line: 48,
                        column: 60
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 65
                    },
                    end: {
                        line: 48,
                        column: 67
                    }
                },
                line: 48
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 51,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 18
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                line: 51
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 60,
                        column: 4
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 60,
                        column: 25
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 60
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 62,
                        column: 86
                    },
                    end: {
                        line: 62,
                        column: 87
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 92
                    },
                    end: {
                        line: 65,
                        column: 9
                    }
                },
                line: 62
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 12
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                line: 68
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 77,
                        column: 4
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 16
                    },
                    end: {
                        line: 82,
                        column: 5
                    }
                },
                line: 77
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 84,
                        column: 4
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 20
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                line: 84
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 94,
                        column: 4
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 20
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                line: 94
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 98,
                        column: 4
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 98,
                        column: 14
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                line: 98
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 102,
                        column: 4
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 19
                    },
                    end: {
                        line: 104,
                        column: 5
                    }
                },
                line: 102
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 106,
                        column: 4
                    },
                    end: {
                        line: 106,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 106,
                        column: 17
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                line: 106
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 114,
                        column: 4
                    },
                    end: {
                        line: 114,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 24
                    },
                    end: {
                        line: 135,
                        column: 5
                    }
                },
                line: 114
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 137,
                        column: 4
                    },
                    end: {
                        line: 137,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 20
                    },
                    end: {
                        line: 144,
                        column: 5
                    }
                },
                line: 137
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 146,
                        column: 4
                    },
                    end: {
                        line: 146,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 146,
                        column: 25
                    },
                    end: {
                        line: 149,
                        column: 5
                    }
                },
                line: 146
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 148,
                        column: 12
                    },
                    end: {
                        line: 148,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 148,
                        column: 18
                    },
                    end: {
                        line: 148,
                        column: 31
                    }
                },
                line: 148
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 151,
                        column: 4
                    },
                    end: {
                        line: 151,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 151,
                        column: 25
                    },
                    end: {
                        line: 156,
                        column: 5
                    }
                },
                line: 151
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 152,
                        column: 72
                    },
                    end: {
                        line: 152,
                        column: 73
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 79
                    },
                    end: {
                        line: 155,
                        column: 9
                    }
                },
                line: 152
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 154,
                        column: 30
                    },
                    end: {
                        line: 154,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 154,
                        column: 36
                    },
                    end: {
                        line: 154,
                        column: 49
                    }
                },
                line: 154
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 158,
                        column: 4
                    },
                    end: {
                        line: 158,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 158,
                        column: 17
                    },
                    end: {
                        line: 162,
                        column: 5
                    }
                },
                line: 158
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 164,
                        column: 4
                    },
                    end: {
                        line: 164,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 164,
                        column: 15
                    },
                    end: {
                        line: 168,
                        column: 5
                    }
                },
                line: 164
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 78,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 78,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 9
                    }
                }, {
                    start: {
                        line: 78,
                        column: 8
                    },
                    end: {
                        line: 80,
                        column: 9
                    }
                }],
                line: 78
            },
            '1': {
                loc: {
                    start: {
                        line: 79,
                        column: 19
                    },
                    end: {
                        line: 79,
                        column: 71
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 79,
                        column: 36
                    },
                    end: {
                        line: 79,
                        column: 53
                    }
                }, {
                    start: {
                        line: 79,
                        column: 56
                    },
                    end: {
                        line: 79,
                        column: 71
                    }
                }],
                line: 79
            },
            '2': {
                loc: {
                    start: {
                        line: 85,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 85,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                }, {
                    start: {
                        line: 85,
                        column: 8
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                }],
                line: 85
            },
            '3': {
                loc: {
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 88,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 88,
                        column: 13
                    }
                }, {
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 88,
                        column: 13
                    }
                }],
                line: 86
            },
            '4': {
                loc: {
                    start: {
                        line: 86,
                        column: 16
                    },
                    end: {
                        line: 86,
                        column: 51
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 86,
                        column: 16
                    },
                    end: {
                        line: 86,
                        column: 35
                    }
                }, {
                    start: {
                        line: 86,
                        column: 39
                    },
                    end: {
                        line: 86,
                        column: 51
                    }
                }],
                line: 86
            },
            '5': {
                loc: {
                    start: {
                        line: 89,
                        column: 19
                    },
                    end: {
                        line: 89,
                        column: 74
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 89,
                        column: 51
                    },
                    end: {
                        line: 89,
                        column: 60
                    }
                }, {
                    start: {
                        line: 89,
                        column: 63
                    },
                    end: {
                        line: 89,
                        column: 74
                    }
                }],
                line: 89
            },
            '6': {
                loc: {
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                }, {
                    start: {
                        line: 107,
                        column: 8
                    },
                    end: {
                        line: 109,
                        column: 9
                    }
                }],
                line: 107
            },
            '7': {
                loc: {
                    start: {
                        line: 107,
                        column: 12
                    },
                    end: {
                        line: 107,
                        column: 75
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 107,
                        column: 12
                    },
                    end: {
                        line: 107,
                        column: 31
                    }
                }, {
                    start: {
                        line: 107,
                        column: 35
                    },
                    end: {
                        line: 107,
                        column: 75
                    }
                }],
                line: 107
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NudgeListController = function () {
    function NudgeListController(mdPanelRef) {
        _classCallCheck(this, NudgeListController);

        cov_2ri02b0mzx.f[0]++;
        cov_2ri02b0mzx.s[0]++;

        this._mdPanelRef = mdPanelRef;
    }

    _createClass(NudgeListController, [{
        key: 'closeDialog',
        value: function closeDialog() {
            cov_2ri02b0mzx.f[1]++;

            var panelRef = (cov_2ri02b0mzx.s[1]++, this._mdPanelRef);

            cov_2ri02b0mzx.s[2]++;
            panelRef.close().then(function () {
                cov_2ri02b0mzx.f[2]++;
                cov_2ri02b0mzx.s[3]++;

                panelRef.destroy();
            });
        }
    }]);

    return NudgeListController;
}();

var PriceCheckController = function () {
    function PriceCheckController($rootScope, NudgesTable, $mdPanel, $mdDialog, $mdToast, SamplePriceChecksTable, $document) {
        _classCallCheck(this, PriceCheckController);

        cov_2ri02b0mzx.f[3]++;
        cov_2ri02b0mzx.s[4]++;

        this.$rootScope = $rootScope;
        cov_2ri02b0mzx.s[5]++;
        this.NudgesTable = NudgesTable;
        cov_2ri02b0mzx.s[6]++;
        this.$mdPanel = $mdPanel;
        cov_2ri02b0mzx.s[7]++;
        this.$mdDialog = $mdDialog;
        cov_2ri02b0mzx.s[8]++;
        this.$mdToast = $mdToast;
        cov_2ri02b0mzx.s[9]++;
        this.displayList = this.displayList.bind(this);
        cov_2ri02b0mzx.s[10]++;
        this.nudge = this.nudge.bind(this);
        cov_2ri02b0mzx.s[11]++;
        this.createNudgeRequest = this.createNudgeRequest.bind(this);
        cov_2ri02b0mzx.s[12]++;
        this.createCheckRequest = this.createCheckRequest.bind(this);
        cov_2ri02b0mzx.s[13]++;
        this.removeCheckRequest = this.removeCheckRequest.bind(this);
        cov_2ri02b0mzx.s[14]++;
        this.SamplePriceChecksTable = SamplePriceChecksTable;
        cov_2ri02b0mzx.s[15]++;
        this.$document = $document;

        cov_2ri02b0mzx.s[16]++;
        this.creating = false;

        cov_2ri02b0mzx.s[17]++;
        this.dialogDetails = {
            title: '',
            content: 'Esta ação vai gerar notificações. Quer prosseguir?',
            ok: 'Sim',
            cancel: 'Não, quero rever.'
        };
    }

    _createClass(PriceCheckController, [{
        key: 'showConfirmationDialog',
        value: function showConfirmationDialog(details, successCallback) {
            cov_2ri02b0mzx.f[4]++;

            var confirm = (cov_2ri02b0mzx.s[18]++, this.$mdDialog.confirm().title(details.title).textContent(details.content).ok(details.ok).cancel(details.cancel));
            cov_2ri02b0mzx.s[19]++;
            this.$mdDialog.show(confirm).then(successCallback, function () {
                cov_2ri02b0mzx.f[5]++;
            });
        }
    }, {
        key: 'createToast',
        value: function createToast() {
            cov_2ri02b0mzx.f[6]++;
            cov_2ri02b0mzx.s[20]++;

            this.$mdToast.show(this.$mdToast.simple().content('Lembrete criado com sucesso.').position('bottom left').parent(angular.element(this.$document.body)).hideDelay(1000));
        }
    }, {
        key: 'createNudgeRequest',
        value: function createNudgeRequest() {
            var _this = this;

            cov_2ri02b0mzx.f[7]++;
            cov_2ri02b0mzx.s[21]++;

            // this.creating = true;
            this.NudgesTable.$create({ sample: this.sample.id, field: this.item.id }).$then(function () {
                cov_2ri02b0mzx.f[8]++;
                cov_2ri02b0mzx.s[22]++;

                // this.creating = false;
                _this.createToast();
            });
        }
    }, {
        key: 'nudge',
        value: function nudge() {
            cov_2ri02b0mzx.f[9]++;
            cov_2ri02b0mzx.s[23]++;

            this.dialogDetails.title = 'Criar Lembrete';
            cov_2ri02b0mzx.s[24]++;
            this.dialogDetails.content = 'Relembrar respetivo departamento que esta validação está em falta?';
            cov_2ri02b0mzx.s[25]++;
            this.dialogDetails.ok = 'Sim';
            cov_2ri02b0mzx.s[26]++;
            this.dialogDetails.cancel = 'Cancelar';
            cov_2ri02b0mzx.s[27]++;
            this.showConfirmationDialog(this.dialogDetails, this.createNudgeRequest);
        }
    }, {
        key: 'getAction',
        value: function getAction() {
            cov_2ri02b0mzx.f[10]++;
            cov_2ri02b0mzx.s[28]++;

            if (this.hasPermission()) {
                cov_2ri02b0mzx.b[0][0]++;
                cov_2ri02b0mzx.s[29]++;

                return this.isValid() ? (cov_2ri02b0mzx.b[1][0]++, this.invalidate()) : (cov_2ri02b0mzx.b[1][1]++, this.validate());
            } else {
                cov_2ri02b0mzx.b[0][1]++;
            }
            cov_2ri02b0mzx.s[30]++;
            return this.nudge();
        }
    }, {
        key: 'getActionName',
        value: function getActionName() {
            cov_2ri02b0mzx.f[11]++;
            cov_2ri02b0mzx.s[31]++;

            if (this.hasPermission()) {
                cov_2ri02b0mzx.b[2][0]++;
                cov_2ri02b0mzx.s[32]++;

                if ((cov_2ri02b0mzx.b[4][0]++, this.isCommercial()) && (cov_2ri02b0mzx.b[4][1]++, this.waiting)) {
                    cov_2ri02b0mzx.b[3][0]++;
                    cov_2ri02b0mzx.s[33]++;

                    return 'A aguardar validações';
                } else {
                    cov_2ri02b0mzx.b[3][1]++;
                }
                cov_2ri02b0mzx.s[34]++;
                return this.item.checks.length === 0 ? (cov_2ri02b0mzx.b[5][0]++, 'validar') : (cov_2ri02b0mzx.b[5][1]++, 'invalidar');
            } else {
                cov_2ri02b0mzx.b[2][1]++;
            }
            cov_2ri02b0mzx.s[35]++;
            return 'lembrar';
        }
    }, {
        key: 'hasPermission',
        value: function hasPermission() {
            cov_2ri02b0mzx.f[12]++;
            cov_2ri02b0mzx.s[36]++;

            return this.item.users.includes(this.$rootScope.me.id);
        }
    }, {
        key: 'isValid',
        value: function isValid() {
            cov_2ri02b0mzx.f[13]++;
            cov_2ri02b0mzx.s[37]++;

            return this.item.checks.length !== 0;
        }
    }, {
        key: 'isCommercial',
        value: function isCommercial() {
            cov_2ri02b0mzx.f[14]++;
            cov_2ri02b0mzx.s[38]++;

            return this.item.id === 'COMMERCIAL';
        }
    }, {
        key: 'isDisabled',
        value: function isDisabled() {
            cov_2ri02b0mzx.f[15]++;
            cov_2ri02b0mzx.s[39]++;

            if ((cov_2ri02b0mzx.b[7][0]++, this.isCommercial()) && (cov_2ri02b0mzx.b[7][1]++, this.$rootScope.me.type === 'COMMERCIAL')) {
                cov_2ri02b0mzx.b[6][0]++;
                cov_2ri02b0mzx.s[40]++;

                return this.waiting;
            } else {
                cov_2ri02b0mzx.b[6][1]++;
            }

            cov_2ri02b0mzx.s[41]++;
            return !this.editable;
        }
    }, {
        key: 'displayList',
        value: function displayList(nudges) {
            cov_2ri02b0mzx.f[16]++;

            var position = (cov_2ri02b0mzx.s[42]++, this.$mdPanel.newPanelPosition().absolute().center());

            var config = (cov_2ri02b0mzx.s[43]++, {
                /** @ngInject */
                controller: NudgeListController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/samplePriceChecks/nudgeList.tmpl.html',
                hasBackdrop: true,
                panelClass: 'sample-price-dialog',
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    nudges: nudges
                }
            });
            cov_2ri02b0mzx.s[44]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'openNudgeList',
        value: function openNudgeList() {
            cov_2ri02b0mzx.f[17]++;

            var params = (cov_2ri02b0mzx.s[45]++, {
                sample: this.sample.id,
                field: this.item.id
            });
            cov_2ri02b0mzx.s[46]++;
            this.NudgesTable.$search(params).$then(this.displayList);
        }
    }, {
        key: 'createCheckRequest',
        value: function createCheckRequest() {
            var _this2 = this;

            cov_2ri02b0mzx.f[18]++;
            cov_2ri02b0mzx.s[47]++;

            this.SamplePriceChecksTable.$create({ sample: this.sample.id, type: this.item.id }).$then(function () {
                cov_2ri02b0mzx.f[19]++;
                cov_2ri02b0mzx.s[48]++;
                return _this2.reload();
            });
        }
    }, {
        key: 'removeCheckRequest',
        value: function removeCheckRequest() {
            var _this3 = this;

            cov_2ri02b0mzx.f[20]++;
            cov_2ri02b0mzx.s[49]++;

            this.SamplePriceChecksTable.$find(this.item.checks[0].id).$then(function (obj) {
                cov_2ri02b0mzx.f[21]++;
                cov_2ri02b0mzx.s[50]++;

                obj.isActive = false;
                cov_2ri02b0mzx.s[51]++;
                obj.$save().$then(function () {
                    cov_2ri02b0mzx.f[22]++;
                    cov_2ri02b0mzx.s[52]++;
                    return _this3.reload();
                });
            });
        }
    }, {
        key: 'invalidate',
        value: function invalidate() {
            cov_2ri02b0mzx.f[23]++;
            cov_2ri02b0mzx.s[53]++;

            this.dialogDetails.title = 'Reabrir Preços';
            cov_2ri02b0mzx.s[54]++;
            this.showConfirmationDialog(this.dialogDetails, this.removeCheckRequest);
        }
    }, {
        key: 'validate',
        value: function validate() {
            cov_2ri02b0mzx.f[24]++;
            cov_2ri02b0mzx.s[55]++;

            this.dialogDetails.title = 'Fechar Preços';
            cov_2ri02b0mzx.s[56]++;
            this.showConfirmationDialog(this.dialogDetails, this.createCheckRequest);
        }
    }]);

    return PriceCheckController;
}();

cov_2ri02b0mzx.s[57]++;


angular.module('app').component('priceCheck', {
    templateUrl: 'app/components/priceCheck/priceCheck.html',
    controller: PriceCheckController,
    bindings: {
        item: '<',
        waiting: '<',
        editable: '<',
        open: '<',
        sample: '<',
        reload: '<'
    }
});