'use strict';

var cov_1codvfnlpa = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/media/media.service.js',
        hash = 'd27055913dc21151ff666002b48b99252e034be9',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/media/media.service.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 39,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 79
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 16
                },
                end: {
                    line: 31,
                    column: 19
                }
            },
            '6': {
                start: {
                    line: 23,
                    column: 20
                },
                end: {
                    line: 23,
                    column: 42
                }
            },
            '7': {
                start: {
                    line: 25,
                    column: 20
                },
                end: {
                    line: 25,
                    column: 84
                }
            },
            '8': {
                start: {
                    line: 26,
                    column: 20
                },
                end: {
                    line: 26,
                    column: 37
                }
            },
            '9': {
                start: {
                    line: 29,
                    column: 47
                },
                end: {
                    line: 29,
                    column: 87
                }
            },
            '10': {
                start: {
                    line: 30,
                    column: 20
                },
                end: {
                    line: 30,
                    column: 90
                }
            },
            '11': {
                start: {
                    line: 34,
                    column: 16
                },
                end: {
                    line: 34,
                    column: 65
                }
            },
            '12': {
                start: {
                    line: 35,
                    column: 16
                },
                end: {
                    line: 35,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 39,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'Service',
                decl: {
                    start: {
                        line: 10,
                        column: 13
                    },
                    end: {
                        line: 10,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 52
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: 'upload',
                decl: {
                    start: {
                        line: 13,
                        column: 17
                    },
                    end: {
                        line: 13,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 30
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 13
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 22,
                        column: 24
                    },
                    end: {
                        line: 22,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 40
                    },
                    end: {
                        line: 24,
                        column: 17
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 24,
                        column: 19
                    },
                    end: {
                        line: 24,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 31
                    },
                    end: {
                        line: 27,
                        column: 17
                    }
                },
                line: 24
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 27,
                        column: 19
                    },
                    end: {
                        line: 27,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 34
                    },
                    end: {
                        line: 31,
                        column: 17
                    }
                },
                line: 27
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                }, {
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 36,
                        column: 13
                    }
                }],
                line: 16
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1codvfnlpa.s[0]++;
(function () {
    'use strict';

    /* eslint-disable angular/no-service-method */

    cov_1codvfnlpa.f[0]++;
    cov_1codvfnlpa.s[1]++;
    angular.module('app').service('MediaService', Service);

    /* @ngInject */
    function Service(Upload, AppSettings, $mdToast) {
        cov_1codvfnlpa.f[1]++;
        cov_1codvfnlpa.s[2]++;

        this.upload = upload;

        function upload(file) {
            cov_1codvfnlpa.f[2]++;
            cov_1codvfnlpa.s[3]++;

            $mdToast.show($mdToast.simple().textContent('A fazer upload ...'));

            cov_1codvfnlpa.s[4]++;
            if (file) {
                cov_1codvfnlpa.b[0][0]++;
                cov_1codvfnlpa.s[5]++;

                return Upload.upload({
                    /* eslint-disable prefer-template */
                    url: AppSettings.apiUrl + '/media/',
                    data: { file: file
                        /* eslint-disable prefer-arrow-callback */
                    } }).then(function (data) {
                    cov_1codvfnlpa.f[3]++;
                    cov_1codvfnlpa.s[6]++;

                    return data.data.file;
                }, function () {
                    cov_1codvfnlpa.f[4]++;
                    cov_1codvfnlpa.s[7]++;

                    $mdToast.updateTextContent('Ocorreu um erro durante o upload.');
                    cov_1codvfnlpa.s[8]++;
                    return undefined;
                }, function (evt) {
                    cov_1codvfnlpa.f[5]++;

                    /* eslint-disable radix */
                    var progressPercentage = (cov_1codvfnlpa.s[9]++, parseInt(100.0 * evt.loaded / evt.total));
                    cov_1codvfnlpa.s[10]++;
                    $mdToast.updateTextContent('Progresso (' + progressPercentage + '%)');
                });
                /* eslint-disable no-else-return */
            } else {
                cov_1codvfnlpa.b[0][1]++;
                cov_1codvfnlpa.s[11]++;

                $mdToast.updateTextContent('Ficheiro Inválido!');
                cov_1codvfnlpa.s[12]++;
                return undefined;
            }
        }
    }
})();