'use strict';

var cov_2124pwh072 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/reportDetails/reportDetails.js',
        hash = '0465f43d252024247aaea5f3bfa19500d1ae4500',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/reportDetails/reportDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 41
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 12
                },
                end: {
                    line: 10,
                    column: 25
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 20
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 21
                },
                end: {
                    line: 67,
                    column: 9
                }
            },
            '6': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 38
                }
            },
            '7': {
                start: {
                    line: 72,
                    column: 8
                },
                end: {
                    line: 72,
                    column: 35
                }
            },
            '8': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 49
                }
            },
            '9': {
                start: {
                    line: 77,
                    column: 19
                },
                end: {
                    line: 77,
                    column: 39
                }
            },
            '10': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 78
                }
            },
            '11': {
                start: {
                    line: 78,
                    column: 62
                },
                end: {
                    line: 78,
                    column: 76
                }
            },
            '12': {
                start: {
                    line: 82,
                    column: 0
                },
                end: {
                    line: 87,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 44
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 19
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 14
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 71,
                        column: 4
                    },
                    end: {
                        line: 71,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 71,
                        column: 14
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                line: 71
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 76,
                        column: 4
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 14
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                line: 76
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 78,
                        column: 56
                    },
                    end: {
                        line: 78,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 62
                    },
                    end: {
                        line: 78,
                        column: 76
                    }
                },
                line: 78
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }, {
                    start: {
                        line: 9,
                        column: 8
                    },
                    end: {
                        line: 11,
                        column: 9
                    }
                }],
                line: 9
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReportController = function () {
    function ReportController(ReportsTable, $stateParams) {
        _classCallCheck(this, ReportController);

        cov_2124pwh072.f[0]++;
        cov_2124pwh072.s[0]++;

        this.ReportsTable = ReportsTable;
        cov_2124pwh072.s[1]++;
        this.$stateParams = $stateParams;
    }

    _createClass(ReportController, [{
        key: 'getFilterBar',
        value: function getFilterBar() {
            cov_2124pwh072.f[1]++;
            cov_2124pwh072.s[2]++;

            if (this.report.type === 'MACHINE_INSTRUCTION_REPORT') {
                cov_2124pwh072.b[0][0]++;
                cov_2124pwh072.s[3]++;

                return false;
            } else {
                cov_2124pwh072.b[0][1]++;
            }
            cov_2124pwh072.s[4]++;
            return true;
        }
    }, {
        key: 'getCols',
        value: function getCols() {
            cov_2124pwh072.f[2]++;

            var cols = (cov_2124pwh072.s[5]++, {
                NOTIFICATION_REPORT: {
                    pk: 'ID',
                    name: 'Nome',
                    type: 'Grupo',
                    notificationCount: 'Nr Notificações'
                },
                WORKSPACE_REPORT: {
                    pk: 'ID',
                    name: 'Nome',
                    type: 'Grupo',
                    customWorkspace: 'Personalizado'
                },
                REQUESTS_REPORT: {
                    pk: 'ID',
                    name: 'Nome',
                    type: 'Grupo',
                    requests: 'Acessos',
                    lastAccess: 'Último Acesso'
                },
                PRICES_REPORT: {
                    pk: 'ID',
                    name: 'Nome',
                    type: 'Grupo',
                    createdPrices: 'Preços Criados',
                    updatedPrices: 'Preços Atualizados',
                    validations: 'Validações',
                    accessoriesPrices: 'P. Acessórios',
                    accessoriesQuantities: 'Qt. Confeção',
                    confectionPrices: 'P. Confeção',
                    externalServices: 'Serviços Ext.',
                    commercial: 'P. Comercial'
                },
                MACHINE_INSTRUCTION_REPORT: {
                    pk: 'ID',
                    orderReference: 'Referência',
                    name: 'Nome',
                    decorations: 'Ornamentos',
                    finishings: 'Acabamentos',
                    instruction: 'Instrução'
                },
                ANNOUNCEMENTS_REPORT: {
                    pk: 'ID',
                    name: 'Nome',
                    type: 'Grupo',
                    template: 'Template',
                    created: 'Criado',
                    showed: 'Mostrado',
                    read: 'Lido',
                    ok: 'Entendido'
                }
            });
            cov_2124pwh072.s[6]++;
            return cols[this.report.type];
        }
    }, {
        key: 'prepare',
        value: function prepare() {
            cov_2124pwh072.f[3]++;
            cov_2124pwh072.s[7]++;

            this.cols = this.getCols();
            cov_2124pwh072.s[8]++;
            this.showFilterBar = this.getFilterBar();
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_2124pwh072.f[4]++;

            var id = (cov_2124pwh072.s[9]++, this.$stateParams.id);
            cov_2124pwh072.s[10]++;
            this.report = this.ReportsTable.$find(id).$then(function () {
                cov_2124pwh072.f[5]++;
                cov_2124pwh072.s[11]++;
                return _this.prepare();
            });
        }
    }]);

    return ReportController;
}();

cov_2124pwh072.s[12]++;


angular.module('app').component('reportDetails', {
    templateUrl: 'app/components/reportDetails/reportDetails.html',
    controller: ReportController
});