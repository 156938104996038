'use strict';

var cov_uw7n753h = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleTileOverview/sampleTileActions.js',
        hash = '3bec3999f0f0cfc7c9f7ea1e2fcb9bb74a5c0f0f',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleTileOverview/sampleTileActions.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 37
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 30
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 33
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 25
                },
                end: {
                    line: 13,
                    column: 48
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 15,
                    column: 49
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 9
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 49
                }
            },
            '8': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 22,
                    column: 9
                }
            },
            '9': {
                start: {
                    line: 21,
                    column: 12
                },
                end: {
                    line: 21,
                    column: 47
                }
            },
            '10': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 57
                }
            },
            '12': {
                start: {
                    line: 26,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 47
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 9
                }
            },
            '15': {
                start: {
                    line: 30,
                    column: 12
                },
                end: {
                    line: 30,
                    column: 75
                }
            },
            '16': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 9
                }
            },
            '17': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 47
                }
            },
            '18': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 9
                }
            },
            '19': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 49
                }
            },
            '21': {
                start: {
                    line: 45,
                    column: 0
                },
                end: {
                    line: 55,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 14
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 12,
                        column: 4
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 24
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 12
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 23
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                line: 40
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 14,
                        column: 8
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 14,
                        column: 8
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                }, {
                    start: {
                        line: 14,
                        column: 8
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                }],
                line: 14
            },
            '1': {
                loc: {
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                }, {
                    start: {
                        line: 17,
                        column: 8
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                }],
                line: 17
            },
            '2': {
                loc: {
                    start: {
                        line: 20,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 20,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                }, {
                    start: {
                        line: 20,
                        column: 8
                    },
                    end: {
                        line: 22,
                        column: 9
                    }
                }],
                line: 20
            },
            '3': {
                loc: {
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                }, {
                    start: {
                        line: 23,
                        column: 8
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                }],
                line: 23
            },
            '4': {
                loc: {
                    start: {
                        line: 26,
                        column: 8
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 26,
                        column: 8
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                }, {
                    start: {
                        line: 26,
                        column: 8
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                }],
                line: 26
            },
            '5': {
                loc: {
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                }, {
                    start: {
                        line: 29,
                        column: 8
                    },
                    end: {
                        line: 31,
                        column: 9
                    }
                }],
                line: 29
            },
            '6': {
                loc: {
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                }, {
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 34,
                        column: 9
                    }
                }],
                line: 32
            },
            '7': {
                loc: {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                }, {
                    start: {
                        line: 35,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                }],
                line: 35
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleTileActionsController = function () {
    function SampleTileActionsController($rootScope) {
        _classCallCheck(this, SampleTileActionsController);

        cov_uw7n753h.f[0]++;
        cov_uw7n753h.s[0]++;

        this.$rootScope = $rootScope;
        cov_uw7n753h.s[1]++;
        this.userActions = [];
    }

    _createClass(SampleTileActionsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_uw7n753h.f[1]++;
            cov_uw7n753h.s[2]++;

            this.selectUserActions();
        }
    }, {
        key: 'selectUserActions',
        value: function selectUserActions() {
            cov_uw7n753h.f[2]++;

            var userType = (cov_uw7n753h.s[3]++, this.$rootScope.me.type);
            cov_uw7n753h.s[4]++;
            if (userType === 'COMMERCIAL') {
                cov_uw7n753h.b[0][0]++;
                cov_uw7n753h.s[5]++;

                this.userActions.push('workHandler');
            } else {
                cov_uw7n753h.b[0][1]++;
            }
            cov_uw7n753h.s[6]++;
            if (userType === 'CAD') {
                cov_uw7n753h.b[1][0]++;
                cov_uw7n753h.s[7]++;

                this.userActions.push('workHandler');
            } else {
                cov_uw7n753h.b[1][1]++;
            }
            cov_uw7n753h.s[8]++;
            if (userType === 'CUTTING') {
                cov_uw7n753h.b[2][0]++;
                cov_uw7n753h.s[9]++;

                this.userActions = ['workHandler'];
            } else {
                cov_uw7n753h.b[2][1]++;
            }
            cov_uw7n753h.s[10]++;
            if (userType === 'ORNAMENTS') {
                cov_uw7n753h.b[3][0]++;
                cov_uw7n753h.s[11]++;

                this.userActions.push('decorationsForeseen');
            } else {
                cov_uw7n753h.b[3][1]++;
            }
            cov_uw7n753h.s[12]++;
            if (userType === 'CONFECTION') {
                cov_uw7n753h.b[4][0]++;
                cov_uw7n753h.s[13]++;

                this.userActions = ['workHandler'];
            } else {
                cov_uw7n753h.b[4][1]++;
            }
            cov_uw7n753h.s[14]++;
            if (userType === 'FINISHINGS') {
                cov_uw7n753h.b[5][0]++;
                cov_uw7n753h.s[15]++;

                this.userActions.push('finishingsForeseenDate', 'workHandler');
            } else {
                cov_uw7n753h.b[5][1]++;
            }
            cov_uw7n753h.s[16]++;
            if (userType === 'CONTROL') {
                cov_uw7n753h.b[6][0]++;
                cov_uw7n753h.s[17]++;

                this.userActions = ['workHandler'];
            } else {
                cov_uw7n753h.b[6][1]++;
            }
            cov_uw7n753h.s[18]++;
            if (userType === 'WAREHOUSE') {
                cov_uw7n753h.b[7][0]++;
                cov_uw7n753h.s[19]++;

                this.userActions.push('meshForeseenDate', 'hasMesh');
            } else {
                cov_uw7n753h.b[7][1]++;
            }
        }
    }, {
        key: 'showAction',
        value: function showAction(action) {
            cov_uw7n753h.f[3]++;
            cov_uw7n753h.s[20]++;

            return this.userActions.includes(action);
        }
    }]);

    return SampleTileActionsController;
}();

cov_uw7n753h.s[21]++;


angular.module('app').component('sampleTileActions', {
    controller: SampleTileActionsController,
    templateUrl: 'app/components/sampleTileOverview/sampleTileActions.html',
    bindings: {
        sample: '<',
        updateField: '<',
        checkWorkItem: '<'
    }
});