'use strict';

var cov_16d0bf3h1p = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/pricesListView/pricesListView.js',
        hash = '7ee67206875df3c4d14537691e8303300d21f7e4',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/pricesListView/pricesListView.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 45
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 22
                },
                end: {
                    line: 11,
                    column: 9
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 64
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 21,
                    column: 25
                },
                end: {
                    line: 21,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 31
                }
            },
            '9': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 31,
                    column: 35
                }
            },
            '10': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 41
                }
            },
            '11': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 61
                }
            },
            '12': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 26
                }
            },
            '13': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 37
                }
            },
            '14': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 33
                }
            },
            '15': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 21
                }
            },
            '16': {
                start: {
                    line: 38,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 37
                }
            },
            '17': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 65
                }
            },
            '18': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 59
                }
            },
            '19': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 29
                }
            },
            '20': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 68
                }
            },
            '21': {
                start: {
                    line: 43,
                    column: 24
                },
                end: {
                    line: 43,
                    column: 50
                }
            },
            '22': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 31
                }
            },
            '23': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 77
                }
            },
            '24': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 50
                }
            },
            '25': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 47
                }
            },
            '26': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 83
                }
            },
            '27': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 44
                }
            },
            '28': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 41
                }
            },
            '29': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 50
                }
            },
            '30': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 51
                }
            },
            '31': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 68
                }
            },
            '32': {
                start: {
                    line: 66,
                    column: 8
                },
                end: {
                    line: 66,
                    column: 58
                }
            },
            '33': {
                start: {
                    line: 67,
                    column: 8
                },
                end: {
                    line: 67,
                    column: 96
                }
            },
            '34': {
                start: {
                    line: 68,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 81
                }
            },
            '35': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 114
                }
            },
            '36': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 78
                }
            },
            '37': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 104
                }
            },
            '38': {
                start: {
                    line: 74,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 112
                }
            },
            '39': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 102
                }
            },
            '40': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 89
                }
            },
            '41': {
                start: {
                    line: 77,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 72
                }
            },
            '42': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 86,
                    column: 9
                }
            },
            '43': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 81,
                    column: 45
                }
            },
            '44': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 49
                }
            },
            '45': {
                start: {
                    line: 83,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 60
                }
            },
            '46': {
                start: {
                    line: 85,
                    column: 12
                },
                end: {
                    line: 85,
                    column: 73
                }
            },
            '47': {
                start: {
                    line: 88,
                    column: 8
                },
                end: {
                    line: 88,
                    column: 31
                }
            },
            '48': {
                start: {
                    line: 92,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 9
                }
            },
            '49': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 36
                }
            },
            '50': {
                start: {
                    line: 96,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 9
                }
            },
            '51': {
                start: {
                    line: 97,
                    column: 12
                },
                end: {
                    line: 97,
                    column: 40
                }
            },
            '52': {
                start: {
                    line: 98,
                    column: 12
                },
                end: {
                    line: 98,
                    column: 48
                }
            },
            '53': {
                start: {
                    line: 100,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 41
                }
            },
            '54': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 31
                }
            },
            '55': {
                start: {
                    line: 104,
                    column: 8
                },
                end: {
                    line: 104,
                    column: 41
                }
            },
            '56': {
                start: {
                    line: 105,
                    column: 8
                },
                end: {
                    line: 105,
                    column: 45
                }
            },
            '57': {
                start: {
                    line: 106,
                    column: 8
                },
                end: {
                    line: 106,
                    column: 31
                }
            },
            '58': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 115,
                    column: 9
                }
            },
            '59': {
                start: {
                    line: 112,
                    column: 12
                },
                end: {
                    line: 112,
                    column: 71
                }
            },
            '60': {
                start: {
                    line: 112,
                    column: 43
                },
                end: {
                    line: 112,
                    column: 69
                }
            },
            '61': {
                start: {
                    line: 114,
                    column: 12
                },
                end: {
                    line: 114,
                    column: 70
                }
            },
            '62': {
                start: {
                    line: 114,
                    column: 43
                },
                end: {
                    line: 114,
                    column: 68
                }
            },
            '63': {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 119,
                    column: 117
                }
            },
            '64': {
                start: {
                    line: 123,
                    column: 25
                },
                end: {
                    line: 123,
                    column: 27
                }
            },
            '65': {
                start: {
                    line: 124,
                    column: 8
                },
                end: {
                    line: 124,
                    column: 115
                }
            },
            '66': {
                start: {
                    line: 124,
                    column: 49
                },
                end: {
                    line: 124,
                    column: 113
                }
            },
            '67': {
                start: {
                    line: 125,
                    column: 8
                },
                end: {
                    line: 125,
                    column: 68
                }
            },
            '68': {
                start: {
                    line: 130,
                    column: 21
                },
                end: {
                    line: 130,
                    column: 108
                }
            },
            '69': {
                start: {
                    line: 131,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 20
                }
            },
            '70': {
                start: {
                    line: 135,
                    column: 8
                },
                end: {
                    line: 135,
                    column: 51
                }
            },
            '71': {
                start: {
                    line: 135,
                    column: 42
                },
                end: {
                    line: 135,
                    column: 49
                }
            },
            '72': {
                start: {
                    line: 136,
                    column: 8
                },
                end: {
                    line: 136,
                    column: 63
                }
            },
            '73': {
                start: {
                    line: 140,
                    column: 25
                },
                end: {
                    line: 140,
                    column: 77
                }
            },
            '74': {
                start: {
                    line: 142,
                    column: 23
                },
                end: {
                    line: 160,
                    column: 9
                }
            },
            '75': {
                start: {
                    line: 161,
                    column: 8
                },
                end: {
                    line: 161,
                    column: 35
                }
            },
            '76': {
                start: {
                    line: 165,
                    column: 8
                },
                end: {
                    line: 165,
                    column: 36
                }
            },
            '77': {
                start: {
                    line: 169,
                    column: 8
                },
                end: {
                    line: 169,
                    column: 37
                }
            },
            '78': {
                start: {
                    line: 174,
                    column: 0
                },
                end: {
                    line: 183,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 21
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 15
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 18
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 20
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 30
                    },
                    end: {
                        line: 23,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 36
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 102
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 30
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 43,
                        column: 18
                    },
                    end: {
                        line: 43,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 24
                    },
                    end: {
                        line: 43,
                        column: 50
                    }
                },
                line: 43
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 46,
                        column: 4
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 46,
                        column: 26
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 46
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 22
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                line: 50
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 59,
                        column: 4
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 14
                    },
                    end: {
                        line: 89,
                        column: 5
                    }
                },
                line: 59
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 91,
                        column: 4
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 33
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                line: 91
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 109,
                        column: 4
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 109,
                        column: 18
                    },
                    end: {
                        line: 116,
                        column: 5
                    }
                },
                line: 109
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 112,
                        column: 33
                    },
                    end: {
                        line: 112,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 112,
                        column: 43
                    },
                    end: {
                        line: 112,
                        column: 69
                    }
                },
                line: 112
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 114,
                        column: 33
                    },
                    end: {
                        line: 114,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 43
                    },
                    end: {
                        line: 114,
                        column: 68
                    }
                },
                line: 114
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 118,
                        column: 4
                    },
                    end: {
                        line: 118,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 118,
                        column: 49
                    },
                    end: {
                        line: 120,
                        column: 5
                    }
                },
                line: 118
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 122,
                        column: 4
                    },
                    end: {
                        line: 122,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 122,
                        column: 31
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                line: 122
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 124,
                        column: 39
                    },
                    end: {
                        line: 124,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 49
                    },
                    end: {
                        line: 124,
                        column: 113
                    }
                },
                line: 124
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 128,
                        column: 4
                    },
                    end: {
                        line: 128,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 128,
                        column: 22
                    },
                    end: {
                        line: 132,
                        column: 5
                    }
                },
                line: 128
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 134,
                        column: 4
                    },
                    end: {
                        line: 134,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 30
                    },
                    end: {
                        line: 137,
                        column: 5
                    }
                },
                line: 134
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 135,
                        column: 34
                    },
                    end: {
                        line: 135,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 135,
                        column: 42
                    },
                    end: {
                        line: 135,
                        column: 49
                    }
                },
                line: 135
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 139,
                        column: 4
                    },
                    end: {
                        line: 139,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 139,
                        column: 35
                    },
                    end: {
                        line: 162,
                        column: 5
                    }
                },
                line: 139
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 164,
                        column: 4
                    },
                    end: {
                        line: 164,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 164,
                        column: 20
                    },
                    end: {
                        line: 166,
                        column: 5
                    }
                },
                line: 164
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 168,
                        column: 4
                    },
                    end: {
                        line: 168,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 168,
                        column: 23
                    },
                    end: {
                        line: 170,
                        column: 5
                    }
                },
                line: 168
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 68,
                        column: 38
                    },
                    end: {
                        line: 68,
                        column: 80
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 68,
                        column: 38
                    },
                    end: {
                        line: 68,
                        column: 74
                    }
                }, {
                    start: {
                        line: 68,
                        column: 78
                    },
                    end: {
                        line: 68,
                        column: 80
                    }
                }],
                line: 68
            },
            '1': {
                loc: {
                    start: {
                        line: 69,
                        column: 54
                    },
                    end: {
                        line: 69,
                        column: 113
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 69,
                        column: 54
                    },
                    end: {
                        line: 69,
                        column: 107
                    }
                }, {
                    start: {
                        line: 69,
                        column: 111
                    },
                    end: {
                        line: 69,
                        column: 113
                    }
                }],
                line: 69
            },
            '2': {
                loc: {
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }, {
                    start: {
                        line: 80,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }],
                line: 80
            },
            '3': {
                loc: {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }, {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }],
                line: 92
            },
            '4': {
                loc: {
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }, {
                    start: {
                        line: 96,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }],
                line: 96
            },
            '5': {
                loc: {
                    start: {
                        line: 110,
                        column: 8
                    },
                    end: {
                        line: 115,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 110,
                        column: 8
                    },
                    end: {
                        line: 115,
                        column: 9
                    }
                }, {
                    start: {
                        line: 110,
                        column: 8
                    },
                    end: {
                        line: 115,
                        column: 9
                    }
                }],
                line: 110
            },
            '6': {
                loc: {
                    start: {
                        line: 130,
                        column: 21
                    },
                    end: {
                        line: 130,
                        column: 108
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 130,
                        column: 41
                    },
                    end: {
                        line: 130,
                        column: 87
                    }
                }, {
                    start: {
                        line: 130,
                        column: 90
                    },
                    end: {
                        line: 130,
                        column: 108
                    }
                }],
                line: 130
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BulkValidationController = function () {
    function BulkValidationController(mdPanelRef) {
        _classCallCheck(this, BulkValidationController);

        cov_16d0bf3h1p.f[0]++;
        cov_16d0bf3h1p.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_16d0bf3h1p.s[1]++;
        this.bulkValidationDate = new Date();
        var names = (cov_16d0bf3h1p.s[2]++, {
            ACCESSORIES_PRICES: 'os preços dos acessórios',
            ACCESSORIES_QUANTITIES: 'as quantidades de acessórios',
            CONFECTION_PRICES: 'os preços de confeção',
            COMMERCIAL: 'o preço comercial'
        });
        cov_16d0bf3h1p.s[3]++;
        this.name = names[this.validation];
    }

    _createClass(BulkValidationController, [{
        key: 'validateSample',
        value: function validateSample() {
            cov_16d0bf3h1p.f[1]++;
            cov_16d0bf3h1p.s[4]++;

            this.validate(this.bulkValidationDate, this.validation);
            cov_16d0bf3h1p.s[5]++;
            this.closeDialog();
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_16d0bf3h1p.f[2]++;

            var panelRef = (cov_16d0bf3h1p.s[6]++, this._mdPanelRef);

            cov_16d0bf3h1p.s[7]++;
            panelRef.close().then(function () {
                cov_16d0bf3h1p.f[3]++;
                cov_16d0bf3h1p.s[8]++;

                panelRef.destroy();
            });
        }
    }]);

    return BulkValidationController;
}();

var PricesListViewController = function () {
    function PricesListViewController($stateParams, $location, $interval, $rootScope, $mdPanel, SamplePriceChecksTable, $q) {
        var _this = this;

        _classCallCheck(this, PricesListViewController);

        cov_16d0bf3h1p.f[4]++;
        cov_16d0bf3h1p.s[9]++;

        this.$location = $location;
        cov_16d0bf3h1p.s[10]++;
        this.$stateParams = $stateParams;
        cov_16d0bf3h1p.s[11]++;
        this.SamplePriceChecksTable = SamplePriceChecksTable;
        cov_16d0bf3h1p.s[12]++;
        this.filters = {};
        cov_16d0bf3h1p.s[13]++;
        this.$rootScope = $rootScope;
        cov_16d0bf3h1p.s[14]++;
        this.$mdPanel = $mdPanel;
        cov_16d0bf3h1p.s[15]++;
        this.$q = $q;
        cov_16d0bf3h1p.s[16]++;
        this.isWorkspaceOpen = false;
        cov_16d0bf3h1p.s[17]++;
        this.closeBottomSheet = this.closeBottomSheet.bind(this);
        cov_16d0bf3h1p.s[18]++;
        this.openWorkspace = this.openWorkspace.bind(this);
        cov_16d0bf3h1p.s[19]++;
        this.default = false;

        cov_16d0bf3h1p.s[20]++;
        $interval(function () {
            cov_16d0bf3h1p.f[5]++;
            cov_16d0bf3h1p.s[21]++;
            return _this._autoRefreshSamples();
        }, 30 * 60 * 1000);
    }

    _createClass(PricesListViewController, [{
        key: '_autoRefreshSamples',
        value: function _autoRefreshSamples() {
            cov_16d0bf3h1p.f[6]++;
            cov_16d0bf3h1p.s[22]++;

            this._refreshSamples();
        }
    }, {
        key: '_refreshSamples',
        value: function _refreshSamples() {
            cov_16d0bf3h1p.f[7]++;
            cov_16d0bf3h1p.s[23]++;

            /* eslint-disable camelcase */
            this.filters.page_size = this.$rootScope.me.workspace.samplesPerPage;
            cov_16d0bf3h1p.s[24]++;
            this.filters.before_price_feature = false;
            cov_16d0bf3h1p.s[25]++;
            this.filters.is_price_reference = true;
            cov_16d0bf3h1p.s[26]++;
            this.filters.current_state__sample_state_type__in = '1,2,3,4,5,6,7,8,9,11';
            cov_16d0bf3h1p.s[27]++;
            this.samples.$refresh(this.filters);
        }
    }, {
        key: '$onInit',
        value: function $onInit() {
            cov_16d0bf3h1p.f[8]++;
            cov_16d0bf3h1p.s[28]++;

            this.filters = this.$stateParams;

            // loading filter state from location
            cov_16d0bf3h1p.s[29]++;
            this.refSearch = this.$stateParams.search;
            cov_16d0bf3h1p.s[30]++;
            this.brandFilter = this.$stateParams.brand;
            cov_16d0bf3h1p.s[31]++;
            this.commercialFilter = this.$stateParams.brand__commercial;
            cov_16d0bf3h1p.s[32]++;
            this.hasPrintFilter = this.$stateParams.has_print;
            cov_16d0bf3h1p.s[33]++;
            this.accessoriesCommercialFilter = this.$stateParams.brand__accessories_commercial_user;
            cov_16d0bf3h1p.s[34]++;
            this.severePriceDelayFilter = (cov_16d0bf3h1p.b[0][0]++, this.$stateParams.severe_price_delay) || (cov_16d0bf3h1p.b[0][1]++, '');
            cov_16d0bf3h1p.s[35]++;
            this.accessoriesPricesValidationDelayFilter = (cov_16d0bf3h1p.b[1][0]++, this.$stateParams.accessories_prices_validation_delay) || (cov_16d0bf3h1p.b[1][1]++, '');
            cov_16d0bf3h1p.s[36]++;
            this.sampleState = this.$stateParams.current_state__sample_state_type;

            // validations
            cov_16d0bf3h1p.s[37]++;
            this.hasAccessoriesPricesValidationFilter = this.$stateParams.has_accessories_prices_validation;
            cov_16d0bf3h1p.s[38]++;
            this.hasAccessoriesQuantitiesValidationFilter = this.$stateParams.has_accessories_quantities_validation;
            cov_16d0bf3h1p.s[39]++;
            this.hasConfectionPricesValidationFilter = this.$stateParams.has_confection_prices_validation;
            cov_16d0bf3h1p.s[40]++;
            this.hasCommercialValidationFilter = this.$stateParams.has_commercial_validation;
            cov_16d0bf3h1p.s[41]++;
            this.validationCountFilter = this.$stateParams.validation_count;

            /* eslint-disable angular/definedundefined */
            cov_16d0bf3h1p.s[42]++;
            if (this.$stateParams.is_price_closed === undefined) {
                cov_16d0bf3h1p.b[2][0]++;
                cov_16d0bf3h1p.s[43]++;

                this.isPriceClosedFilter = false;
                cov_16d0bf3h1p.s[44]++;
                this.filters.is_price_closed = false;
                cov_16d0bf3h1p.s[45]++;
                this.$location.search('is_price_closed', false);
            } else {
                cov_16d0bf3h1p.b[2][1]++;
                cov_16d0bf3h1p.s[46]++;

                this.isPriceClosedFilter = this.$stateParams.is_price_closed;
            }

            cov_16d0bf3h1p.s[47]++;
            this._refreshSamples();
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_16d0bf3h1p.f[9]++;
            cov_16d0bf3h1p.s[48]++;

            if (angular.isArray(value)) {
                cov_16d0bf3h1p.b[3][0]++;
                cov_16d0bf3h1p.s[49]++;

                value = value.join(',');
            } else {
                cov_16d0bf3h1p.b[3][1]++;
            }

            cov_16d0bf3h1p.s[50]++;
            if (value === null) {
                cov_16d0bf3h1p.b[4][0]++;
                cov_16d0bf3h1p.s[51]++;

                delete this.filters[filter];
                cov_16d0bf3h1p.s[52]++;
                delete this.samples.$params[filter];
            } else {
                cov_16d0bf3h1p.b[4][1]++;
                cov_16d0bf3h1p.s[53]++;

                this.filters[filter] = value;
            }

            cov_16d0bf3h1p.s[54]++;
            this.samples.$page = 1;
            cov_16d0bf3h1p.s[55]++;
            this.$location.search('page', 1);
            cov_16d0bf3h1p.s[56]++;
            this.$location.search(filter, value);
            cov_16d0bf3h1p.s[57]++;
            this._refreshSamples();
        }
    }, {
        key: 'allSelector',
        value: function allSelector() {
            cov_16d0bf3h1p.f[10]++;
            cov_16d0bf3h1p.s[58]++;

            if (this.samples.length === this.samples.$selected.length) {
                cov_16d0bf3h1p.b[5][0]++;
                cov_16d0bf3h1p.s[59]++;

                /* eslint-disable no-return-assign */
                this.samples.forEach(function (sample) {
                    cov_16d0bf3h1p.f[11]++;
                    cov_16d0bf3h1p.s[60]++;
                    return sample.$isSelected = false;
                });
            } else {
                cov_16d0bf3h1p.b[5][1]++;
                cov_16d0bf3h1p.s[61]++;

                this.samples.forEach(function (sample) {
                    cov_16d0bf3h1p.f[12]++;
                    cov_16d0bf3h1p.s[62]++;
                    return sample.$isSelected = true;
                });
            }
        }
    }, {
        key: 'createCheckRequest',
        value: function createCheckRequest(sample, date, validation) {
            cov_16d0bf3h1p.f[13]++;
            cov_16d0bf3h1p.s[63]++;

            return this.SamplePriceChecksTable.$create({ sample: sample.id, type: validation, checked_at: date }).$promise;
        }
    }, {
        key: 'validate',
        value: function validate(date, validation) {
            var _this2 = this;

            cov_16d0bf3h1p.f[14]++;

            var requests = (cov_16d0bf3h1p.s[64]++, []);
            cov_16d0bf3h1p.s[65]++;
            this.samples.$selected.forEach(function (sample) {
                cov_16d0bf3h1p.f[15]++;
                cov_16d0bf3h1p.s[66]++;
                return requests.push(_this2.createCheckRequest(sample, date, validation));
            });
            cov_16d0bf3h1p.s[67]++;
            this.$q.all(requests).then(this._refreshSamples.bind(this));
        }
    }, {
        key: 'getSelectedText',
        value: function getSelectedText() {
            cov_16d0bf3h1p.f[16]++;

            /* eslint-disable prefer-template */
            var size = (cov_16d0bf3h1p.s[68]++, this.sampleType ? (cov_16d0bf3h1p.b[6][0]++, this.sampleType.length + ' tipos selecionados') : (cov_16d0bf3h1p.b[6][1]++, 'Tipo de amostras'));
            cov_16d0bf3h1p.s[69]++;
            return size;
        }
    }, {
        key: 'selectAllSampleTypes',
        value: function selectAllSampleTypes(all) {
            cov_16d0bf3h1p.f[17]++;
            cov_16d0bf3h1p.s[70]++;

            this.sampleType = all.map(function (type) {
                cov_16d0bf3h1p.f[18]++;
                cov_16d0bf3h1p.s[71]++;
                return type.id;
            });
            cov_16d0bf3h1p.s[72]++;
            this.changeFilters('sample_type__in', this.sampleType);
        }
    }, {
        key: 'showBulkValidation',
        value: function showBulkValidation(validation) {
            cov_16d0bf3h1p.f[19]++;

            var position = (cov_16d0bf3h1p.s[73]++, this.$mdPanel.newPanelPosition().absolute().center());

            var config = (cov_16d0bf3h1p.s[74]++, {
                /** @ngInject */
                controller: BulkValidationController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/pricesListView/bulkValidationDialog.html',
                hasBackdrop: true,
                panelClass: 'sample-price-dialog',
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    count: this.samples.$selected.length,
                    validate: this.validate.bind(this),
                    validation: validation
                }
            });
            cov_16d0bf3h1p.s[75]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'openWorkspace',
        value: function openWorkspace() {
            cov_16d0bf3h1p.f[20]++;
            cov_16d0bf3h1p.s[76]++;

            this.isWorkspaceOpen = true;
        }
    }, {
        key: 'closeBottomSheet',
        value: function closeBottomSheet() {
            cov_16d0bf3h1p.f[21]++;
            cov_16d0bf3h1p.s[77]++;

            this.isWorkspaceOpen = false;
        }
    }]);

    return PricesListViewController;
}();

/* eslint-disable angular/controller-name */


cov_16d0bf3h1p.s[78]++;
angular.module('app').component('pricesListView', {
    templateUrl: 'app/components/pricesListView/pricesListView.html',
    controller: PricesListViewController,
    bindings: {
        samples: '<',
        archivedState: '<'
    }
});