'use strict';

var cov_vp2ahwp8j = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentLogDetails/sampleDevelopmentLogDetails.js',
        hash = '5c6cd347fa0f67d6aad08b3ed99c81ec8a51fb85',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentLogDetails/sampleDevelopmentLogDetails.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 105
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 53
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 57
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 0
                },
                end: {
                    line: 22,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 18
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                line: 8
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 4,
                        column: 42
                    },
                    end: {
                        line: 4,
                        column: 104
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 4,
                        column: 87
                    },
                    end: {
                        line: 4,
                        column: 95
                    }
                }, {
                    start: {
                        line: 4,
                        column: 98
                    },
                    end: {
                        line: 4,
                        column: 104
                    }
                }],
                line: 4
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleDevelopmentLogDetailsController = function () {
    function SampleDevelopmentLogDetailsController() {
        _classCallCheck(this, SampleDevelopmentLogDetailsController);
    }

    _createClass(SampleDevelopmentLogDetailsController, [{
        key: 'toogleState',
        value: function toogleState() {
            cov_vp2ahwp8j.f[0]++;
            cov_vp2ahwp8j.s[0]++;

            this.sampleDevelopmentLog.state = this.sampleDevelopmentLog.state === 'OPEN' ? (cov_vp2ahwp8j.b[0][0]++, 'CLOSED') : (cov_vp2ahwp8j.b[0][1]++, 'OPEN');
            cov_vp2ahwp8j.s[1]++;
            this.sampleDevelopmentLog.$save(['state']);
        }
    }, {
        key: 'openRequest',
        value: function openRequest() {
            cov_vp2ahwp8j.f[1]++;
            cov_vp2ahwp8j.s[2]++;

            this.sampleDevelopmentLog.openRequest = true;
            cov_vp2ahwp8j.s[3]++;
            this.sampleDevelopmentLog.$save(['openRequest']);
        }
    }]);

    return SampleDevelopmentLogDetailsController;
}();

cov_vp2ahwp8j.s[4]++;


angular.module('app').component('sampleDevelopmentLogDetails', {
    templateUrl: 'app/components/sampleDevelopmentLogDetails/sampleDevelopmentLogDetails.html',
    controller: SampleDevelopmentLogDetailsController,
    bindings: {
        sampleDevelopmentLog: '<'
    }
});