'use strict';

var cov_1rjg4cxhhj = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/workspaces/workspaces.js',
        hash = '896b93eaa181c7127e6cac9e610329bca1abfbcb',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/workspaces/workspaces.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 39
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 62
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 45
                }
            },
            '4': {
                start: {
                    line: 50,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 45
                }
            },
            '5': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 37
                }
            },
            '6': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 41
                }
            },
            '7': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 9
                }
            },
            '8': {
                start: {
                    line: 57,
                    column: 12
                },
                end: {
                    line: 57,
                    column: 42
                }
            },
            '9': {
                start: {
                    line: 59,
                    column: 12
                },
                end: {
                    line: 59,
                    column: 41
                }
            },
            '10': {
                start: {
                    line: 64,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 55
                }
            },
            '11': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 66
                }
            },
            '12': {
                start: {
                    line: 69,
                    column: 8
                },
                end: {
                    line: 73,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 70,
                    column: 12
                },
                end: {
                    line: 72,
                    column: 34
                }
            },
            '14': {
                start: {
                    line: 77,
                    column: 21
                },
                end: {
                    line: 77,
                    column: 44
                }
            },
            '15': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 95,
                    column: 0
                },
                end: {
                    line: 104,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 42,
                        column: 4
                    },
                    end: {
                        line: 42,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 22
                    },
                    end: {
                        line: 44,
                        column: 5
                    }
                },
                line: 42
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 49,
                        column: 4
                    },
                    end: {
                        line: 49,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 49,
                        column: 44
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                line: 49
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 55,
                        column: 4
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 24
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                line: 55
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 63,
                        column: 4
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 11
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 63
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 15
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                line: 68
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 69,
                        column: 42
                    },
                    end: {
                        line: 69,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 48
                    },
                    end: {
                        line: 73,
                        column: 9
                    }
                },
                line: 69
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 76,
                        column: 4
                    },
                    end: {
                        line: 76,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 76,
                        column: 29
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                line: 76
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 89,
                        column: 16
                    },
                    end: {
                        line: 89,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 89,
                        column: 22
                    },
                    end: {
                        line: 90,
                        column: 9
                    }
                },
                line: 89
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 90,
                        column: 17
                    },
                    end: {
                        line: 90,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 90,
                        column: 23
                    },
                    end: {
                        line: 91,
                        column: 9
                    }
                },
                line: 90
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                }, {
                    start: {
                        line: 56,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                }],
                line: 56
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var WorkspacesDialogController = function () {
    function WorkspacesDialogController() {
        _classCallCheck(this, WorkspacesDialogController);

        cov_1rjg4cxhhj.f[0]++;
        cov_1rjg4cxhhj.s[0]++;

        this.title = 'Vista de Preços';
        cov_1rjg4cxhhj.s[1]++;
        this.disabled = ['isPriceReference', 'isPriceClosed'];
        cov_1rjg4cxhhj.s[2]++;
        this.filters = {
            names: ["Marca", "Comercial", "Com. Acessórios", "Tipo Amostra", "Estado Amostra", "Número Validações", "Preços Acessórios", "Quantidades Acessórios", "Preços Confeção", "Preço Comercial", "Estado Preço", "Atraso VPC", "Atraso VPA", "Print"],
            models: ['brand', 'brandCommercial', 'accessoriesCommercial', 'sampleTypeIn', 'currentStateSampleStateType', 'validationCount', 'hasAccessoriesPricesValidation', 'hasAccessoriesQuantitiesValidation', 'hasConfectionPricesValidation', 'hasCommercialValidation', 'isPriceClosed', 'severePriceDelay', 'accessoriesPricesValidationDelay', 'hasPrint']
        };
    }

    _createClass(WorkspacesDialogController, [{
        key: 'isDisabled',
        value: function isDisabled(model) {
            cov_1rjg4cxhhj.f[1]++;
            cov_1rjg4cxhhj.s[3]++;

            return this.disabled.includes(model);
        }
    }]);

    return WorkspacesDialogController;
}();

var WorkspacesController = function () {
    function WorkspacesController($mdBottomSheet, $rootScope) {
        _classCallCheck(this, WorkspacesController);

        cov_1rjg4cxhhj.f[2]++;
        cov_1rjg4cxhhj.s[4]++;

        this.$mdBottomSheet = $mdBottomSheet;
        cov_1rjg4cxhhj.s[5]++;
        this.$rootScope = $rootScope;
        cov_1rjg4cxhhj.s[6]++;
        this.save = this.save.bind(this);
    }

    _createClass(WorkspacesController, [{
        key: '$onChanges',
        value: function $onChanges(changes) {
            cov_1rjg4cxhhj.f[3]++;
            cov_1rjg4cxhhj.s[7]++;

            if (changes.opened.currentValue) {
                cov_1rjg4cxhhj.b[0][0]++;
                cov_1rjg4cxhhj.s[8]++;

                this.displayWorkspaceEditor();
            } else {
                cov_1rjg4cxhhj.b[0][1]++;
                cov_1rjg4cxhhj.s[9]++;

                this.$mdBottomSheet.cancel();
            }
        }
    }, {
        key: 'save',
        value: function save() {
            cov_1rjg4cxhhj.f[4]++;
            cov_1rjg4cxhhj.s[10]++;

            this.$rootScope.me.workspace.version = '3.0.0';
            cov_1rjg4cxhhj.s[11]++;
            this.$rootScope.me.$save(['workspace']).$then(this.close);
        }
    }, {
        key: 'toCamel',
        value: function toCamel(s) {
            cov_1rjg4cxhhj.f[5]++;
            cov_1rjg4cxhhj.s[12]++;

            return s.replace(/([-_][a-z])/ig, function ($1) {
                cov_1rjg4cxhhj.f[6]++;
                cov_1rjg4cxhhj.s[13]++;

                return $1.toUpperCase().replace('-', '').replace('_', '');
            });
        }
    }, {
        key: 'displayWorkspaceEditor',
        value: function displayWorkspaceEditor() {
            cov_1rjg4cxhhj.f[7]++;

            var view = (cov_1rjg4cxhhj.s[14]++, this.toCamel(this.view));
            cov_1rjg4cxhhj.s[15]++;
            this.$mdBottomSheet.show({
                templateUrl: 'app/components/workspaces/workspaces.html',
                controller: WorkspacesDialogController,
                controllerAs: '$ctrl',
                clickOutsideToClose: false,
                escapeToClose: false,
                bindToController: true,
                locals: {
                    save: this.save,
                    view: view
                }
            }).then(function () {
                cov_1rjg4cxhhj.f[8]++;
            }).catch(function () {
                cov_1rjg4cxhhj.f[9]++;
            });
        }
    }]);

    return WorkspacesController;
}();

cov_1rjg4cxhhj.s[16]++;


angular.module('app').component('workspaces', {
    controller: WorkspacesController,
    bindings: {
        opened: '<',
        view: '@',
        close: '='
    }
});