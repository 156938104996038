"use strict";

var cov_2mpsjr6ini = function () {
    var path = "/home/lobo/repos/mypedrosa-app/src/app/shared/js/json2.js",
        hash = "c4de056f13fe1c3e253b7fea658648c52adbb3d0",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/mypedrosa-app/src/app/shared/js/json2.js",
        statementMap: {
            "0": {
                start: {
                    line: 171,
                    column: 0
                },
                end: {
                    line: 173,
                    column: 1
                }
            },
            "1": {
                start: {
                    line: 172,
                    column: 4
                },
                end: {
                    line: 172,
                    column: 14
                }
            },
            "2": {
                start: {
                    line: 175,
                    column: 0
                },
                end: {
                    line: 508,
                    column: 5
                }
            },
            "3": {
                start: {
                    line: 179,
                    column: 17
                },
                end: {
                    line: 179,
                    column: 32
                }
            },
            "4": {
                start: {
                    line: 180,
                    column: 17
                },
                end: {
                    line: 180,
                    column: 54
                }
            },
            "5": {
                start: {
                    line: 181,
                    column: 19
                },
                end: {
                    line: 181,
                    column: 85
                }
            },
            "6": {
                start: {
                    line: 182,
                    column: 18
                },
                end: {
                    line: 182,
                    column: 40
                }
            },
            "7": {
                start: {
                    line: 183,
                    column: 23
                },
                end: {
                    line: 183,
                    column: 152
                }
            },
            "8": {
                start: {
                    line: 184,
                    column: 23
                },
                end: {
                    line: 184,
                    column: 129
                }
            },
            "9": {
                start: {
                    line: 188,
                    column: 8
                },
                end: {
                    line: 190,
                    column: 16
                }
            },
            "10": {
                start: {
                    line: 194,
                    column: 8
                },
                end: {
                    line: 194,
                    column: 30
                }
            },
            "11": {
                start: {
                    line: 197,
                    column: 4
                },
                end: {
                    line: 212,
                    column: 5
                }
            },
            "12": {
                start: {
                    line: 198,
                    column: 8
                },
                end: {
                    line: 207,
                    column: 10
                }
            },
            "13": {
                start: {
                    line: 199,
                    column: 12
                },
                end: {
                    line: 206,
                    column: 23
                }
            },
            "14": {
                start: {
                    line: 209,
                    column: 8
                },
                end: {
                    line: 209,
                    column: 46
                }
            },
            "15": {
                start: {
                    line: 210,
                    column: 8
                },
                end: {
                    line: 210,
                    column: 45
                }
            },
            "16": {
                start: {
                    line: 211,
                    column: 8
                },
                end: {
                    line: 211,
                    column: 45
                }
            },
            "17": {
                start: {
                    line: 225,
                    column: 8
                },
                end: {
                    line: 225,
                    column: 35
                }
            },
            "18": {
                start: {
                    line: 226,
                    column: 8
                },
                end: {
                    line: 233,
                    column: 35
                }
            },
            "19": {
                start: {
                    line: 228,
                    column: 24
                },
                end: {
                    line: 228,
                    column: 31
                }
            },
            "20": {
                start: {
                    line: 229,
                    column: 16
                },
                end: {
                    line: 231,
                    column: 80
                }
            },
            "21": {
                start: {
                    line: 243,
                    column: 19
                },
                end: {
                    line: 243,
                    column: 22
                }
            },
            "22": {
                start: {
                    line: 245,
                    column: 20
                },
                end: {
                    line: 245,
                    column: 31
                }
            },
            "23": {
                start: {
                    line: 249,
                    column: 8
                },
                end: {
                    line: 252,
                    column: 9
                }
            },
            "24": {
                start: {
                    line: 251,
                    column: 12
                },
                end: {
                    line: 251,
                    column: 38
                }
            },
            "25": {
                start: {
                    line: 257,
                    column: 8
                },
                end: {
                    line: 259,
                    column: 9
                }
            },
            "26": {
                start: {
                    line: 258,
                    column: 12
                },
                end: {
                    line: 258,
                    column: 49
                }
            },
            "27": {
                start: {
                    line: 263,
                    column: 8
                },
                end: {
                    line: 367,
                    column: 9
                }
            },
            "28": {
                start: {
                    line: 265,
                    column: 12
                },
                end: {
                    line: 265,
                    column: 32
                }
            },
            "29": {
                start: {
                    line: 271,
                    column: 12
                },
                end: {
                    line: 273,
                    column: 25
                }
            },
            "30": {
                start: {
                    line: 282,
                    column: 12
                },
                end: {
                    line: 282,
                    column: 33
                }
            },
            "31": {
                start: {
                    line: 292,
                    column: 12
                },
                end: {
                    line: 294,
                    column: 13
                }
            },
            "32": {
                start: {
                    line: 293,
                    column: 16
                },
                end: {
                    line: 293,
                    column: 30
                }
            },
            "33": {
                start: {
                    line: 298,
                    column: 12
                },
                end: {
                    line: 298,
                    column: 26
                }
            },
            "34": {
                start: {
                    line: 299,
                    column: 12
                },
                end: {
                    line: 299,
                    column: 25
                }
            },
            "35": {
                start: {
                    line: 303,
                    column: 12
                },
                end: {
                    line: 321,
                    column: 13
                }
            },
            "36": {
                start: {
                    line: 307,
                    column: 16
                },
                end: {
                    line: 307,
                    column: 38
                }
            },
            "37": {
                start: {
                    line: 308,
                    column: 16
                },
                end: {
                    line: 310,
                    column: 17
                }
            },
            "38": {
                start: {
                    line: 309,
                    column: 20
                },
                end: {
                    line: 309,
                    column: 57
                }
            },
            "39": {
                start: {
                    line: 314,
                    column: 16
                },
                end: {
                    line: 318,
                    column: 56
                }
            },
            "40": {
                start: {
                    line: 319,
                    column: 16
                },
                end: {
                    line: 319,
                    column: 27
                }
            },
            "41": {
                start: {
                    line: 320,
                    column: 16
                },
                end: {
                    line: 320,
                    column: 25
                }
            },
            "42": {
                start: {
                    line: 325,
                    column: 12
                },
                end: {
                    line: 355,
                    column: 13
                }
            },
            "43": {
                start: {
                    line: 326,
                    column: 16
                },
                end: {
                    line: 326,
                    column: 36
                }
            },
            "44": {
                start: {
                    line: 327,
                    column: 16
                },
                end: {
                    line: 339,
                    column: 17
                }
            },
            "45": {
                start: {
                    line: 328,
                    column: 20
                },
                end: {
                    line: 338,
                    column: 21
                }
            },
            "46": {
                start: {
                    line: 329,
                    column: 24
                },
                end: {
                    line: 329,
                    column: 35
                }
            },
            "47": {
                start: {
                    line: 330,
                    column: 24
                },
                end: {
                    line: 330,
                    column: 42
                }
            },
            "48": {
                start: {
                    line: 331,
                    column: 24
                },
                end: {
                    line: 337,
                    column: 25
                }
            },
            "49": {
                start: {
                    line: 332,
                    column: 28
                },
                end: {
                    line: 336,
                    column: 35
                }
            },
            "50": {
                start: {
                    line: 343,
                    column: 16
                },
                end: {
                    line: 354,
                    column: 17
                }
            },
            "51": {
                start: {
                    line: 344,
                    column: 20
                },
                end: {
                    line: 353,
                    column: 21
                }
            },
            "52": {
                start: {
                    line: 345,
                    column: 24
                },
                end: {
                    line: 345,
                    column: 42
                }
            },
            "53": {
                start: {
                    line: 346,
                    column: 24
                },
                end: {
                    line: 352,
                    column: 25
                }
            },
            "54": {
                start: {
                    line: 347,
                    column: 28
                },
                end: {
                    line: 351,
                    column: 35
                }
            },
            "55": {
                start: {
                    line: 360,
                    column: 12
                },
                end: {
                    line: 364,
                    column: 52
                }
            },
            "56": {
                start: {
                    line: 365,
                    column: 12
                },
                end: {
                    line: 365,
                    column: 23
                }
            },
            "57": {
                start: {
                    line: 366,
                    column: 12
                },
                end: {
                    line: 366,
                    column: 21
                }
            },
            "58": {
                start: {
                    line: 372,
                    column: 4
                },
                end: {
                    line: 421,
                    column: 5
                }
            },
            "59": {
                start: {
                    line: 373,
                    column: 8
                },
                end: {
                    line: 381,
                    column: 10
                }
            },
            "60": {
                start: {
                    line: 382,
                    column: 8
                },
                end: {
                    line: 420,
                    column: 10
                }
            },
            "61": {
                start: {
                    line: 390,
                    column: 12
                },
                end: {
                    line: 390,
                    column: 21
                }
            },
            "62": {
                start: {
                    line: 391,
                    column: 12
                },
                end: {
                    line: 391,
                    column: 24
                }
            },
            "63": {
                start: {
                    line: 396,
                    column: 12
                },
                end: {
                    line: 404,
                    column: 13
                }
            },
            "64": {
                start: {
                    line: 397,
                    column: 16
                },
                end: {
                    line: 399,
                    column: 17
                }
            },
            "65": {
                start: {
                    line: 398,
                    column: 20
                },
                end: {
                    line: 398,
                    column: 34
                }
            },
            "66": {
                start: {
                    line: 402,
                    column: 19
                },
                end: {
                    line: 404,
                    column: 13
                }
            },
            "67": {
                start: {
                    line: 403,
                    column: 16
                },
                end: {
                    line: 403,
                    column: 31
                }
            },
            "68": {
                start: {
                    line: 409,
                    column: 12
                },
                end: {
                    line: 409,
                    column: 27
                }
            },
            "69": {
                start: {
                    line: 410,
                    column: 12
                },
                end: {
                    line: 414,
                    column: 13
                }
            },
            "70": {
                start: {
                    line: 413,
                    column: 16
                },
                end: {
                    line: 413,
                    column: 50
                }
            },
            "71": {
                start: {
                    line: 419,
                    column: 12
                },
                end: {
                    line: 419,
                    column: 40
                }
            },
            "72": {
                start: {
                    line: 425,
                    column: 4
                },
                end: {
                    line: 507,
                    column: 5
                }
            },
            "73": {
                start: {
                    line: 426,
                    column: 8
                },
                end: {
                    line: 506,
                    column: 10
                }
            },
            "74": {
                start: {
                    line: 438,
                    column: 28
                },
                end: {
                    line: 438,
                    column: 39
                }
            },
            "75": {
                start: {
                    line: 439,
                    column: 16
                },
                end: {
                    line: 450,
                    column: 17
                }
            },
            "76": {
                start: {
                    line: 440,
                    column: 20
                },
                end: {
                    line: 449,
                    column: 21
                }
            },
            "77": {
                start: {
                    line: 441,
                    column: 24
                },
                end: {
                    line: 448,
                    column: 25
                }
            },
            "78": {
                start: {
                    line: 442,
                    column: 28
                },
                end: {
                    line: 442,
                    column: 47
                }
            },
            "79": {
                start: {
                    line: 443,
                    column: 28
                },
                end: {
                    line: 447,
                    column: 29
                }
            },
            "80": {
                start: {
                    line: 444,
                    column: 32
                },
                end: {
                    line: 444,
                    column: 45
                }
            },
            "81": {
                start: {
                    line: 446,
                    column: 32
                },
                end: {
                    line: 446,
                    column: 48
                }
            },
            "82": {
                start: {
                    line: 451,
                    column: 16
                },
                end: {
                    line: 451,
                    column: 56
                }
            },
            "83": {
                start: {
                    line: 458,
                    column: 12
                },
                end: {
                    line: 458,
                    column: 32
                }
            },
            "84": {
                start: {
                    line: 459,
                    column: 12
                },
                end: {
                    line: 459,
                    column: 39
                }
            },
            "85": {
                start: {
                    line: 460,
                    column: 12
                },
                end: {
                    line: 465,
                    column: 13
                }
            },
            "86": {
                start: {
                    line: 461,
                    column: 16
                },
                end: {
                    line: 464,
                    column: 19
                }
            },
            "87": {
                start: {
                    line: 462,
                    column: 20
                },
                end: {
                    line: 463,
                    column: 78
                }
            },
            "88": {
                start: {
                    line: 480,
                    column: 12
                },
                end: {
                    line: 501,
                    column: 13
                }
            },
            "89": {
                start: {
                    line: 493,
                    column: 16
                },
                end: {
                    line: 493,
                    column: 43
                }
            },
            "90": {
                start: {
                    line: 498,
                    column: 16
                },
                end: {
                    line: 500,
                    column: 24
                }
            },
            "91": {
                start: {
                    line: 505,
                    column: 12
                },
                end: {
                    line: 505,
                    column: 48
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 175,
                        column: 1
                    },
                    end: {
                        line: 175,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 175,
                        column: 13
                    },
                    end: {
                        line: 508,
                        column: 1
                    }
                },
                line: 175
            },
            "1": {
                name: "f",
                decl: {
                    start: {
                        line: 186,
                        column: 13
                    },
                    end: {
                        line: 186,
                        column: 14
                    }
                },
                loc: {
                    start: {
                        line: 186,
                        column: 18
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                line: 186
            },
            "2": {
                name: "this_value",
                decl: {
                    start: {
                        line: 193,
                        column: 13
                    },
                    end: {
                        line: 193,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 193,
                        column: 26
                    },
                    end: {
                        line: 195,
                        column: 5
                    }
                },
                line: 193
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 198,
                        column: 32
                    },
                    end: {
                        line: 198,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 198,
                        column: 44
                    },
                    end: {
                        line: 207,
                        column: 9
                    }
                },
                line: 198
            },
            "4": {
                name: "quote",
                decl: {
                    start: {
                        line: 219,
                        column: 13
                    },
                    end: {
                        line: 219,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 219,
                        column: 27
                    },
                    end: {
                        line: 234,
                        column: 5
                    }
                },
                line: 219
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 227,
                        column: 50
                    },
                    end: {
                        line: 227,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 227,
                        column: 63
                    },
                    end: {
                        line: 232,
                        column: 13
                    }
                },
                line: 227
            },
            "6": {
                name: "str",
                decl: {
                    start: {
                        line: 236,
                        column: 13
                    },
                    end: {
                        line: 236,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 236,
                        column: 30
                    },
                    end: {
                        line: 368,
                        column: 5
                    }
                },
                line: 236
            },
            "7": {
                name: "(anonymous_7)",
                decl: {
                    start: {
                        line: 382,
                        column: 25
                    },
                    end: {
                        line: 382,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 382,
                        column: 59
                    },
                    end: {
                        line: 420,
                        column: 9
                    }
                },
                line: 382
            },
            "8": {
                name: "(anonymous_8)",
                decl: {
                    start: {
                        line: 426,
                        column: 21
                    },
                    end: {
                        line: 426,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 426,
                        column: 46
                    },
                    end: {
                        line: 506,
                        column: 9
                    }
                },
                line: 426
            },
            "9": {
                name: "walk",
                decl: {
                    start: {
                        line: 432,
                        column: 21
                    },
                    end: {
                        line: 432,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 432,
                        column: 39
                    },
                    end: {
                        line: 452,
                        column: 13
                    }
                },
                line: 432
            },
            "10": {
                name: "(anonymous_10)",
                decl: {
                    start: {
                        line: 461,
                        column: 50
                    },
                    end: {
                        line: 461,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 461,
                        column: 63
                    },
                    end: {
                        line: 464,
                        column: 17
                    }
                },
                line: 461
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 171,
                        column: 0
                    },
                    end: {
                        line: 173,
                        column: 1
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 171,
                        column: 0
                    },
                    end: {
                        line: 173,
                        column: 1
                    }
                }, {
                    start: {
                        line: 171,
                        column: 0
                    },
                    end: {
                        line: 173,
                        column: 1
                    }
                }],
                line: 171
            },
            "1": {
                loc: {
                    start: {
                        line: 188,
                        column: 15
                    },
                    end: {
                        line: 190,
                        column: 15
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 189,
                        column: 14
                    },
                    end: {
                        line: 189,
                        column: 21
                    }
                }, {
                    start: {
                        line: 190,
                        column: 14
                    },
                    end: {
                        line: 190,
                        column: 15
                    }
                }],
                line: 188
            },
            "2": {
                loc: {
                    start: {
                        line: 197,
                        column: 4
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 197,
                        column: 4
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                }, {
                    start: {
                        line: 197,
                        column: 4
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                }],
                line: 197
            },
            "3": {
                loc: {
                    start: {
                        line: 199,
                        column: 19
                    },
                    end: {
                        line: 206,
                        column: 22
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 200,
                        column: 18
                    },
                    end: {
                        line: 205,
                        column: 53
                    }
                }, {
                    start: {
                        line: 206,
                        column: 18
                    },
                    end: {
                        line: 206,
                        column: 22
                    }
                }],
                line: 199
            },
            "4": {
                loc: {
                    start: {
                        line: 226,
                        column: 15
                    },
                    end: {
                        line: 233,
                        column: 34
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 227,
                        column: 14
                    },
                    end: {
                        line: 232,
                        column: 21
                    }
                }, {
                    start: {
                        line: 233,
                        column: 14
                    },
                    end: {
                        line: 233,
                        column: 34
                    }
                }],
                line: 226
            },
            "5": {
                loc: {
                    start: {
                        line: 229,
                        column: 23
                    },
                    end: {
                        line: 231,
                        column: 79
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 230,
                        column: 22
                    },
                    end: {
                        line: 230,
                        column: 23
                    }
                }, {
                    start: {
                        line: 231,
                        column: 22
                    },
                    end: {
                        line: 231,
                        column: 79
                    }
                }],
                line: 229
            },
            "6": {
                loc: {
                    start: {
                        line: 249,
                        column: 8
                    },
                    end: {
                        line: 252,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 249,
                        column: 8
                    },
                    end: {
                        line: 252,
                        column: 9
                    }
                }, {
                    start: {
                        line: 249,
                        column: 8
                    },
                    end: {
                        line: 252,
                        column: 9
                    }
                }],
                line: 249
            },
            "7": {
                loc: {
                    start: {
                        line: 249,
                        column: 12
                    },
                    end: {
                        line: 250,
                        column: 50
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 249,
                        column: 12
                    },
                    end: {
                        line: 249,
                        column: 17
                    }
                }, {
                    start: {
                        line: 249,
                        column: 21
                    },
                    end: {
                        line: 249,
                        column: 46
                    }
                }, {
                    start: {
                        line: 250,
                        column: 16
                    },
                    end: {
                        line: 250,
                        column: 50
                    }
                }],
                line: 249
            },
            "8": {
                loc: {
                    start: {
                        line: 257,
                        column: 8
                    },
                    end: {
                        line: 259,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 257,
                        column: 8
                    },
                    end: {
                        line: 259,
                        column: 9
                    }
                }, {
                    start: {
                        line: 257,
                        column: 8
                    },
                    end: {
                        line: 259,
                        column: 9
                    }
                }],
                line: 257
            },
            "9": {
                loc: {
                    start: {
                        line: 263,
                        column: 8
                    },
                    end: {
                        line: 367,
                        column: 9
                    }
                },
                type: "switch",
                locations: [{
                    start: {
                        line: 264,
                        column: 8
                    },
                    end: {
                        line: 265,
                        column: 32
                    }
                }, {
                    start: {
                        line: 267,
                        column: 8
                    },
                    end: {
                        line: 273,
                        column: 25
                    }
                }, {
                    start: {
                        line: 275,
                        column: 8
                    },
                    end: {
                        line: 275,
                        column: 23
                    }
                }, {
                    start: {
                        line: 276,
                        column: 8
                    },
                    end: {
                        line: 282,
                        column: 33
                    }
                }, {
                    start: {
                        line: 287,
                        column: 8
                    },
                    end: {
                        line: 366,
                        column: 21
                    }
                }],
                line: 263
            },
            "10": {
                loc: {
                    start: {
                        line: 271,
                        column: 19
                    },
                    end: {
                        line: 273,
                        column: 24
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 272,
                        column: 18
                    },
                    end: {
                        line: 272,
                        column: 31
                    }
                }, {
                    start: {
                        line: 273,
                        column: 18
                    },
                    end: {
                        line: 273,
                        column: 24
                    }
                }],
                line: 271
            },
            "11": {
                loc: {
                    start: {
                        line: 292,
                        column: 12
                    },
                    end: {
                        line: 294,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 292,
                        column: 12
                    },
                    end: {
                        line: 294,
                        column: 13
                    }
                }, {
                    start: {
                        line: 292,
                        column: 12
                    },
                    end: {
                        line: 294,
                        column: 13
                    }
                }],
                line: 292
            },
            "12": {
                loc: {
                    start: {
                        line: 303,
                        column: 12
                    },
                    end: {
                        line: 321,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 303,
                        column: 12
                    },
                    end: {
                        line: 321,
                        column: 13
                    }
                }, {
                    start: {
                        line: 303,
                        column: 12
                    },
                    end: {
                        line: 321,
                        column: 13
                    }
                }],
                line: 303
            },
            "13": {
                loc: {
                    start: {
                        line: 309,
                        column: 33
                    },
                    end: {
                        line: 309,
                        column: 56
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 309,
                        column: 33
                    },
                    end: {
                        line: 309,
                        column: 46
                    }
                }, {
                    start: {
                        line: 309,
                        column: 50
                    },
                    end: {
                        line: 309,
                        column: 56
                    }
                }],
                line: 309
            },
            "14": {
                loc: {
                    start: {
                        line: 314,
                        column: 20
                    },
                    end: {
                        line: 318,
                        column: 55
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 315,
                        column: 22
                    },
                    end: {
                        line: 315,
                        column: 26
                    }
                }, {
                    start: {
                        line: 316,
                        column: 22
                    },
                    end: {
                        line: 318,
                        column: 55
                    }
                }],
                line: 314
            },
            "15": {
                loc: {
                    start: {
                        line: 316,
                        column: 22
                    },
                    end: {
                        line: 318,
                        column: 55
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 317,
                        column: 26
                    },
                    end: {
                        line: 317,
                        column: 85
                    }
                }, {
                    start: {
                        line: 318,
                        column: 26
                    },
                    end: {
                        line: 318,
                        column: 55
                    }
                }],
                line: 316
            },
            "16": {
                loc: {
                    start: {
                        line: 325,
                        column: 12
                    },
                    end: {
                        line: 355,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 325,
                        column: 12
                    },
                    end: {
                        line: 355,
                        column: 13
                    }
                }, {
                    start: {
                        line: 325,
                        column: 12
                    },
                    end: {
                        line: 355,
                        column: 13
                    }
                }],
                line: 325
            },
            "17": {
                loc: {
                    start: {
                        line: 325,
                        column: 16
                    },
                    end: {
                        line: 325,
                        column: 46
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 325,
                        column: 16
                    },
                    end: {
                        line: 325,
                        column: 19
                    }
                }, {
                    start: {
                        line: 325,
                        column: 23
                    },
                    end: {
                        line: 325,
                        column: 46
                    }
                }],
                line: 325
            },
            "18": {
                loc: {
                    start: {
                        line: 328,
                        column: 20
                    },
                    end: {
                        line: 338,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 328,
                        column: 20
                    },
                    end: {
                        line: 338,
                        column: 21
                    }
                }, {
                    start: {
                        line: 328,
                        column: 20
                    },
                    end: {
                        line: 338,
                        column: 21
                    }
                }],
                line: 328
            },
            "19": {
                loc: {
                    start: {
                        line: 331,
                        column: 24
                    },
                    end: {
                        line: 337,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 331,
                        column: 24
                    },
                    end: {
                        line: 337,
                        column: 25
                    }
                }, {
                    start: {
                        line: 331,
                        column: 24
                    },
                    end: {
                        line: 337,
                        column: 25
                    }
                }],
                line: 331
            },
            "20": {
                loc: {
                    start: {
                        line: 333,
                        column: 32
                    },
                    end: {
                        line: 335,
                        column: 41
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 334,
                        column: 38
                    },
                    end: {
                        line: 334,
                        column: 42
                    }
                }, {
                    start: {
                        line: 335,
                        column: 38
                    },
                    end: {
                        line: 335,
                        column: 41
                    }
                }],
                line: 333
            },
            "21": {
                loc: {
                    start: {
                        line: 344,
                        column: 20
                    },
                    end: {
                        line: 353,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 344,
                        column: 20
                    },
                    end: {
                        line: 353,
                        column: 21
                    }
                }, {
                    start: {
                        line: 344,
                        column: 20
                    },
                    end: {
                        line: 353,
                        column: 21
                    }
                }],
                line: 344
            },
            "22": {
                loc: {
                    start: {
                        line: 346,
                        column: 24
                    },
                    end: {
                        line: 352,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 346,
                        column: 24
                    },
                    end: {
                        line: 352,
                        column: 25
                    }
                }, {
                    start: {
                        line: 346,
                        column: 24
                    },
                    end: {
                        line: 352,
                        column: 25
                    }
                }],
                line: 346
            },
            "23": {
                loc: {
                    start: {
                        line: 348,
                        column: 32
                    },
                    end: {
                        line: 350,
                        column: 41
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 349,
                        column: 38
                    },
                    end: {
                        line: 349,
                        column: 42
                    }
                }, {
                    start: {
                        line: 350,
                        column: 38
                    },
                    end: {
                        line: 350,
                        column: 41
                    }
                }],
                line: 348
            },
            "24": {
                loc: {
                    start: {
                        line: 360,
                        column: 16
                    },
                    end: {
                        line: 364,
                        column: 51
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 361,
                        column: 18
                    },
                    end: {
                        line: 361,
                        column: 22
                    }
                }, {
                    start: {
                        line: 362,
                        column: 18
                    },
                    end: {
                        line: 364,
                        column: 51
                    }
                }],
                line: 360
            },
            "25": {
                loc: {
                    start: {
                        line: 362,
                        column: 18
                    },
                    end: {
                        line: 364,
                        column: 51
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 363,
                        column: 22
                    },
                    end: {
                        line: 363,
                        column: 81
                    }
                }, {
                    start: {
                        line: 364,
                        column: 22
                    },
                    end: {
                        line: 364,
                        column: 51
                    }
                }],
                line: 362
            },
            "26": {
                loc: {
                    start: {
                        line: 372,
                        column: 4
                    },
                    end: {
                        line: 421,
                        column: 5
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 372,
                        column: 4
                    },
                    end: {
                        line: 421,
                        column: 5
                    }
                }, {
                    start: {
                        line: 372,
                        column: 4
                    },
                    end: {
                        line: 421,
                        column: 5
                    }
                }],
                line: 372
            },
            "27": {
                loc: {
                    start: {
                        line: 396,
                        column: 12
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 396,
                        column: 12
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                }, {
                    start: {
                        line: 396,
                        column: 12
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                }],
                line: 396
            },
            "28": {
                loc: {
                    start: {
                        line: 402,
                        column: 19
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 402,
                        column: 19
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                }, {
                    start: {
                        line: 402,
                        column: 19
                    },
                    end: {
                        line: 404,
                        column: 13
                    }
                }],
                line: 402
            },
            "29": {
                loc: {
                    start: {
                        line: 410,
                        column: 12
                    },
                    end: {
                        line: 414,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 410,
                        column: 12
                    },
                    end: {
                        line: 414,
                        column: 13
                    }
                }, {
                    start: {
                        line: 410,
                        column: 12
                    },
                    end: {
                        line: 414,
                        column: 13
                    }
                }],
                line: 410
            },
            "30": {
                loc: {
                    start: {
                        line: 410,
                        column: 16
                    },
                    end: {
                        line: 412,
                        column: 56
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 410,
                        column: 16
                    },
                    end: {
                        line: 410,
                        column: 24
                    }
                }, {
                    start: {
                        line: 410,
                        column: 28
                    },
                    end: {
                        line: 410,
                        column: 58
                    }
                }, {
                    start: {
                        line: 411,
                        column: 21
                    },
                    end: {
                        line: 411,
                        column: 49
                    }
                }, {
                    start: {
                        line: 412,
                        column: 20
                    },
                    end: {
                        line: 412,
                        column: 55
                    }
                }],
                line: 410
            },
            "31": {
                loc: {
                    start: {
                        line: 425,
                        column: 4
                    },
                    end: {
                        line: 507,
                        column: 5
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 425,
                        column: 4
                    },
                    end: {
                        line: 507,
                        column: 5
                    }
                }, {
                    start: {
                        line: 425,
                        column: 4
                    },
                    end: {
                        line: 507,
                        column: 5
                    }
                }],
                line: 425
            },
            "32": {
                loc: {
                    start: {
                        line: 439,
                        column: 16
                    },
                    end: {
                        line: 450,
                        column: 17
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 439,
                        column: 16
                    },
                    end: {
                        line: 450,
                        column: 17
                    }
                }, {
                    start: {
                        line: 439,
                        column: 16
                    },
                    end: {
                        line: 450,
                        column: 17
                    }
                }],
                line: 439
            },
            "33": {
                loc: {
                    start: {
                        line: 439,
                        column: 20
                    },
                    end: {
                        line: 439,
                        column: 54
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 439,
                        column: 20
                    },
                    end: {
                        line: 439,
                        column: 25
                    }
                }, {
                    start: {
                        line: 439,
                        column: 29
                    },
                    end: {
                        line: 439,
                        column: 54
                    }
                }],
                line: 439
            },
            "34": {
                loc: {
                    start: {
                        line: 441,
                        column: 24
                    },
                    end: {
                        line: 448,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 441,
                        column: 24
                    },
                    end: {
                        line: 448,
                        column: 25
                    }
                }, {
                    start: {
                        line: 441,
                        column: 24
                    },
                    end: {
                        line: 448,
                        column: 25
                    }
                }],
                line: 441
            },
            "35": {
                loc: {
                    start: {
                        line: 443,
                        column: 28
                    },
                    end: {
                        line: 447,
                        column: 29
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 443,
                        column: 28
                    },
                    end: {
                        line: 447,
                        column: 29
                    }
                }, {
                    start: {
                        line: 443,
                        column: 28
                    },
                    end: {
                        line: 447,
                        column: 29
                    }
                }],
                line: 443
            },
            "36": {
                loc: {
                    start: {
                        line: 460,
                        column: 12
                    },
                    end: {
                        line: 465,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 460,
                        column: 12
                    },
                    end: {
                        line: 465,
                        column: 13
                    }
                }, {
                    start: {
                        line: 460,
                        column: 12
                    },
                    end: {
                        line: 465,
                        column: 13
                    }
                }],
                line: 460
            },
            "37": {
                loc: {
                    start: {
                        line: 480,
                        column: 12
                    },
                    end: {
                        line: 501,
                        column: 13
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 480,
                        column: 12
                    },
                    end: {
                        line: 501,
                        column: 13
                    }
                }, {
                    start: {
                        line: 480,
                        column: 12
                    },
                    end: {
                        line: 501,
                        column: 13
                    }
                }],
                line: 480
            },
            "38": {
                loc: {
                    start: {
                        line: 498,
                        column: 23
                    },
                    end: {
                        line: 500,
                        column: 23
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 499,
                        column: 22
                    },
                    end: {
                        line: 499,
                        column: 39
                    }
                }, {
                    start: {
                        line: 500,
                        column: 22
                    },
                    end: {
                        line: 500,
                        column: 23
                    }
                }],
                line: 498
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0,
            "61": 0,
            "62": 0,
            "63": 0,
            "64": 0,
            "65": 0,
            "66": 0,
            "67": 0,
            "68": 0,
            "69": 0,
            "70": 0,
            "71": 0,
            "72": 0,
            "73": 0,
            "74": 0,
            "75": 0,
            "76": 0,
            "77": 0,
            "78": 0,
            "79": 0,
            "80": 0,
            "81": 0,
            "82": 0,
            "83": 0,
            "84": 0,
            "85": 0,
            "86": 0,
            "87": 0,
            "88": 0,
            "89": 0,
            "90": 0,
            "91": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0],
            "4": [0, 0],
            "5": [0, 0],
            "6": [0, 0],
            "7": [0, 0, 0],
            "8": [0, 0],
            "9": [0, 0, 0, 0, 0],
            "10": [0, 0],
            "11": [0, 0],
            "12": [0, 0],
            "13": [0, 0],
            "14": [0, 0],
            "15": [0, 0],
            "16": [0, 0],
            "17": [0, 0],
            "18": [0, 0],
            "19": [0, 0],
            "20": [0, 0],
            "21": [0, 0],
            "22": [0, 0],
            "23": [0, 0],
            "24": [0, 0],
            "25": [0, 0],
            "26": [0, 0],
            "27": [0, 0],
            "28": [0, 0],
            "29": [0, 0],
            "30": [0, 0, 0, 0],
            "31": [0, 0],
            "32": [0, 0],
            "33": [0, 0],
            "34": [0, 0],
            "35": [0, 0],
            "36": [0, 0],
            "37": [0, 0],
            "38": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

cov_2mpsjr6ini.s[0]++;
//  json2.js
//  2016-10-28
//  Public Domain.
//  NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
//  See http://www.JSON.org/js.html
//  This code should be minified before deployment.
//  See http://javascript.crockford.com/jsmin.html

//  USE YOUR OWN COPY. IT IS EXTREMELY UNWISE TO LOAD CODE FROM SERVERS YOU DO
//  NOT CONTROL.

//  This file creates a global JSON object containing two methods: stringify
//  and parse. This file provides the ES5 JSON capability to ES3 systems.
//  If a project might run on IE8 or earlier, then this file should be included.
//  This file does nothing on ES5 systems.

//      JSON.stringify(value, replacer, space)
//          value       any JavaScript value, usually an object or array.
//          replacer    an optional parameter that determines how object
//                      values are stringified for objects. It can be a
//                      function or an array of strings.
//          space       an optional parameter that specifies the indentation
//                      of nested structures. If it is omitted, the text will
//                      be packed without extra whitespace. If it is a number,
//                      it will specify the number of spaces to indent at each
//                      level. If it is a string (such as "\t" or "&nbsp;"),
//                      it contains the characters used to indent at each level.
//          This method produces a JSON text from a JavaScript value.
//          When an object value is found, if the object contains a toJSON
//          method, its toJSON method will be called and the result will be
//          stringified. A toJSON method does not serialize: it returns the
//          value represented by the name/value pair that should be serialized,
//          or undefined if nothing should be serialized. The toJSON method
//          will be passed the key associated with the value, and this will be
//          bound to the value.

//          For example, this would serialize Dates as ISO strings.

//              Date.prototype.toJSON = function (key) {
//                  function f(n) {
//                      // Format integers to have at least two digits.
//                      return (n < 10)
//                          ? "0" + n
//                          : n;
//                  }
//                  return this.getUTCFullYear()   + "-" +
//                       f(this.getUTCMonth() + 1) + "-" +
//                       f(this.getUTCDate())      + "T" +
//                       f(this.getUTCHours())     + ":" +
//                       f(this.getUTCMinutes())   + ":" +
//                       f(this.getUTCSeconds())   + "Z";
//              };

//          You can provide an optional replacer method. It will be passed the
//          key and value of each member, with this bound to the containing
//          object. The value that is returned from your method will be
//          serialized. If your method returns undefined, then the member will
//          be excluded from the serialization.

//          If the replacer parameter is an array of strings, then it will be
//          used to select the members to be serialized. It filters the results
//          such that only members with keys listed in the replacer array are
//          stringified.

//          Values that do not have JSON representations, such as undefined or
//          functions, will not be serialized. Such values in objects will be
//          dropped; in arrays they will be replaced with null. You can use
//          a replacer function to replace those with JSON values.

//          JSON.stringify(undefined) returns undefined.

//          The optional space parameter produces a stringification of the
//          value that is filled with line breaks and indentation to make it
//          easier to read.

//          If the space parameter is a non-empty string, then that string will
//          be used for indentation. If the space parameter is a number, then
//          the indentation will be that many spaces.

//          Example:

//          text = JSON.stringify(["e", {pluribus: "unum"}]);
//          // text is '["e",{"pluribus":"unum"}]'

//          text = JSON.stringify(["e", {pluribus: "unum"}], null, "\t");
//          // text is '[\n\t"e",\n\t{\n\t\t"pluribus": "unum"\n\t}\n]'

//          text = JSON.stringify([new Date()], function (key, value) {
//              return this[key] instanceof Date
//                  ? "Date(" + this[key] + ")"
//                  : value;
//          });
//          // text is '["Date(---current time---)"]'

//      JSON.parse(text, reviver)
//          This method parses a JSON text to produce an object or array.
//          It can throw a SyntaxError exception.

//          The optional reviver parameter is a function that can filter and
//          transform the results. It receives each of the keys and values,
//          and its return value is used instead of the original value.
//          If it returns what it received, then the structure is not modified.
//          If it returns undefined then the member is deleted.

//          Example:

//          // Parse the text. Values that look like ISO date strings will
//          // be converted to Date objects.

//          myData = JSON.parse(text, function (key, value) {
//              var a;
//              if (typeof value === "string") {
//                  a =
//   /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
//                  if (a) {
//                      return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4],
//                          +a[5], +a[6]));
//                  }
//              }
//              return value;
//          });

//          myData = JSON.parse('["Date(09/09/2001)"]', function (key, value) {
//              var d;
//              if (typeof value === "string" &&
//                      value.slice(0, 5) === "Date(" &&
//                      value.slice(-1) === ")") {
//                  d = new Date(value.slice(5, -1));
//                  if (d) {
//                      return d;
//                  }
//              }
//              return value;
//          });

//  This is a reference implementation. You are free to copy, modify, or
//  redistribute.

/* jslint
    eval, for, this
*/

/* property
    JSON, apply, call, charCodeAt, getUTCDate, getUTCFullYear, getUTCHours,
    getUTCMinutes, getUTCMonth, getUTCSeconds, hasOwnProperty, join,
    lastIndex, length, parse, prototype, push, replace, slice, stringify,
    test, toJSON, toString, valueOf
*/

// Create a JSON object only if one does not already exist. We create the
// methods in a closure to avoid creating global variables.

/* eslint-disable no-extend-native*/
/* eslint-disable no-var */
/* eslint-disable angular/typecheck-function */
/* eslint-disable angular/json-functions */
/* eslint-disable angular/typecheck-object */
/* eslint-disable angular/typecheck-string */
/* eslint-disable angular/typecheck-number */
/* eslint-disable angular/definedundefined */
/* eslint-disable operator-linebreak */
/* eslint-disable wrap-iife */
/* eslint-disable no-native-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-eval */
/* eslint-disable default-case */
/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-negated-condition */

if ((typeof JSON === "undefined" ? "undefined" : _typeof(JSON)) !== "object") {
    cov_2mpsjr6ini.b[0][0]++;
    cov_2mpsjr6ini.s[1]++;

    JSON = {};
} else {
    cov_2mpsjr6ini.b[0][1]++;
}

cov_2mpsjr6ini.s[2]++;
(function () {
    "use strict";

    /* eslint-disable camelcase */

    cov_2mpsjr6ini.f[0]++;
    var rx_one = (cov_2mpsjr6ini.s[3]++, /^[\],:{}\s]*$/);
    var rx_two = (cov_2mpsjr6ini.s[4]++, /\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g);
    var rx_three = (cov_2mpsjr6ini.s[5]++, /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g);
    var rx_four = (cov_2mpsjr6ini.s[6]++, /(?:^|:|,)(?:\s*\[)+/g);
    var rx_escapable = (cov_2mpsjr6ini.s[7]++, /[\\"\u0000-\u001f\u007f-\u009f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g);
    var rx_dangerous = (cov_2mpsjr6ini.s[8]++, /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g);

    function f(n) {
        cov_2mpsjr6ini.f[1]++;
        cov_2mpsjr6ini.s[9]++;

        // Format integers to have at least two digits.
        return n < 10 ? (cov_2mpsjr6ini.b[1][0]++, "0" + n) : (cov_2mpsjr6ini.b[1][1]++, n);
    }

    function this_value() {
        cov_2mpsjr6ini.f[2]++;
        cov_2mpsjr6ini.s[10]++;

        return this.valueOf();
    }

    cov_2mpsjr6ini.s[11]++;
    if (typeof Date.prototype.toJSON !== "function") {
        cov_2mpsjr6ini.b[2][0]++;
        cov_2mpsjr6ini.s[12]++;

        Date.prototype.toJSON = function () {
            cov_2mpsjr6ini.f[3]++;
            cov_2mpsjr6ini.s[13]++;

            return isFinite(this.valueOf()) ? (cov_2mpsjr6ini.b[3][0]++, this.getUTCFullYear() + "-" + f(this.getUTCMonth() + 1) + "-" + f(this.getUTCDate()) + "T" + f(this.getUTCHours()) + ":" + f(this.getUTCMinutes()) + ":" + f(this.getUTCSeconds()) + "Z") : (cov_2mpsjr6ini.b[3][1]++, null);
        };

        cov_2mpsjr6ini.s[14]++;
        Boolean.prototype.toJSON = this_value;
        cov_2mpsjr6ini.s[15]++;
        Number.prototype.toJSON = this_value;
        cov_2mpsjr6ini.s[16]++;
        String.prototype.toJSON = this_value;
    } else {
        cov_2mpsjr6ini.b[2][1]++;
    }

    var gap;
    var indent;
    var meta;
    var rep;

    function quote(string) {
        cov_2mpsjr6ini.f[4]++;
        cov_2mpsjr6ini.s[17]++;

        // If the string contains no control characters, no quote characters, and no
        // backslash characters, then we can safely slap some quotes around it.
        // Otherwise we must also replace the offending characters with safe escape
        // sequences.

        rx_escapable.lastIndex = 0;
        cov_2mpsjr6ini.s[18]++;
        return rx_escapable.test(string) ? (cov_2mpsjr6ini.b[4][0]++, "\"" + string.replace(rx_escapable, function (a) {
            cov_2mpsjr6ini.f[5]++;

            var c = (cov_2mpsjr6ini.s[19]++, meta[a]);
            cov_2mpsjr6ini.s[20]++;
            return typeof c === "string" ? (cov_2mpsjr6ini.b[5][0]++, c) : (cov_2mpsjr6ini.b[5][1]++, "\\u" + ("0000" + a.charCodeAt(0).toString(16)).slice(-4));
        }) + "\"") : (cov_2mpsjr6ini.b[4][1]++, "\"" + string + "\"");
    }

    function str(key, holder) {
        cov_2mpsjr6ini.f[6]++;

        // Produce a string from holder[key].

        var i; // The loop counter.
        var k; // The member key.
        var v; // The member value.
        var length;
        var mind = (cov_2mpsjr6ini.s[21]++, gap);
        var partial;
        var value = (cov_2mpsjr6ini.s[22]++, holder[key]);

        // If the value has a toJSON method, call it to obtain a replacement value.

        cov_2mpsjr6ini.s[23]++;
        if ((cov_2mpsjr6ini.b[7][0]++, value) && (cov_2mpsjr6ini.b[7][1]++, (typeof value === "undefined" ? "undefined" : _typeof(value)) === "object") && (cov_2mpsjr6ini.b[7][2]++, typeof value.toJSON === "function")) {
            cov_2mpsjr6ini.b[6][0]++;
            cov_2mpsjr6ini.s[24]++;

            value = value.toJSON(key);
        } else {
            cov_2mpsjr6ini.b[6][1]++;
        }

        // If we were called with a replacer function, then call the replacer to
        // obtain a replacement value.

        cov_2mpsjr6ini.s[25]++;
        if (typeof rep === "function") {
            cov_2mpsjr6ini.b[8][0]++;
            cov_2mpsjr6ini.s[26]++;

            value = rep.call(holder, key, value);
        } else {
            cov_2mpsjr6ini.b[8][1]++;
        }

        // What happens next depends on the value's type.

        cov_2mpsjr6ini.s[27]++;
        switch (typeof value === "undefined" ? "undefined" : _typeof(value)) {
            case "string":
                cov_2mpsjr6ini.b[9][0]++;
                cov_2mpsjr6ini.s[28]++;

                return quote(value);

            case "number":
                cov_2mpsjr6ini.b[9][1]++;
                cov_2mpsjr6ini.s[29]++;


                // JSON numbers must be finite. Encode non-finite numbers as null.

                return isFinite(value) ? (cov_2mpsjr6ini.b[10][0]++, String(value)) : (cov_2mpsjr6ini.b[10][1]++, "null");

            case "boolean":
                cov_2mpsjr6ini.b[9][2]++;

            case "null":
                cov_2mpsjr6ini.b[9][3]++;
                cov_2mpsjr6ini.s[30]++;


                // If the value is a boolean or null, convert it to a string. Note:
                // typeof null does not produce "null". The case is included here in
                // the remote chance that this gets fixed someday.

                return String(value);

            // If the type is "object", we might be dealing with an object or an array or
            // null.

            case "object":
                cov_2mpsjr6ini.b[9][4]++;
                cov_2mpsjr6ini.s[31]++;


                // Due to a specification blunder in ECMAScript, typeof null is "object",
                // so watch out for that case.

                if (!value) {
                    cov_2mpsjr6ini.b[11][0]++;
                    cov_2mpsjr6ini.s[32]++;

                    return "null";
                } else {
                    cov_2mpsjr6ini.b[11][1]++;
                }

                // Make an array to hold the partial results of stringifying this object value.

                cov_2mpsjr6ini.s[33]++;
                gap += indent;
                cov_2mpsjr6ini.s[34]++;
                partial = [];

                // Is the value an array?

                cov_2mpsjr6ini.s[35]++;
                if (Object.prototype.toString.apply(value) === "[object Array]") {
                    cov_2mpsjr6ini.b[12][0]++;
                    cov_2mpsjr6ini.s[36]++;

                    // The value is an array. Stringify every element. Use null as a placeholder
                    // for non-JSON values.

                    length = value.length;
                    cov_2mpsjr6ini.s[37]++;
                    for (i = 0; i < length; i += 1) {
                        cov_2mpsjr6ini.s[38]++;

                        partial[i] = (cov_2mpsjr6ini.b[13][0]++, str(i, value)) || (cov_2mpsjr6ini.b[13][1]++, "null");
                    }

                    // Join all of the elements together, separated with commas, and wrap them in
                    // brackets.
                    cov_2mpsjr6ini.s[39]++;
                    v = partial.length === 0 ? (cov_2mpsjr6ini.b[14][0]++, "[]") : (cov_2mpsjr6ini.b[14][1]++, gap ? (cov_2mpsjr6ini.b[15][0]++, "[\n" + gap + partial.join(",\n" + gap) + "\n" + mind + "]") : (cov_2mpsjr6ini.b[15][1]++, "[" + partial.join(",") + "]"));
                    cov_2mpsjr6ini.s[40]++;
                    gap = mind;
                    cov_2mpsjr6ini.s[41]++;
                    return v;
                } else {
                    cov_2mpsjr6ini.b[12][1]++;
                }

                // If the replacer is an array, use it to select the members to be stringified.

                cov_2mpsjr6ini.s[42]++;
                if ((cov_2mpsjr6ini.b[17][0]++, rep) && (cov_2mpsjr6ini.b[17][1]++, (typeof rep === "undefined" ? "undefined" : _typeof(rep)) === "object")) {
                    cov_2mpsjr6ini.b[16][0]++;
                    cov_2mpsjr6ini.s[43]++;

                    length = rep.length;
                    cov_2mpsjr6ini.s[44]++;
                    for (i = 0; i < length; i += 1) {
                        cov_2mpsjr6ini.s[45]++;

                        if (typeof rep[i] === "string") {
                            cov_2mpsjr6ini.b[18][0]++;
                            cov_2mpsjr6ini.s[46]++;

                            k = rep[i];
                            cov_2mpsjr6ini.s[47]++;
                            v = str(k, value);
                            cov_2mpsjr6ini.s[48]++;
                            if (v) {
                                cov_2mpsjr6ini.b[19][0]++;
                                cov_2mpsjr6ini.s[49]++;

                                partial.push(quote(k) + (gap ? (cov_2mpsjr6ini.b[20][0]++, ": ") : (cov_2mpsjr6ini.b[20][1]++, ":")) + v);
                            } else {
                                cov_2mpsjr6ini.b[19][1]++;
                            }
                        } else {
                            cov_2mpsjr6ini.b[18][1]++;
                        }
                    }
                } else {
                    cov_2mpsjr6ini.b[16][1]++;
                    cov_2mpsjr6ini.s[50]++;

                    // Otherwise, iterate through all of the keys in the object.

                    for (k in value) {
                        cov_2mpsjr6ini.s[51]++;

                        if (Object.prototype.hasOwnProperty.call(value, k)) {
                            cov_2mpsjr6ini.b[21][0]++;
                            cov_2mpsjr6ini.s[52]++;

                            v = str(k, value);
                            cov_2mpsjr6ini.s[53]++;
                            if (v) {
                                cov_2mpsjr6ini.b[22][0]++;
                                cov_2mpsjr6ini.s[54]++;

                                partial.push(quote(k) + (gap ? (cov_2mpsjr6ini.b[23][0]++, ": ") : (cov_2mpsjr6ini.b[23][1]++, ":")) + v);
                            } else {
                                cov_2mpsjr6ini.b[22][1]++;
                            }
                        } else {
                            cov_2mpsjr6ini.b[21][1]++;
                        }
                    }
                }

                // Join all of the member texts together, separated with commas,
                // and wrap them in braces.

                cov_2mpsjr6ini.s[55]++;
                v = partial.length === 0 ? (cov_2mpsjr6ini.b[24][0]++, "{}") : (cov_2mpsjr6ini.b[24][1]++, gap ? (cov_2mpsjr6ini.b[25][0]++, "{\n" + gap + partial.join(",\n" + gap) + "\n" + mind + "}") : (cov_2mpsjr6ini.b[25][1]++, "{" + partial.join(",") + "}"));
                cov_2mpsjr6ini.s[56]++;
                gap = mind;
                cov_2mpsjr6ini.s[57]++;
                return v;
        }
    }

    // If the JSON object does not yet have a stringify method, give it one.

    cov_2mpsjr6ini.s[58]++;
    if (typeof JSON.stringify !== "function") {
        cov_2mpsjr6ini.b[26][0]++;
        cov_2mpsjr6ini.s[59]++;

        meta = { // table of character substitutions
            "\b": "\\b",
            "\t": "\\t",
            "\n": "\\n",
            "\f": "\\f",
            "\r": "\\r",
            "\"": "\\\"",
            "\\": "\\\\"
        };
        cov_2mpsjr6ini.s[60]++;
        JSON.stringify = function (value, replacer, space) {
            cov_2mpsjr6ini.f[7]++;

            // The stringify method takes a value and an optional replacer, and an optional
            // space parameter, and returns a JSON text. The replacer can be a function
            // that can replace values, or an array of strings that will select the keys.
            // A default replacer method can be provided. Use of the space parameter can
            // produce text that is more easily readable.

            var i;
            cov_2mpsjr6ini.s[61]++;
            gap = "";
            cov_2mpsjr6ini.s[62]++;
            indent = "";

            // If the space parameter is a number, make an indent string containing that
            // many spaces.

            cov_2mpsjr6ini.s[63]++;
            if (typeof space === "number") {
                cov_2mpsjr6ini.b[27][0]++;
                cov_2mpsjr6ini.s[64]++;

                for (i = 0; i < space; i += 1) {
                    cov_2mpsjr6ini.s[65]++;

                    indent += " ";
                }

                // If the space parameter is a string, it will be used as the indent string.
            } else {
                    cov_2mpsjr6ini.b[27][1]++;
                    cov_2mpsjr6ini.s[66]++;
                    if (typeof space === "string") {
                        cov_2mpsjr6ini.b[28][0]++;
                        cov_2mpsjr6ini.s[67]++;

                        indent = space;
                    } else {
                        cov_2mpsjr6ini.b[28][1]++;
                    }
                } // If there is a replacer, it must be a function or an array.
            // Otherwise, throw an error.

            cov_2mpsjr6ini.s[68]++;
            rep = replacer;
            cov_2mpsjr6ini.s[69]++;
            if ((cov_2mpsjr6ini.b[30][0]++, replacer) && (cov_2mpsjr6ini.b[30][1]++, typeof replacer !== "function") && ((cov_2mpsjr6ini.b[30][2]++, (typeof replacer === "undefined" ? "undefined" : _typeof(replacer)) !== "object") || (cov_2mpsjr6ini.b[30][3]++, typeof replacer.length !== "number"))) {
                cov_2mpsjr6ini.b[29][0]++;
                cov_2mpsjr6ini.s[70]++;

                throw new Error("JSON.stringify");
            } else {
                cov_2mpsjr6ini.b[29][1]++;
            }

            // Make a fake root object containing our value under the key of "".
            // Return the result of stringifying the value.

            cov_2mpsjr6ini.s[71]++;
            return str("", { "": value });
        };
    } else {
        cov_2mpsjr6ini.b[26][1]++;
    }

    // If the JSON object does not yet have a parse method, give it one.

    cov_2mpsjr6ini.s[72]++;
    if (typeof JSON.parse !== "function") {
        cov_2mpsjr6ini.b[31][0]++;
        cov_2mpsjr6ini.s[73]++;

        JSON.parse = function (text, reviver) {
            cov_2mpsjr6ini.f[8]++;

            // The parse method takes a text and an optional reviver function, and returns
            // a JavaScript value if the text is a valid JSON text.

            var j;

            function walk(holder, key) {
                cov_2mpsjr6ini.f[9]++;

                // The walk method is used to recursively walk the resulting structure so
                // that modifications can be made.

                var k;
                var v;
                var value = (cov_2mpsjr6ini.s[74]++, holder[key]);
                cov_2mpsjr6ini.s[75]++;
                if ((cov_2mpsjr6ini.b[33][0]++, value) && (cov_2mpsjr6ini.b[33][1]++, (typeof value === "undefined" ? "undefined" : _typeof(value)) === "object")) {
                    cov_2mpsjr6ini.b[32][0]++;
                    cov_2mpsjr6ini.s[76]++;

                    for (k in value) {
                        cov_2mpsjr6ini.s[77]++;

                        if (Object.prototype.hasOwnProperty.call(value, k)) {
                            cov_2mpsjr6ini.b[34][0]++;
                            cov_2mpsjr6ini.s[78]++;

                            v = walk(value, k);
                            cov_2mpsjr6ini.s[79]++;
                            if (v !== undefined) {
                                cov_2mpsjr6ini.b[35][0]++;
                                cov_2mpsjr6ini.s[80]++;

                                value[k] = v;
                            } else {
                                cov_2mpsjr6ini.b[35][1]++;
                                cov_2mpsjr6ini.s[81]++;

                                delete value[k];
                            }
                        } else {
                            cov_2mpsjr6ini.b[34][1]++;
                        }
                    }
                } else {
                    cov_2mpsjr6ini.b[32][1]++;
                }
                cov_2mpsjr6ini.s[82]++;
                return reviver.call(holder, key, value);
            }

            // Parsing happens in four stages. In the first stage, we replace certain
            // Unicode characters with escape sequences. JavaScript handles many characters
            // incorrectly, either silently deleting them, or treating them as line endings.

            cov_2mpsjr6ini.s[83]++;
            text = String(text);
            cov_2mpsjr6ini.s[84]++;
            rx_dangerous.lastIndex = 0;
            cov_2mpsjr6ini.s[85]++;
            if (rx_dangerous.test(text)) {
                cov_2mpsjr6ini.b[36][0]++;
                cov_2mpsjr6ini.s[86]++;

                text = text.replace(rx_dangerous, function (a) {
                    cov_2mpsjr6ini.f[10]++;
                    cov_2mpsjr6ini.s[87]++;

                    return "\\u" + ("0000" + a.charCodeAt(0).toString(16)).slice(-4);
                });
            } else {
                cov_2mpsjr6ini.b[36][1]++;
            }

            // In the second stage, we run the text against regular expressions that look
            // for non-JSON patterns. We are especially concerned with "()" and "new"
            // because they can cause invocation, and "=" because it can cause mutation.
            // But just to be safe, we want to reject all unexpected forms.

            // We split the second stage into 4 regexp operations in order to work around
            // crippling inefficiencies in IE's and Safari's regexp engines. First we
            // replace the JSON backslash pairs with "@" (a non-JSON character). Second, we
            // replace all simple value tokens with "]" characters. Third, we delete all
            // open brackets that follow a colon or comma or that begin the text. Finally,
            // we look to see that the remaining characters are only whitespace or "]" or
            // "," or ":" or "{" or "}". If that is so, then the text is safe for eval.

            cov_2mpsjr6ini.s[88]++;
            if (rx_one.test(text.replace(rx_two, "@").replace(rx_three, "]").replace(rx_four, ""))) {
                cov_2mpsjr6ini.b[37][0]++;
                cov_2mpsjr6ini.s[89]++;

                // In the third stage we use the eval function to compile the text into a
                // JavaScript structure. The "{" operator is subject to a syntactic ambiguity
                // in JavaScript: it can begin a block or an object literal. We wrap the text
                // in parens to eliminate the ambiguity.

                j = eval("(" + text + ")");

                // In the optional fourth stage, we recursively walk the new structure, passing
                // each name/value pair to a reviver function for possible transformation.

                cov_2mpsjr6ini.s[90]++;
                return typeof reviver === "function" ? (cov_2mpsjr6ini.b[38][0]++, walk({ "": j }, "")) : (cov_2mpsjr6ini.b[38][1]++, j);
            } else {
                cov_2mpsjr6ini.b[37][1]++;
            }

            // If the text is not JSON parseable, then a SyntaxError is thrown.

            cov_2mpsjr6ini.s[91]++;
            throw new SyntaxError("JSON.parse");
        };
    } else {
        cov_2mpsjr6ini.b[31][1]++;
    }
})();