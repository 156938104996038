'use strict';

var cov_1ca297j9xj = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/shortMonths.filter.js',
        hash = 'b3821be138ab9971cd031ad05d0c93d4c5356488',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/shortMonths.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 28,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 44
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 26,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 27
                },
                end: {
                    line: 24,
                    column: 13
                }
            },
            '4': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 33
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 28,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'shortMonths',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 27
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 32
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 10
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1ca297j9xj.s[0]++;
(function () {
    'use strict';

    cov_1ca297j9xj.f[0]++;
    cov_1ca297j9xj.s[1]++;
    angular.module('app').filter('shortMonths', shortMonths);

    /* @ngInject */
    function shortMonths() {
        cov_1ca297j9xj.f[1]++;
        cov_1ca297j9xj.s[2]++;

        return function (value) {
            cov_1ca297j9xj.f[2]++;

            var months = (cov_1ca297j9xj.s[3]++, {
                1: "Jan",
                2: "Fev",
                3: "Mar",
                4: "Abr",
                5: "Mai",
                6: "Jun",
                7: "Jul",
                8: "Ago",
                9: "Set",
                10: "Out",
                11: "Nov",
                12: "Dez"
            });
            cov_1ca297j9xj.s[4]++;
            return months[value];
        };
    }
})();