'use strict';

var cov_10il4bhnwi = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/notificationsView/notificationsView.js',
        hash = 'd9ec74f0610b1c6ca3480f4b606ca6d2eb2e357e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/notificationsView/notificationsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 28
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 26
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 9
                }
            },
            '4': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 32
                }
            },
            '5': {
                start: {
                    line: 19,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 65
                }
            },
            '6': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 23,
                    column: 72
                }
            },
            '7': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 9
                }
            },
            '9': {
                start: {
                    line: 29,
                    column: 12
                },
                end: {
                    line: 29,
                    column: 36
                }
            },
            '10': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 9
                }
            },
            '11': {
                start: {
                    line: 33,
                    column: 12
                },
                end: {
                    line: 33,
                    column: 40
                }
            },
            '12': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 54
                }
            },
            '13': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 41
                }
            },
            '14': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 37
                }
            },
            '15': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 37
                }
            },
            '16': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 50
                }
            },
            '17': {
                start: {
                    line: 48,
                    column: 0
                },
                end: {
                    line: 56,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 16
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 15
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 27,
                        column: 4
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 33
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 27
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 28
                    },
                    end: {
                        line: 45,
                        column: 5
                    }
                },
                line: 43
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                }, {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 20,
                        column: 9
                    }
                }],
                line: 16
            },
            '1': {
                loc: {
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 16,
                        column: 97
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 16,
                        column: 12
                    },
                    end: {
                        line: 16,
                        column: 48
                    }
                }, {
                    start: {
                        line: 16,
                        column: 52
                    },
                    end: {
                        line: 16,
                        column: 97
                    }
                }],
                line: 16
            },
            '2': {
                loc: {
                    start: {
                        line: 19,
                        column: 29
                    },
                    end: {
                        line: 19,
                        column: 64
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 19,
                        column: 56
                    },
                    end: {
                        line: 19,
                        column: 58
                    }
                }, {
                    start: {
                        line: 19,
                        column: 61
                    },
                    end: {
                        line: 19,
                        column: 64
                    }
                }],
                line: 19
            },
            '3': {
                loc: {
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                }, {
                    start: {
                        line: 28,
                        column: 8
                    },
                    end: {
                        line: 30,
                        column: 9
                    }
                }],
                line: 28
            },
            '4': {
                loc: {
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                }, {
                    start: {
                        line: 32,
                        column: 8
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                }],
                line: 32
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NotificationsViewController = function () {
    function NotificationsViewController() {
        _classCallCheck(this, NotificationsViewController);

        cov_10il4bhnwi.f[0]++;
        cov_10il4bhnwi.s[0]++;

        this.direction = '';
        cov_10il4bhnwi.s[1]++;
        this.filters = {};
    }

    _createClass(NotificationsViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_10il4bhnwi.f[1]++;
            cov_10il4bhnwi.s[2]++;

            this.types = {
                SAMPLE_CREATED: 'Amostra criada',
                SAMPLE_UPDATED: 'Amostra alterada',
                SAMPLE_DELETED: 'Amostra removida'
            };
        }
    }, {
        key: 'sort',
        value: function sort(field) {
            cov_10il4bhnwi.f[2]++;
            cov_10il4bhnwi.s[3]++;

            if ((cov_10il4bhnwi.b[1][0]++, !this.notifications.$params.ordering) || (cov_10il4bhnwi.b[1][1]++, this.notifications.$params.ordering !== field)) {
                cov_10il4bhnwi.b[0][0]++;
                cov_10il4bhnwi.s[4]++;

                this.direction = '';
            } else {
                cov_10il4bhnwi.b[0][1]++;
                cov_10il4bhnwi.s[5]++;

                this.direction = this.direction === '-' ? (cov_10il4bhnwi.b[2][0]++, '') : (cov_10il4bhnwi.b[2][1]++, '-');
            }

            /* eslint-disable dot-notation */
            cov_10il4bhnwi.s[6]++;
            this.notifications.$params['ordering'] = this.direction + field;
            cov_10il4bhnwi.s[7]++;
            this.notifications.$refresh();
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_10il4bhnwi.f[3]++;
            cov_10il4bhnwi.s[8]++;

            if (angular.isArray(value)) {
                cov_10il4bhnwi.b[3][0]++;
                cov_10il4bhnwi.s[9]++;

                value = value.join(',');
            } else {
                cov_10il4bhnwi.b[3][1]++;
            }

            cov_10il4bhnwi.s[10]++;
            if (value === null) {
                cov_10il4bhnwi.b[4][0]++;
                cov_10il4bhnwi.s[11]++;

                delete this.filters[filter];
                cov_10il4bhnwi.s[12]++;
                delete this.notifications.$params[filter];
            } else {
                cov_10il4bhnwi.b[4][1]++;
                cov_10il4bhnwi.s[13]++;

                this.filters[filter] = value;
            }

            cov_10il4bhnwi.s[14]++;
            this.notifications.$page = 1;
            cov_10il4bhnwi.s[15]++;
            this._refreshnotifications();
        }
    }, {
        key: '_refreshnotifications',
        value: function _refreshnotifications() {
            cov_10il4bhnwi.f[4]++;
            cov_10il4bhnwi.s[16]++;

            this.notifications.$refresh(this.filters);
        }
    }]);

    return NotificationsViewController;
}();

cov_10il4bhnwi.s[17]++;


angular.module('app').component('notificationsView', {
    templateUrl: 'app/components/notificationsView/notificationsView.html',
    controller: NotificationsViewController,
    bindings: {
        notifications: '<'
    }
});