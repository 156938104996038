'use strict';

var cov_ih87yp1s4 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/notificationTemplatesDetailsView/notificationTemplatesDetailsView.js',
        hash = 'aacfd3e1ce479a6bc10bb0e553c086780c75ea25',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/notificationTemplatesDetailsView/notificationTemplatesDetailsView.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 9
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 12
                },
                end: {
                    line: 5,
                    column: 53
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 0
                },
                end: {
                    line: 18,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 20
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                }, {
                    start: {
                        line: 4,
                        column: 8
                    },
                    end: {
                        line: 6,
                        column: 9
                    }
                }],
                line: 4
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        f: {
            '0': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NotificationTemplatesDetailsViewController = function () {
    function NotificationTemplatesDetailsViewController() {
        _classCallCheck(this, NotificationTemplatesDetailsViewController);
    }

    _createClass(NotificationTemplatesDetailsViewController, [{
        key: 'autosave',
        value: function autosave(field) {
            cov_ih87yp1s4.f[0]++;
            cov_ih87yp1s4.s[0]++;

            if (this.notificationTemplate.id) {
                cov_ih87yp1s4.b[0][0]++;
                cov_ih87yp1s4.s[1]++;

                this.notificationTemplate.$save([field]);
            } else {
                cov_ih87yp1s4.b[0][1]++;
            }
        }
    }]);

    return NotificationTemplatesDetailsViewController;
}();

cov_ih87yp1s4.s[2]++;


angular.module('app').component('notificationTemplatesDetailsView', {
    templateUrl: 'app/components/notificationTemplatesDetailsView/notificationTemplatesDetailsView.html',
    controller: NotificationTemplatesDetailsViewController,
    bindings: {
        notificationTemplate: '<'
    }
});