'use strict';

var cov_20j3tg1fd = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/support/support.js',
        hash = '8b86777e3c4e70d6a68391d2db5564916a192dd1',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/support/support.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 25
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 31
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 31
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 72
                }
            },
            '5': {
                start: {
                    line: 15,
                    column: 20
                },
                end: {
                    line: 15,
                    column: 61
                }
            },
            '6': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 31
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 21,
                    column: 25
                },
                end: {
                    line: 21,
                    column: 41
                }
            },
            '9': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '10': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 31
                }
            },
            '11': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 38
                }
            },
            '12': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 45
                }
            },
            '14': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 28
                }
            },
            '15': {
                start: {
                    line: 36,
                    column: 8
                },
                end: {
                    line: 36,
                    column: 34
                }
            },
            '16': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 40,
                    column: 57
                }
            },
            '17': {
                start: {
                    line: 44,
                    column: 25
                },
                end: {
                    line: 44,
                    column: 41
                }
            },
            '18': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 11
                }
            },
            '19': {
                start: {
                    line: 46,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 31
                }
            },
            '20': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 56
                }
            },
            '21': {
                start: {
                    line: 51,
                    column: 27
                },
                end: {
                    line: 52,
                    column: 54
                }
            },
            '22': {
                start: {
                    line: 52,
                    column: 21
                },
                end: {
                    line: 52,
                    column: 53
                }
            },
            '23': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 38
                }
            },
            '24': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 35
                }
            },
            '25': {
                start: {
                    line: 65,
                    column: 25
                },
                end: {
                    line: 65,
                    column: 41
                }
            },
            '26': {
                start: {
                    line: 66,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 11
                }
            },
            '27': {
                start: {
                    line: 67,
                    column: 12
                },
                end: {
                    line: 67,
                    column: 31
                }
            },
            '28': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 33
                }
            },
            '29': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 51
                }
            },
            '30': {
                start: {
                    line: 77,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 49
                }
            },
            '31': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 77
                }
            },
            '32': {
                start: {
                    line: 81,
                    column: 26
                },
                end: {
                    line: 81,
                    column: 75
                }
            },
            '33': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 90,
                    column: 15
                }
            },
            '34': {
                start: {
                    line: 87,
                    column: 16
                },
                end: {
                    line: 87,
                    column: 66
                }
            },
            '35': {
                start: {
                    line: 88,
                    column: 16
                },
                end: {
                    line: 88,
                    column: 47
                }
            },
            '36': {
                start: {
                    line: 89,
                    column: 16
                },
                end: {
                    line: 89,
                    column: 40
                }
            },
            '37': {
                start: {
                    line: 94,
                    column: 25
                },
                end: {
                    line: 94,
                    column: 77
                }
            },
            '38': {
                start: {
                    line: 95,
                    column: 23
                },
                end: {
                    line: 110,
                    column: 9
                }
            },
            '39': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 111,
                    column: 35
                }
            },
            '40': {
                start: {
                    line: 115,
                    column: 25
                },
                end: {
                    line: 115,
                    column: 77
                }
            },
            '41': {
                start: {
                    line: 116,
                    column: 23
                },
                end: {
                    line: 131,
                    column: 9
                }
            },
            '42': {
                start: {
                    line: 134,
                    column: 8
                },
                end: {
                    line: 134,
                    column: 77
                }
            },
            '43': {
                start: {
                    line: 134,
                    column: 47
                },
                end: {
                    line: 134,
                    column: 75
                }
            },
            '44': {
                start: {
                    line: 138,
                    column: 25
                },
                end: {
                    line: 138,
                    column: 77
                }
            },
            '45': {
                start: {
                    line: 139,
                    column: 23
                },
                end: {
                    line: 154,
                    column: 9
                }
            },
            '46': {
                start: {
                    line: 155,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 35
                }
            },
            '47': {
                start: {
                    line: 159,
                    column: 23
                },
                end: {
                    line: 163,
                    column: 9
                }
            },
            '48': {
                start: {
                    line: 164,
                    column: 8
                },
                end: {
                    line: 164,
                    column: 29
                }
            },
            '49': {
                start: {
                    line: 168,
                    column: 22
                },
                end: {
                    line: 172,
                    column: 9
                }
            },
            '50': {
                start: {
                    line: 173,
                    column: 8
                },
                end: {
                    line: 173,
                    column: 33
                }
            },
            '51': {
                start: {
                    line: 178,
                    column: 0
                },
                end: {
                    line: 187,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 52
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 15
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 18
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 20
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 23,
                        column: 30
                    },
                    end: {
                        line: 23,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 36
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 23
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 56
                    },
                    end: {
                        line: 37,
                        column: 5
                    }
                },
                line: 31
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 39,
                        column: 4
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 39,
                        column: 14
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                line: 39
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 43,
                        column: 4
                    },
                    end: {
                        line: 43,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 18
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 43
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 45,
                        column: 30
                    },
                    end: {
                        line: 45,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 45,
                        column: 36
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                line: 45
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 24
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                line: 50
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 51,
                        column: 19
                    },
                    end: {
                        line: 51,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 27
                    },
                    end: {
                        line: 52,
                        column: 54
                    }
                },
                line: 51
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 52,
                        column: 12
                    },
                    end: {
                        line: 52,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 21
                    },
                    end: {
                        line: 52,
                        column: 53
                    }
                },
                line: 52
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 59,
                        column: 4
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 59,
                        column: 28
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 59
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 64,
                        column: 4
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 18
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 64
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 66,
                        column: 30
                    },
                    end: {
                        line: 66,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 36
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                },
                line: 66
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 44
                    },
                    end: {
                        line: 78,
                        column: 5
                    }
                },
                line: 74
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 80,
                        column: 4
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 33
                    },
                    end: {
                        line: 82,
                        column: 5
                    }
                },
                line: 80
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 81,
                        column: 18
                    },
                    end: {
                        line: 81,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 26
                    },
                    end: {
                        line: 81,
                        column: 75
                    }
                },
                line: 81
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 84,
                        column: 4
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 22
                    },
                    end: {
                        line: 91,
                        column: 5
                    }
                },
                line: 84
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 86,
                        column: 12
                    },
                    end: {
                        line: 86,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 86,
                        column: 24
                    },
                    end: {
                        line: 90,
                        column: 13
                    }
                },
                line: 86
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 93,
                        column: 4
                    },
                    end: {
                        line: 93,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 93,
                        column: 21
                    },
                    end: {
                        line: 112,
                        column: 5
                    }
                },
                line: 93
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 114,
                        column: 4
                    },
                    end: {
                        line: 114,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 20
                    },
                    end: {
                        line: 135,
                        column: 5
                    }
                },
                line: 114
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 134,
                        column: 40
                    },
                    end: {
                        line: 134,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 47
                    },
                    end: {
                        line: 134,
                        column: 75
                    }
                },
                line: 134
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 137,
                        column: 4
                    },
                    end: {
                        line: 137,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 23
                    },
                    end: {
                        line: 156,
                        column: 5
                    }
                },
                line: 137
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 158,
                        column: 4
                    },
                    end: {
                        line: 158,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 158,
                        column: 28
                    },
                    end: {
                        line: 165,
                        column: 5
                    }
                },
                line: 158
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 167,
                        column: 4
                    },
                    end: {
                        line: 167,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 167,
                        column: 21
                    },
                    end: {
                        line: 174,
                        column: 5
                    }
                },
                line: 167
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SupportTopicController = function () {
    function SupportTopicController(mdPanelRef, $sce, $filter, $window) {
        _classCallCheck(this, SupportTopicController);

        cov_20j3tg1fd.f[0]++;
        cov_20j3tg1fd.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_20j3tg1fd.s[1]++;
        this.$sce = $sce;
        cov_20j3tg1fd.s[2]++;
        this.$filter = $filter;
        cov_20j3tg1fd.s[3]++;
        this.$window = $window;
    }

    _createClass(SupportTopicController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_20j3tg1fd.f[1]++;
            cov_20j3tg1fd.s[4]++;

            this.trustedUrl = this.$sce.trustAsResourceUrl(this.topic.loom);
        }
    }, {
        key: 'download',
        value: function download() {
            cov_20j3tg1fd.f[2]++;

            var url = (cov_20j3tg1fd.s[5]++, this.$filter('mediaUrl')(this.topic.file));
            cov_20j3tg1fd.s[6]++;
            this.$window.open(url);
            cov_20j3tg1fd.s[7]++;
            this.closeDialog();
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_20j3tg1fd.f[3]++;

            var panelRef = (cov_20j3tg1fd.s[8]++, this._mdPanelRef);

            cov_20j3tg1fd.s[9]++;
            panelRef.close().then(function () {
                cov_20j3tg1fd.f[4]++;
                cov_20j3tg1fd.s[10]++;

                panelRef.destroy();
            });
        }
    }]);

    return SupportTopicController;
}();

var NewTicketController = function () {
    function NewTicketController(mdPanelRef, $rootScope, ProcessesTable) {
        _classCallCheck(this, NewTicketController);

        cov_20j3tg1fd.f[5]++;
        cov_20j3tg1fd.s[11]++;

        this._mdPanelRef = mdPanelRef;
        cov_20j3tg1fd.s[12]++;
        this.$rootScope = $rootScope;
        cov_20j3tg1fd.s[13]++;
        this.ProcessesTable = ProcessesTable;
        cov_20j3tg1fd.s[14]++;
        this.newTicket = {};
        cov_20j3tg1fd.s[15]++;
        this.newTicket.files = [];
    }

    _createClass(NewTicketController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_20j3tg1fd.f[6]++;
            cov_20j3tg1fd.s[16]++;

            this.processes = this.ProcessesTable.$search({});
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_20j3tg1fd.f[7]++;

            var panelRef = (cov_20j3tg1fd.s[17]++, this._mdPanelRef);
            cov_20j3tg1fd.s[18]++;
            panelRef.close().then(function () {
                cov_20j3tg1fd.f[8]++;
                cov_20j3tg1fd.s[19]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'uploadFiles',
        value: function uploadFiles($files) {
            var _this = this;

            cov_20j3tg1fd.f[9]++;
            cov_20j3tg1fd.s[20]++;

            $files.map(function (file) {
                cov_20j3tg1fd.f[10]++;
                cov_20j3tg1fd.s[21]++;
                return _this.$rootScope.$Media.upload(file).then(function (media) {
                    cov_20j3tg1fd.f[11]++;
                    cov_20j3tg1fd.s[22]++;
                    return _this.newTicket.files.push(media);
                });
            });
        }
    }]);

    return NewTicketController;
}();

var TicketController = function () {
    function TicketController(mdPanelRef) {
        _classCallCheck(this, TicketController);

        cov_20j3tg1fd.f[12]++;
        cov_20j3tg1fd.s[23]++;

        this._mdPanelRef = mdPanelRef;
        cov_20j3tg1fd.s[24]++;
        this.ticket.$load('files');
    }

    _createClass(TicketController, [{
        key: 'closeDialog',
        value: function closeDialog() {
            cov_20j3tg1fd.f[13]++;

            var panelRef = (cov_20j3tg1fd.s[25]++, this._mdPanelRef);
            cov_20j3tg1fd.s[26]++;
            panelRef.close().then(function () {
                cov_20j3tg1fd.f[14]++;
                cov_20j3tg1fd.s[27]++;

                panelRef.destroy();
            });
        }
    }]);

    return TicketController;
}();

var SupportController = function () {
    function SupportController($mdPanel, TicketFilesTable) {
        _classCallCheck(this, SupportController);

        cov_20j3tg1fd.f[15]++;
        cov_20j3tg1fd.s[28]++;

        this.$mdPanel = $mdPanel;
        cov_20j3tg1fd.s[29]++;
        this.addTicket = this.addTicket.bind(this);
        cov_20j3tg1fd.s[30]++;
        this.TicketFilesTable = TicketFilesTable;
    }

    _createClass(SupportController, [{
        key: 'createTicketFiles',
        value: function createTicketFiles(id, files) {
            var _this2 = this;

            cov_20j3tg1fd.f[16]++;
            cov_20j3tg1fd.s[31]++;

            files.map(function (file) {
                cov_20j3tg1fd.f[17]++;
                cov_20j3tg1fd.s[32]++;
                return _this2.TicketFilesTable.$create({ ticket: id, file: file });
            });
        }
    }, {
        key: 'addTicket',
        value: function addTicket(ticket) {
            var _this3 = this;

            cov_20j3tg1fd.f[18]++;
            cov_20j3tg1fd.s[33]++;

            this.tickets.$create(ticket).$then(function (response) {
                cov_20j3tg1fd.f[19]++;
                cov_20j3tg1fd.s[34]++;

                _this3.createTicketFiles(response.id, ticket.files);
                cov_20j3tg1fd.s[35]++;
                _this3.newTicketPanelRef.close();
                cov_20j3tg1fd.s[36]++;
                _this3.tickets.$refresh();
            });
        }
    }, {
        key: 'openTopic',
        value: function openTopic(topic) {
            cov_20j3tg1fd.f[20]++;

            var position = (cov_20j3tg1fd.s[37]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_20j3tg1fd.s[38]++, {
                /** @ngInject */
                controller: SupportTopicController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/support/topic.tmpl.html',
                hasBackdrop: true,
                panelClass: 'support-topic-panel',
                position: position,
                trapFocus: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    topic: topic
                }
            });
            cov_20j3tg1fd.s[39]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'openNewTicket',
        value: function openNewTicket() {
            var _this4 = this;

            cov_20j3tg1fd.f[21]++;

            var position = (cov_20j3tg1fd.s[40]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_20j3tg1fd.s[41]++, {
                /** @ngInject */
                controller: NewTicketController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/support/newTicket.tmpl.html',
                hasBackdrop: true,
                panelClass: 'ticket-panel',
                position: position,
                trapFocus: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    addTicket: this.addTicket
                }
            });
            /* eslint-disable no-return-assign */

            cov_20j3tg1fd.s[42]++;
            this.$mdPanel.open(config).then(function (ref) {
                cov_20j3tg1fd.f[22]++;
                cov_20j3tg1fd.s[43]++;
                return _this4.newTicketPanelRef = ref;
            });
        }
    }, {
        key: 'openTicket',
        value: function openTicket(ticket) {
            cov_20j3tg1fd.f[23]++;

            var position = (cov_20j3tg1fd.s[44]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_20j3tg1fd.s[45]++, {
                /** @ngInject */
                controller: TicketController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/support/ticket.tmpl.html',
                hasBackdrop: true,
                panelClass: 'ticket-panel',
                position: position,
                trapFocus: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    ticket: ticket
                }
            });
            cov_20j3tg1fd.s[46]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'ticketStateColor',
        value: function ticketStateColor(state) {
            cov_20j3tg1fd.f[24]++;

            var colors = (cov_20j3tg1fd.s[47]++, {
                NEW: '#CCCCCC',
                PROGRESS: '#00a1ff ', // #006382
                CLOSED: '#00FD57'
            });
            cov_20j3tg1fd.s[48]++;
            return colors[state];
        }
    }, {
        key: 'topicIcon',
        value: function topicIcon(topic) {
            cov_20j3tg1fd.f[25]++;

            var icons = (cov_20j3tg1fd.s[49]++, {
                TEXT: 'contact_support',
                PDF: 'description',
                VIDEO: 'ondemand_video'
            });
            cov_20j3tg1fd.s[50]++;
            return icons[topic.type];
        }
    }]);

    return SupportController;
}();

cov_20j3tg1fd.s[51]++;


angular.module('app').component('support', {
    templateUrl: 'app/components/support/support.html',
    controller: SupportController,
    bindings: {
        supportTopics: '<',
        tickets: '<'
    }
});