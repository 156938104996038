'use strict';

var cov_2gg5kleizn = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/offline/offline.js',
        hash = 'c7d8c5c3cd24b1c5f2d6bfa35c62dfd7e115ee9c',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/offline/offline.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 31
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 21
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 12
                },
                end: {
                    line: 16,
                    column: 14
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 20
                },
                end: {
                    line: 11,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 20
                },
                end: {
                    line: 14,
                    column: 42
                }
            },
            '5': {
                start: {
                    line: 21,
                    column: 0
                },
                end: {
                    line: 26,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 47
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 5,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 5,
                        column: 24
                    },
                    end: {
                        line: 17,
                        column: 9
                    }
                },
                line: 5
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 16
                    },
                    end: {
                        line: 10,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 22
                    },
                    end: {
                        line: 12,
                        column: 17
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 16
                    },
                    end: {
                        line: 13,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 22
                    },
                    end: {
                        line: 15,
                        column: 17
                    }
                },
                line: 13
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OfflineController = function OfflineController($interval, $http, AppSettings) {
    var _this = this;

    _classCallCheck(this, OfflineController);

    cov_2gg5kleizn.f[0]++;
    cov_2gg5kleizn.s[0]++;

    this.isOffline = false;

    cov_2gg5kleizn.s[1]++;
    $interval(function () {
        cov_2gg5kleizn.f[1]++;
        cov_2gg5kleizn.s[2]++;

        /* eslint-disable prefer-template */
        $http.get(AppSettings.apiUrl + '/api-status/', {
            timeout: 4500
        }).then(function () {
            cov_2gg5kleizn.f[2]++;
            cov_2gg5kleizn.s[3]++;

            _this.isOffline = false;
        }, function () {
            cov_2gg5kleizn.f[3]++;
            cov_2gg5kleizn.s[4]++;

            _this.isOffline = true;
        });
    }, 1000 * 5);
};

cov_2gg5kleizn.s[5]++;


angular.module('app').component('offline', {
    templateUrl: 'app/components/offline/offline.html',
    controller: OfflineController
});