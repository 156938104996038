'use strict';

var cov_28bu17e6op = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/contenteditable.directive.js',
        hash = '3ed1ff99b4fc4efd501e195cf6934848bc15a70b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/contenteditable.directive.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 27,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 3,
                    column: 4
                },
                end: {
                    line: 26,
                    column: 11
                }
            },
            '2': {
                start: {
                    line: 7,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 14
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 24
                },
                end: {
                    line: 13,
                    column: 62
                }
            },
            '4': {
                start: {
                    line: 14,
                    column: 24
                },
                end: {
                    line: 14,
                    column: 63
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 20
                },
                end: {
                    line: 19,
                    column: 22
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 24
                },
                end: {
                    line: 18,
                    column: 63
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 20
                },
                end: {
                    line: 23,
                    column: 23
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 24
                },
                end: {
                    line: 22,
                    column: 43
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 27,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 38
                    },
                    end: {
                        line: 6,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 50
                    },
                    end: {
                        line: 26,
                        column: 9
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 22
                    },
                    end: {
                        line: 11,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 64
                    },
                    end: {
                        line: 24,
                        column: 17
                    }
                },
                line: 11
            },
            '3': {
                name: 'read',
                decl: {
                    start: {
                        line: 12,
                        column: 29
                    },
                    end: {
                        line: 12,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 12,
                        column: 36
                    },
                    end: {
                        line: 15,
                        column: 21
                    }
                },
                line: 12
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 17,
                        column: 38
                    },
                    end: {
                        line: 17,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 50
                    },
                    end: {
                        line: 19,
                        column: 21
                    }
                },
                line: 17
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 21,
                        column: 54
                    },
                    end: {
                        line: 21,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 66
                    },
                    end: {
                        line: 23,
                        column: 21
                    }
                },
                line: 21
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 14,
                        column: 37
                    },
                    end: {
                        line: 14,
                        column: 61
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 14,
                        column: 37
                    },
                    end: {
                        line: 14,
                        column: 55
                    }
                }, {
                    start: {
                        line: 14,
                        column: 59
                    },
                    end: {
                        line: 14,
                        column: 61
                    }
                }],
                line: 14
            },
            '1': {
                loc: {
                    start: {
                        line: 18,
                        column: 37
                    },
                    end: {
                        line: 18,
                        column: 61
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 18,
                        column: 37
                    },
                    end: {
                        line: 18,
                        column: 55
                    }
                }, {
                    start: {
                        line: 18,
                        column: 59
                    },
                    end: {
                        line: 18,
                        column: 61
                    }
                }],
                line: 18
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_28bu17e6op.s[0]++;
(function () {
    'use strict';

    cov_28bu17e6op.f[0]++;
    cov_28bu17e6op.s[1]++;
    angular.module('app')
    /* eslint-disable prefer-arrow-callback */
    .directive("contenteditable", function () {
        cov_28bu17e6op.f[1]++;
        cov_28bu17e6op.s[2]++;

        return {
            restrict: "A",
            require: "ngModel",
            /* eslint-disable babel/object-shorthand */
            link: function link(scope, element, attrs, ngModel) {
                cov_28bu17e6op.f[2]++;

                function read() {
                    cov_28bu17e6op.f[3]++;
                    cov_28bu17e6op.s[3]++;

                    ngModel.$setViewValue(element.text());
                    cov_28bu17e6op.s[4]++;
                    element.text((cov_28bu17e6op.b[0][0]++, ngModel.$viewValue) || (cov_28bu17e6op.b[0][1]++, ""));
                }

                cov_28bu17e6op.s[5]++;
                ngModel.$render = function () {
                    cov_28bu17e6op.f[4]++;
                    cov_28bu17e6op.s[6]++;

                    element.text((cov_28bu17e6op.b[1][0]++, ngModel.$viewValue) || (cov_28bu17e6op.b[1][1]++, ""));
                };

                cov_28bu17e6op.s[7]++;
                element.bind("blur keyup change", function () {
                    cov_28bu17e6op.f[5]++;
                    cov_28bu17e6op.s[8]++;

                    scope.$apply(read);
                });
            }
        };
    });
})();