'use strict';

var cov_qc99k6j47 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentLogs/sampleDevelopmentLogs.js',
        hash = '78e0f04f5d358ebe89b05680bb5007f3035708ea',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentLogs/sampleDevelopmentLogs.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 55
                }
            },
            '4': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 53
                }
            },
            '5': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 51
                }
            },
            '6': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 42
                }
            },
            '7': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 24
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 55
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 9
                }
            },
            '10': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 80
                }
            },
            '11': {
                start: {
                    line: 22,
                    column: 25
                },
                end: {
                    line: 22,
                    column: 41
                }
            },
            '12': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 11
                }
            },
            '13': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 31
                }
            },
            '14': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 27
                }
            },
            '15': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 73
                }
            },
            '16': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 38,
                    column: 9
                }
            },
            '17': {
                start: {
                    line: 35,
                    column: 12
                },
                end: {
                    line: 35,
                    column: 74
                }
            },
            '18': {
                start: {
                    line: 37,
                    column: 12
                },
                end: {
                    line: 37,
                    column: 74
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 93
                }
            },
            '20': {
                start: {
                    line: 52,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 33
                }
            },
            '21': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 37
                }
            },
            '22': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 35
                }
            },
            '23': {
                start: {
                    line: 55,
                    column: 8
                },
                end: {
                    line: 55,
                    column: 41
                }
            },
            '24': {
                start: {
                    line: 59,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 41
                }
            },
            '25': {
                start: {
                    line: 60,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 64
                }
            },
            '26': {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 64,
                    column: 10
                }
            },
            '27': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 65,
                    column: 24
                }
            },
            '28': {
                start: {
                    line: 70,
                    column: 8
                },
                end: {
                    line: 70,
                    column: 36
                }
            },
            '29': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 58
                }
            },
            '30': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 9
                }
            },
            '31': {
                start: {
                    line: 76,
                    column: 12
                },
                end: {
                    line: 76,
                    column: 36
                }
            },
            '32': {
                start: {
                    line: 79,
                    column: 8
                },
                end: {
                    line: 84,
                    column: 9
                }
            },
            '33': {
                start: {
                    line: 80,
                    column: 12
                },
                end: {
                    line: 80,
                    column: 40
                }
            },
            '34': {
                start: {
                    line: 81,
                    column: 12
                },
                end: {
                    line: 81,
                    column: 62
                }
            },
            '35': {
                start: {
                    line: 83,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 41
                }
            },
            '36': {
                start: {
                    line: 86,
                    column: 8
                },
                end: {
                    line: 86,
                    column: 45
                }
            },
            '37': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 41
                }
            },
            '38': {
                start: {
                    line: 88,
                    column: 8
                },
                end: {
                    line: 88,
                    column: 45
                }
            },
            '39': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 24
                }
            },
            '40': {
                start: {
                    line: 93,
                    column: 25
                },
                end: {
                    line: 93,
                    column: 77
                }
            },
            '41': {
                start: {
                    line: 94,
                    column: 23
                },
                end: {
                    line: 109,
                    column: 9
                }
            },
            '42': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 110,
                    column: 35
                }
            },
            '43': {
                start: {
                    line: 114,
                    column: 0
                },
                end: {
                    line: 122,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 48
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 14
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 14
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 18
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 21
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 30
                    },
                    end: {
                        line: 23,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 36
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 25
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 28
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 24
                    },
                    end: {
                        line: 39,
                        column: 5
                    }
                },
                line: 33
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 41,
                        column: 4
                    },
                    end: {
                        line: 41,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 41,
                        column: 13
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 41
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 51,
                        column: 4
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 51,
                        column: 119
                    },
                    end: {
                        line: 56,
                        column: 5
                    }
                },
                line: 51
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 58,
                        column: 4
                    },
                    end: {
                        line: 58,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 14
                    },
                    end: {
                        line: 66,
                        column: 5
                    }
                },
                line: 58
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 68,
                        column: 4
                    },
                    end: {
                        line: 68,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 15
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 68
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 33
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                line: 74
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 92,
                        column: 4
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 23
                    },
                    end: {
                        line: 111,
                        column: 5
                    }
                },
                line: 92
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                }, {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                }],
                line: 16
            },
            '1': {
                loc: {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }, {
                    start: {
                        line: 34,
                        column: 8
                    },
                    end: {
                        line: 38,
                        column: 9
                    }
                }],
                line: 34
            },
            '2': {
                loc: {
                    start: {
                        line: 75,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 75,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                }, {
                    start: {
                        line: 75,
                        column: 8
                    },
                    end: {
                        line: 77,
                        column: 9
                    }
                }],
                line: 75
            },
            '3': {
                loc: {
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 84,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 84,
                        column: 9
                    }
                }, {
                    start: {
                        line: 79,
                        column: 8
                    },
                    end: {
                        line: 84,
                        column: 9
                    }
                }],
                line: 79
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleDevelopmentLogDialogController = function () {
    function SampleDevelopmentLogDialogController(mdPanelRef, $rootScope, $state) {
        _classCallCheck(this, SampleDevelopmentLogDialogController);

        cov_qc99k6j47.f[0]++;
        cov_qc99k6j47.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_qc99k6j47.s[1]++;
        this.$rootScope = $rootScope;
        cov_qc99k6j47.s[2]++;
        this.$state = $state;
        cov_qc99k6j47.s[3]++;
        this.closeDialog = this.closeDialog.bind(this);
        cov_qc99k6j47.s[4]++;
        this.closeAndGo = this.closeAndGo.bind(this);
        cov_qc99k6j47.s[5]++;
        this.showError = this.showError.bind(this);
        cov_qc99k6j47.s[6]++;
        this.newSampleDevelopmentLog = {};
        cov_qc99k6j47.s[7]++;
        this.error = '';
    }

    _createClass(SampleDevelopmentLogDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_qc99k6j47.f[1]++;
            cov_qc99k6j47.s[8]++;

            this.newSampleDevelopmentLog.date = new Date();
            cov_qc99k6j47.s[9]++;
            if (this.$rootScope.me.type === 'CONFECTION') {
                cov_qc99k6j47.b[0][0]++;
                cov_qc99k6j47.s[10]++;

                this.newSampleDevelopmentLog.confectionUser = this.$rootScope.me.id;
            } else {
                cov_qc99k6j47.b[0][1]++;
            }
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_qc99k6j47.f[2]++;

            var panelRef = (cov_qc99k6j47.s[11]++, this._mdPanelRef);
            cov_qc99k6j47.s[12]++;
            panelRef.close().then(function () {
                cov_qc99k6j47.f[3]++;
                cov_qc99k6j47.s[13]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'closeAndGo',
        value: function closeAndGo(response) {
            cov_qc99k6j47.f[4]++;
            cov_qc99k6j47.s[14]++;

            this.closeDialog();
            cov_qc99k6j47.s[15]++;
            this.$state.go('sampleDevelopmentLogDetails', { id: response.id });
        }
    }, {
        key: 'showError',
        value: function showError(response) {
            cov_qc99k6j47.f[5]++;
            cov_qc99k6j47.s[16]++;

            if (response.$response.data.non_field_errors[0] === 'The fields date, confection_overseer must make a unique set.') {
                cov_qc99k6j47.b[1][0]++;
                cov_qc99k6j47.s[17]++;

                this.error = 'Já existe registo para este dia e encarregada.';
            } else {
                cov_qc99k6j47.b[1][1]++;
                cov_qc99k6j47.s[18]++;

                this.error = 'Ocorreu um erro, por favor verifique os dados.';
            }
        }
    }, {
        key: 'create',
        value: function create() {
            cov_qc99k6j47.f[6]++;
            cov_qc99k6j47.s[19]++;

            this.sampleDevelopmentLogs.$create({
                /* eslint-disable camelcase */
                confection_overseer: this.newSampleDevelopmentLog.confectionUser,
                date: this.newSampleDevelopmentLog.date }).$then(this.closeAndGo, this.showError);
        }
    }]);

    return SampleDevelopmentLogDialogController;
}();

var SampleDevelopmentLogsController = function () {
    function SampleDevelopmentLogsController($rootScope, UsersTable, SampleDevelopmentCyclesTable, SamplesTable, $mdPanel, $location, $stateParams) {
        _classCallCheck(this, SampleDevelopmentLogsController);

        cov_qc99k6j47.f[7]++;
        cov_qc99k6j47.s[20]++;

        this.$mdPanel = $mdPanel;
        cov_qc99k6j47.s[21]++;
        this.UsersTable = UsersTable;
        cov_qc99k6j47.s[22]++;
        this.$location = $location;
        cov_qc99k6j47.s[23]++;
        this.$stateParams = $stateParams;
    }

    _createClass(SampleDevelopmentLogsController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_qc99k6j47.f[8]++;
            cov_qc99k6j47.s[24]++;

            this.filters = this.$stateParams;
            cov_qc99k6j47.s[25]++;
            this.userFilter = this.$stateParams.confection_overseer;
            cov_qc99k6j47.s[26]++;
            this.sampleDevelopmentLogs.$params = {
                /* eslint-disable camelcase */
                page_size: 25
            };
            cov_qc99k6j47.s[27]++;
            this._refresh();
        }
    }, {
        key: '_refresh',
        value: function _refresh() {
            cov_qc99k6j47.f[9]++;
            cov_qc99k6j47.s[28]++;

            /* eslint-disable camelcase */
            this.filters.page_size = 25;
            cov_qc99k6j47.s[29]++;
            this.sampleDevelopmentLogs.$refresh(this.filters);
        }
    }, {
        key: 'changeFilters',
        value: function changeFilters(filter, value) {
            cov_qc99k6j47.f[10]++;
            cov_qc99k6j47.s[30]++;

            if (angular.isArray(value)) {
                cov_qc99k6j47.b[2][0]++;
                cov_qc99k6j47.s[31]++;

                value = value.join(',');
            } else {
                cov_qc99k6j47.b[2][1]++;
            }

            cov_qc99k6j47.s[32]++;
            if (value === null) {
                cov_qc99k6j47.b[3][0]++;
                cov_qc99k6j47.s[33]++;

                delete this.filters[filter];
                cov_qc99k6j47.s[34]++;
                delete this.sampleDevelopmentLogs.$params[filter];
            } else {
                cov_qc99k6j47.b[3][1]++;
                cov_qc99k6j47.s[35]++;

                this.filters[filter] = value;
            }

            cov_qc99k6j47.s[36]++;
            this.sampleDevelopmentLogs.$page = 1;
            cov_qc99k6j47.s[37]++;
            this.$location.search('page', 1);
            cov_qc99k6j47.s[38]++;
            this.$location.search(filter, value);
            cov_qc99k6j47.s[39]++;
            this._refresh();
        }
    }, {
        key: 'showCreateDialog',
        value: function showCreateDialog() {
            cov_qc99k6j47.f[11]++;

            var position = (cov_qc99k6j47.s[40]++, this.$mdPanel.newPanelPosition().absolute().center());
            var config = (cov_qc99k6j47.s[41]++, {
                /** @ngInject */
                controller: SampleDevelopmentLogDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/sampleDevelopmentLogs/newSampleDevelopmentLog.tmpl.html',
                hasBackdrop: true,
                panelClass: 'prices-field-updates-dialog',
                position: position,
                trapFocus: true,
                zIndex: 50,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    sampleDevelopmentLogs: this.sampleDevelopmentLogs
                }
            });
            cov_qc99k6j47.s[42]++;
            this.$mdPanel.open(config);
        }
    }]);

    return SampleDevelopmentLogsController;
}();

cov_qc99k6j47.s[43]++;


angular.module('app').component('sampleDevelopmentLogs', {
    templateUrl: 'app/components/sampleDevelopmentLogs/sampleDevelopmentLogs.html',
    controller: SampleDevelopmentLogsController,
    bindings: {
        sampleDevelopmentLogs: '<'
    }
});