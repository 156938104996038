'use strict';

var cov_13g0zdyaqf = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/samplePriceChecksDisplay/samplePriceChecksDisplay.js',
        hash = 'eed274a2add73be1000bbd352ccfbc89a2cf5fd3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/samplePriceChecksDisplay/samplePriceChecksDisplay.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 10,
                    column: 10
                }
            },
            '1': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 36
                }
            },
            '3': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 43,
                    column: 12
                },
                end: {
                    line: 46,
                    column: 15
                }
            },
            '5': {
                start: {
                    line: 44,
                    column: 16
                },
                end: {
                    line: 44,
                    column: 52
                }
            },
            '6': {
                start: {
                    line: 45,
                    column: 16
                },
                end: {
                    line: 45,
                    column: 28
                }
            },
            '7': {
                start: {
                    line: 51,
                    column: 0
                },
                end: {
                    line: 59,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 14
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 40
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 42,
                        column: 52
                    },
                    end: {
                        line: 42,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 42,
                        column: 62
                    },
                    end: {
                        line: 47,
                        column: 9
                    }
                },
                line: 42
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 43,
                        column: 36
                    },
                    end: {
                        line: 43,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 43,
                        column: 45
                    },
                    end: {
                        line: 46,
                        column: 13
                    }
                },
                line: 43
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SamplePriceChecksDisplayController = function () {
    function SamplePriceChecksDisplayController() {
        _classCallCheck(this, SamplePriceChecksDisplayController);

        cov_13g0zdyaqf.f[0]++;
        cov_13g0zdyaqf.s[0]++;

        this.validations = {
            ACCESSORIES_PRICES: false,
            ACCESSORIES_QUANTITIES: false,
            CONFECTION_PRICES: false,
            EXTERNAL_SERVICES: false,
            COMMERCIAL: false
        };
        cov_13g0zdyaqf.s[1]++;
        this.samplePriceCheckTypes = [{
            id: 'ACCESSORIES_PRICES',
            name: 'Preços Acessórios',
            groups: ['ACCESSORIES']
        }, {
            id: 'ACCESSORIES_QUANTITIES',
            name: 'Quantidade Acessórios',
            groups: ['ACCESSORIES']
        }, {
            id: 'CONFECTION_PRICES',
            name: 'Preços Confeção',
            groups: ['CONTROL']
        }, {
            id: 'EXTERNAL_SERVICES',
            name: 'Serviços Externos',
            groups: ['CONTROL']
        }, {
            id: 'COMMERCIAL',
            name: 'Comercial',
            groups: ['CONTROL']
        }];
    }

    _createClass(SamplePriceChecksDisplayController, [{
        key: '$onInit',
        value: function $onInit() {
            var _this = this;

            cov_13g0zdyaqf.f[1]++;
            cov_13g0zdyaqf.s[2]++;

            this.sample.$load('checks');
            cov_13g0zdyaqf.s[3]++;
            this.sample.$checks.$on('after-fetch-many', function (checks) {
                cov_13g0zdyaqf.f[2]++;
                cov_13g0zdyaqf.s[4]++;

                checks.data.results.map(function (check) {
                    cov_13g0zdyaqf.f[3]++;
                    cov_13g0zdyaqf.s[5]++;

                    _this.validations[check.type] = true;
                    cov_13g0zdyaqf.s[6]++;
                    return null;
                });
            });
        }
    }]);

    return SamplePriceChecksDisplayController;
}();

cov_13g0zdyaqf.s[7]++;


angular.module('app').component('samplePriceChecksDisplay', {
    templateUrl: 'app/components/samplePriceChecksDisplay/samplePriceChecksDisplay.html',
    controller: SamplePriceChecksDisplayController,
    bindings: {
        sample: '<'
    }
});