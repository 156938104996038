'use strict';

var cov_1vf0uqow8h = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/pricesTable/pricesTable.js',
        hash = '97071a99a88e2ad865b6132c3f6ac4bb5cda8388',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/pricesTable/pricesTable.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 24
                },
                end: {
                    line: 4,
                    column: 64
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 9
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 12
                },
                end: {
                    line: 8,
                    column: 13
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 16
                },
                end: {
                    line: 7,
                    column: 41
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 10,
                    column: 16
                },
                end: {
                    line: 10,
                    column: 64
                }
            },
            '6': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 13
                }
            },
            '7': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 13,
                    column: 70
                }
            },
            '8': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 16,
                    column: 16
                },
                end: {
                    line: 16,
                    column: 93
                }
            },
            '10': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 20
                }
            },
            '11': {
                start: {
                    line: 24,
                    column: 0
                },
                end: {
                    line: 34,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 30
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 5,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 5,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                }, {
                    start: {
                        line: 5,
                        column: 8
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                }],
                line: 5
            },
            '1': {
                loc: {
                    start: {
                        line: 6,
                        column: 12
                    },
                    end: {
                        line: 8,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 6,
                        column: 12
                    },
                    end: {
                        line: 8,
                        column: 13
                    }
                }, {
                    start: {
                        line: 6,
                        column: 12
                    },
                    end: {
                        line: 8,
                        column: 13
                    }
                }],
                line: 6
            },
            '2': {
                loc: {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }, {
                    start: {
                        line: 9,
                        column: 12
                    },
                    end: {
                        line: 11,
                        column: 13
                    }
                }],
                line: 9
            },
            '3': {
                loc: {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }, {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }],
                line: 12
            },
            '4': {
                loc: {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }, {
                    start: {
                        line: 15,
                        column: 12
                    },
                    end: {
                        line: 17,
                        column: 13
                    }
                }],
                line: 15
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        f: {
            '0': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PricesTableController = function () {
    function PricesTableController() {
        _classCallCheck(this, PricesTableController);
    }

    _createClass(PricesTableController, [{
        key: 'getItemTotal',
        value: function getItemTotal(samplePrice) {
            cov_1vf0uqow8h.f[0]++;

            var formula = (cov_1vf0uqow8h.s[0]++, samplePrice.$sampleItemPriceType.formula);
            cov_1vf0uqow8h.s[1]++;
            if (formula) {
                cov_1vf0uqow8h.b[0][0]++;
                cov_1vf0uqow8h.s[2]++;

                if (formula === 'P') {
                    cov_1vf0uqow8h.b[1][0]++;
                    cov_1vf0uqow8h.s[3]++;

                    return samplePrice.price;
                } else {
                    cov_1vf0uqow8h.b[1][1]++;
                }
                cov_1vf0uqow8h.s[4]++;
                if (formula === 'QP') {
                    cov_1vf0uqow8h.b[2][0]++;
                    cov_1vf0uqow8h.s[5]++;

                    return samplePrice.quantity * samplePrice.price;
                } else {
                    cov_1vf0uqow8h.b[2][1]++;
                }
                cov_1vf0uqow8h.s[6]++;
                if (formula === 'LP') {
                    cov_1vf0uqow8h.b[3][0]++;
                    cov_1vf0uqow8h.s[7]++;

                    return samplePrice.length * (samplePrice.price / 100);
                } else {
                    cov_1vf0uqow8h.b[3][1]++;
                }
                cov_1vf0uqow8h.s[8]++;
                if (formula === 'QLP') {
                    cov_1vf0uqow8h.b[4][0]++;
                    cov_1vf0uqow8h.s[9]++;

                    return samplePrice.quantity * samplePrice.length * (samplePrice.price / 100);
                } else {
                    cov_1vf0uqow8h.b[4][1]++;
                }
            } else {
                cov_1vf0uqow8h.b[0][1]++;
            }
            cov_1vf0uqow8h.s[10]++;
            return null;
        }
    }]);

    return PricesTableController;
}();

cov_1vf0uqow8h.s[11]++;


angular.module('app').component('pricesTable', {
    templateUrl: 'app/components/pricesTable/pricesTable.html',
    controller: PricesTableController,
    bindings: {
        editable: '<',
        prices: '<',
        callback: '&'
    }
});