'use strict';

var cov_20hyu7os4p = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/finishingsForeseenDate/finishingsForeseenDate.js',
        hash = 'd2d74ff6e782ed2674a1acf25cc0159b8024b42e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/finishingsForeseenDate/finishingsForeseenDate.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 9,
                    column: 25
                },
                end: {
                    line: 9,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 12
                },
                end: {
                    line: 11,
                    column: 31
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 22
                },
                end: {
                    line: 16,
                    column: 46
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 38
                }
            },
            '7': {
                start: {
                    line: 18,
                    column: 18
                },
                end: {
                    line: 18,
                    column: 36
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 22
                },
                end: {
                    line: 22,
                    column: 46
                }
            },
            '9': {
                start: {
                    line: 23,
                    column: 22
                },
                end: {
                    line: 23,
                    column: 49
                }
            },
            '10': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 38
                }
            },
            '11': {
                start: {
                    line: 25,
                    column: 18
                },
                end: {
                    line: 25,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 33
                }
            },
            '13': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 77
                }
            },
            '14': {
                start: {
                    line: 37,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 33
                }
            },
            '15': {
                start: {
                    line: 38,
                    column: 23
                },
                end: {
                    line: 55,
                    column: 9
                }
            },
            '16': {
                start: {
                    line: 56,
                    column: 8
                },
                end: {
                    line: 56,
                    column: 35
                }
            },
            '17': {
                start: {
                    line: 60,
                    column: 0
                },
                end: {
                    line: 69,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 18
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 30
                    },
                    end: {
                        line: 10,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 36
                    },
                    end: {
                        line: 12,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 16
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 18,
                        column: 12
                    },
                    end: {
                        line: 18,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 18
                    },
                    end: {
                        line: 18,
                        column: 36
                    }
                },
                line: 18
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 21,
                        column: 4
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 15
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 21
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 25,
                        column: 12
                    },
                    end: {
                        line: 25,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 25,
                        column: 18
                    },
                    end: {
                        line: 25,
                        column: 36
                    }
                },
                line: 25
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 26
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 31
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 36,
                        column: 4
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 36,
                        column: 23
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                line: 36
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FinishingsForeseenDateDialogController = function () {
    function FinishingsForeseenDateDialogController(mdPanelRef) {
        _classCallCheck(this, FinishingsForeseenDateDialogController);

        cov_20hyu7os4p.f[0]++;
        cov_20hyu7os4p.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_20hyu7os4p.s[1]++;
        this.saveDate = this.saveDate.bind(this);
    }

    _createClass(FinishingsForeseenDateDialogController, [{
        key: 'closeDialog',
        value: function closeDialog() {
            cov_20hyu7os4p.f[1]++;

            var panelRef = (cov_20hyu7os4p.s[2]++, this._mdPanelRef);
            cov_20hyu7os4p.s[3]++;
            panelRef.close().then(function () {
                cov_20hyu7os4p.f[2]++;
                cov_20hyu7os4p.s[4]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'clearDate',
        value: function clearDate() {
            var _this = this;

            cov_20hyu7os4p.f[3]++;

            var field = (cov_20hyu7os4p.s[5]++, 'finishingsForeseenDate');
            cov_20hyu7os4p.s[6]++;
            this.updateField(field, null).$then(function () {
                cov_20hyu7os4p.f[4]++;
                cov_20hyu7os4p.s[7]++;
                return _this.closeDialog();
            });
        }
    }, {
        key: 'saveDate',
        value: function saveDate() {
            var _this2 = this;

            cov_20hyu7os4p.f[5]++;

            var field = (cov_20hyu7os4p.s[8]++, 'finishingsForeseenDate');
            var value = (cov_20hyu7os4p.s[9]++, this.finishingsForeseenDate);
            cov_20hyu7os4p.s[10]++;
            this.updateField(field, value).$then(function () {
                cov_20hyu7os4p.f[6]++;
                cov_20hyu7os4p.s[11]++;
                return _this2.closeDialog();
            });
        }
    }]);

    return FinishingsForeseenDateDialogController;
}();

var FinishingsForeseenDateController = function () {
    function FinishingsForeseenDateController($mdPanel) {
        _classCallCheck(this, FinishingsForeseenDateController);

        cov_20hyu7os4p.f[7]++;
        cov_20hyu7os4p.s[12]++;

        this.$mdPanel = $mdPanel;
        cov_20hyu7os4p.s[13]++;
        this.position = this.$mdPanel.newPanelPosition().absolute().center();
    }

    _createClass(FinishingsForeseenDateController, [{
        key: 'openDialog',
        value: function openDialog($event) {
            cov_20hyu7os4p.f[8]++;
            cov_20hyu7os4p.s[14]++;

            $event.stopPropagation();
            var config = (cov_20hyu7os4p.s[15]++, {
                /** @ngInject */
                controller: FinishingsForeseenDateDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/finishingsForeseenDate/finishingsForeseenDateDialog.html',
                hasBackdrop: true,
                panelClass: 'announcement-dialog',
                position: this.position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    updateField: this.updateField,
                    finishingsForeseenDate: this.finishingsForeseenDate
                }
            });
            cov_20hyu7os4p.s[16]++;
            this.$mdPanel.open(config);
        }
    }]);

    return FinishingsForeseenDateController;
}();

cov_20hyu7os4p.s[17]++;


angular.module('app').component('finishingsForeseenDate', {
    controller: FinishingsForeseenDateController,
    templateUrl: 'app/components/finishingsForeseenDate/finishingsForeseenDate.html',
    bindings: {
        finishingsForeseenDate: '<',
        updateField: '<'
    }
});