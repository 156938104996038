'use strict';

var cov_25glq8lplw = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentCycles/sampleDevelopmentCycles.js',
        hash = '8899629f680e1cc721c85b6c176d0ec789e6a2bd',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleDevelopmentCycles/sampleDevelopmentCycles.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 84
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 0
                },
                end: {
                    line: 16,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 14
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleDevelopmentCyclesController = function () {
    function SampleDevelopmentCyclesController() {
        _classCallCheck(this, SampleDevelopmentCyclesController);
    }

    _createClass(SampleDevelopmentCyclesController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_25glq8lplw.f[0]++;
            cov_25glq8lplw.s[0]++;

            this.sampleDevelopmentCycles = this.sample.$load('sampleDevelopmentCycles');
        }
    }]);

    return SampleDevelopmentCyclesController;
}();

cov_25glq8lplw.s[1]++;


angular.module('app').component('sampleDevelopmentCycles', {
    templateUrl: 'app/components/sampleDevelopmentCycles/sampleDevelopmentCycles.html',
    controller: SampleDevelopmentCyclesController,
    bindings: {
        sample: '<'
    }
});