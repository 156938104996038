'use strict';

var cov_495fq9cbp = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/hasMesh/hasMesh.js',
        hash = 'e62eb59a700112588f161ad790c84089eb4f685e',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/hasMesh/hasMesh.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 22
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 43
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 0
                },
                end: {
                    line: 19,
                    column: 3
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 19
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                line: 3
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        f: {
            '0': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HasMeshController = function () {
    function HasMeshController() {
        _classCallCheck(this, HasMeshController);
    }

    _createClass(HasMeshController, [{
        key: 'toggle',
        value: function toggle($event) {
            cov_495fq9cbp.f[0]++;
            cov_495fq9cbp.s[0]++;

            $event.stopPropagation();
            var value = (cov_495fq9cbp.s[1]++, !this.hasMesh);
            cov_495fq9cbp.s[2]++;
            this.updateField('hasMesh', value);
        }
    }]);

    return HasMeshController;
}();

cov_495fq9cbp.s[3]++;


angular.module('app').component('hasMesh', {
    controller: HasMeshController,
    templateUrl: 'app/components/hasMesh/hasMesh.html',
    bindings: {
        hasMesh: '<',
        updateField: '<'
    }
});