'use strict';

var cov_g2w7whxmy = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/priceTileOverview/priceTileOverview.js',
        hash = '4db653d56605e61500e51595ef5d1e153e5ab6d6',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/priceTileOverview/priceTileOverview.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_g2w7whxmy.s[0]++;
angular.module('app').component('priceTileOverview', {
    templateUrl: 'app/components/priceTileOverview/priceTileOverview.html',
    bindings: {
        sample: '<'
    }
});