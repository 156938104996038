'use strict';

var cov_21jjuba154 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/notificationsList/notificationsList.js',
        hash = '673f039e1bdf4775f1148f5f0bff3e3eabfe9a11',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/notificationsList/notificationsList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 11
                }
            },
            '1': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 22
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 40
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 40
                }
            },
            '4': {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 35
                }
            },
            '5': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 29
                }
            },
            '6': {
                start: {
                    line: 23,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 25,
                    column: 16
                },
                end: {
                    line: 25,
                    column: 43
                }
            },
            '9': {
                start: {
                    line: 26,
                    column: 16
                },
                end: {
                    line: 26,
                    column: 37
                }
            },
            '10': {
                start: {
                    line: 32,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 27
                }
            },
            '11': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 37,
                    column: 0
                },
                end: {
                    line: 43,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 59
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 11,
                        column: 18
                    },
                    end: {
                        line: 11,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 24
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                },
                line: 11
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 17,
                        column: 4
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 29
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 17
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 22,
                        column: 4
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 22,
                        column: 18
                    },
                    end: {
                        line: 29,
                        column: 5
                    }
                },
                line: 22
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 23,
                        column: 35
                    },
                    end: {
                        line: 23,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 51
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 23
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 31,
                        column: 4
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 31,
                        column: 16
                    },
                    end: {
                        line: 34,
                        column: 5
                    }
                },
                line: 31
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 27,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 27,
                        column: 13
                    }
                }, {
                    start: {
                        line: 24,
                        column: 12
                    },
                    end: {
                        line: 27,
                        column: 13
                    }
                }],
                line: 24
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NotificationsListController = function () {
    function NotificationsListController(NotificationsTable, $interval, $rootScope) {
        var _this = this;

        _classCallCheck(this, NotificationsListController);

        cov_21jjuba154.f[0]++;
        cov_21jjuba154.s[0]++;

        /* eslint-disable camelcase */
        this.notifications = $rootScope.notifications = NotificationsTable.$search({
            kind: 'REGULAR',
            is_open: false,
            page_size: 99999,
            ordering: '-created'
        });

        cov_21jjuba154.s[1]++;
        $interval(function () {
            cov_21jjuba154.f[1]++;
            cov_21jjuba154.s[2]++;

            _this.notifications.$clear();
            cov_21jjuba154.s[3]++;
            _this.notifications.$fetch();
        }, 30 * 1000);
    }

    _createClass(NotificationsListController, [{
        key: 'markAsRead',
        value: function markAsRead(notification) {
            cov_21jjuba154.f[2]++;
            cov_21jjuba154.s[4]++;

            notification.isRead = true;
            cov_21jjuba154.s[5]++;
            notification.$save();
        }
    }, {
        key: 'markAllOpen',
        value: function markAllOpen() {
            cov_21jjuba154.f[3]++;
            cov_21jjuba154.s[6]++;

            this.notifications.forEach(function (notification) {
                cov_21jjuba154.f[4]++;
                cov_21jjuba154.s[7]++;

                if (!notification.isOpen) {
                    cov_21jjuba154.b[0][0]++;
                    cov_21jjuba154.s[8]++;

                    notification.isOpen = true;
                    cov_21jjuba154.s[9]++;
                    notification.$save();
                } else {
                    cov_21jjuba154.b[0][1]++;
                }
            });
        }
    }, {
        key: 'clearList',
        value: function clearList() {
            cov_21jjuba154.f[5]++;
            cov_21jjuba154.s[10]++;

            this.markAllOpen();
            cov_21jjuba154.s[11]++;
            this.notifications.$clear();
        }
    }]);

    return NotificationsListController;
}();

cov_21jjuba154.s[12]++;


angular.module('app').component('notificationsList', {
    templateUrl: 'app/components/notificationsList/notificationsList.html',
    controller: NotificationsListController,
    bindings: {}
});