'use strict';

var cov_cmsb62gzk = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardUsers.js',
        hash = '8490af2dd0e04a808b24984348b2ecb5def932a8',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardUsers.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 0
                },
                end: {
                    line: 8,
                    column: 7
                }
            }
        },
        fnMap: {},
        branchMap: {},
        s: {
            '0': 0
        },
        f: {},
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardUsersController = function DashboardUsersController() {
    _classCallCheck(this, DashboardUsersController);
};

cov_cmsb62gzk.s[0]++;


angular.module('app').component('dashboardUsers', {
    templateUrl: 'app/components/dashboard/dashboardUsers.html',
    controller: DashboardUsersController
});