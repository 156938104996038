'use strict';

var cov_yyjrtjebc = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/startsWith.filter.js',
        hash = '34c5a8c35b3bf608f0ed9a0c3194659e4007ef6d',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/startsWith.filter.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 20,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 42
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 18,
                    column: 10
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 25
                },
                end: {
                    line: 16,
                    column: 14
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 16
                },
                end: {
                    line: 14,
                    column: 17
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 20
                },
                end: {
                    line: 13,
                    column: 58
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 16
                },
                end: {
                    line: 15,
                    column: 29
                }
            },
            '7': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 17,
                    column: 24
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 20,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'startsWith',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 26
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 15
                    },
                    end: {
                        line: 10,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 47
                    },
                    end: {
                        line: 18,
                        column: 9
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 11,
                        column: 37
                    },
                    end: {
                        line: 11,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 44
                    },
                    end: {
                        line: 16,
                        column: 13
                    }
                },
                line: 11
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 14,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 14,
                        column: 17
                    }
                }, {
                    start: {
                        line: 12,
                        column: 16
                    },
                    end: {
                        line: 14,
                        column: 17
                    }
                }],
                line: 12
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_yyjrtjebc.s[0]++;
(function () {
    'use strict';

    cov_yyjrtjebc.f[0]++;
    cov_yyjrtjebc.s[1]++;
    angular.module('app').filter('startsWith', startsWith);

    /* @ngInject */
    function startsWith() {
        cov_yyjrtjebc.f[1]++;
        cov_yyjrtjebc.s[2]++;

        return function (list, field, pattern) {
            cov_yyjrtjebc.f[2]++;

            var objs = (cov_yyjrtjebc.s[3]++, list.filter(function (obj) {
                cov_yyjrtjebc.f[3]++;
                cov_yyjrtjebc.s[4]++;

                if (obj[field]) {
                    cov_yyjrtjebc.b[0][0]++;
                    cov_yyjrtjebc.s[5]++;

                    return obj[field].startsWith(pattern);
                } else {
                    cov_yyjrtjebc.b[0][1]++;
                }
                cov_yyjrtjebc.s[6]++;
                return false;
            }));
            cov_yyjrtjebc.s[7]++;
            return objs;
        };
    }
})();