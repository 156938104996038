'use strict';

var cov_23o4tmu656 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/index.js',
        hash = 'b5d6dc0380f896dacc21a6688e0167e35a30bb74',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/index.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 113,
                    column: 7
                }
            },
            '1': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 32,
                    column: 10
                }
            },
            '2': {
                start: {
                    line: 31,
                    column: 12
                },
                end: {
                    line: 31,
                    column: 67
                }
            },
            '3': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 37,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 35,
                    column: 22
                },
                end: {
                    line: 35,
                    column: 60
                }
            },
            '5': {
                start: {
                    line: 36,
                    column: 12
                },
                end: {
                    line: 36,
                    column: 60
                }
            },
            '6': {
                start: {
                    line: 40,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 11
                }
            },
            '7': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 109,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 110,
                    column: 8
                },
                end: {
                    line: 112,
                    column: 37
                }
            },
            '9': {
                start: {
                    line: 117,
                    column: 4
                },
                end: {
                    line: 117,
                    column: 41
                }
            },
            '10': {
                start: {
                    line: 118,
                    column: 4
                },
                end: {
                    line: 118,
                    column: 35
                }
            },
            '11': {
                start: {
                    line: 120,
                    column: 4
                },
                end: {
                    line: 122,
                    column: 6
                }
            },
            '12': {
                start: {
                    line: 121,
                    column: 8
                },
                end: {
                    line: 121,
                    column: 41
                }
            },
            '13': {
                start: {
                    line: 124,
                    column: 4
                },
                end: {
                    line: 126,
                    column: 6
                }
            },
            '14': {
                start: {
                    line: 125,
                    column: 8
                },
                end: {
                    line: 125,
                    column: 42
                }
            },
            '15': {
                start: {
                    line: 128,
                    column: 4
                },
                end: {
                    line: 130,
                    column: 6
                }
            },
            '16': {
                start: {
                    line: 129,
                    column: 8
                },
                end: {
                    line: 129,
                    column: 31
                }
            },
            '17': {
                start: {
                    line: 132,
                    column: 4
                },
                end: {
                    line: 135,
                    column: 6
                }
            },
            '18': {
                start: {
                    line: 133,
                    column: 32
                },
                end: {
                    line: 133,
                    column: 58
                }
            },
            '19': {
                start: {
                    line: 134,
                    column: 8
                },
                end: {
                    line: 134,
                    column: 72
                }
            },
            '20': {
                start: {
                    line: 138,
                    column: 4
                },
                end: {
                    line: 141,
                    column: 6
                }
            },
            '21': {
                start: {
                    line: 139,
                    column: 23
                },
                end: {
                    line: 139,
                    column: 78
                }
            },
            '22': {
                start: {
                    line: 139,
                    column: 58
                },
                end: {
                    line: 139,
                    column: 77
                }
            },
            '23': {
                start: {
                    line: 140,
                    column: 8
                },
                end: {
                    line: 140,
                    column: 55
                }
            },
            '24': {
                start: {
                    line: 140,
                    column: 35
                },
                end: {
                    line: 140,
                    column: 53
                }
            },
            '25': {
                start: {
                    line: 143,
                    column: 4
                },
                end: {
                    line: 143,
                    column: 38
                }
            },
            '26': {
                start: {
                    line: 145,
                    column: 4
                },
                end: {
                    line: 208,
                    column: 6
                }
            },
            '27': {
                start: {
                    line: 147,
                    column: 8
                },
                end: {
                    line: 147,
                    column: 104
                }
            },
            '28': {
                start: {
                    line: 148,
                    column: 8
                },
                end: {
                    line: 148,
                    column: 90
                }
            },
            '29': {
                start: {
                    line: 149,
                    column: 8
                },
                end: {
                    line: 149,
                    column: 110
                }
            },
            '30': {
                start: {
                    line: 150,
                    column: 8
                },
                end: {
                    line: 150,
                    column: 100
                }
            },
            '31': {
                start: {
                    line: 151,
                    column: 8
                },
                end: {
                    line: 155,
                    column: 11
                }
            },
            '32': {
                start: {
                    line: 156,
                    column: 8
                },
                end: {
                    line: 156,
                    column: 98
                }
            },
            '33': {
                start: {
                    line: 157,
                    column: 8
                },
                end: {
                    line: 157,
                    column: 87
                }
            },
            '34': {
                start: {
                    line: 158,
                    column: 8
                },
                end: {
                    line: 158,
                    column: 92
                }
            },
            '35': {
                start: {
                    line: 159,
                    column: 8
                },
                end: {
                    line: 159,
                    column: 96
                }
            },
            '36': {
                start: {
                    line: 160,
                    column: 8
                },
                end: {
                    line: 160,
                    column: 125
                }
            },
            '37': {
                start: {
                    line: 161,
                    column: 8
                },
                end: {
                    line: 161,
                    column: 88
                }
            },
            '38': {
                start: {
                    line: 162,
                    column: 8
                },
                end: {
                    line: 162,
                    column: 89
                }
            },
            '39': {
                start: {
                    line: 163,
                    column: 8
                },
                end: {
                    line: 163,
                    column: 103
                }
            },
            '40': {
                start: {
                    line: 164,
                    column: 8
                },
                end: {
                    line: 164,
                    column: 115
                }
            },
            '41': {
                start: {
                    line: 165,
                    column: 8
                },
                end: {
                    line: 165,
                    column: 75
                }
            },
            '42': {
                start: {
                    line: 166,
                    column: 8
                },
                end: {
                    line: 166,
                    column: 89
                }
            },
            '43': {
                start: {
                    line: 167,
                    column: 8
                },
                end: {
                    line: 167,
                    column: 105
                }
            },
            '44': {
                start: {
                    line: 168,
                    column: 8
                },
                end: {
                    line: 168,
                    column: 114
                }
            },
            '45': {
                start: {
                    line: 169,
                    column: 8
                },
                end: {
                    line: 169,
                    column: 122
                }
            },
            '46': {
                start: {
                    line: 170,
                    column: 8
                },
                end: {
                    line: 170,
                    column: 79
                }
            },
            '47': {
                start: {
                    line: 172,
                    column: 8
                },
                end: {
                    line: 172,
                    column: 90
                }
            },
            '48': {
                start: {
                    line: 173,
                    column: 8
                },
                end: {
                    line: 173,
                    column: 84
                }
            },
            '49': {
                start: {
                    line: 174,
                    column: 8
                },
                end: {
                    line: 174,
                    column: 94
                }
            },
            '50': {
                start: {
                    line: 176,
                    column: 8
                },
                end: {
                    line: 176,
                    column: 82
                }
            },
            '51': {
                start: {
                    line: 177,
                    column: 8
                },
                end: {
                    line: 177,
                    column: 78
                }
            },
            '52': {
                start: {
                    line: 178,
                    column: 8
                },
                end: {
                    line: 178,
                    column: 90
                }
            },
            '53': {
                start: {
                    line: 179,
                    column: 8
                },
                end: {
                    line: 179,
                    column: 88
                }
            },
            '54': {
                start: {
                    line: 180,
                    column: 8
                },
                end: {
                    line: 180,
                    column: 92
                }
            },
            '55': {
                start: {
                    line: 181,
                    column: 8
                },
                end: {
                    line: 181,
                    column: 82
                }
            },
            '56': {
                start: {
                    line: 182,
                    column: 8
                },
                end: {
                    line: 182,
                    column: 84
                }
            },
            '57': {
                start: {
                    line: 183,
                    column: 8
                },
                end: {
                    line: 183,
                    column: 90
                }
            },
            '58': {
                start: {
                    line: 186,
                    column: 8
                },
                end: {
                    line: 186,
                    column: 120
                }
            },
            '59': {
                start: {
                    line: 186,
                    column: 73
                },
                end: {
                    line: 186,
                    column: 118
                }
            },
            '60': {
                start: {
                    line: 188,
                    column: 8
                },
                end: {
                    line: 188,
                    column: 130
                }
            },
            '61': {
                start: {
                    line: 189,
                    column: 8
                },
                end: {
                    line: 189,
                    column: 89
                }
            },
            '62': {
                start: {
                    line: 191,
                    column: 8
                },
                end: {
                    line: 194,
                    column: 11
                }
            },
            '63': {
                start: {
                    line: 193,
                    column: 12
                },
                end: {
                    line: 193,
                    column: 71
                }
            },
            '64': {
                start: {
                    line: 196,
                    column: 8
                },
                end: {
                    line: 199,
                    column: 11
                }
            },
            '65': {
                start: {
                    line: 198,
                    column: 12
                },
                end: {
                    line: 198,
                    column: 66
                }
            },
            '66': {
                start: {
                    line: 201,
                    column: 8
                },
                end: {
                    line: 204,
                    column: 11
                }
            },
            '67': {
                start: {
                    line: 203,
                    column: 12
                },
                end: {
                    line: 203,
                    column: 61
                }
            },
            '68': {
                start: {
                    line: 206,
                    column: 8
                },
                end: {
                    line: 206,
                    column: 131
                }
            },
            '69': {
                start: {
                    line: 207,
                    column: 8
                },
                end: {
                    line: 207,
                    column: 120
                }
            },
            '70': {
                start: {
                    line: 210,
                    column: 4
                },
                end: {
                    line: 210,
                    column: 34
                }
            },
            '71': {
                start: {
                    line: 211,
                    column: 4
                },
                end: {
                    line: 211,
                    column: 33
                }
            },
            '72': {
                start: {
                    line: 212,
                    column: 4
                },
                end: {
                    line: 212,
                    column: 35
                }
            },
            '73': {
                start: {
                    line: 213,
                    column: 4
                },
                end: {
                    line: 213,
                    column: 84
                }
            },
            '74': {
                start: {
                    line: 214,
                    column: 4
                },
                end: {
                    line: 214,
                    column: 141
                }
            },
            '75': {
                start: {
                    line: 215,
                    column: 4
                },
                end: {
                    line: 215,
                    column: 48
                }
            },
            '76': {
                start: {
                    line: 217,
                    column: 4
                },
                end: {
                    line: 217,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 29,
                        column: 12
                    },
                    end: {
                        line: 29,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 29,
                        column: 65
                    },
                    end: {
                        line: 113,
                        column: 5
                    }
                },
                line: 29
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 30,
                        column: 43
                    },
                    end: {
                        line: 30,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 59
                    },
                    end: {
                        line: 32,
                        column: 9
                    }
                },
                line: 30
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 34,
                        column: 42
                    },
                    end: {
                        line: 34,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 34,
                        column: 64
                    },
                    end: {
                        line: 37,
                        column: 9
                    }
                },
                line: 34
            },
            '3': {
                name: 'runBlock',
                decl: {
                    start: {
                        line: 116,
                        column: 9
                    },
                    end: {
                        line: 116,
                        column: 17
                    }
                },
                loc: {
                    start: {
                        line: 116,
                        column: 403
                    },
                    end: {
                        line: 218,
                        column: 1
                    }
                },
                line: 116
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 120,
                        column: 29
                    },
                    end: {
                        line: 120,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 120,
                        column: 41
                    },
                    end: {
                        line: 122,
                        column: 5
                    }
                },
                line: 120
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 124,
                        column: 30
                    },
                    end: {
                        line: 124,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 42
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                line: 124
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 128,
                        column: 24
                    },
                    end: {
                        line: 128,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 128,
                        column: 36
                    },
                    end: {
                        line: 130,
                        column: 5
                    }
                },
                line: 128
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 132,
                        column: 33
                    },
                    end: {
                        line: 132,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 132,
                        column: 49
                    },
                    end: {
                        line: 135,
                        column: 5
                    }
                },
                line: 132
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 138,
                        column: 32
                    },
                    end: {
                        line: 138,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 50
                    },
                    end: {
                        line: 141,
                        column: 5
                    }
                },
                line: 138
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 139,
                        column: 50
                    },
                    end: {
                        line: 139,
                        column: 51
                    }
                },
                loc: {
                    start: {
                        line: 139,
                        column: 58
                    },
                    end: {
                        line: 139,
                        column: 77
                    }
                },
                line: 139
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 140,
                        column: 27
                    },
                    end: {
                        line: 140,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 140,
                        column: 35
                    },
                    end: {
                        line: 140,
                        column: 53
                    }
                },
                line: 140
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 145,
                        column: 32
                    },
                    end: {
                        line: 145,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 145,
                        column: 44
                    },
                    end: {
                        line: 208,
                        column: 5
                    }
                },
                line: 145
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 186,
                        column: 61
                    },
                    end: {
                        line: 186,
                        column: 62
                    }
                },
                loc: {
                    start: {
                        line: 186,
                        column: 73
                    },
                    end: {
                        line: 186,
                        column: 118
                    }
                },
                line: 186
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 191,
                        column: 100
                    },
                    end: {
                        line: 191,
                        column: 101
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 108
                    },
                    end: {
                        line: 194,
                        column: 9
                    }
                },
                line: 191
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 196,
                        column: 90
                    },
                    end: {
                        line: 196,
                        column: 91
                    }
                },
                loc: {
                    start: {
                        line: 196,
                        column: 100
                    },
                    end: {
                        line: 199,
                        column: 9
                    }
                },
                line: 196
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 201,
                        column: 54
                    },
                    end: {
                        line: 201,
                        column: 55
                    }
                },
                loc: {
                    start: {
                        line: 201,
                        column: 64
                    },
                    end: {
                        line: 204,
                        column: 9
                    }
                },
                line: 201
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 31,
                        column: 19
                    },
                    end: {
                        line: 31,
                        column: 66
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 31,
                        column: 26
                    },
                    end: {
                        line: 31,
                        column: 59
                    }
                }, {
                    start: {
                        line: 31,
                        column: 62
                    },
                    end: {
                        line: 31,
                        column: 66
                    }
                }],
                line: 31
            },
            '1': {
                loc: {
                    start: {
                        line: 36,
                        column: 19
                    },
                    end: {
                        line: 36,
                        column: 59
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 36,
                        column: 33
                    },
                    end: {
                        line: 36,
                        column: 43
                    }
                }, {
                    start: {
                        line: 36,
                        column: 46
                    },
                    end: {
                        line: 36,
                        column: 59
                    }
                }],
                line: 36
            },
            '2': {
                loc: {
                    start: {
                        line: 134,
                        column: 15
                    },
                    end: {
                        line: 134,
                        column: 71
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 134,
                        column: 15
                    },
                    end: {
                        line: 134,
                        column: 32
                    }
                }, {
                    start: {
                        line: 134,
                        column: 36
                    },
                    end: {
                        line: 134,
                        column: 71
                    }
                }],
                line: 134
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_23o4tmu656.s[0]++;
angular.module('app', ['ui.router', 'restmod', 'restmod.styles.drfPaged', 'ngMaterial', 'satellizer', 'ngFileUpload', 'ngSanitize', 'cgBusy', 'angularMoment', 'angular-loading-bar', 'angular.filter', 'angular-click-outside'])
/* eslint-disable no-undef */
.constant('moment', moment).constant('AppSettings', {
    appUrl: 'https://mypedrosa.soloweb.pt',
    apiUrl: 'https://api.mypedrosa.soloweb.pt',
    mediaUrl: 'https://api.mypedrosa.soloweb.pt/static/media'
}).value('cgBusyDefaults', {
    message: '',
    templateUrl: 'app/shared/html/cgbusy/cgbusy.template.html'
}).run(runBlock)
/* eslint-disable prefer-arrow-callback */
.config(function ($mdDateLocaleProvider, $mdThemingProvider) {
    cov_23o4tmu656.f[0]++;
    cov_23o4tmu656.s[1]++;

    $mdDateLocaleProvider.formatDate = function (date) {
        cov_23o4tmu656.f[1]++;
        cov_23o4tmu656.s[2]++;

        return date ? (cov_23o4tmu656.b[0][0]++, moment(date).format('DD/MM/YYYY')) : (cov_23o4tmu656.b[0][1]++, null);
    };

    cov_23o4tmu656.s[3]++;
    $mdDateLocaleProvider.parseDate = function (dateString) {
        cov_23o4tmu656.f[2]++;

        var m = (cov_23o4tmu656.s[4]++, moment(dateString, 'DD/MM/YYYY', true));
        cov_23o4tmu656.s[5]++;
        return m.isValid() ? (cov_23o4tmu656.b[1][0]++, m.toDate()) : (cov_23o4tmu656.b[1][1]++, new Date(NaN));
    };

    /* eslint-disable quote-props */
    cov_23o4tmu656.s[6]++;
    $mdThemingProvider.definePalette('primary', {
        '50': 'f1e0e0',
        '100': 'dcb3b3',
        '200': 'c58080',
        '300': 'ae4d4d',
        '400': '9c2626',
        '500': '8b0000',
        '600': '830000',
        '700': '780000',
        '800': '6e0000',
        '900': '5b0000',
        'A100': 'ff8c8c',
        'A200': 'ff5959',
        'A400': 'ff2626',
        'A700': 'ff0d0d',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': ['50', '100', '200', 'A100', 'A200'],
        'contrastLightColors': ['300', '400', '500', '600', '700', '800', '900', 'A400', 'A700']
    });
    cov_23o4tmu656.s[7]++;
    $mdThemingProvider.definePalette('accent', {
        '50': 'e4e4e4',
        '100': 'bdbdbd',
        '200': '919191',
        '300': '646464',
        '400': '434343',
        '500': '222222',
        '600': '1e1e1e',
        '700': '191919',
        '800': '141414',
        '900': '0c0c0c',
        'A100': 'e76c6c',
        'A200': 'e04040',
        'A400': 'ec0000',
        'A700': 'd30000',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': ['50', '100', '200', 'A100'],
        'contrastLightColors': ['300', '400', '500', '600', '700', '800', '900', 'A200', 'A400', 'A700']
    });
    cov_23o4tmu656.s[8]++;
    $mdThemingProvider.theme('default').primaryPalette('primary').accentPalette('accent');
});

/** @ngInject */
function runBlock($rootScope, AppSettings, $mdSidenav, $mdMedia, BrandsTable, ConfigurationsTable, SampleStatesTable, MeshTypesTable, AccessoriesTypesTable, UserTypesTable, DecorationTypesTable, FinishingTypesTable, SampleTypesTable, SampleStateTypesTable, SampleItemPriceTypesTable, UsersTable, SeasonsTable, $window, TypifiedRoutingsTable, ForeseenDateReasonsTable, ForeseenDateReasonCategoriesTable) {
    cov_23o4tmu656.f[3]++;
    cov_23o4tmu656.s[9]++;

    $rootScope.AppSettings = AppSettings; // You can now access AppSettings from any component with $root.AppSettings
    cov_23o4tmu656.s[10]++;
    $rootScope.$mdMedia = $mdMedia;

    cov_23o4tmu656.s[11]++;
    $rootScope.openSidebar = function () {
        cov_23o4tmu656.f[4]++;
        cov_23o4tmu656.s[12]++;

        return $mdSidenav('menu').open();
    };

    cov_23o4tmu656.s[13]++;
    $rootScope.closeSidebar = function () {
        cov_23o4tmu656.f[5]++;
        cov_23o4tmu656.s[14]++;

        return $mdSidenav('menu').close();
    };

    cov_23o4tmu656.s[15]++;
    $rootScope.goBack = function () {
        cov_23o4tmu656.f[6]++;
        cov_23o4tmu656.s[16]++;

        $window.history.back();
    };

    cov_23o4tmu656.s[17]++;
    $rootScope.virtualCreators = function (user) {
        cov_23o4tmu656.f[7]++;

        var virtualCreators = (cov_23o4tmu656.s[18]++, ['ACCESSORIES', 'CONTROL']);
        cov_23o4tmu656.s[19]++;
        return (cov_23o4tmu656.b[2][0]++, user.isSupervisor) || (cov_23o4tmu656.b[2][1]++, virtualCreators.includes(user.type));
    };

    // util in case we have an user_id and not a user
    cov_23o4tmu656.s[20]++;
    $rootScope.isProcessOwner = function (userId) {
        cov_23o4tmu656.f[8]++;

        var owners = (cov_23o4tmu656.s[21]++, $rootScope.allUsers.filter(function (user) {
            cov_23o4tmu656.f[9]++;
            cov_23o4tmu656.s[22]++;
            return user.isProcessOwner;
        }));
        cov_23o4tmu656.s[23]++;
        return owners.find(function (user) {
            cov_23o4tmu656.f[10]++;
            cov_23o4tmu656.s[24]++;
            return user.id === userId;
        });
    };

    cov_23o4tmu656.s[25]++;
    $rootScope.virtualSampleType = 19;

    cov_23o4tmu656.s[26]++;
    $rootScope.initStaticData = function () {
        cov_23o4tmu656.f[11]++;
        cov_23o4tmu656.s[27]++;

        /* eslint-disable camelcase */
        $rootScope.brands = BrandsTable.$search({ ordering: 'name', is_active: true, page_size: 999999 });
        cov_23o4tmu656.s[28]++;
        $rootScope.allBrands = BrandsTable.$search({ ordering: 'name', page_size: 999999 });
        cov_23o4tmu656.s[29]++;
        $rootScope.commercials = UsersTable.$search({ ordering: 'name', type: 'COMMERCIAL', page_size: 99999 });
        cov_23o4tmu656.s[30]++;
        $rootScope.stylists = UsersTable.$search({ ordering: 'name', type: 'CAD', page_size: 99999 });
        cov_23o4tmu656.s[31]++;
        $rootScope.accessoriesCommercials = UsersTable.$search({
            ordering: 'name',
            type: 'ACCESSORIES',
            page_size: 99999
        });
        cov_23o4tmu656.s[32]++;
        $rootScope.users = UsersTable.$search({ ordering: 'name', type: 'USER', page_size: 99999 });
        cov_23o4tmu656.s[33]++;
        $rootScope.allUsers = UsersTable.$search({ ordering: 'name', page_size: 99999 });
        cov_23o4tmu656.s[34]++;
        $rootScope.userTypes = UserTypesTable.$search({ ordering: 'name', page_size: 99999 });
        cov_23o4tmu656.s[35]++;
        $rootScope.sampleTypes = SampleTypesTable.$search({ ordering: 'name', page_size: 99999 });
        cov_23o4tmu656.s[36]++;
        $rootScope.nonVirtualSampleTypes = SampleTypesTable.$search({ ordering: 'name', page_size: 99999, is_virtual: false });
        cov_23o4tmu656.s[37]++;
        $rootScope.seasons = SeasonsTable.$search({ ordering: 'name', page_size: 99999 });
        cov_23o4tmu656.s[38]++;
        $rootScope.sampleStateTypes = SampleStateTypesTable.$search({ page_size: 999999 });
        cov_23o4tmu656.s[39]++;
        $rootScope.finishingTypes = FinishingTypesTable.$search({ ordering: 'name', page_size: 999999 });
        cov_23o4tmu656.s[40]++;
        $rootScope.sampleItemPriceTypes = SampleItemPriceTypesTable.$search({ ordering: 'name', page_size: 999999 });
        cov_23o4tmu656.s[41]++;
        $rootScope.meshTypes = MeshTypesTable.$search({ page_size: 999999 });
        cov_23o4tmu656.s[42]++;
        $rootScope.accessoriesTypes = AccessoriesTypesTable.$search({ page_size: 999999 });
        cov_23o4tmu656.s[43]++;
        $rootScope.decorationTypes = DecorationTypesTable.$search({ ordering: 'name', page_size: 999999 });
        cov_23o4tmu656.s[44]++;
        $rootScope.confectionUsers = UsersTable.$search({ ordering: 'name', type: 'CONFECTION', page_size: 99999 });
        cov_23o4tmu656.s[45]++;
        $rootScope.seamstresses = UsersTable.$search({ ordering: 'name', type__in: 'SEWING,CONFECTION', page_size: 99999 });
        cov_23o4tmu656.s[46]++;
        $rootScope.archivedState = ConfigurationsTable.$find('ARCHIVED_STATE');
        // feature toggles
        cov_23o4tmu656.s[47]++;
        $rootScope.ENABLE_AUTOCOMPLETE = ConfigurationsTable.$find('ENABLE_AUTOCOMPLETE');
        cov_23o4tmu656.s[48]++;
        $rootScope.ENABLE_WORKLOADS = ConfigurationsTable.$find('ENABLE_WORKLOADS');
        cov_23o4tmu656.s[49]++;
        $rootScope.ENABLE_MEASUREMENTFILES = ConfigurationsTable.$find('ENABLE_MEASUREFILES');
        // configurations
        cov_23o4tmu656.s[50]++;
        $rootScope.WORKLOAD_MEDIUM = ConfigurationsTable.$find('WORKLOAD_MEDIUM');
        cov_23o4tmu656.s[51]++;
        $rootScope.WORKLOAD_HIGH = ConfigurationsTable.$find('WORKLOAD_HIGH');
        cov_23o4tmu656.s[52]++;
        $rootScope.PRICE_DELAY_WARNING = ConfigurationsTable.$find('PRICE_DELAY_WARNING');
        cov_23o4tmu656.s[53]++;
        $rootScope.PRICE_DELAY_SEVERE = ConfigurationsTable.$find('PRICE_DELAY_SEVERE');
        cov_23o4tmu656.s[54]++;
        $rootScope.ENABLE_KPI_DASHBOARD = ConfigurationsTable.$find('ENABLE_KPI_DASHBOARD');
        cov_23o4tmu656.s[55]++;
        $rootScope.FORESEEN_REASON = ConfigurationsTable.$find('FORESEEN_REASON');
        cov_23o4tmu656.s[56]++;
        $rootScope.FORESEEN_UPDATED = ConfigurationsTable.$find('FORESEEN_UPDATED');
        cov_23o4tmu656.s[57]++;
        $rootScope.CAD_ATTACH_POSITION = ConfigurationsTable.$find('CAD_ATTACH_POSITION');

        /* eslint-disable no-return-assign*/
        cov_23o4tmu656.s[58]++;
        ConfigurationsTable.$find('ANNOUNCEMENT_HELP').$then(function (response) {
            cov_23o4tmu656.f[12]++;
            cov_23o4tmu656.s[59]++;
            return $rootScope.ANNOUNCEMENT_HELP = response.value;
        });

        cov_23o4tmu656.s[60]++;
        $rootScope.workflowSampleStateTypes = SampleStateTypesTable.$search({ page_size: 999999, id__in: '1,2,3,4,6,7,8,9,10,11' });
        cov_23o4tmu656.s[61]++;
        $rootScope.typifiedRoutings = TypifiedRoutingsTable.$search({ page_size: 999999 });

        cov_23o4tmu656.s[62]++;
        $rootScope.sampleWritePermissions = ConfigurationsTable.$find('SAMPLE_WRITE_PERMISS').$then(function (perm) {
            cov_23o4tmu656.f[13]++;
            cov_23o4tmu656.s[63]++;

            /* eslint-disable angular/json-functions */
            $rootScope.sampleWritePermissions = JSON.parse(perm.value);
        });

        cov_23o4tmu656.s[64]++;
        $rootScope.planBulkActions = ConfigurationsTable.$find('PLAN_BULK_ACTIONS').$then(function (action) {
            cov_23o4tmu656.f[14]++;
            cov_23o4tmu656.s[65]++;

            /* eslint-disable angular/json-functions */
            $rootScope.planBulkActions = JSON.parse(action.value);
        });

        cov_23o4tmu656.s[66]++;
        ConfigurationsTable.$find('VALIDATORS').$then(function (action) {
            cov_23o4tmu656.f[15]++;
            cov_23o4tmu656.s[67]++;

            /* eslint-disable angular/json-functions */
            $rootScope.validators = JSON.parse(action.value);
        });

        cov_23o4tmu656.s[68]++;
        $rootScope.foreseenDateReasonCategories = ForeseenDateReasonCategoriesTable.$search({ page_size: 999999, ordering: 'name' });
        cov_23o4tmu656.s[69]++;
        $rootScope.foreseenDateReasons = ForeseenDateReasonsTable.$search({ page_size: 999999, ordering: 'description' });
    };

    cov_23o4tmu656.s[70]++;
    $rootScope.WAITING_STATE = 11;
    cov_23o4tmu656.s[71]++;
    $rootScope.SHIPPED_STATE = 9;
    cov_23o4tmu656.s[72]++;
    $rootScope.CANCELED_STATE = 10;
    cov_23o4tmu656.s[73]++;
    $rootScope.FINAL_STATES = [$rootScope.SHIPPED_STATE, $rootScope.CANCELED_STATE];
    cov_23o4tmu656.s[74]++;
    $rootScope.MY_WORK_FILTER_GROUPS = ['COMMERCIAL', 'ACCESSORIES', 'CONFECTION', 'CAD', 'CUTTING', 'FINISHINGS', 'ORNAMENTS', 'WAREHOUSE'];
    cov_23o4tmu656.s[75]++;
    $rootScope.redirectObservationsMaxLen = 250;

    cov_23o4tmu656.s[76]++;
    $rootScope.initStaticData();
}