'use strict';

var cov_1lrmlfm2r4 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/samplePriceChecks/samplePriceChecks.js',
        hash = '095197d44ea7109201927fc2b1c174ef5a00a334',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/samplePriceChecks/samplePriceChecks.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 37
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 61
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 53
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 10
                }
            },
            '5': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 10
                }
            },
            '6': {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 49,
                    column: 26
                }
            },
            '7': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 53,
                    column: 36
                }
            },
            '8': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 11
                }
            },
            '9': {
                start: {
                    line: 55,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 15
                }
            },
            '10': {
                start: {
                    line: 56,
                    column: 28
                },
                end: {
                    line: 56,
                    column: 109
                }
            },
            '11': {
                start: {
                    line: 56,
                    column: 70
                },
                end: {
                    line: 56,
                    column: 105
                }
            },
            '12': {
                start: {
                    line: 57,
                    column: 16
                },
                end: {
                    line: 57,
                    column: 105
                }
            },
            '13': {
                start: {
                    line: 57,
                    column: 65
                },
                end: {
                    line: 57,
                    column: 103
                }
            },
            '14': {
                start: {
                    line: 59,
                    column: 23
                },
                end: {
                    line: 59,
                    column: 72
                }
            },
            '15': {
                start: {
                    line: 60,
                    column: 12
                },
                end: {
                    line: 60,
                    column: 39
                }
            },
            '16': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 15
                }
            },
            '17': {
                start: {
                    line: 67,
                    column: 16
                },
                end: {
                    line: 67,
                    column: 34
                }
            },
            '18': {
                start: {
                    line: 68,
                    column: 16
                },
                end: {
                    line: 70,
                    column: 17
                }
            },
            '19': {
                start: {
                    line: 69,
                    column: 20
                },
                end: {
                    line: 69,
                    column: 50
                }
            },
            '20': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 51
                }
            },
            '21': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 74
                }
            },
            '22': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 11
                }
            },
            '23': {
                start: {
                    line: 82,
                    column: 12
                },
                end: {
                    line: 82,
                    column: 33
                }
            },
            '24': {
                start: {
                    line: 83,
                    column: 12
                },
                end: {
                    line: 88,
                    column: 15
                }
            },
            '25': {
                start: {
                    line: 84,
                    column: 16
                },
                end: {
                    line: 84,
                    column: 34
                }
            },
            '26': {
                start: {
                    line: 85,
                    column: 16
                },
                end: {
                    line: 87,
                    column: 17
                }
            },
            '27': {
                start: {
                    line: 86,
                    column: 20
                },
                end: {
                    line: 86,
                    column: 49
                }
            },
            '28': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 52
                }
            },
            '29': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 74
                }
            },
            '30': {
                start: {
                    line: 99,
                    column: 24
                },
                end: {
                    line: 103,
                    column: 37
                }
            },
            '31': {
                start: {
                    line: 104,
                    column: 8
                },
                end: {
                    line: 104,
                    column: 69
                }
            },
            '32': {
                start: {
                    line: 108,
                    column: 8
                },
                end: {
                    line: 108,
                    column: 61
                }
            },
            '33': {
                start: {
                    line: 112,
                    column: 8
                },
                end: {
                    line: 116,
                    column: 10
                }
            },
            '34': {
                start: {
                    line: 120,
                    column: 0
                },
                end: {
                    line: 130,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 63
                    },
                    end: {
                        line: 46,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 48,
                        column: 4
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 48,
                        column: 14
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                line: 48
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 52,
                        column: 4
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 52,
                        column: 17
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 52
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 54,
                        column: 52
                    },
                    end: {
                        line: 54,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 62
                    },
                    end: {
                        line: 61,
                        column: 9
                    }
                },
                line: 54
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 55,
                        column: 56
                    },
                    end: {
                        line: 55,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 80
                    },
                    end: {
                        line: 58,
                        column: 13
                    }
                },
                line: 55
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 56,
                        column: 62
                    },
                    end: {
                        line: 56,
                        column: 63
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 70
                    },
                    end: {
                        line: 56,
                        column: 105
                    }
                },
                line: 56
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 57,
                        column: 56
                    },
                    end: {
                        line: 57,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 65
                    },
                    end: {
                        line: 57,
                        column: 103
                    }
                },
                line: 57
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 64,
                        column: 4
                    },
                    end: {
                        line: 64,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 64,
                        column: 29
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 64
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 66,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 18
                    },
                    end: {
                        line: 71,
                        column: 13
                    }
                },
                line: 66
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 22
                    },
                    end: {
                        line: 78,
                        column: 5
                    }
                },
                line: 74
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 80,
                        column: 4
                    },
                    end: {
                        line: 80,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 29
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                line: 80
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 81,
                        column: 67
                    },
                    end: {
                        line: 81,
                        column: 68
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 74
                    },
                    end: {
                        line: 89,
                        column: 9
                    }
                },
                line: 81
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 83,
                        column: 30
                    },
                    end: {
                        line: 83,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 36
                    },
                    end: {
                        line: 88,
                        column: 13
                    }
                },
                line: 83
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 92,
                        column: 4
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 22
                    },
                    end: {
                        line: 96,
                        column: 5
                    }
                },
                line: 92
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 98,
                        column: 4
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 98,
                        column: 53
                    },
                    end: {
                        line: 105,
                        column: 5
                    }
                },
                line: 98
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 104,
                        column: 59
                    },
                    end: {
                        line: 104,
                        column: 60
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 65
                    },
                    end: {
                        line: 104,
                        column: 67
                    }
                },
                line: 104
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 107,
                        column: 4
                    },
                    end: {
                        line: 107,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 107,
                        column: 24
                    },
                    end: {
                        line: 109,
                        column: 5
                    }
                },
                line: 107
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 111,
                        column: 4
                    },
                    end: {
                        line: 111,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 111,
                        column: 26
                    },
                    end: {
                        line: 117,
                        column: 5
                    }
                },
                line: 111
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 68,
                        column: 16
                    },
                    end: {
                        line: 70,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 68,
                        column: 16
                    },
                    end: {
                        line: 70,
                        column: 17
                    }
                }, {
                    start: {
                        line: 68,
                        column: 16
                    },
                    end: {
                        line: 70,
                        column: 17
                    }
                }],
                line: 68
            },
            '1': {
                loc: {
                    start: {
                        line: 85,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 85,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                }, {
                    start: {
                        line: 85,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 17
                    }
                }],
                line: 85
            },
            '2': {
                loc: {
                    start: {
                        line: 113,
                        column: 12
                    },
                    end: {
                        line: 115,
                        column: 57
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 113,
                        column: 12
                    },
                    end: {
                        line: 113,
                        column: 54
                    }
                }, {
                    start: {
                        line: 114,
                        column: 16
                    },
                    end: {
                        line: 114,
                        column: 62
                    }
                }, {
                    start: {
                        line: 115,
                        column: 16
                    },
                    end: {
                        line: 115,
                        column: 57
                    }
                }],
                line: 113
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SamplePriceChecksController = function () {
    function SamplePriceChecksController($rootScope, SamplePriceChecksTable, $mdDialog) {
        _classCallCheck(this, SamplePriceChecksController);

        cov_1lrmlfm2r4.f[0]++;
        cov_1lrmlfm2r4.s[0]++;

        this.$mdDialog = $mdDialog;
        cov_1lrmlfm2r4.s[1]++;
        this.$rootScope = $rootScope;
        cov_1lrmlfm2r4.s[2]++;
        this.SamplePriceChecksTable = SamplePriceChecksTable;
        cov_1lrmlfm2r4.s[3]++;
        this.loadChecks = this.loadChecks.bind(this);

        cov_1lrmlfm2r4.s[4]++;
        this.dialogDetails = {
            title: '',
            content: 'Esta ação vai gerar notificações para os restantes departamentos. Quer prosseguir?',
            ok: 'Sim',
            cancel: 'Não, quero rever.'
        };

        cov_1lrmlfm2r4.s[5]++;
        this.samplePriceCheckTypes = [{
            id: 'ACCESSORIES_PRICES',
            name: 'Preços Acessórios',
            groups: ['ACCESSORIES'],
            users: this.$rootScope.validators.vpa,
            checks: []
        }, {
            id: 'ACCESSORIES_QUANTITIES',
            name: 'Quantidade Acessórios',
            groups: ['ACCESSORIES'],
            users: this.$rootScope.validators.vqa,
            checks: []
        }, {
            id: 'CONFECTION_PRICES',
            name: 'Preços Confeção',
            groups: ['CONTROL'],
            users: this.$rootScope.validators.vpf,
            checks: []
        }, {
            id: 'COMMERCIAL',
            name: 'Preço Comercial',
            groups: ['COMMERCIAL'],
            users: this.$rootScope.validators.vpc,
            checks: []
        }];
    }

    _createClass(SamplePriceChecksController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1lrmlfm2r4.f[1]++;
            cov_1lrmlfm2r4.s[6]++;

            this.loadChecks();
        }
    }, {
        key: 'loadChecks',
        value: function loadChecks() {
            var _this = this;

            cov_1lrmlfm2r4.f[2]++;
            cov_1lrmlfm2r4.s[7]++;

            this.sample.$load('checks');
            cov_1lrmlfm2r4.s[8]++;
            this.sample.$checks.$on('after-fetch-many', function (checks) {
                cov_1lrmlfm2r4.f[3]++;
                cov_1lrmlfm2r4.s[9]++;

                angular.forEach(_this.samplePriceCheckTypes, function (samplePriceCheckType) {
                    cov_1lrmlfm2r4.f[4]++;

                    var obj = (cov_1lrmlfm2r4.s[10]++, _this.samplePriceCheckTypes.filter(function (type) {
                        cov_1lrmlfm2r4.f[5]++;
                        cov_1lrmlfm2r4.s[11]++;
                        return type.id === samplePriceCheckType.id;
                    })[0]);
                    cov_1lrmlfm2r4.s[12]++;
                    obj.checks = checks.data.results.filter(function (check) {
                        cov_1lrmlfm2r4.f[6]++;
                        cov_1lrmlfm2r4.s[13]++;
                        return check.type === samplePriceCheckType.id;
                    });
                });
                var ed = (cov_1lrmlfm2r4.s[14]++, _this.samplePriceCheckTypes[3].checks.length === 0);
                cov_1lrmlfm2r4.s[15]++;
                _this.callback({ state: ed });
            });
        }
    }, {
        key: 'createCheckRequest',
        value: function createCheckRequest(item) {
            var _this2 = this;

            cov_1lrmlfm2r4.f[7]++;
            cov_1lrmlfm2r4.s[16]++;

            this.SamplePriceChecksTable.$create({ sample: this.sample.id, type: item.id }).$then(function () {
                cov_1lrmlfm2r4.f[8]++;
                cov_1lrmlfm2r4.s[17]++;

                _this2.loadChecks();
                cov_1lrmlfm2r4.s[18]++;
                if (item.id === 'COMMERCIAL') {
                    cov_1lrmlfm2r4.b[0][0]++;
                    cov_1lrmlfm2r4.s[19]++;

                    _this2.callback({ state: false });
                } else {
                    cov_1lrmlfm2r4.b[0][1]++;
                }
            });
        }
    }, {
        key: 'createCheck',
        value: function createCheck(item) {
            cov_1lrmlfm2r4.f[9]++;
            cov_1lrmlfm2r4.s[20]++;

            this.dialogDetails.title = 'Fechar Preços';
            cov_1lrmlfm2r4.s[21]++;
            this.showConfirmationDialog(this.dialogDetails, this.createCheckRequest.bind(this, item));
        }
    }, {
        key: 'removeCheckRequest',
        value: function removeCheckRequest(item) {
            var _this3 = this;

            cov_1lrmlfm2r4.f[10]++;
            cov_1lrmlfm2r4.s[22]++;

            this.SamplePriceChecksTable.$find(item.checks[0].id).$then(function (obj) {
                cov_1lrmlfm2r4.f[11]++;
                cov_1lrmlfm2r4.s[23]++;

                obj.isActive = false;
                cov_1lrmlfm2r4.s[24]++;
                obj.$save().$then(function () {
                    cov_1lrmlfm2r4.f[12]++;
                    cov_1lrmlfm2r4.s[25]++;

                    _this3.loadChecks();
                    cov_1lrmlfm2r4.s[26]++;
                    if (item.id === 'COMMERCIAL') {
                        cov_1lrmlfm2r4.b[1][0]++;
                        cov_1lrmlfm2r4.s[27]++;

                        _this3.callback({ state: true });
                    } else {
                        cov_1lrmlfm2r4.b[1][1]++;
                    }
                });
            });
        }
    }, {
        key: 'removeCheck',
        value: function removeCheck(item) {
            cov_1lrmlfm2r4.f[13]++;
            cov_1lrmlfm2r4.s[28]++;

            this.dialogDetails.title = 'Reabrir Preços';
            cov_1lrmlfm2r4.s[29]++;
            this.showConfirmationDialog(this.dialogDetails, this.removeCheckRequest.bind(this, item));
        }
    }, {
        key: 'showConfirmationDialog',
        value: function showConfirmationDialog(details, successCallback) {
            cov_1lrmlfm2r4.f[14]++;

            var confirm = (cov_1lrmlfm2r4.s[30]++, this.$mdDialog.confirm().title(details.title).textContent(details.content).ok(details.ok).cancel(details.cancel));
            cov_1lrmlfm2r4.s[31]++;
            this.$mdDialog.show(confirm).then(successCallback, function () {
                cov_1lrmlfm2r4.f[15]++;
            });
        }
    }, {
        key: 'hasPermission',
        value: function hasPermission(item) {
            cov_1lrmlfm2r4.f[16]++;
            cov_1lrmlfm2r4.s[32]++;

            return item.groups.includes(this.$rootScope.me.type);
        }
    }, {
        key: 'requiredValidations',
        value: function requiredValidations() {
            cov_1lrmlfm2r4.f[17]++;
            cov_1lrmlfm2r4.s[33]++;

            return (cov_1lrmlfm2r4.b[2][0]++, this.sample.hasAccessoriesPricesValidation) && (cov_1lrmlfm2r4.b[2][1]++, this.sample.hasAccessoriesQuantitiesValidation) && (cov_1lrmlfm2r4.b[2][2]++, this.sample.hasConfectionPricesValidation);
        }
    }]);

    return SamplePriceChecksController;
}();

cov_1lrmlfm2r4.s[34]++;


angular.module('app').component('samplePriceChecks', {
    templateUrl: 'app/components/samplePriceChecks/samplePriceChecks.html',
    controller: SamplePriceChecksController,
    bindings: {
        sample: '<',
        callback: '&',
        editable: '<'
    }
});