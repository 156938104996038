'use strict';

var cov_219xy6xnil = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/routes.js',
        hash = '42f7a96b788cd5a9d3f3150b3c87ba8a781b1d37',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/routes.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 3,
                    column: 26
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 7,
                    column: 54
                }
            },
            '2': {
                start: {
                    line: 8,
                    column: 4
                },
                end: {
                    line: 8,
                    column: 38
                }
            },
            '3': {
                start: {
                    line: 10,
                    column: 4
                },
                end: {
                    line: 350,
                    column: 5
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 53
                },
                end: {
                    line: 15,
                    column: 81
                }
            },
            '5': {
                start: {
                    line: 23,
                    column: 68
                },
                end: {
                    line: 23,
                    column: 154
                }
            },
            '6': {
                start: {
                    line: 23,
                    column: 132
                },
                end: {
                    line: 23,
                    column: 144
                }
            },
            '7': {
                start: {
                    line: 47,
                    column: 36
                },
                end: {
                    line: 47,
                    column: 49
                }
            },
            '8': {
                start: {
                    line: 68,
                    column: 45
                },
                end: {
                    line: 68,
                    column: 75
                }
            },
            '9': {
                start: {
                    line: 69,
                    column: 41
                },
                end: {
                    line: 69,
                    column: 69
                }
            },
            '10': {
                start: {
                    line: 70,
                    column: 34
                },
                end: {
                    line: 70,
                    column: 56
                }
            },
            '11': {
                start: {
                    line: 79,
                    column: 20
                },
                end: {
                    line: 100,
                    column: 21
                }
            },
            '12': {
                start: {
                    line: 80,
                    column: 24
                },
                end: {
                    line: 80,
                    column: 100
                }
            },
            '13': {
                start: {
                    line: 80,
                    column: 83
                },
                end: {
                    line: 80,
                    column: 89
                }
            },
            '14': {
                start: {
                    line: 83,
                    column: 24
                },
                end: {
                    line: 98,
                    column: 25
                }
            },
            '15': {
                start: {
                    line: 84,
                    column: 28
                },
                end: {
                    line: 97,
                    column: 40
                }
            },
            '16': {
                start: {
                    line: 88,
                    column: 45
                },
                end: {
                    line: 88,
                    column: 72
                }
            },
            '17': {
                start: {
                    line: 89,
                    column: 32
                },
                end: {
                    line: 89,
                    column: 52
                }
            },
            '18': {
                start: {
                    line: 90,
                    column: 32
                },
                end: {
                    line: 90,
                    column: 56
                }
            },
            '19': {
                start: {
                    line: 91,
                    column: 32
                },
                end: {
                    line: 91,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 92,
                    column: 32
                },
                end: {
                    line: 92,
                    column: 64
                }
            },
            '21': {
                start: {
                    line: 93,
                    column: 32
                },
                end: {
                    line: 93,
                    column: 62
                }
            },
            '22': {
                start: {
                    line: 94,
                    column: 32
                },
                end: {
                    line: 94,
                    column: 57
                }
            },
            '23': {
                start: {
                    line: 95,
                    column: 32
                },
                end: {
                    line: 95,
                    column: 57
                }
            },
            '24': {
                start: {
                    line: 96,
                    column: 32
                },
                end: {
                    line: 96,
                    column: 46
                }
            },
            '25': {
                start: {
                    line: 99,
                    column: 24
                },
                end: {
                    line: 99,
                    column: 53
                }
            },
            '26': {
                start: {
                    line: 102,
                    column: 36
                },
                end: {
                    line: 102,
                    column: 58
                }
            },
            '27': {
                start: {
                    line: 103,
                    column: 43
                },
                end: {
                    line: 103,
                    column: 85
                }
            },
            '28': {
                start: {
                    line: 113,
                    column: 41
                },
                end: {
                    line: 113,
                    column: 69
                }
            },
            '29': {
                start: {
                    line: 114,
                    column: 34
                },
                end: {
                    line: 114,
                    column: 56
                }
            },
            '30': {
                start: {
                    line: 122,
                    column: 69
                },
                end: {
                    line: 122,
                    column: 105
                }
            },
            '31': {
                start: {
                    line: 130,
                    column: 84
                },
                end: {
                    line: 130,
                    column: 133
                }
            },
            '32': {
                start: {
                    line: 138,
                    column: 41
                },
                end: {
                    line: 138,
                    column: 63
                }
            },
            '33': {
                start: {
                    line: 152,
                    column: 37
                },
                end: {
                    line: 152,
                    column: 106
                }
            },
            '34': {
                start: {
                    line: 152,
                    column: 91
                },
                end: {
                    line: 152,
                    column: 96
                }
            },
            '35': {
                start: {
                    line: 161,
                    column: 20
                },
                end: {
                    line: 165,
                    column: 21
                }
            },
            '36': {
                start: {
                    line: 162,
                    column: 24
                },
                end: {
                    line: 162,
                    column: 94
                }
            },
            '37': {
                start: {
                    line: 162,
                    column: 79
                },
                end: {
                    line: 162,
                    column: 83
                }
            },
            '38': {
                start: {
                    line: 164,
                    column: 24
                },
                end: {
                    line: 164,
                    column: 76
                }
            },
            '39': {
                start: {
                    line: 179,
                    column: 42
                },
                end: {
                    line: 179,
                    column: 89
                }
            },
            '40': {
                start: {
                    line: 179,
                    column: 77
                },
                end: {
                    line: 179,
                    column: 79
                }
            },
            '41': {
                start: {
                    line: 180,
                    column: 35
                },
                end: {
                    line: 180,
                    column: 45
                }
            },
            '42': {
                start: {
                    line: 181,
                    column: 38
                },
                end: {
                    line: 181,
                    column: 68
                }
            },
            '43': {
                start: {
                    line: 182,
                    column: 35
                },
                end: {
                    line: 182,
                    column: 63
                }
            },
            '44': {
                start: {
                    line: 183,
                    column: 41
                },
                end: {
                    line: 183,
                    column: 69
                }
            },
            '45': {
                start: {
                    line: 192,
                    column: 20
                },
                end: {
                    line: 196,
                    column: 21
                }
            },
            '46': {
                start: {
                    line: 193,
                    column: 24
                },
                end: {
                    line: 193,
                    column: 90
                }
            },
            '47': {
                start: {
                    line: 195,
                    column: 24
                },
                end: {
                    line: 195,
                    column: 63
                }
            },
            '48': {
                start: {
                    line: 205,
                    column: 42
                },
                end: {
                    line: 205,
                    column: 93
                }
            },
            '49': {
                start: {
                    line: 205,
                    column: 81
                },
                end: {
                    line: 205,
                    column: 83
                }
            },
            '50': {
                start: {
                    line: 206,
                    column: 35
                },
                end: {
                    line: 206,
                    column: 45
                }
            },
            '51': {
                start: {
                    line: 207,
                    column: 38
                },
                end: {
                    line: 207,
                    column: 62
                }
            },
            '52': {
                start: {
                    line: 208,
                    column: 35
                },
                end: {
                    line: 208,
                    column: 57
                }
            },
            '53': {
                start: {
                    line: 209,
                    column: 41
                },
                end: {
                    line: 209,
                    column: 63
                }
            },
            '54': {
                start: {
                    line: 218,
                    column: 20
                },
                end: {
                    line: 222,
                    column: 21
                }
            },
            '55': {
                start: {
                    line: 219,
                    column: 24
                },
                end: {
                    line: 219,
                    column: 94
                }
            },
            '56': {
                start: {
                    line: 221,
                    column: 24
                },
                end: {
                    line: 221,
                    column: 67
                }
            },
            '57': {
                start: {
                    line: 231,
                    column: 43
                },
                end: {
                    line: 231,
                    column: 89
                }
            },
            '58': {
                start: {
                    line: 231,
                    column: 77
                },
                end: {
                    line: 231,
                    column: 79
                }
            },
            '59': {
                start: {
                    line: 232,
                    column: 35
                },
                end: {
                    line: 232,
                    column: 45
                }
            },
            '60': {
                start: {
                    line: 233,
                    column: 38
                },
                end: {
                    line: 233,
                    column: 59
                }
            },
            '61': {
                start: {
                    line: 234,
                    column: 35
                },
                end: {
                    line: 234,
                    column: 53
                }
            },
            '62': {
                start: {
                    line: 235,
                    column: 41
                },
                end: {
                    line: 235,
                    column: 59
                }
            },
            '63': {
                start: {
                    line: 236,
                    column: 35
                },
                end: {
                    line: 236,
                    column: 39
                }
            },
            '64': {
                start: {
                    line: 245,
                    column: 20
                },
                end: {
                    line: 257,
                    column: 21
                }
            },
            '65': {
                start: {
                    line: 246,
                    column: 35
                },
                end: {
                    line: 246,
                    column: 67
                }
            },
            '66': {
                start: {
                    line: 247,
                    column: 24
                },
                end: {
                    line: 254,
                    column: 25
                }
            },
            '67': {
                start: {
                    line: 248,
                    column: 28
                },
                end: {
                    line: 248,
                    column: 40
                }
            },
            '68': {
                start: {
                    line: 250,
                    column: 28
                },
                end: {
                    line: 253,
                    column: 40
                }
            },
            '69': {
                start: {
                    line: 251,
                    column: 32
                },
                end: {
                    line: 251,
                    column: 55
                }
            },
            '70': {
                start: {
                    line: 252,
                    column: 32
                },
                end: {
                    line: 252,
                    column: 45
                }
            },
            '71': {
                start: {
                    line: 256,
                    column: 24
                },
                end: {
                    line: 256,
                    column: 51
                }
            },
            '72': {
                start: {
                    line: 266,
                    column: 42
                },
                end: {
                    line: 266,
                    column: 92
                }
            },
            '73': {
                start: {
                    line: 266,
                    column: 80
                },
                end: {
                    line: 266,
                    column: 82
                }
            },
            '74': {
                start: {
                    line: 267,
                    column: 35
                },
                end: {
                    line: 267,
                    column: 45
                }
            },
            '75': {
                start: {
                    line: 268,
                    column: 38
                },
                end: {
                    line: 268,
                    column: 71
                }
            },
            '76': {
                start: {
                    line: 269,
                    column: 35
                },
                end: {
                    line: 269,
                    column: 66
                }
            },
            '77': {
                start: {
                    line: 270,
                    column: 41
                },
                end: {
                    line: 270,
                    column: 73
                }
            },
            '78': {
                start: {
                    line: 279,
                    column: 20
                },
                end: {
                    line: 283,
                    column: 21
                }
            },
            '79': {
                start: {
                    line: 280,
                    column: 24
                },
                end: {
                    line: 280,
                    column: 93
                }
            },
            '80': {
                start: {
                    line: 282,
                    column: 24
                },
                end: {
                    line: 282,
                    column: 66
                }
            },
            '81': {
                start: {
                    line: 292,
                    column: 42
                },
                end: {
                    line: 292,
                    column: 98
                }
            },
            '82': {
                start: {
                    line: 292,
                    column: 86
                },
                end: {
                    line: 292,
                    column: 88
                }
            },
            '83': {
                start: {
                    line: 293,
                    column: 35
                },
                end: {
                    line: 293,
                    column: 45
                }
            },
            '84': {
                start: {
                    line: 294,
                    column: 38
                },
                end: {
                    line: 294,
                    column: 57
                }
            },
            '85': {
                start: {
                    line: 295,
                    column: 35
                },
                end: {
                    line: 295,
                    column: 52
                }
            },
            '86': {
                start: {
                    line: 296,
                    column: 41
                },
                end: {
                    line: 296,
                    column: 58
                }
            },
            '87': {
                start: {
                    line: 305,
                    column: 20
                },
                end: {
                    line: 310,
                    column: 21
                }
            },
            '88': {
                start: {
                    line: 306,
                    column: 24
                },
                end: {
                    line: 306,
                    column: 99
                }
            },
            '89': {
                start: {
                    line: 308,
                    column: 24
                },
                end: {
                    line: 309,
                    column: 113
                }
            },
            '90': {
                start: {
                    line: 319,
                    column: 58
                },
                end: {
                    line: 319,
                    column: 110
                }
            },
            '91': {
                start: {
                    line: 320,
                    column: 35
                },
                end: {
                    line: 320,
                    column: 45
                }
            },
            '92': {
                start: {
                    line: 321,
                    column: 38
                },
                end: {
                    line: 321,
                    column: 57
                }
            },
            '93': {
                start: {
                    line: 322,
                    column: 35
                },
                end: {
                    line: 322,
                    column: 52
                }
            },
            '94': {
                start: {
                    line: 323,
                    column: 41
                },
                end: {
                    line: 323,
                    column: 58
                }
            },
            '95': {
                start: {
                    line: 332,
                    column: 20
                },
                end: {
                    line: 337,
                    column: 21
                }
            },
            '96': {
                start: {
                    line: 334,
                    column: 24
                },
                end: {
                    line: 334,
                    column: 142
                }
            },
            '97': {
                start: {
                    line: 334,
                    column: 111
                },
                end: {
                    line: 334,
                    column: 131
                }
            },
            '98': {
                start: {
                    line: 336,
                    column: 24
                },
                end: {
                    line: 336,
                    column: 36
                }
            },
            '99': {
                start: {
                    line: 346,
                    column: 53
                },
                end: {
                    line: 346,
                    column: 132
                }
            },
            '100': {
                start: {
                    line: 346,
                    column: 118
                },
                end: {
                    line: 346,
                    column: 122
                }
            }
        },
        fnMap: {
            '0': {
                name: 'routesConfig',
                decl: {
                    start: {
                        line: 6,
                        column: 9
                    },
                    end: {
                        line: 6,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 77
                    },
                    end: {
                        line: 351,
                        column: 1
                    }
                },
                line: 6
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 15,
                        column: 31
                    },
                    end: {
                        line: 15,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 53
                    },
                    end: {
                        line: 15,
                        column: 81
                    }
                },
                line: 15
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 23,
                        column: 30
                    },
                    end: {
                        line: 23,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 68
                    },
                    end: {
                        line: 23,
                        column: 154
                    }
                },
                line: 23
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 23,
                        column: 116
                    },
                    end: {
                        line: 23,
                        column: 117
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 132
                    },
                    end: {
                        line: 23,
                        column: 144
                    }
                },
                line: 23
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 47,
                        column: 22
                    },
                    end: {
                        line: 47,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 47,
                        column: 36
                    },
                    end: {
                        line: 47,
                        column: 49
                    }
                },
                line: 47
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 68,
                        column: 27
                    },
                    end: {
                        line: 68,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 68,
                        column: 45
                    },
                    end: {
                        line: 68,
                        column: 75
                    }
                },
                line: 68
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 69,
                        column: 25
                    },
                    end: {
                        line: 69,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 69,
                        column: 41
                    },
                    end: {
                        line: 69,
                        column: 69
                    }
                },
                line: 69
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 70,
                        column: 20
                    },
                    end: {
                        line: 70,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 70,
                        column: 34
                    },
                    end: {
                        line: 70,
                        column: 56
                    }
                },
                line: 70
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 78,
                        column: 24
                    },
                    end: {
                        line: 78,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 78,
                        column: 56
                    },
                    end: {
                        line: 101,
                        column: 17
                    }
                },
                line: 78
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 80,
                        column: 73
                    },
                    end: {
                        line: 80,
                        column: 74
                    }
                },
                loc: {
                    start: {
                        line: 80,
                        column: 83
                    },
                    end: {
                        line: 80,
                        column: 89
                    }
                },
                line: 80
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 84,
                        column: 79
                    },
                    end: {
                        line: 84,
                        column: 80
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 89
                    },
                    end: {
                        line: 97,
                        column: 29
                    }
                },
                line: 84
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 102,
                        column: 22
                    },
                    end: {
                        line: 102,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 102,
                        column: 36
                    },
                    end: {
                        line: 102,
                        column: 58
                    }
                },
                line: 102
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 103,
                        column: 29
                    },
                    end: {
                        line: 103,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 103,
                        column: 43
                    },
                    end: {
                        line: 103,
                        column: 85
                    }
                },
                line: 103
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 113,
                        column: 25
                    },
                    end: {
                        line: 113,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 113,
                        column: 41
                    },
                    end: {
                        line: 113,
                        column: 69
                    }
                },
                line: 113
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 114,
                        column: 20
                    },
                    end: {
                        line: 114,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 114,
                        column: 34
                    },
                    end: {
                        line: 114,
                        column: 56
                    }
                },
                line: 114
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 122,
                        column: 39
                    },
                    end: {
                        line: 122,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 122,
                        column: 69
                    },
                    end: {
                        line: 122,
                        column: 105
                    }
                },
                line: 122
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 130,
                        column: 38
                    },
                    end: {
                        line: 130,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 130,
                        column: 84
                    },
                    end: {
                        line: 130,
                        column: 133
                    }
                },
                line: 130
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 138,
                        column: 25
                    },
                    end: {
                        line: 138,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 41
                    },
                    end: {
                        line: 138,
                        column: 63
                    }
                },
                line: 138
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 152,
                        column: 23
                    },
                    end: {
                        line: 152,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 37
                    },
                    end: {
                        line: 152,
                        column: 106
                    }
                },
                line: 152
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 152,
                        column: 82
                    },
                    end: {
                        line: 152,
                        column: 83
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 91
                    },
                    end: {
                        line: 152,
                        column: 96
                    }
                },
                line: 152
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 160,
                        column: 22
                    },
                    end: {
                        line: 160,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 160,
                        column: 52
                    },
                    end: {
                        line: 166,
                        column: 17
                    }
                },
                line: 160
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 162,
                        column: 71
                    },
                    end: {
                        line: 162,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 162,
                        column: 79
                    },
                    end: {
                        line: 162,
                        column: 83
                    }
                },
                line: 162
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 179,
                        column: 28
                    },
                    end: {
                        line: 179,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 179,
                        column: 42
                    },
                    end: {
                        line: 179,
                        column: 89
                    }
                },
                line: 179
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 179,
                        column: 71
                    },
                    end: {
                        line: 179,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 179,
                        column: 77
                    },
                    end: {
                        line: 179,
                        column: 79
                    }
                },
                line: 179
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 180,
                        column: 29
                    },
                    end: {
                        line: 180,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 180,
                        column: 35
                    },
                    end: {
                        line: 180,
                        column: 45
                    }
                },
                line: 180
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 181,
                        column: 32
                    },
                    end: {
                        line: 181,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 181,
                        column: 38
                    },
                    end: {
                        line: 181,
                        column: 68
                    }
                },
                line: 181
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 182,
                        column: 29
                    },
                    end: {
                        line: 182,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 182,
                        column: 35
                    },
                    end: {
                        line: 182,
                        column: 63
                    }
                },
                line: 182
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 183,
                        column: 35
                    },
                    end: {
                        line: 183,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 183,
                        column: 41
                    },
                    end: {
                        line: 183,
                        column: 69
                    }
                },
                line: 183
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 191,
                        column: 24
                    },
                    end: {
                        line: 191,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 191,
                        column: 54
                    },
                    end: {
                        line: 197,
                        column: 17
                    }
                },
                line: 191
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 205,
                        column: 28
                    },
                    end: {
                        line: 205,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 205,
                        column: 42
                    },
                    end: {
                        line: 205,
                        column: 93
                    }
                },
                line: 205
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 205,
                        column: 75
                    },
                    end: {
                        line: 205,
                        column: 76
                    }
                },
                loc: {
                    start: {
                        line: 205,
                        column: 81
                    },
                    end: {
                        line: 205,
                        column: 83
                    }
                },
                line: 205
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 206,
                        column: 29
                    },
                    end: {
                        line: 206,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 206,
                        column: 35
                    },
                    end: {
                        line: 206,
                        column: 45
                    }
                },
                line: 206
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 207,
                        column: 32
                    },
                    end: {
                        line: 207,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 207,
                        column: 38
                    },
                    end: {
                        line: 207,
                        column: 62
                    }
                },
                line: 207
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 208,
                        column: 29
                    },
                    end: {
                        line: 208,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 208,
                        column: 35
                    },
                    end: {
                        line: 208,
                        column: 57
                    }
                },
                line: 208
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 209,
                        column: 35
                    },
                    end: {
                        line: 209,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 209,
                        column: 41
                    },
                    end: {
                        line: 209,
                        column: 63
                    }
                },
                line: 209
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 217,
                        column: 32
                    },
                    end: {
                        line: 217,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 217,
                        column: 62
                    },
                    end: {
                        line: 223,
                        column: 17
                    }
                },
                line: 217
            },
            '36': {
                name: '(anonymous_36)',
                decl: {
                    start: {
                        line: 231,
                        column: 28
                    },
                    end: {
                        line: 231,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 231,
                        column: 43
                    },
                    end: {
                        line: 231,
                        column: 89
                    }
                },
                line: 231
            },
            '37': {
                name: '(anonymous_37)',
                decl: {
                    start: {
                        line: 231,
                        column: 71
                    },
                    end: {
                        line: 231,
                        column: 72
                    }
                },
                loc: {
                    start: {
                        line: 231,
                        column: 77
                    },
                    end: {
                        line: 231,
                        column: 79
                    }
                },
                line: 231
            },
            '38': {
                name: '(anonymous_38)',
                decl: {
                    start: {
                        line: 232,
                        column: 29
                    },
                    end: {
                        line: 232,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 232,
                        column: 35
                    },
                    end: {
                        line: 232,
                        column: 45
                    }
                },
                line: 232
            },
            '39': {
                name: '(anonymous_39)',
                decl: {
                    start: {
                        line: 233,
                        column: 32
                    },
                    end: {
                        line: 233,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 233,
                        column: 38
                    },
                    end: {
                        line: 233,
                        column: 59
                    }
                },
                line: 233
            },
            '40': {
                name: '(anonymous_40)',
                decl: {
                    start: {
                        line: 234,
                        column: 29
                    },
                    end: {
                        line: 234,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 234,
                        column: 35
                    },
                    end: {
                        line: 234,
                        column: 53
                    }
                },
                line: 234
            },
            '41': {
                name: '(anonymous_41)',
                decl: {
                    start: {
                        line: 235,
                        column: 35
                    },
                    end: {
                        line: 235,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 235,
                        column: 41
                    },
                    end: {
                        line: 235,
                        column: 59
                    }
                },
                line: 235
            },
            '42': {
                name: '(anonymous_42)',
                decl: {
                    start: {
                        line: 236,
                        column: 29
                    },
                    end: {
                        line: 236,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 236,
                        column: 35
                    },
                    end: {
                        line: 236,
                        column: 39
                    }
                },
                line: 236
            },
            '43': {
                name: '(anonymous_43)',
                decl: {
                    start: {
                        line: 244,
                        column: 23
                    },
                    end: {
                        line: 244,
                        column: 24
                    }
                },
                loc: {
                    start: {
                        line: 244,
                        column: 66
                    },
                    end: {
                        line: 258,
                        column: 17
                    }
                },
                line: 244
            },
            '44': {
                name: '(anonymous_44)',
                decl: {
                    start: {
                        line: 250,
                        column: 76
                    },
                    end: {
                        line: 250,
                        column: 77
                    }
                },
                loc: {
                    start: {
                        line: 250,
                        column: 85
                    },
                    end: {
                        line: 253,
                        column: 29
                    }
                },
                line: 250
            },
            '45': {
                name: '(anonymous_45)',
                decl: {
                    start: {
                        line: 266,
                        column: 28
                    },
                    end: {
                        line: 266,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 266,
                        column: 42
                    },
                    end: {
                        line: 266,
                        column: 92
                    }
                },
                line: 266
            },
            '46': {
                name: '(anonymous_46)',
                decl: {
                    start: {
                        line: 266,
                        column: 74
                    },
                    end: {
                        line: 266,
                        column: 75
                    }
                },
                loc: {
                    start: {
                        line: 266,
                        column: 80
                    },
                    end: {
                        line: 266,
                        column: 82
                    }
                },
                line: 266
            },
            '47': {
                name: '(anonymous_47)',
                decl: {
                    start: {
                        line: 267,
                        column: 29
                    },
                    end: {
                        line: 267,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 267,
                        column: 35
                    },
                    end: {
                        line: 267,
                        column: 45
                    }
                },
                line: 267
            },
            '48': {
                name: '(anonymous_48)',
                decl: {
                    start: {
                        line: 268,
                        column: 32
                    },
                    end: {
                        line: 268,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 268,
                        column: 38
                    },
                    end: {
                        line: 268,
                        column: 71
                    }
                },
                line: 268
            },
            '49': {
                name: '(anonymous_49)',
                decl: {
                    start: {
                        line: 269,
                        column: 29
                    },
                    end: {
                        line: 269,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 269,
                        column: 35
                    },
                    end: {
                        line: 269,
                        column: 66
                    }
                },
                line: 269
            },
            '50': {
                name: '(anonymous_50)',
                decl: {
                    start: {
                        line: 270,
                        column: 35
                    },
                    end: {
                        line: 270,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 270,
                        column: 41
                    },
                    end: {
                        line: 270,
                        column: 73
                    }
                },
                line: 270
            },
            '51': {
                name: '(anonymous_51)',
                decl: {
                    start: {
                        line: 278,
                        column: 22
                    },
                    end: {
                        line: 278,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 278,
                        column: 52
                    },
                    end: {
                        line: 284,
                        column: 17
                    }
                },
                line: 278
            },
            '52': {
                name: '(anonymous_52)',
                decl: {
                    start: {
                        line: 292,
                        column: 28
                    },
                    end: {
                        line: 292,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 292,
                        column: 42
                    },
                    end: {
                        line: 292,
                        column: 98
                    }
                },
                line: 292
            },
            '53': {
                name: '(anonymous_53)',
                decl: {
                    start: {
                        line: 292,
                        column: 80
                    },
                    end: {
                        line: 292,
                        column: 81
                    }
                },
                loc: {
                    start: {
                        line: 292,
                        column: 86
                    },
                    end: {
                        line: 292,
                        column: 88
                    }
                },
                line: 292
            },
            '54': {
                name: '(anonymous_54)',
                decl: {
                    start: {
                        line: 293,
                        column: 29
                    },
                    end: {
                        line: 293,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 293,
                        column: 35
                    },
                    end: {
                        line: 293,
                        column: 45
                    }
                },
                line: 293
            },
            '55': {
                name: '(anonymous_55)',
                decl: {
                    start: {
                        line: 294,
                        column: 32
                    },
                    end: {
                        line: 294,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 294,
                        column: 38
                    },
                    end: {
                        line: 294,
                        column: 57
                    }
                },
                line: 294
            },
            '56': {
                name: '(anonymous_56)',
                decl: {
                    start: {
                        line: 295,
                        column: 29
                    },
                    end: {
                        line: 295,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 295,
                        column: 35
                    },
                    end: {
                        line: 295,
                        column: 52
                    }
                },
                line: 295
            },
            '57': {
                name: '(anonymous_57)',
                decl: {
                    start: {
                        line: 296,
                        column: 35
                    },
                    end: {
                        line: 296,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 296,
                        column: 41
                    },
                    end: {
                        line: 296,
                        column: 58
                    }
                },
                line: 296
            },
            '58': {
                name: '(anonymous_58)',
                decl: {
                    start: {
                        line: 304,
                        column: 37
                    },
                    end: {
                        line: 304,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 304,
                        column: 67
                    },
                    end: {
                        line: 311,
                        column: 17
                    }
                },
                line: 304
            },
            '59': {
                name: '(anonymous_59)',
                decl: {
                    start: {
                        line: 319,
                        column: 28
                    },
                    end: {
                        line: 319,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 319,
                        column: 58
                    },
                    end: {
                        line: 319,
                        column: 110
                    }
                },
                line: 319
            },
            '60': {
                name: '(anonymous_60)',
                decl: {
                    start: {
                        line: 320,
                        column: 29
                    },
                    end: {
                        line: 320,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 320,
                        column: 35
                    },
                    end: {
                        line: 320,
                        column: 45
                    }
                },
                line: 320
            },
            '61': {
                name: '(anonymous_61)',
                decl: {
                    start: {
                        line: 321,
                        column: 32
                    },
                    end: {
                        line: 321,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 321,
                        column: 38
                    },
                    end: {
                        line: 321,
                        column: 57
                    }
                },
                line: 321
            },
            '62': {
                name: '(anonymous_62)',
                decl: {
                    start: {
                        line: 322,
                        column: 29
                    },
                    end: {
                        line: 322,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 322,
                        column: 35
                    },
                    end: {
                        line: 322,
                        column: 52
                    }
                },
                line: 322
            },
            '63': {
                name: '(anonymous_63)',
                decl: {
                    start: {
                        line: 323,
                        column: 35
                    },
                    end: {
                        line: 323,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 323,
                        column: 41
                    },
                    end: {
                        line: 323,
                        column: 58
                    }
                },
                line: 323
            },
            '64': {
                name: '(anonymous_64)',
                decl: {
                    start: {
                        line: 331,
                        column: 38
                    },
                    end: {
                        line: 331,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 331,
                        column: 84
                    },
                    end: {
                        line: 338,
                        column: 17
                    }
                },
                line: 331
            },
            '65': {
                name: '(anonymous_65)',
                decl: {
                    start: {
                        line: 334,
                        column: 87
                    },
                    end: {
                        line: 334,
                        column: 88
                    }
                },
                loc: {
                    start: {
                        line: 334,
                        column: 111
                    },
                    end: {
                        line: 334,
                        column: 131
                    }
                },
                line: 334
            },
            '66': {
                name: '(anonymous_66)',
                decl: {
                    start: {
                        line: 346,
                        column: 31
                    },
                    end: {
                        line: 346,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 346,
                        column: 53
                    },
                    end: {
                        line: 346,
                        column: 132
                    }
                },
                line: 346
            },
            '67': {
                name: '(anonymous_67)',
                decl: {
                    start: {
                        line: 346,
                        column: 110
                    },
                    end: {
                        line: 346,
                        column: 111
                    }
                },
                loc: {
                    start: {
                        line: 346,
                        column: 118
                    },
                    end: {
                        line: 346,
                        column: 122
                    }
                },
                line: 346
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 79,
                        column: 20
                    },
                    end: {
                        line: 100,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 79,
                        column: 20
                    },
                    end: {
                        line: 100,
                        column: 21
                    }
                }, {
                    start: {
                        line: 79,
                        column: 20
                    },
                    end: {
                        line: 100,
                        column: 21
                    }
                }],
                line: 79
            },
            '1': {
                loc: {
                    start: {
                        line: 83,
                        column: 24
                    },
                    end: {
                        line: 98,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 83,
                        column: 24
                    },
                    end: {
                        line: 98,
                        column: 25
                    }
                }, {
                    start: {
                        line: 83,
                        column: 24
                    },
                    end: {
                        line: 98,
                        column: 25
                    }
                }],
                line: 83
            },
            '2': {
                loc: {
                    start: {
                        line: 161,
                        column: 20
                    },
                    end: {
                        line: 165,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 161,
                        column: 20
                    },
                    end: {
                        line: 165,
                        column: 21
                    }
                }, {
                    start: {
                        line: 161,
                        column: 20
                    },
                    end: {
                        line: 165,
                        column: 21
                    }
                }],
                line: 161
            },
            '3': {
                loc: {
                    start: {
                        line: 192,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 192,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                }, {
                    start: {
                        line: 192,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                }],
                line: 192
            },
            '4': {
                loc: {
                    start: {
                        line: 218,
                        column: 20
                    },
                    end: {
                        line: 222,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 218,
                        column: 20
                    },
                    end: {
                        line: 222,
                        column: 21
                    }
                }, {
                    start: {
                        line: 218,
                        column: 20
                    },
                    end: {
                        line: 222,
                        column: 21
                    }
                }],
                line: 218
            },
            '5': {
                loc: {
                    start: {
                        line: 245,
                        column: 20
                    },
                    end: {
                        line: 257,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 245,
                        column: 20
                    },
                    end: {
                        line: 257,
                        column: 21
                    }
                }, {
                    start: {
                        line: 245,
                        column: 20
                    },
                    end: {
                        line: 257,
                        column: 21
                    }
                }],
                line: 245
            },
            '6': {
                loc: {
                    start: {
                        line: 247,
                        column: 24
                    },
                    end: {
                        line: 254,
                        column: 25
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 247,
                        column: 24
                    },
                    end: {
                        line: 254,
                        column: 25
                    }
                }, {
                    start: {
                        line: 247,
                        column: 24
                    },
                    end: {
                        line: 254,
                        column: 25
                    }
                }],
                line: 247
            },
            '7': {
                loc: {
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 283,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 283,
                        column: 21
                    }
                }, {
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 283,
                        column: 21
                    }
                }],
                line: 279
            },
            '8': {
                loc: {
                    start: {
                        line: 305,
                        column: 20
                    },
                    end: {
                        line: 310,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 305,
                        column: 20
                    },
                    end: {
                        line: 310,
                        column: 21
                    }
                }, {
                    start: {
                        line: 305,
                        column: 20
                    },
                    end: {
                        line: 310,
                        column: 21
                    }
                }],
                line: 305
            },
            '9': {
                loc: {
                    start: {
                        line: 332,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 332,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                }, {
                    start: {
                        line: 332,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                }],
                line: 332
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0,
            '94': 0,
            '95': 0,
            '96': 0,
            '97': 0,
            '98': 0,
            '99': 0,
            '100': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_219xy6xnil.s[0]++;
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    cov_219xy6xnil.f[0]++;
    cov_219xy6xnil.s[1]++;

    $locationProvider.html5Mode(true).hashPrefix('!');
    cov_219xy6xnil.s[2]++;
    $urlRouterProvider.otherwise('/');

    cov_219xy6xnil.s[3]++;
    $stateProvider.state('announcementsList', {
        url: '/anuncios/?user',
        component: 'announcementsList',
        resolve: {
            announcements: function announcements(AnnouncementsTable) {
                cov_219xy6xnil.f[1]++;
                cov_219xy6xnil.s[4]++;
                return AnnouncementsTable.$search();
            }
        },
        requireLogin: true
    }).state('announcementDetails', {
        url: '/anuncio/:id',
        component: 'announcementDetails',
        resolve: {
            announcement: function announcement(AnnouncementsTable, $stateParams) {
                cov_219xy6xnil.f[2]++;
                cov_219xy6xnil.s[5]++;
                return AnnouncementsTable.$find($stateParams.id).$then(function (announcement) {
                    cov_219xy6xnil.f[3]++;
                    cov_219xy6xnil.s[6]++;
                    return announcement;
                }).$promise;
            }
        },
        requireLogin: true
    }).state('dashboard', {
        url: '/dashboard',
        component: 'dashboard'
    }).state('login', {
        url: '/login',
        templateUrl: 'app/components/auth/views/auth.login.view.html',
        controller: 'AuthLoginController',
        controllerAs: 'vm'
    }).state('logout', {
        url: '/logout',
        template: '<div></div>',
        controller: 'AuthLogoutController',
        controllerAs: 'vm'
    }).state('profile', {
        url: '/perfil',
        template: '<user-details user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user($rootScope) {
                cov_219xy6xnil.f[4]++;
                cov_219xy6xnil.s[7]++;
                return $rootScope.me;
            }
        },
        requireLogin: true
    }).state('forgot', {
        url: '/recuperar_password',
        templateUrl: 'app/components/auth/views/auth.forgot.html',
        controller: 'AuthForgotController',
        controllerAs: 'vm'
    }).state('set_password', {
        url: '/alterar_password?token=&email=',
        templateUrl: 'app/components/auth/views/auth.set.password.html',
        controller: 'AuthSetPasswordController',
        controllerAs: 'vm'
    }).state('samplesList', {
        url: '/?search&sample_type__in&brand&brand__commercial&brand__stylist_user&brand__accessories_commercial_user&decoration__in&foreseen_date__gte&season&foreseen_date__lte&intended_date__gte&intended_date__lte&shipping_date__gte&shipping_date__lte&foreseen_date__isnull&has_mesh&viewType&finishing__in&myWork&workflow_state&fast_track&decoration_position&confection_overseer&mesh_type&page&ordering',
        component: 'samplesListView',
        reloadOnSearch: false,
        resolve: {
            workloads: function workloads(WorkloadsTable) {
                cov_219xy6xnil.f[5]++;
                cov_219xy6xnil.s[8]++;
                return WorkloadsTable.$collection({});
            },
            samples: function samples(SamplesTable) {
                cov_219xy6xnil.f[6]++;
                cov_219xy6xnil.s[9]++;
                return SamplesTable.$collection({});
            },
            me: function me($rootScope) {
                cov_219xy6xnil.f[7]++;
                cov_219xy6xnil.s[10]++;
                return $rootScope.me.$promise;
            }
        },
        requireLogin: true
    }).state('sampleDetails', {
        url: '/amostras/:id?copy&tab&is_virtual',
        component: 'sampleDetailsView',
        resolve: {
            sample: function sample(SamplesTable, $stateParams) {
                cov_219xy6xnil.f[8]++;
                cov_219xy6xnil.s[11]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[0][0]++;
                    cov_219xy6xnil.s[12]++;

                    return SamplesTable.$find($stateParams.id).$then(function (sample) {
                        cov_219xy6xnil.f[9]++;
                        cov_219xy6xnil.s[13]++;
                        return sample;
                    }).$promise;
                    /* eslint-disable no-else-return */
                } else {
                    cov_219xy6xnil.b[0][1]++;
                    cov_219xy6xnil.s[14]++;

                    if ($stateParams.copy) {
                        cov_219xy6xnil.b[1][0]++;
                        cov_219xy6xnil.s[15]++;

                        return SamplesTable.$find($stateParams.copy).$then(function (sample) {
                            cov_219xy6xnil.f[10]++;

                            /* eslint-disable no-redeclare */
                            /* eslint-disable no-var */
                            /* eslint-disable dot-notation */
                            var sample = (cov_219xy6xnil.s[16]++, SamplesTable.$build(sample));
                            cov_219xy6xnil.s[17]++;
                            delete sample['id'];
                            cov_219xy6xnil.s[18]++;
                            delete sample['sketch'];
                            cov_219xy6xnil.s[19]++;
                            delete sample['clientReference'];
                            cov_219xy6xnil.s[20]++;
                            delete sample['orderReference'];
                            cov_219xy6xnil.s[21]++;
                            delete sample['currentState'];
                            cov_219xy6xnil.s[22]++;
                            delete sample['created'];
                            cov_219xy6xnil.s[23]++;
                            delete sample['updated'];
                            cov_219xy6xnil.s[24]++;
                            return sample;
                        }).$promise;
                    } else {
                        cov_219xy6xnil.b[1][1]++;
                    }
                    cov_219xy6xnil.s[25]++;
                    return SamplesTable.$build();
                }
            },
            user: function user($rootScope) {
                cov_219xy6xnil.f[11]++;
                cov_219xy6xnil.s[26]++;
                return $rootScope.me.$promise;
            },
            permissions: function permissions($rootScope) {
                cov_219xy6xnil.f[12]++;
                cov_219xy6xnil.s[27]++;
                return $rootScope.sampleWritePermissions.$promise;
            }
        },
        requireLogin: true
    }).state('pricesList', {
        url: '/precos/?search&brand&brand__commercial&has_accessories_prices_validation&has_accessories_quantities_validation&has_confection_prices_validation&has_commercial_validation&page&ordering&severe_price_delay&validation_count&is_price_closed&accessories_prices_validation_delay&brand__accessories_commercial_user&has_print&current_state__sample_state_type',
        component: 'pricesListView',
        reloadOnSearch: false,
        resolve: {
            /* eslint-disable camelcase */
            samples: function samples(SamplesTable) {
                cov_219xy6xnil.f[13]++;
                cov_219xy6xnil.s[28]++;
                return SamplesTable.$collection({});
            },
            me: function me($rootScope) {
                cov_219xy6xnil.f[14]++;
                cov_219xy6xnil.s[29]++;
                return $rootScope.me.$promise;
            }
        },
        requireLogin: true
    }).state('sampleDevelopmentLogs', {
        url: '/registos/?confection_overseer',
        component: 'sampleDevelopmentLogs',
        resolve: {
            sampleDevelopmentLogs: function sampleDevelopmentLogs(SampleDevelopmentLogsTable) {
                cov_219xy6xnil.f[15]++;
                cov_219xy6xnil.s[30]++;
                return SampleDevelopmentLogsTable.$search();
            }
        },
        requireLogin: true
    }).state('sampleDevelopmentLogDetails', {
        url: '/registo/:id',
        component: 'sampleDevelopmentLogDetails',
        resolve: {
            sampleDevelopmentLog: function sampleDevelopmentLog(SampleDevelopmentLogsTable, $stateParams) {
                cov_219xy6xnil.f[16]++;
                cov_219xy6xnil.s[31]++;
                return SampleDevelopmentLogsTable.$find($stateParams.id);
            }
        },
        requireLogin: true
    }).state('reportsList', {
        url: '/relatorios/',
        component: 'reportsList',
        resolve: {
            reports: function reports(ReportsTable) {
                cov_219xy6xnil.f[17]++;
                cov_219xy6xnil.s[32]++;
                return ReportsTable.$search();
            }
        },
        requireLogin: true
    }).state('reportDetails', {
        url: '/relatorio/:id',
        component: 'reportDetails',
        requireLogin: true
    }).state('usersList', {
        url: '/utilizadores',
        template: '<users-list users="$resolve.users" flex layout="column"/>',
        resolve: {
            /* eslint-disable camelcase */
            users: function users(UsersTable) {
                cov_219xy6xnil.f[18]++;
                cov_219xy6xnil.s[33]++;
                return UsersTable.$search({ page_size: 99999 }).$then(function (users) {
                    cov_219xy6xnil.f[19]++;
                    cov_219xy6xnil.s[34]++;
                    return users;
                }).$promise;
            }
        },
        requireLogin: true
    }).state('userDetails', {
        url: '/utilizador/:id',
        template: '<user-details user="$resolve.user" flex layout="column"/>',
        resolve: {
            user: function user(UsersTable, $stateParams) {
                cov_219xy6xnil.f[20]++;
                cov_219xy6xnil.s[35]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[2][0]++;
                    cov_219xy6xnil.s[36]++;

                    return UsersTable.$find($stateParams.id).$then(function (user) {
                        cov_219xy6xnil.f[21]++;
                        cov_219xy6xnil.s[37]++;
                        return user;
                    }).$promise;
                } else {
                    cov_219xy6xnil.b[2][1]++;
                    cov_219xy6xnil.s[38]++;

                    return UsersTable.$build({ type: $stateParams.type });
                }
            }
        },
        requireLogin: true
    }).state('settings', {
        url: '/configuracoes',
        component: 'settingsView',
        requireLogin: true
    }).state('settings.sampleTypes', {
        url: '/tipos-de-amostras',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection($rootScope) {
                cov_219xy6xnil.f[22]++;
                cov_219xy6xnil.s[39]++;
                return $rootScope.sampleTypes.$then(function (st) {
                    cov_219xy6xnil.f[23]++;
                    cov_219xy6xnil.s[40]++;
                    return st;
                }).$promise;
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[24]++;
                cov_219xy6xnil.s[41]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[25]++;
                cov_219xy6xnil.s[42]++;
                return 'Selecione um tipo de amostra';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[26]++;
                cov_219xy6xnil.s[43]++;
                return 'Criar novo tipo de amostra';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[27]++;
                cov_219xy6xnil.s[44]++;
                return 'Pesquisar tipos de amostra';
            }
        },
        requireLogin: true
    }).state('settings.sampleTypes.details', {
        url: '/:id',
        component: 'sampleTypeDetailsView',
        resolve: {
            sample: function sample($rootScope, $stateParams) {
                cov_219xy6xnil.f[28]++;
                cov_219xy6xnil.s[45]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[3][0]++;
                    cov_219xy6xnil.s[46]++;

                    return $rootScope.sampleTypes.$get(parseInt($stateParams.id, 10));
                } else {
                    cov_219xy6xnil.b[3][1]++;
                    cov_219xy6xnil.s[47]++;

                    return $rootScope.sampleTypes.$build();
                }
            }
        },
        requireLogin: true
    }).state('settings.decorationTypes', {
        url: '/ornamentos',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection($rootScope) {
                cov_219xy6xnil.f[29]++;
                cov_219xy6xnil.s[48]++;
                return $rootScope.decorationTypes.$then(function (st) {
                    cov_219xy6xnil.f[30]++;
                    cov_219xy6xnil.s[49]++;
                    return st;
                }).$promise;
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[31]++;
                cov_219xy6xnil.s[50]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[32]++;
                cov_219xy6xnil.s[51]++;
                return 'Selecione um ornamento';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[33]++;
                cov_219xy6xnil.s[52]++;
                return 'Criar novo ornamento';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[34]++;
                cov_219xy6xnil.s[53]++;
                return 'Pesquisar ornamentos';
            }
        },
        requireLogin: true
    }).state('settings.decorationTypes.details', {
        url: '/:id',
        component: 'decorationTypeDetailsView',
        resolve: {
            decorationType: function decorationType($rootScope, $stateParams) {
                cov_219xy6xnil.f[35]++;
                cov_219xy6xnil.s[54]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[4][0]++;
                    cov_219xy6xnil.s[55]++;

                    return $rootScope.decorationTypes.$get(parseInt($stateParams.id, 10));
                } else {
                    cov_219xy6xnil.b[4][1]++;
                    cov_219xy6xnil.s[56]++;

                    return $rootScope.decorationTypes.$build();
                }
            }
        },
        requireLogin: true
    }).state('settings.brands', {
        url: '/marcas',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection(BrandsTable) {
                cov_219xy6xnil.f[36]++;
                cov_219xy6xnil.s[57]++;
                return BrandsTable.$search().$then(function (st) {
                    cov_219xy6xnil.f[37]++;
                    cov_219xy6xnil.s[58]++;
                    return st;
                }).$promise;
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[38]++;
                cov_219xy6xnil.s[59]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[39]++;
                cov_219xy6xnil.s[60]++;
                return 'Selecione uma marca';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[40]++;
                cov_219xy6xnil.s[61]++;
                return 'Criar nova marca';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[41]++;
                cov_219xy6xnil.s[62]++;
                return 'Pesquisar marcas';
            },
            searchOnAPI: function searchOnAPI() {
                cov_219xy6xnil.f[42]++;
                cov_219xy6xnil.s[63]++;
                return true;
            }
        },
        requireLogin: true
    }).state('settings.brands.details', {
        url: '/:id',
        component: 'brandDetailsView',
        resolve: {
            brand: function brand(collection, $stateParams, BrandsTable) {
                cov_219xy6xnil.f[43]++;
                cov_219xy6xnil.s[64]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[5][0]++;

                    var item = (cov_219xy6xnil.s[65]++, collection.$get($stateParams.id));
                    cov_219xy6xnil.s[66]++;
                    if (item) {
                        cov_219xy6xnil.b[6][0]++;
                        cov_219xy6xnil.s[67]++;

                        return item;
                    } else {
                        cov_219xy6xnil.b[6][1]++;
                        cov_219xy6xnil.s[68]++;

                        return BrandsTable.$find($stateParams.id).$then(function (brand) {
                            cov_219xy6xnil.f[44]++;
                            cov_219xy6xnil.s[69]++;

                            collection.$add(brand);
                            cov_219xy6xnil.s[70]++;
                            return brand;
                        }).$promise;
                    }
                } else {
                    cov_219xy6xnil.b[5][1]++;
                    cov_219xy6xnil.s[71]++;

                    return collection.$build();
                }
            }
        },
        requireLogin: true
    }).state('settings.finishingTypes', {
        url: '/tipos-de-acabamentos',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection($rootScope) {
                cov_219xy6xnil.f[45]++;
                cov_219xy6xnil.s[72]++;
                return $rootScope.finishingTypes.$then(function (st) {
                    cov_219xy6xnil.f[46]++;
                    cov_219xy6xnil.s[73]++;
                    return st;
                }).$promise;
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[47]++;
                cov_219xy6xnil.s[74]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[48]++;
                cov_219xy6xnil.s[75]++;
                return 'Selecione um tipo de acabamento';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[49]++;
                cov_219xy6xnil.s[76]++;
                return 'Criar novo tipo de acabamento';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[50]++;
                cov_219xy6xnil.s[77]++;
                return 'Pesquisar tipos de acabamentos';
            }
        },
        requireLogin: true
    }).state('settings.finishingTypes.details', {
        url: '/:id',
        component: 'finishingTypeDetailsView',
        resolve: {
            type: function type($rootScope, $stateParams) {
                cov_219xy6xnil.f[51]++;
                cov_219xy6xnil.s[78]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[7][0]++;
                    cov_219xy6xnil.s[79]++;

                    return $rootScope.finishingTypes.$get(parseInt($stateParams.id, 10));
                } else {
                    cov_219xy6xnil.b[7][1]++;
                    cov_219xy6xnil.s[80]++;

                    return $rootScope.finishingTypes.$build();
                }
            }
        },
        requireLogin: true
    }).state('settings.sampleItemPriceTypes', {
        url: '/precos',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection($rootScope) {
                cov_219xy6xnil.f[52]++;
                cov_219xy6xnil.s[81]++;
                return $rootScope.sampleItemPriceTypes.$then(function (st) {
                    cov_219xy6xnil.f[53]++;
                    cov_219xy6xnil.s[82]++;
                    return st;
                }).$promise;
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[54]++;
                cov_219xy6xnil.s[83]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[55]++;
                cov_219xy6xnil.s[84]++;
                return 'Selecione um item';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[56]++;
                cov_219xy6xnil.s[85]++;
                return 'Criar novo item';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[57]++;
                cov_219xy6xnil.s[86]++;
                return 'Pesquisar items';
            }
        },
        requireLogin: true
    }).state('settings.sampleItemPriceTypes.details', {
        url: '/:id',
        component: 'sampleItemPriceTypeDetailsView',
        resolve: {
            sampleItemPriceType: function sampleItemPriceType($rootScope, $stateParams) {
                cov_219xy6xnil.f[58]++;
                cov_219xy6xnil.s[87]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[8][0]++;
                    cov_219xy6xnil.s[88]++;

                    return $rootScope.sampleItemPriceTypes.$get(parseInt($stateParams.id, 10));
                } else {
                    cov_219xy6xnil.b[8][1]++;
                    cov_219xy6xnil.s[89]++;

                    return $rootScope.sampleItemPriceTypes.$build({
                        hasSize: true, hasPrice: true, hasLength: true, hasHeight: true, hasQuantity: true });
                }
            }
        },
        requireLogin: true
    }).state('settings.notificationTemplates', {
        url: '/notificacoes',
        component: 'selectAndEdit',
        resolve: {
            collection: function collection(NotificationTemplatesTable) {
                cov_219xy6xnil.f[59]++;
                cov_219xy6xnil.s[90]++;
                return NotificationTemplatesTable.$search({ page_size: 999 });
            },
            selectState: function selectState() {
                cov_219xy6xnil.f[60]++;
                cov_219xy6xnil.s[91]++;
                return '.details';
            },
            selectItemText: function selectItemText() {
                cov_219xy6xnil.f[61]++;
                cov_219xy6xnil.s[92]++;
                return 'Selecione um item';
            },
            newItemText: function newItemText() {
                cov_219xy6xnil.f[62]++;
                cov_219xy6xnil.s[93]++;
                return 'Criar novo item';
            },
            searchPlaceholder: function searchPlaceholder() {
                cov_219xy6xnil.f[63]++;
                cov_219xy6xnil.s[94]++;
                return 'Pesquisar items';
            }
        },
        requireLogin: true
    }).state('settings.notificationTemplates.details', {
        url: '/:id',
        component: 'notificationTemplatesDetailsView',
        resolve: {
            notificationTemplate: function notificationTemplate($stateParams, NotificationTemplatesTable) {
                cov_219xy6xnil.f[64]++;
                cov_219xy6xnil.s[95]++;

                if ($stateParams.id) {
                    cov_219xy6xnil.b[9][0]++;
                    cov_219xy6xnil.s[96]++;

                    // console.log('what is my id=', $stateParams.id);
                    return NotificationTemplatesTable.$find($stateParams.id).$then(function (notificationTemplate) {
                        cov_219xy6xnil.f[65]++;
                        cov_219xy6xnil.s[97]++;
                        return notificationTemplate;
                    }).$promise;
                } else {
                    cov_219xy6xnil.b[9][1]++;
                    cov_219xy6xnil.s[98]++;

                    return null;
                }
            }
        },
        requireLogin: true
    }).state('notificationsView', {
        url: '/notificacoes',
        component: 'notificationsView',
        resolve: {
            notifications: function notifications(NotificationsTable) {
                cov_219xy6xnil.f[66]++;
                cov_219xy6xnil.s[99]++;
                return NotificationsTable.$search({ ordering: '-created' }).$then(function (item) {
                    cov_219xy6xnil.f[67]++;
                    cov_219xy6xnil.s[100]++;
                    return item;
                }).$promise;
            }
        },
        requireLogin: true
    });
}