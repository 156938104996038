'use strict';

var cov_znhv0bk4d = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/samplePhotos/samplePhotos.js',
        hash = '2058c1edf8fbcca9c2799171ee7476d479c63dcb',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/samplePhotos/samplePhotos.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 25
                },
                end: {
                    line: 12,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 14,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 11
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 12
                },
                end: {
                    line: 15,
                    column: 31
                }
            },
            '5': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 30
                }
            },
            '6': {
                start: {
                    line: 24,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 51
                }
            },
            '7': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 21
                }
            },
            '8': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 51
                }
            },
            '9': {
                start: {
                    line: 33,
                    column: 8
                },
                end: {
                    line: 33,
                    column: 21
                }
            },
            '10': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 51
                }
            },
            '11': {
                start: {
                    line: 41,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 33
                }
            },
            '12': {
                start: {
                    line: 42,
                    column: 8
                },
                end: {
                    line: 42,
                    column: 37
                }
            },
            '13': {
                start: {
                    line: 43,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 33
                }
            },
            '14': {
                start: {
                    line: 44,
                    column: 8
                },
                end: {
                    line: 44,
                    column: 51
                }
            },
            '15': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 39
                }
            },
            '16': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 67
                }
            },
            '17': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 35
                }
            },
            '18': {
                start: {
                    line: 51,
                    column: 8
                },
                end: {
                    line: 51,
                    column: 36
                }
            },
            '19': {
                start: {
                    line: 55,
                    column: 25
                },
                end: {
                    line: 55,
                    column: 77
                }
            },
            '20': {
                start: {
                    line: 56,
                    column: 22
                },
                end: {
                    line: 56,
                    column: 62
                }
            },
            '21': {
                start: {
                    line: 58,
                    column: 23
                },
                end: {
                    line: 70,
                    column: 9
                }
            },
            '22': {
                start: {
                    line: 71,
                    column: 8
                },
                end: {
                    line: 71,
                    column: 35
                }
            },
            '23': {
                start: {
                    line: 75,
                    column: 21
                },
                end: {
                    line: 75,
                    column: 23
                }
            },
            '24': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 9
                }
            },
            '25': {
                start: {
                    line: 77,
                    column: 12
                },
                end: {
                    line: 77,
                    column: 53
                }
            },
            '26': {
                start: {
                    line: 80,
                    column: 20
                },
                end: {
                    line: 80,
                    column: 55
                }
            },
            '27': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 68
                }
            },
            '28': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 42
                }
            },
            '29': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 46
                }
            },
            '30': {
                start: {
                    line: 90,
                    column: 8
                },
                end: {
                    line: 91,
                    column: 91
                }
            },
            '31': {
                start: {
                    line: 90,
                    column: 27
                },
                end: {
                    line: 91,
                    column: 89
                }
            },
            '32': {
                start: {
                    line: 91,
                    column: 21
                },
                end: {
                    line: 91,
                    column: 88
                }
            },
            '33': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 95,
                    column: 33
                }
            },
            '34': {
                start: {
                    line: 96,
                    column: 20
                },
                end: {
                    line: 96,
                    column: 71
                }
            },
            '35': {
                start: {
                    line: 97,
                    column: 8
                },
                end: {
                    line: 97,
                    column: 43
                }
            },
            '36': {
                start: {
                    line: 101,
                    column: 20
                },
                end: {
                    line: 101,
                    column: 40
                }
            },
            '37': {
                start: {
                    line: 102,
                    column: 8
                },
                end: {
                    line: 102,
                    column: 35
                }
            },
            '38': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 41
                }
            },
            '39': {
                start: {
                    line: 104,
                    column: 8
                },
                end: {
                    line: 114,
                    column: 10
                }
            },
            '40': {
                start: {
                    line: 105,
                    column: 25
                },
                end: {
                    line: 105,
                    column: 62
                }
            },
            '41': {
                start: {
                    line: 106,
                    column: 36
                },
                end: {
                    line: 106,
                    column: 65
                }
            },
            '42': {
                start: {
                    line: 107,
                    column: 25
                },
                end: {
                    line: 107,
                    column: 60
                }
            },
            '43': {
                start: {
                    line: 109,
                    column: 27
                },
                end: {
                    line: 109,
                    column: 54
                }
            },
            '44': {
                start: {
                    line: 111,
                    column: 12
                },
                end: {
                    line: 111,
                    column: 59
                }
            },
            '45': {
                start: {
                    line: 112,
                    column: 12
                },
                end: {
                    line: 112,
                    column: 39
                }
            },
            '46': {
                start: {
                    line: 113,
                    column: 12
                },
                end: {
                    line: 113,
                    column: 27
                }
            },
            '47': {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 115,
                    column: 19
                }
            },
            '48': {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 124,
                    column: 15
                }
            },
            '49': {
                start: {
                    line: 121,
                    column: 28
                },
                end: {
                    line: 121,
                    column: 75
                }
            },
            '50': {
                start: {
                    line: 122,
                    column: 33
                },
                end: {
                    line: 122,
                    column: 83
                }
            },
            '51': {
                start: {
                    line: 123,
                    column: 16
                },
                end: {
                    line: 123,
                    column: 52
                }
            },
            '52': {
                start: {
                    line: 128,
                    column: 24
                },
                end: {
                    line: 132,
                    column: 38
                }
            },
            '53': {
                start: {
                    line: 134,
                    column: 8
                },
                end: {
                    line: 137,
                    column: 25
                }
            },
            '54': {
                start: {
                    line: 136,
                    column: 16
                },
                end: {
                    line: 136,
                    column: 31
                }
            },
            '55': {
                start: {
                    line: 142,
                    column: 0
                },
                end: {
                    line: 150,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 28
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 14
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 18
                    },
                    end: {
                        line: 17,
                        column: 5
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 14,
                        column: 30
                    },
                    end: {
                        line: 14,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 36
                    },
                    end: {
                        line: 16,
                        column: 9
                    }
                },
                line: 14
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 19,
                        column: 4
                    },
                    end: {
                        line: 19,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 18
                    },
                    end: {
                        line: 21,
                        column: 5
                    }
                },
                line: 19
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 23,
                        column: 4
                    },
                    end: {
                        line: 23,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 23,
                        column: 14
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 23
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 27,
                        column: 4
                    },
                    end: {
                        line: 27,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 27,
                        column: 15
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                line: 27
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 32,
                        column: 4
                    },
                    end: {
                        line: 32,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 32,
                        column: 11
                    },
                    end: {
                        line: 35,
                        column: 5
                    }
                },
                line: 32
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 40,
                        column: 4
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 40,
                        column: 99
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 40
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 14
                    },
                    end: {
                        line: 52,
                        column: 5
                    }
                },
                line: 50
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 54,
                        column: 4
                    },
                    end: {
                        line: 54,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 32
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                line: 54
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 20
                    },
                    end: {
                        line: 82,
                        column: 5
                    }
                },
                line: 74
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 84,
                        column: 4
                    },
                    end: {
                        line: 84,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 84,
                        column: 37
                    },
                    end: {
                        line: 86,
                        column: 5
                    }
                },
                line: 84
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 88,
                        column: 4
                    },
                    end: {
                        line: 88,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 88,
                        column: 39
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                line: 88
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 90,
                        column: 19
                    },
                    end: {
                        line: 90,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 90,
                        column: 27
                    },
                    end: {
                        line: 91,
                        column: 89
                    }
                },
                line: 90
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 91,
                        column: 12
                    },
                    end: {
                        line: 91,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 91,
                        column: 21
                    },
                    end: {
                        line: 91,
                        column: 88
                    }
                },
                line: 91
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 94,
                        column: 4
                    },
                    end: {
                        line: 94,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 94,
                        column: 34
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                line: 94
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 100,
                        column: 4
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 100,
                        column: 35
                    },
                    end: {
                        line: 116,
                        column: 5
                    }
                },
                line: 100
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 104,
                        column: 21
                    },
                    end: {
                        line: 104,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 33
                    },
                    end: {
                        line: 114,
                        column: 9
                    }
                },
                line: 104
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 118,
                        column: 4
                    },
                    end: {
                        line: 118,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 118,
                        column: 18
                    },
                    end: {
                        line: 125,
                        column: 5
                    }
                },
                line: 118
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 120,
                        column: 12
                    },
                    end: {
                        line: 120,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 120,
                        column: 24
                    },
                    end: {
                        line: 124,
                        column: 13
                    }
                },
                line: 120
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 127,
                        column: 4
                    },
                    end: {
                        line: 127,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 127,
                        column: 21
                    },
                    end: {
                        line: 138,
                        column: 5
                    }
                },
                line: 127
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 135,
                        column: 12
                    },
                    end: {
                        line: 135,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 135,
                        column: 18
                    },
                    end: {
                        line: 137,
                        column: 13
                    }
                },
                line: 135
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 137,
                        column: 15
                    },
                    end: {
                        line: 137,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 21
                    },
                    end: {
                        line: 137,
                        column: 23
                    }
                },
                line: 137
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                }, {
                    start: {
                        line: 76,
                        column: 8
                    },
                    end: {
                        line: 78,
                        column: 9
                    }
                }],
                line: 76
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SamplePhotoModalController = function () {
    function SamplePhotoModalController(mdPanelRef) {
        _classCallCheck(this, SamplePhotoModalController);

        cov_znhv0bk4d.f[0]++;
        cov_znhv0bk4d.s[0]++;

        this._mdPanelRef = mdPanelRef;
    }

    _createClass(SamplePhotoModalController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_znhv0bk4d.f[1]++;
            cov_znhv0bk4d.s[1]++;

            this.samplePhoto = this.photos[this.index];
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_znhv0bk4d.f[2]++;

            var panelRef = (cov_znhv0bk4d.s[2]++, this._mdPanelRef);

            cov_znhv0bk4d.s[3]++;
            panelRef.close().then(function () {
                cov_znhv0bk4d.f[3]++;
                cov_znhv0bk4d.s[4]++;

                panelRef.destroy();
            });
        }
    }, {
        key: 'hasPrevious',
        value: function hasPrevious() {
            cov_znhv0bk4d.f[4]++;
            cov_znhv0bk4d.s[5]++;

            return this.index > 0;
        }
    }, {
        key: 'hasNext',
        value: function hasNext() {
            cov_znhv0bk4d.f[5]++;
            cov_znhv0bk4d.s[6]++;

            return this.index < this.photos.length - 1;
        }
    }, {
        key: 'previous',
        value: function previous() {
            cov_znhv0bk4d.f[6]++;
            cov_znhv0bk4d.s[7]++;

            this.index--;
            cov_znhv0bk4d.s[8]++;
            this.samplePhoto = this.photos[this.index];
        }
    }, {
        key: 'next',
        value: function next() {
            cov_znhv0bk4d.f[7]++;
            cov_znhv0bk4d.s[9]++;

            this.index++;
            cov_znhv0bk4d.s[10]++;
            this.samplePhoto = this.photos[this.index];
        }
    }]);

    return SamplePhotoModalController;
}();

var SamplePhotosController = function () {
    function SamplePhotosController($mdPanel, $rootScope, $mdToast, AppSettings, SamplePhotoDownloadsTable, $mdDialog) {
        _classCallCheck(this, SamplePhotosController);

        cov_znhv0bk4d.f[8]++;
        cov_znhv0bk4d.s[11]++;

        this.$mdPanel = $mdPanel;
        cov_znhv0bk4d.s[12]++;
        this.$rootScope = $rootScope;
        cov_znhv0bk4d.s[13]++;
        this.$mdToast = $mdToast;
        cov_znhv0bk4d.s[14]++;
        this.showToast = this.showToast.bind(this);
        cov_znhv0bk4d.s[15]++;
        this.AppSettings = AppSettings;
        cov_znhv0bk4d.s[16]++;
        this.SamplePhotoDownloadsTable = SamplePhotoDownloadsTable;
        cov_znhv0bk4d.s[17]++;
        this.$mdDialog = $mdDialog;
    }

    _createClass(SamplePhotosController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_znhv0bk4d.f[9]++;
            cov_znhv0bk4d.s[18]++;

            this.sample.$load('photos');
        }
    }, {
        key: 'showPhotoModal',
        value: function showPhotoModal(samplePhoto) {
            cov_znhv0bk4d.f[10]++;

            var position = (cov_znhv0bk4d.s[19]++, this.$mdPanel.newPanelPosition().absolute().center());
            var index = (cov_znhv0bk4d.s[20]++, this.sample.$photos.indexOf(samplePhoto));

            var config = (cov_znhv0bk4d.s[21]++, {
                /** @ngInject */
                controller: SamplePhotoModalController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/samplePhotos/samplePhotosModal.tmpl.html',
                panelClass: 'sample-photo-dialog',
                position: position,
                zIndex: 150,
                locals: {
                    photos: this.sample.$photos,
                    index: index
                }
            });
            cov_znhv0bk4d.s[22]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'showToast',
        value: function showToast(file) {
            cov_znhv0bk4d.f[11]++;

            var reason = (cov_znhv0bk4d.s[23]++, '');
            cov_znhv0bk4d.s[24]++;
            if (file.$error === 'maxSize') {
                cov_znhv0bk4d.b[0][0]++;
                cov_znhv0bk4d.s[25]++;

                reason = ' .Tamanho excedido (máx 5MB).';
            } else {
                cov_znhv0bk4d.b[0][1]++;
            }
            /* eslint-disable prefer-template */
            var msg = (cov_znhv0bk4d.s[26]++, 'Erro imagem ' + file.name + reason);
            cov_znhv0bk4d.s[27]++;
            this.$mdToast.show(this.$mdToast.simple().textContent(msg));
        }
    }, {
        key: 'alertInvalidFiles',
        value: function alertInvalidFiles($invalidFiles) {
            cov_znhv0bk4d.f[12]++;
            cov_znhv0bk4d.s[28]++;

            $invalidFiles.map(this.showToast);
        }
    }, {
        key: 'uploadFiles',
        value: function uploadFiles($files, $invalidFiles) {
            var _this = this;

            cov_znhv0bk4d.f[13]++;
            cov_znhv0bk4d.s[29]++;

            this.alertInvalidFiles($invalidFiles);
            cov_znhv0bk4d.s[30]++;
            $files.map(function (file) {
                cov_znhv0bk4d.f[14]++;
                cov_znhv0bk4d.s[31]++;
                return _this.$rootScope.$Media.upload(file).then(function (media) {
                    cov_znhv0bk4d.f[15]++;
                    cov_znhv0bk4d.s[32]++;
                    return _this.sample.$photos.$create({ sample: _this.sample.id, photo: media });
                });
            });
        }
    }, {
        key: 'download',
        value: function download($event, samplePhoto) {
            cov_znhv0bk4d.f[16]++;
            cov_znhv0bk4d.s[33]++;

            $event.stopPropagation();
            var url = (cov_znhv0bk4d.s[34]++, this.AppSettings.mediaUrl + '/' + samplePhoto.photo);
            cov_znhv0bk4d.s[35]++;
            this.downloadRequest(url, 'photo');
        }
    }, {
        key: 'downloadRequest',
        value: function downloadRequest(url, filename) {
            cov_znhv0bk4d.f[17]++;

            var xhr = (cov_znhv0bk4d.s[36]++, new XMLHttpRequest());
            cov_znhv0bk4d.s[37]++;
            xhr.open("GET", url, true);
            cov_znhv0bk4d.s[38]++;
            xhr.responseType = "arraybuffer";
            cov_znhv0bk4d.s[39]++;
            xhr.onload = function () {
                cov_znhv0bk4d.f[18]++;

                var type = (cov_znhv0bk4d.s[40]++, xhr.getResponseHeader("content-type"));
                var arrayBufferView = (cov_znhv0bk4d.s[41]++, new Uint8Array(this.response));
                var blob = (cov_znhv0bk4d.s[42]++, new Blob([arrayBufferView], { type: type }));
                /* eslint-disable angular/document-service */
                var anchor = (cov_znhv0bk4d.s[43]++, document.createElement('a'));
                /* eslint-disable angular/window-service */
                cov_znhv0bk4d.s[44]++;
                anchor.href = window.URL.createObjectURL(blob);
                cov_znhv0bk4d.s[45]++;
                anchor.download = filename;
                cov_znhv0bk4d.s[46]++;
                anchor.click();
            };
            cov_znhv0bk4d.s[47]++;
            xhr.send();
        }
    }, {
        key: 'downloadAll',
        value: function downloadAll() {
            var _this2 = this;

            cov_znhv0bk4d.f[19]++;
            cov_znhv0bk4d.s[48]++;

            this.SamplePhotoDownloadsTable.$create({ sample: this.sample.id }).$then(function (response) {
                cov_znhv0bk4d.f[20]++;

                var url = (cov_znhv0bk4d.s[49]++, _this2.AppSettings.mediaUrl + '/' + response.file);
                var filename = (cov_znhv0bk4d.s[50]++, 'sample_' + _this2.sample.orderReference + '_photos');
                cov_znhv0bk4d.s[51]++;
                _this2.downloadRequest(url, filename);
            });
        }
    }, {
        key: 'deletePhoto',
        value: function deletePhoto(obj) {
            cov_znhv0bk4d.f[21]++;

            var confirm = (cov_znhv0bk4d.s[52]++, this.$mdDialog.confirm().title('Apagar Foto?').textContent('Esta ação é irreversível. Quer prosseguir?').ok('Sim').cancel('Não, quero rever.'));

            cov_znhv0bk4d.s[53]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_znhv0bk4d.f[22]++;
                cov_znhv0bk4d.s[54]++;

                obj.$destroy();
            }, function () {
                cov_znhv0bk4d.f[23]++;
            });
        }
    }]);

    return SamplePhotosController;
}();

cov_znhv0bk4d.s[55]++;


angular.module('app').component('samplePhotos', {
    controller: SamplePhotosController,
    templateUrl: 'app/components/samplePhotos/samplePhotos.html',
    bindings: {
        sample: '<'
    }
});