'use strict';

var cov_2fy735b3k = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/samplesDisplayList/samplesDisplayList.js',
        hash = '082b82d392c253ac4d2274f93295759cf0c47678',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/samplesDisplayList/samplesDisplayList.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 29
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 35
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 51
                }
            },
            '4': {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 37
                }
            },
            '5': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 28
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 22
                },
                end: {
                    line: 15,
                    column: 48
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 9
                }
            },
            '8': {
                start: {
                    line: 17,
                    column: 12
                },
                end: {
                    line: 19,
                    column: 13
                }
            },
            '9': {
                start: {
                    line: 18,
                    column: 16
                },
                end: {
                    line: 18,
                    column: 37
                }
            },
            '10': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 53
                }
            },
            '11': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 36
                }
            },
            '12': {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 66
                }
            },
            '13': {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 36
                }
            },
            '14': {
                start: {
                    line: 31,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 9
                }
            },
            '15': {
                start: {
                    line: 32,
                    column: 12
                },
                end: {
                    line: 32,
                    column: 53
                }
            },
            '16': {
                start: {
                    line: 34,
                    column: 12
                },
                end: {
                    line: 34,
                    column: 61
                }
            },
            '17': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 43,
                    column: 9
                }
            },
            '18': {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 32
                }
            },
            '19': {
                start: {
                    line: 42,
                    column: 12
                },
                end: {
                    line: 42,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 45,
                    column: 8
                },
                end: {
                    line: 45,
                    column: 66
                }
            },
            '21': {
                start: {
                    line: 46,
                    column: 8
                },
                end: {
                    line: 46,
                    column: 73
                }
            },
            '22': {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 32
                }
            },
            '23': {
                start: {
                    line: 51,
                    column: 25
                },
                end: {
                    line: 51,
                    column: 66
                }
            },
            '24': {
                start: {
                    line: 53,
                    column: 8
                },
                end: {
                    line: 59,
                    column: 11
                }
            },
            '25': {
                start: {
                    line: 58,
                    column: 12
                },
                end: {
                    line: 58,
                    column: 73
                }
            },
            '26': {
                start: {
                    line: 58,
                    column: 44
                },
                end: {
                    line: 58,
                    column: 71
                }
            },
            '27': {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 63,
                    column: 51
                }
            },
            '28': {
                start: {
                    line: 65,
                    column: 8
                },
                end: {
                    line: 69,
                    column: 11
                }
            },
            '29': {
                start: {
                    line: 66,
                    column: 12
                },
                end: {
                    line: 68,
                    column: 13
                }
            },
            '30': {
                start: {
                    line: 67,
                    column: 16
                },
                end: {
                    line: 67,
                    column: 39
                }
            },
            '31': {
                start: {
                    line: 73,
                    column: 8
                },
                end: {
                    line: 74,
                    column: 71
                }
            },
            '32': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 69
                }
            },
            '33': {
                start: {
                    line: 82,
                    column: 8
                },
                end: {
                    line: 86,
                    column: 9
                }
            },
            '34': {
                start: {
                    line: 83,
                    column: 12
                },
                end: {
                    line: 83,
                    column: 57
                }
            },
            '35': {
                start: {
                    line: 83,
                    column: 34
                },
                end: {
                    line: 83,
                    column: 55
                }
            },
            '36': {
                start: {
                    line: 84,
                    column: 15
                },
                end: {
                    line: 86,
                    column: 9
                }
            },
            '37': {
                start: {
                    line: 85,
                    column: 12
                },
                end: {
                    line: 85,
                    column: 56
                }
            },
            '38': {
                start: {
                    line: 85,
                    column: 34
                },
                end: {
                    line: 85,
                    column: 54
                }
            },
            '39': {
                start: {
                    line: 90,
                    column: 26
                },
                end: {
                    line: 90,
                    column: 64
                }
            },
            '40': {
                start: {
                    line: 92,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 9
                }
            },
            '41': {
                start: {
                    line: 93,
                    column: 12
                },
                end: {
                    line: 93,
                    column: 24
                }
            },
            '42': {
                start: {
                    line: 95,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 9
                }
            },
            '43': {
                start: {
                    line: 96,
                    column: 12
                },
                end: {
                    line: 96,
                    column: 45
                }
            },
            '44': {
                start: {
                    line: 98,
                    column: 12
                },
                end: {
                    line: 100,
                    column: 13
                }
            },
            '45': {
                start: {
                    line: 99,
                    column: 16
                },
                end: {
                    line: 99,
                    column: 28
                }
            },
            '46': {
                start: {
                    line: 103,
                    column: 8
                },
                end: {
                    line: 103,
                    column: 21
                }
            },
            '47': {
                start: {
                    line: 108,
                    column: 0
                },
                end: {
                    line: 151,
                    column: 7
                }
            },
            '48': {
                start: {
                    line: 119,
                    column: 8
                },
                end: {
                    line: 136,
                    column: 10
                }
            },
            '49': {
                start: {
                    line: 124,
                    column: 16
                },
                end: {
                    line: 134,
                    column: 19
                }
            },
            '50': {
                start: {
                    line: 125,
                    column: 39
                },
                end: {
                    line: 125,
                    column: 46
                }
            },
            '51': {
                start: {
                    line: 126,
                    column: 20
                },
                end: {
                    line: 128,
                    column: 21
                }
            },
            '52': {
                start: {
                    line: 127,
                    column: 24
                },
                end: {
                    line: 127,
                    column: 61
                }
            },
            '53': {
                start: {
                    line: 129,
                    column: 20
                },
                end: {
                    line: 129,
                    column: 62
                }
            },
            '54': {
                start: {
                    line: 131,
                    column: 20
                },
                end: {
                    line: 133,
                    column: 21
                }
            },
            '55': {
                start: {
                    line: 132,
                    column: 24
                },
                end: {
                    line: 132,
                    column: 40
                }
            },
            '56': {
                start: {
                    line: 138,
                    column: 8
                },
                end: {
                    line: 150,
                    column: 10
                }
            },
            '57': {
                start: {
                    line: 140,
                    column: 12
                },
                end: {
                    line: 148,
                    column: 13
                }
            },
            '58': {
                start: {
                    line: 142,
                    column: 16
                },
                end: {
                    line: 142,
                    column: 52
                }
            },
            '59': {
                start: {
                    line: 145,
                    column: 16
                },
                end: {
                    line: 147,
                    column: 17
                }
            },
            '60': {
                start: {
                    line: 146,
                    column: 20
                },
                end: {
                    line: 146,
                    column: 36
                }
            },
            '61': {
                start: {
                    line: 149,
                    column: 12
                },
                end: {
                    line: 149,
                    column: 24
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 80
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 14
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                line: 14
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 30,
                        column: 4
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 30,
                        column: 36
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                line: 30
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 16
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 38
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 24
                    },
                    end: {
                        line: 60,
                        column: 5
                    }
                },
                line: 50
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 56,
                        column: 17
                    },
                    end: {
                        line: 56,
                        column: 18
                    }
                },
                loc: {
                    start: {
                        line: 56,
                        column: 23
                    },
                    end: {
                        line: 59,
                        column: 9
                    }
                },
                line: 56
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 58,
                        column: 34
                    },
                    end: {
                        line: 58,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 58,
                        column: 44
                    },
                    end: {
                        line: 58,
                        column: 71
                    }
                },
                line: 58
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 30
                    },
                    end: {
                        line: 70,
                        column: 5
                    }
                },
                line: 62
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 65,
                        column: 25
                    },
                    end: {
                        line: 65,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 65,
                        column: 30
                    },
                    end: {
                        line: 69,
                        column: 9
                    }
                },
                line: 65
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 72,
                        column: 4
                    },
                    end: {
                        line: 72,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 72,
                        column: 22
                    },
                    end: {
                        line: 75,
                        column: 5
                    }
                },
                line: 72
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 77,
                        column: 4
                    },
                    end: {
                        line: 77,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 77,
                        column: 16
                    },
                    end: {
                        line: 79,
                        column: 5
                    }
                },
                line: 77
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 81,
                        column: 4
                    },
                    end: {
                        line: 81,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 16
                    },
                    end: {
                        line: 87,
                        column: 5
                    }
                },
                line: 81
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 83,
                        column: 29
                    },
                    end: {
                        line: 83,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 83,
                        column: 34
                    },
                    end: {
                        line: 83,
                        column: 55
                    }
                },
                line: 83
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 85,
                        column: 29
                    },
                    end: {
                        line: 85,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 85,
                        column: 34
                    },
                    end: {
                        line: 85,
                        column: 54
                    }
                },
                line: 85
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 89,
                        column: 4
                    },
                    end: {
                        line: 89,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 89,
                        column: 31
                    },
                    end: {
                        line: 104,
                        column: 5
                    }
                },
                line: 89
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 118,
                        column: 37
                    },
                    end: {
                        line: 118,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 118,
                        column: 49
                    },
                    end: {
                        line: 137,
                        column: 5
                    }
                },
                line: 118
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 123,
                        column: 18
                    },
                    end: {
                        line: 123,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 123,
                        column: 44
                    },
                    end: {
                        line: 135,
                        column: 13
                    }
                },
                line: 123
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 124,
                        column: 29
                    },
                    end: {
                        line: 124,
                        column: 30
                    }
                },
                loc: {
                    start: {
                        line: 124,
                        column: 41
                    },
                    end: {
                        line: 130,
                        column: 17
                    }
                },
                line: 124
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 130,
                        column: 19
                    },
                    end: {
                        line: 130,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 130,
                        column: 37
                    },
                    end: {
                        line: 134,
                        column: 17
                    }
                },
                line: 130
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 137,
                        column: 31
                    },
                    end: {
                        line: 137,
                        column: 32
                    }
                },
                loc: {
                    start: {
                        line: 137,
                        column: 43
                    },
                    end: {
                        line: 151,
                        column: 5
                    }
                },
                line: 137
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 138,
                        column: 15
                    },
                    end: {
                        line: 138,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 138,
                        column: 32
                    },
                    end: {
                        line: 150,
                        column: 9
                    }
                },
                line: 138
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }, {
                    start: {
                        line: 16,
                        column: 8
                    },
                    end: {
                        line: 27,
                        column: 9
                    }
                }],
                line: 16
            },
            '1': {
                loc: {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }, {
                    start: {
                        line: 17,
                        column: 12
                    },
                    end: {
                        line: 19,
                        column: 13
                    }
                }],
                line: 17
            },
            '2': {
                loc: {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                }, {
                    start: {
                        line: 31,
                        column: 8
                    },
                    end: {
                        line: 35,
                        column: 9
                    }
                }],
                line: 31
            },
            '3': {
                loc: {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                }, {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 43,
                        column: 9
                    }
                }],
                line: 39
            },
            '4': {
                loc: {
                    start: {
                        line: 39,
                        column: 12
                    },
                    end: {
                        line: 39,
                        column: 85
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 39,
                        column: 12
                    },
                    end: {
                        line: 39,
                        column: 42
                    }
                }, {
                    start: {
                        line: 39,
                        column: 46
                    },
                    end: {
                        line: 39,
                        column: 85
                    }
                }],
                line: 39
            },
            '5': {
                loc: {
                    start: {
                        line: 42,
                        column: 29
                    },
                    end: {
                        line: 42,
                        column: 64
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 42,
                        column: 56
                    },
                    end: {
                        line: 42,
                        column: 58
                    }
                }, {
                    start: {
                        line: 42,
                        column: 61
                    },
                    end: {
                        line: 42,
                        column: 64
                    }
                }],
                line: 42
            },
            '6': {
                loc: {
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                }, {
                    start: {
                        line: 66,
                        column: 12
                    },
                    end: {
                        line: 68,
                        column: 13
                    }
                }],
                line: 66
            },
            '7': {
                loc: {
                    start: {
                        line: 73,
                        column: 16
                    },
                    end: {
                        line: 74,
                        column: 69
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 73,
                        column: 16
                    },
                    end: {
                        line: 73,
                        column: 51
                    }
                }, {
                    start: {
                        line: 74,
                        column: 16
                    },
                    end: {
                        line: 74,
                        column: 69
                    }
                }],
                line: 73
            },
            '8': {
                loc: {
                    start: {
                        line: 82,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 82,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }, {
                    start: {
                        line: 82,
                        column: 8
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }],
                line: 82
            },
            '9': {
                loc: {
                    start: {
                        line: 84,
                        column: 15
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 84,
                        column: 15
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }, {
                    start: {
                        line: 84,
                        column: 15
                    },
                    end: {
                        line: 86,
                        column: 9
                    }
                }],
                line: 84
            },
            '10': {
                loc: {
                    start: {
                        line: 84,
                        column: 19
                    },
                    end: {
                        line: 84,
                        column: 91
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 84,
                        column: 19
                    },
                    end: {
                        line: 84,
                        column: 54
                    }
                }, {
                    start: {
                        line: 84,
                        column: 58
                    },
                    end: {
                        line: 84,
                        column: 91
                    }
                }],
                line: 84
            },
            '11': {
                loc: {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }, {
                    start: {
                        line: 92,
                        column: 8
                    },
                    end: {
                        line: 94,
                        column: 9
                    }
                }],
                line: 92
            },
            '12': {
                loc: {
                    start: {
                        line: 95,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 95,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }, {
                    start: {
                        line: 95,
                        column: 8
                    },
                    end: {
                        line: 101,
                        column: 9
                    }
                }],
                line: 95
            },
            '13': {
                loc: {
                    start: {
                        line: 98,
                        column: 12
                    },
                    end: {
                        line: 100,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 98,
                        column: 12
                    },
                    end: {
                        line: 100,
                        column: 13
                    }
                }, {
                    start: {
                        line: 98,
                        column: 12
                    },
                    end: {
                        line: 100,
                        column: 13
                    }
                }],
                line: 98
            },
            '14': {
                loc: {
                    start: {
                        line: 131,
                        column: 20
                    },
                    end: {
                        line: 133,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 131,
                        column: 20
                    },
                    end: {
                        line: 133,
                        column: 21
                    }
                }, {
                    start: {
                        line: 131,
                        column: 20
                    },
                    end: {
                        line: 133,
                        column: 21
                    }
                }],
                line: 131
            },
            '15': {
                loc: {
                    start: {
                        line: 140,
                        column: 12
                    },
                    end: {
                        line: 148,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 140,
                        column: 12
                    },
                    end: {
                        line: 148,
                        column: 13
                    }
                }, {
                    start: {
                        line: 140,
                        column: 12
                    },
                    end: {
                        line: 148,
                        column: 13
                    }
                }],
                line: 140
            },
            '16': {
                loc: {
                    start: {
                        line: 145,
                        column: 16
                    },
                    end: {
                        line: 147,
                        column: 17
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 145,
                        column: 16
                    },
                    end: {
                        line: 147,
                        column: 17
                    }
                }, {
                    start: {
                        line: 145,
                        column: 16
                    },
                    end: {
                        line: 147,
                        column: 17
                    }
                }],
                line: 145
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0],
            '15': [0, 0],
            '16': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SamplesDisplayListController = function () {
    function SamplesDisplayListController($state, $location, $stateParams, SampleStatesTable, $rootScope) {
        _classCallCheck(this, SamplesDisplayListController);

        cov_2fy735b3k.f[0]++;
        cov_2fy735b3k.s[0]++;

        this.$state = $state;
        cov_2fy735b3k.s[1]++;
        this.$location = $location;
        cov_2fy735b3k.s[2]++;
        this.$stateParams = $stateParams;
        cov_2fy735b3k.s[3]++;
        this.SampleStatesTable = SampleStatesTable;

        cov_2fy735b3k.s[4]++;
        this.$rootScope = $rootScope;

        cov_2fy735b3k.s[5]++;
        this.direction = '';
    }

    _createClass(SamplesDisplayListController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_2fy735b3k.f[1]++;

            var order = (cov_2fy735b3k.s[6]++, this.$stateParams.ordering);
            cov_2fy735b3k.s[7]++;
            if (order) {
                cov_2fy735b3k.b[0][0]++;
                cov_2fy735b3k.s[8]++;

                if (order.charAt(0) === '-') {
                    cov_2fy735b3k.b[1][0]++;
                    cov_2fy735b3k.s[9]++;

                    this.direction = '-';
                } else {
                    cov_2fy735b3k.b[1][1]++;
                }

                /* eslint-disable dot-notation */
                cov_2fy735b3k.s[10]++;
                this.samples.$params['ordering'] = order;
                cov_2fy735b3k.s[11]++;
                this.samples.$refresh();
            } else {
                cov_2fy735b3k.b[0][1]++;
                cov_2fy735b3k.s[12]++;

                this.samples.$params['ordering'] = '-order_reference';
                cov_2fy735b3k.s[13]++;
                this.samples.$refresh();
            }
        }
    }, {
        key: 'selectSampleIfSelecting',
        value: function selectSampleIfSelecting(sample) {
            cov_2fy735b3k.f[2]++;
            cov_2fy735b3k.s[14]++;

            if (this.samples.$selected.length) {
                cov_2fy735b3k.b[2][0]++;
                cov_2fy735b3k.s[15]++;

                sample.$isSelected = !sample.$isSelected;
            } else {
                cov_2fy735b3k.b[2][1]++;
                cov_2fy735b3k.s[16]++;

                this.$state.go('sampleDetails', { id: sample.id });
            }
        }
    }, {
        key: 'sort',
        value: function sort(field) {
            cov_2fy735b3k.f[3]++;
            cov_2fy735b3k.s[17]++;

            if ((cov_2fy735b3k.b[4][0]++, !this.samples.$params.ordering) || (cov_2fy735b3k.b[4][1]++, this.samples.$params.ordering !== field)) {
                cov_2fy735b3k.b[3][0]++;
                cov_2fy735b3k.s[18]++;

                this.direction = '';
            } else {
                cov_2fy735b3k.b[3][1]++;
                cov_2fy735b3k.s[19]++;

                this.direction = this.direction === '-' ? (cov_2fy735b3k.b[5][0]++, '') : (cov_2fy735b3k.b[5][1]++, '-');
            }

            cov_2fy735b3k.s[20]++;
            this.samples.$params['ordering'] = this.direction + field;
            cov_2fy735b3k.s[21]++;
            this.$location.search('ordering', this.samples.$params.ordering);
            cov_2fy735b3k.s[22]++;
            this.samples.$refresh();
        }
    }, {
        key: 'changeState',
        value: function changeState(sample) {
            cov_2fy735b3k.f[4]++;

            var newState = (cov_2fy735b3k.s[23]++, sample.$_('currentState').sampleStateType);
            /* eslint-disable camelcase */
            cov_2fy735b3k.s[24]++;
            this.SampleStatesTable.$create({
                sample: sample.id,
                sample_state_type: newState
            }).$then(function () {
                cov_2fy735b3k.f[5]++;
                cov_2fy735b3k.s[25]++;

                /* eslint-disable no-return-assign */
                sample.$fetch().$then(function (sample) {
                    cov_2fy735b3k.f[6]++;
                    cov_2fy735b3k.s[26]++;
                    return sample.$currentState = null;
                });
            });
        }
    }, {
        key: 'toggleSampleNotes',
        value: function toggleSampleNotes(sample) {
            cov_2fy735b3k.f[7]++;
            cov_2fy735b3k.s[27]++;

            sample.$notesActive = !sample.$notesActive;
            /* eslint-disable array-callback-return */
            cov_2fy735b3k.s[28]++;
            this.samples.map(function (s) {
                cov_2fy735b3k.f[8]++;
                cov_2fy735b3k.s[29]++;

                if (sample.id !== s.id) {
                    cov_2fy735b3k.b[6][0]++;
                    cov_2fy735b3k.s[30]++;

                    s.$notesActive = false;
                } else {
                    cov_2fy735b3k.b[6][1]++;
                }
            });
        }
    }, {
        key: 'isIndeterminate',
        value: function isIndeterminate() {
            cov_2fy735b3k.f[9]++;
            cov_2fy735b3k.s[31]++;

            return (cov_2fy735b3k.b[7][0]++, this.samples.$selected.length !== 0) && (cov_2fy735b3k.b[7][1]++, this.samples.$selected.length !== this.samples.length);
        }
    }, {
        key: 'isChecked',
        value: function isChecked() {
            cov_2fy735b3k.f[10]++;
            cov_2fy735b3k.s[32]++;

            return this.samples.$selected.length === this.samples.length;
        }
    }, {
        key: 'toggleAll',
        value: function toggleAll() {
            cov_2fy735b3k.f[11]++;
            cov_2fy735b3k.s[33]++;

            if (this.samples.$selected.length === this.samples.length) {
                cov_2fy735b3k.b[8][0]++;
                cov_2fy735b3k.s[34]++;

                this.samples.map(function (s) {
                    cov_2fy735b3k.f[12]++;
                    cov_2fy735b3k.s[35]++;
                    return s.$isSelected = false;
                });
            } else {
                    cov_2fy735b3k.b[8][1]++;
                    cov_2fy735b3k.s[36]++;
                    if ((cov_2fy735b3k.b[10][0]++, this.samples.$selected.length === 0) || (cov_2fy735b3k.b[10][1]++, this.samples.$selected.length > 0)) {
                        cov_2fy735b3k.b[9][0]++;
                        cov_2fy735b3k.s[37]++;

                        this.samples.map(function (s) {
                            cov_2fy735b3k.f[13]++;
                            cov_2fy735b3k.s[38]++;
                            return s.$isSelected = true;
                        });
                    } else {
                        cov_2fy735b3k.b[9][1]++;
                    }
                }
        }
    }, {
        key: 'hasWritePermissions',
        value: function hasWritePermissions(field) {
            cov_2fy735b3k.f[14]++;

            var permissions = (cov_2fy735b3k.s[39]++, this.$rootScope.sampleWritePermissions);

            cov_2fy735b3k.s[40]++;
            if (this.$rootScope.me.isSupervisor) {
                cov_2fy735b3k.b[11][0]++;
                cov_2fy735b3k.s[41]++;

                return true;
            } else {
                cov_2fy735b3k.b[11][1]++;
            }
            cov_2fy735b3k.s[42]++;
            if (permissions.hasOwnProperty(field)) {
                cov_2fy735b3k.b[12][0]++;
                cov_2fy735b3k.s[43]++;

                permissions = permissions[field];

                cov_2fy735b3k.s[44]++;
                if (permissions.indexOf(this.$rootScope.me.type) !== -1) {
                    cov_2fy735b3k.b[13][0]++;
                    cov_2fy735b3k.s[45]++;

                    return true;
                } else {
                    cov_2fy735b3k.b[13][1]++;
                }
            } else {
                cov_2fy735b3k.b[12][1]++;
            }

            cov_2fy735b3k.s[46]++;
            return false;
        }
    }]);

    return SamplesDisplayListController;
}();

cov_2fy735b3k.s[47]++;


angular.module('app').component('samplesDisplayList', {
    templateUrl: 'app/components/samplesDisplayList/samplesDisplayList.html',
    controller: SamplesDisplayListController,
    bindings: {
        samples: '<',
        checkWorkItem: '<'
        /* eslint-disable prefer-arrow-callback */
    } }).directive('forceSelectFocus', function () {
    cov_2fy735b3k.f[15]++;
    cov_2fy735b3k.s[48]++;

    return {
        restrict: 'A',
        require: ['^^mdSelect', '^ngModel'],
        /* eslint-disable babel/object-shorthand */
        link: function link(scope, element) {
            cov_2fy735b3k.f[16]++;
            cov_2fy735b3k.s[49]++;

            scope.$watch(function () {
                cov_2fy735b3k.f[17]++;

                var foundElement = (cov_2fy735b3k.s[50]++, element);
                cov_2fy735b3k.s[51]++;
                while (!foundElement.hasClass('md-select-menu-container')) {
                    cov_2fy735b3k.s[52]++;

                    foundElement = foundElement.parent();
                }
                cov_2fy735b3k.s[53]++;
                return foundElement.hasClass('md-active');
            }, function (newVal) {
                cov_2fy735b3k.f[18]++;
                cov_2fy735b3k.s[54]++;

                if (newVal) {
                    cov_2fy735b3k.b[14][0]++;
                    cov_2fy735b3k.s[55]++;

                    element.focus();
                } else {
                    cov_2fy735b3k.b[14][1]++;
                }
            });
        }
    };
}).filter('daysFromState', function () {
    cov_2fy735b3k.f[19]++;
    cov_2fy735b3k.s[56]++;

    return function (input) {
        cov_2fy735b3k.f[20]++;
        cov_2fy735b3k.s[57]++;

        /* eslint-disable no-lonely-if */
        if (input) {
            cov_2fy735b3k.b[15][0]++;
            cov_2fy735b3k.s[58]++;

            /* eslint-disable prefer-template */
            return '(' + Math.abs(input) + 'd)';
            /* eslint-disable no-else-return */
        } else {
            cov_2fy735b3k.b[15][1]++;
            cov_2fy735b3k.s[59]++;

            if (input === 0) {
                cov_2fy735b3k.b[16][0]++;
                cov_2fy735b3k.s[60]++;

                return '(hoje)';
            } else {
                cov_2fy735b3k.b[16][1]++;
            }
        }
        cov_2fy735b3k.s[61]++;
        return null;
    };
});