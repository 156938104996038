'use strict';

var cov_64mjk2nhm = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.samples.factory.js',
        hash = 'ab07a459ec58584b7d57e73acf60709423e428d2',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/ORM/tables.samples.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 391,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 8
                },
                end: {
                    line: 389,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 242,
                    column: 20
                },
                end: {
                    line: 244,
                    column: 21
                }
            },
            '4': {
                start: {
                    line: 243,
                    column: 24
                },
                end: {
                    line: 243,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 245,
                    column: 20
                },
                end: {
                    line: 245,
                    column: 32
                }
            },
            '6': {
                start: {
                    line: 251,
                    column: 20
                },
                end: {
                    line: 253,
                    column: 21
                }
            },
            '7': {
                start: {
                    line: 252,
                    column: 24
                },
                end: {
                    line: 252,
                    column: 65
                }
            },
            '8': {
                start: {
                    line: 254,
                    column: 20
                },
                end: {
                    line: 254,
                    column: 32
                }
            },
            '9': {
                start: {
                    line: 257,
                    column: 20
                },
                end: {
                    line: 259,
                    column: 21
                }
            },
            '10': {
                start: {
                    line: 258,
                    column: 24
                },
                end: {
                    line: 258,
                    column: 46
                }
            },
            '11': {
                start: {
                    line: 260,
                    column: 20
                },
                end: {
                    line: 260,
                    column: 32
                }
            },
            '12': {
                start: {
                    line: 265,
                    column: 20
                },
                end: {
                    line: 267,
                    column: 21
                }
            },
            '13': {
                start: {
                    line: 266,
                    column: 24
                },
                end: {
                    line: 266,
                    column: 65
                }
            },
            '14': {
                start: {
                    line: 268,
                    column: 20
                },
                end: {
                    line: 268,
                    column: 32
                }
            },
            '15': {
                start: {
                    line: 271,
                    column: 20
                },
                end: {
                    line: 273,
                    column: 21
                }
            },
            '16': {
                start: {
                    line: 272,
                    column: 24
                },
                end: {
                    line: 272,
                    column: 46
                }
            },
            '17': {
                start: {
                    line: 274,
                    column: 20
                },
                end: {
                    line: 274,
                    column: 32
                }
            },
            '18': {
                start: {
                    line: 279,
                    column: 20
                },
                end: {
                    line: 281,
                    column: 21
                }
            },
            '19': {
                start: {
                    line: 280,
                    column: 24
                },
                end: {
                    line: 280,
                    column: 65
                }
            },
            '20': {
                start: {
                    line: 282,
                    column: 20
                },
                end: {
                    line: 282,
                    column: 32
                }
            },
            '21': {
                start: {
                    line: 285,
                    column: 20
                },
                end: {
                    line: 287,
                    column: 21
                }
            },
            '22': {
                start: {
                    line: 286,
                    column: 24
                },
                end: {
                    line: 286,
                    column: 46
                }
            },
            '23': {
                start: {
                    line: 288,
                    column: 20
                },
                end: {
                    line: 288,
                    column: 32
                }
            },
            '24': {
                start: {
                    line: 293,
                    column: 20
                },
                end: {
                    line: 295,
                    column: 21
                }
            },
            '25': {
                start: {
                    line: 294,
                    column: 24
                },
                end: {
                    line: 294,
                    column: 65
                }
            },
            '26': {
                start: {
                    line: 296,
                    column: 20
                },
                end: {
                    line: 296,
                    column: 32
                }
            },
            '27': {
                start: {
                    line: 299,
                    column: 20
                },
                end: {
                    line: 301,
                    column: 21
                }
            },
            '28': {
                start: {
                    line: 300,
                    column: 24
                },
                end: {
                    line: 300,
                    column: 46
                }
            },
            '29': {
                start: {
                    line: 302,
                    column: 20
                },
                end: {
                    line: 302,
                    column: 32
                }
            },
            '30': {
                start: {
                    line: 307,
                    column: 20
                },
                end: {
                    line: 309,
                    column: 21
                }
            },
            '31': {
                start: {
                    line: 308,
                    column: 24
                },
                end: {
                    line: 308,
                    column: 65
                }
            },
            '32': {
                start: {
                    line: 310,
                    column: 20
                },
                end: {
                    line: 310,
                    column: 32
                }
            },
            '33': {
                start: {
                    line: 313,
                    column: 20
                },
                end: {
                    line: 315,
                    column: 21
                }
            },
            '34': {
                start: {
                    line: 314,
                    column: 24
                },
                end: {
                    line: 314,
                    column: 46
                }
            },
            '35': {
                start: {
                    line: 316,
                    column: 20
                },
                end: {
                    line: 316,
                    column: 32
                }
            },
            '36': {
                start: {
                    line: 321,
                    column: 20
                },
                end: {
                    line: 323,
                    column: 21
                }
            },
            '37': {
                start: {
                    line: 322,
                    column: 24
                },
                end: {
                    line: 322,
                    column: 65
                }
            },
            '38': {
                start: {
                    line: 324,
                    column: 20
                },
                end: {
                    line: 324,
                    column: 32
                }
            },
            '39': {
                start: {
                    line: 327,
                    column: 20
                },
                end: {
                    line: 329,
                    column: 21
                }
            },
            '40': {
                start: {
                    line: 328,
                    column: 24
                },
                end: {
                    line: 328,
                    column: 46
                }
            },
            '41': {
                start: {
                    line: 330,
                    column: 20
                },
                end: {
                    line: 330,
                    column: 32
                }
            },
            '42': {
                start: {
                    line: 335,
                    column: 20
                },
                end: {
                    line: 337,
                    column: 21
                }
            },
            '43': {
                start: {
                    line: 336,
                    column: 24
                },
                end: {
                    line: 336,
                    column: 65
                }
            },
            '44': {
                start: {
                    line: 338,
                    column: 20
                },
                end: {
                    line: 338,
                    column: 32
                }
            },
            '45': {
                start: {
                    line: 341,
                    column: 20
                },
                end: {
                    line: 343,
                    column: 21
                }
            },
            '46': {
                start: {
                    line: 342,
                    column: 24
                },
                end: {
                    line: 342,
                    column: 46
                }
            },
            '47': {
                start: {
                    line: 344,
                    column: 20
                },
                end: {
                    line: 344,
                    column: 32
                }
            },
            '48': {
                start: {
                    line: 349,
                    column: 20
                },
                end: {
                    line: 351,
                    column: 21
                }
            },
            '49': {
                start: {
                    line: 350,
                    column: 24
                },
                end: {
                    line: 350,
                    column: 65
                }
            },
            '50': {
                start: {
                    line: 352,
                    column: 20
                },
                end: {
                    line: 352,
                    column: 32
                }
            },
            '51': {
                start: {
                    line: 355,
                    column: 20
                },
                end: {
                    line: 357,
                    column: 21
                }
            },
            '52': {
                start: {
                    line: 356,
                    column: 24
                },
                end: {
                    line: 356,
                    column: 46
                }
            },
            '53': {
                start: {
                    line: 358,
                    column: 20
                },
                end: {
                    line: 358,
                    column: 32
                }
            },
            '54': {
                start: {
                    line: 363,
                    column: 20
                },
                end: {
                    line: 365,
                    column: 21
                }
            },
            '55': {
                start: {
                    line: 364,
                    column: 24
                },
                end: {
                    line: 364,
                    column: 65
                }
            },
            '56': {
                start: {
                    line: 366,
                    column: 20
                },
                end: {
                    line: 366,
                    column: 32
                }
            },
            '57': {
                start: {
                    line: 369,
                    column: 20
                },
                end: {
                    line: 371,
                    column: 21
                }
            },
            '58': {
                start: {
                    line: 370,
                    column: 24
                },
                end: {
                    line: 370,
                    column: 46
                }
            },
            '59': {
                start: {
                    line: 372,
                    column: 20
                },
                end: {
                    line: 372,
                    column: 32
                }
            },
            '60': {
                start: {
                    line: 377,
                    column: 20
                },
                end: {
                    line: 379,
                    column: 21
                }
            },
            '61': {
                start: {
                    line: 378,
                    column: 24
                },
                end: {
                    line: 378,
                    column: 65
                }
            },
            '62': {
                start: {
                    line: 380,
                    column: 20
                },
                end: {
                    line: 380,
                    column: 32
                }
            },
            '63': {
                start: {
                    line: 383,
                    column: 20
                },
                end: {
                    line: 385,
                    column: 21
                }
            },
            '64': {
                start: {
                    line: 384,
                    column: 24
                },
                end: {
                    line: 384,
                    column: 46
                }
            },
            '65': {
                start: {
                    line: 386,
                    column: 20
                },
                end: {
                    line: 386,
                    column: 32
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 391,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'RestmodFactory',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 37
                    },
                    end: {
                        line: 390,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 241,
                        column: 24
                    },
                    end: {
                        line: 241,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 241,
                        column: 29
                    },
                    end: {
                        line: 246,
                        column: 17
                    }
                },
                line: 241
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 249,
                        column: 24
                    },
                    end: {
                        line: 249,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 249,
                        column: 32
                    },
                    end: {
                        line: 255,
                        column: 17
                    }
                },
                line: 249
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 256,
                        column: 24
                    },
                    end: {
                        line: 256,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 256,
                        column: 32
                    },
                    end: {
                        line: 261,
                        column: 17
                    }
                },
                line: 256
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 264,
                        column: 24
                    },
                    end: {
                        line: 264,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 264,
                        column: 32
                    },
                    end: {
                        line: 269,
                        column: 17
                    }
                },
                line: 264
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 270,
                        column: 24
                    },
                    end: {
                        line: 270,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 270,
                        column: 32
                    },
                    end: {
                        line: 275,
                        column: 17
                    }
                },
                line: 270
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 278,
                        column: 24
                    },
                    end: {
                        line: 278,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 278,
                        column: 32
                    },
                    end: {
                        line: 283,
                        column: 17
                    }
                },
                line: 278
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 284,
                        column: 24
                    },
                    end: {
                        line: 284,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 284,
                        column: 32
                    },
                    end: {
                        line: 289,
                        column: 17
                    }
                },
                line: 284
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 292,
                        column: 24
                    },
                    end: {
                        line: 292,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 292,
                        column: 32
                    },
                    end: {
                        line: 297,
                        column: 17
                    }
                },
                line: 292
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 298,
                        column: 24
                    },
                    end: {
                        line: 298,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 298,
                        column: 32
                    },
                    end: {
                        line: 303,
                        column: 17
                    }
                },
                line: 298
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 306,
                        column: 24
                    },
                    end: {
                        line: 306,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 306,
                        column: 32
                    },
                    end: {
                        line: 311,
                        column: 17
                    }
                },
                line: 306
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 312,
                        column: 24
                    },
                    end: {
                        line: 312,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 312,
                        column: 32
                    },
                    end: {
                        line: 317,
                        column: 17
                    }
                },
                line: 312
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 320,
                        column: 24
                    },
                    end: {
                        line: 320,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 320,
                        column: 32
                    },
                    end: {
                        line: 325,
                        column: 17
                    }
                },
                line: 320
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 326,
                        column: 24
                    },
                    end: {
                        line: 326,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 326,
                        column: 32
                    },
                    end: {
                        line: 331,
                        column: 17
                    }
                },
                line: 326
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 334,
                        column: 24
                    },
                    end: {
                        line: 334,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 334,
                        column: 32
                    },
                    end: {
                        line: 339,
                        column: 17
                    }
                },
                line: 334
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 340,
                        column: 24
                    },
                    end: {
                        line: 340,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 340,
                        column: 32
                    },
                    end: {
                        line: 345,
                        column: 17
                    }
                },
                line: 340
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 348,
                        column: 24
                    },
                    end: {
                        line: 348,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 348,
                        column: 32
                    },
                    end: {
                        line: 353,
                        column: 17
                    }
                },
                line: 348
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 354,
                        column: 24
                    },
                    end: {
                        line: 354,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 354,
                        column: 32
                    },
                    end: {
                        line: 359,
                        column: 17
                    }
                },
                line: 354
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 362,
                        column: 24
                    },
                    end: {
                        line: 362,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 362,
                        column: 32
                    },
                    end: {
                        line: 367,
                        column: 17
                    }
                },
                line: 362
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 368,
                        column: 24
                    },
                    end: {
                        line: 368,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 368,
                        column: 32
                    },
                    end: {
                        line: 373,
                        column: 17
                    }
                },
                line: 368
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 376,
                        column: 24
                    },
                    end: {
                        line: 376,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 376,
                        column: 32
                    },
                    end: {
                        line: 381,
                        column: 17
                    }
                },
                line: 376
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 382,
                        column: 24
                    },
                    end: {
                        line: 382,
                        column: 25
                    }
                },
                loc: {
                    start: {
                        line: 382,
                        column: 32
                    },
                    end: {
                        line: 387,
                        column: 17
                    }
                },
                line: 382
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 242,
                        column: 20
                    },
                    end: {
                        line: 244,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 242,
                        column: 20
                    },
                    end: {
                        line: 244,
                        column: 21
                    }
                }, {
                    start: {
                        line: 242,
                        column: 20
                    },
                    end: {
                        line: 244,
                        column: 21
                    }
                }],
                line: 242
            },
            '1': {
                loc: {
                    start: {
                        line: 251,
                        column: 20
                    },
                    end: {
                        line: 253,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 251,
                        column: 20
                    },
                    end: {
                        line: 253,
                        column: 21
                    }
                }, {
                    start: {
                        line: 251,
                        column: 20
                    },
                    end: {
                        line: 253,
                        column: 21
                    }
                }],
                line: 251
            },
            '2': {
                loc: {
                    start: {
                        line: 257,
                        column: 20
                    },
                    end: {
                        line: 259,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 257,
                        column: 20
                    },
                    end: {
                        line: 259,
                        column: 21
                    }
                }, {
                    start: {
                        line: 257,
                        column: 20
                    },
                    end: {
                        line: 259,
                        column: 21
                    }
                }],
                line: 257
            },
            '3': {
                loc: {
                    start: {
                        line: 265,
                        column: 20
                    },
                    end: {
                        line: 267,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 265,
                        column: 20
                    },
                    end: {
                        line: 267,
                        column: 21
                    }
                }, {
                    start: {
                        line: 265,
                        column: 20
                    },
                    end: {
                        line: 267,
                        column: 21
                    }
                }],
                line: 265
            },
            '4': {
                loc: {
                    start: {
                        line: 271,
                        column: 20
                    },
                    end: {
                        line: 273,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 271,
                        column: 20
                    },
                    end: {
                        line: 273,
                        column: 21
                    }
                }, {
                    start: {
                        line: 271,
                        column: 20
                    },
                    end: {
                        line: 273,
                        column: 21
                    }
                }],
                line: 271
            },
            '5': {
                loc: {
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 281,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 281,
                        column: 21
                    }
                }, {
                    start: {
                        line: 279,
                        column: 20
                    },
                    end: {
                        line: 281,
                        column: 21
                    }
                }],
                line: 279
            },
            '6': {
                loc: {
                    start: {
                        line: 285,
                        column: 20
                    },
                    end: {
                        line: 287,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 285,
                        column: 20
                    },
                    end: {
                        line: 287,
                        column: 21
                    }
                }, {
                    start: {
                        line: 285,
                        column: 20
                    },
                    end: {
                        line: 287,
                        column: 21
                    }
                }],
                line: 285
            },
            '7': {
                loc: {
                    start: {
                        line: 293,
                        column: 20
                    },
                    end: {
                        line: 295,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 293,
                        column: 20
                    },
                    end: {
                        line: 295,
                        column: 21
                    }
                }, {
                    start: {
                        line: 293,
                        column: 20
                    },
                    end: {
                        line: 295,
                        column: 21
                    }
                }],
                line: 293
            },
            '8': {
                loc: {
                    start: {
                        line: 299,
                        column: 20
                    },
                    end: {
                        line: 301,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 299,
                        column: 20
                    },
                    end: {
                        line: 301,
                        column: 21
                    }
                }, {
                    start: {
                        line: 299,
                        column: 20
                    },
                    end: {
                        line: 301,
                        column: 21
                    }
                }],
                line: 299
            },
            '9': {
                loc: {
                    start: {
                        line: 307,
                        column: 20
                    },
                    end: {
                        line: 309,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 307,
                        column: 20
                    },
                    end: {
                        line: 309,
                        column: 21
                    }
                }, {
                    start: {
                        line: 307,
                        column: 20
                    },
                    end: {
                        line: 309,
                        column: 21
                    }
                }],
                line: 307
            },
            '10': {
                loc: {
                    start: {
                        line: 313,
                        column: 20
                    },
                    end: {
                        line: 315,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 313,
                        column: 20
                    },
                    end: {
                        line: 315,
                        column: 21
                    }
                }, {
                    start: {
                        line: 313,
                        column: 20
                    },
                    end: {
                        line: 315,
                        column: 21
                    }
                }],
                line: 313
            },
            '11': {
                loc: {
                    start: {
                        line: 321,
                        column: 20
                    },
                    end: {
                        line: 323,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 321,
                        column: 20
                    },
                    end: {
                        line: 323,
                        column: 21
                    }
                }, {
                    start: {
                        line: 321,
                        column: 20
                    },
                    end: {
                        line: 323,
                        column: 21
                    }
                }],
                line: 321
            },
            '12': {
                loc: {
                    start: {
                        line: 327,
                        column: 20
                    },
                    end: {
                        line: 329,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 327,
                        column: 20
                    },
                    end: {
                        line: 329,
                        column: 21
                    }
                }, {
                    start: {
                        line: 327,
                        column: 20
                    },
                    end: {
                        line: 329,
                        column: 21
                    }
                }],
                line: 327
            },
            '13': {
                loc: {
                    start: {
                        line: 335,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 335,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                }, {
                    start: {
                        line: 335,
                        column: 20
                    },
                    end: {
                        line: 337,
                        column: 21
                    }
                }],
                line: 335
            },
            '14': {
                loc: {
                    start: {
                        line: 341,
                        column: 20
                    },
                    end: {
                        line: 343,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 341,
                        column: 20
                    },
                    end: {
                        line: 343,
                        column: 21
                    }
                }, {
                    start: {
                        line: 341,
                        column: 20
                    },
                    end: {
                        line: 343,
                        column: 21
                    }
                }],
                line: 341
            },
            '15': {
                loc: {
                    start: {
                        line: 349,
                        column: 20
                    },
                    end: {
                        line: 351,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 349,
                        column: 20
                    },
                    end: {
                        line: 351,
                        column: 21
                    }
                }, {
                    start: {
                        line: 349,
                        column: 20
                    },
                    end: {
                        line: 351,
                        column: 21
                    }
                }],
                line: 349
            },
            '16': {
                loc: {
                    start: {
                        line: 355,
                        column: 20
                    },
                    end: {
                        line: 357,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 355,
                        column: 20
                    },
                    end: {
                        line: 357,
                        column: 21
                    }
                }, {
                    start: {
                        line: 355,
                        column: 20
                    },
                    end: {
                        line: 357,
                        column: 21
                    }
                }],
                line: 355
            },
            '17': {
                loc: {
                    start: {
                        line: 363,
                        column: 20
                    },
                    end: {
                        line: 365,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 363,
                        column: 20
                    },
                    end: {
                        line: 365,
                        column: 21
                    }
                }, {
                    start: {
                        line: 363,
                        column: 20
                    },
                    end: {
                        line: 365,
                        column: 21
                    }
                }],
                line: 363
            },
            '18': {
                loc: {
                    start: {
                        line: 369,
                        column: 20
                    },
                    end: {
                        line: 371,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 369,
                        column: 20
                    },
                    end: {
                        line: 371,
                        column: 21
                    }
                }, {
                    start: {
                        line: 369,
                        column: 20
                    },
                    end: {
                        line: 371,
                        column: 21
                    }
                }],
                line: 369
            },
            '19': {
                loc: {
                    start: {
                        line: 377,
                        column: 20
                    },
                    end: {
                        line: 379,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 377,
                        column: 20
                    },
                    end: {
                        line: 379,
                        column: 21
                    }
                }, {
                    start: {
                        line: 377,
                        column: 20
                    },
                    end: {
                        line: 379,
                        column: 21
                    }
                }],
                line: 377
            },
            '20': {
                loc: {
                    start: {
                        line: 383,
                        column: 20
                    },
                    end: {
                        line: 385,
                        column: 21
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 383,
                        column: 20
                    },
                    end: {
                        line: 385,
                        column: 21
                    }
                }, {
                    start: {
                        line: 383,
                        column: 20
                    },
                    end: {
                        line: 385,
                        column: 21
                    }
                }],
                line: 383
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0],
            '15': [0, 0],
            '16': [0, 0],
            '17': [0, 0],
            '18': [0, 0],
            '19': [0, 0],
            '20': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_64mjk2nhm.s[0]++;
(function () {
    'use strict';

    cov_64mjk2nhm.f[0]++;
    cov_64mjk2nhm.s[1]++;
    angular.module('app').factory('SamplesTable', RestmodFactory);

    /* @ngInject */
    function RestmodFactory(restmod) {
        cov_64mjk2nhm.f[1]++;
        cov_64mjk2nhm.s[2]++;

        return restmod.model('/samples').mix('MixinContainable', {
            $config: {
                contains: {
                    sampleType: {
                        type: 'belongsTo',
                        table: 'SampleTypesTable',
                        foreignKey: 'sampleType'
                    },
                    brand: {
                        type: 'belongsTo',
                        table: 'BrandsTable',
                        foreignKey: 'brand'
                    },
                    currentState: {
                        type: 'belongsTo',
                        table: 'SampleStatesTable',
                        foreignKey: 'currentState'
                    },
                    finishings: {
                        type: 'hasMany',
                        table: 'SampleFinishingsTable',
                        foreignKey: 'sample'
                    },
                    states: {
                        type: 'hasMany',
                        table: 'SampleStatesTable',
                        foreignKey: 'sample',
                        filters: {
                            ordering: '-checkout_date',
                            /* eslint-disable camelcase */
                            page_size: 999
                        }
                    },
                    foreseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'foreseen_date'
                        }
                    },
                    meshForeseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'mesh_foreseen_date'
                        }
                    },
                    confectionAccessoriesForeseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'confection_accessories_foreseen_date'
                        }
                    },
                    packingAccessoriesForeseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'packing_accessories_foreseen_date'
                        }
                    },
                    finishingsForeseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'finishings_foreseen_date'
                        }
                    },
                    decorationsForeseenDateChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'decorations_foreseen_date'
                        }
                    },

                    decorationsSentAtChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'decorations_sent_at'
                        }
                    },
                    decorationsReceivedAtChanges: {
                        type: 'hasMany',
                        table: 'ForeseenDateChangesTable',
                        foreignKey: 'sample',
                        filters: {
                            /* eslint-disable camelcase */
                            page_size: 999,
                            field: 'decorations_received_at'
                        }
                    },
                    prices: {
                        type: 'hasMany',
                        table: 'SampleItemPricesTable',
                        foreignKey: 'sample',
                        filters: {
                            ordering: 'sample_item_price_type__type,sample_item_price_type__order,sample_item_price_type__name'
                        }
                    },
                    checks: {
                        type: 'hasMany',
                        table: 'SamplePriceChecksTable',
                        foreignKey: 'sample',
                        /* eslint-disable camelcase */
                        filters: {
                            is_active: 'True'
                        }
                    },
                    decoration: {
                        type: 'belongsTo',
                        table: 'DecorationTypesTable',
                        foreignKey: 'decoration'
                    },
                    observationsChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'observationsUpdatedBy'
                    },
                    hasMeshChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'hasMeshUpdatedBy'
                    },
                    hasConfectionAccessoriesChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'hasConfectionAccessoriesUpdatedBy'
                    },
                    hasPackingAccessoriesChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'hasPackingAccessoriesUpdatedBy'
                    },
                    decorationsForeseenDateChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'decorationsForeseenDateUpdatedBy'
                    },
                    finishingsForeseenDateChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'finishingsForeseenDateUpdatedBy'
                    },
                    sampleMeshes: {
                        type: 'hasMany',
                        table: 'SampleMeshesTable',
                        foreignKey: 'sample',
                        /* eslint-disable camelcase */
                        filters: {
                            page_size: 99
                        }
                    },
                    nudges: {
                        type: 'hasMany',
                        table: 'NudgesTable',
                        foreignKey: 'sample'
                    },
                    photos: {
                        type: 'hasMany',
                        table: 'SamplePhotosTable',
                        foreignKey: 'sample',
                        /* eslint-disable camelcase */
                        filters: {
                            page_size: 99
                        }
                    },
                    files: {
                        type: 'hasMany',
                        table: 'SampleFilesTable',
                        foreignKey: 'sample',
                        /* eslint-disable camelcase */
                        filters: {
                            page_size: 99
                        }
                    },
                    measurementFiles: {
                        type: 'hasMany',
                        table: 'SampleFilesTable',
                        foreignKey: 'sample',
                        /* eslint-disable camelcase */
                        filters: {
                            field: 'MEASUREMENT_FILES',
                            page_size: 99
                        }
                    },
                    sampleDevelopmentCycles: {
                        type: 'hasMany',
                        table: 'SampleDevelopmentCyclesTable',
                        foreignKey: 'sample'
                    },
                    nextSampleStateType: {
                        type: 'belongsTo',
                        table: 'SampleStateTypesTable',
                        foreignKey: 'nextSampleStateType'
                    },
                    previousSampleStateType: {
                        type: 'belongsTo',
                        table: 'SampleStateTypesTable',
                        foreignKey: 'previousSampleStateType'
                    },
                    decorationsObservationsChanger: {
                        type: 'belongsTo',
                        table: 'UsersTable',
                        foreignKey: 'decorationsObservationsUpdatedBy'
                    }
                }
            },
            orderReference: {
                decode: function decode(n) {
                    cov_64mjk2nhm.f[2]++;
                    cov_64mjk2nhm.s[3]++;

                    if (n) {
                        cov_64mjk2nhm.b[0][0]++;
                        cov_64mjk2nhm.s[4]++;

                        return Number(n);
                    } else {
                        cov_64mjk2nhm.b[0][1]++;
                    }
                    cov_64mjk2nhm.s[5]++;
                    return null;
                }
            },
            intendedDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[3]++;
                    cov_64mjk2nhm.s[6]++;

                    /* eslint-disable no-undef */
                    if (date) {
                        cov_64mjk2nhm.b[1][0]++;
                        cov_64mjk2nhm.s[7]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[1][1]++;
                    }
                    cov_64mjk2nhm.s[8]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[4]++;
                    cov_64mjk2nhm.s[9]++;

                    if (date) {
                        cov_64mjk2nhm.b[2][0]++;
                        cov_64mjk2nhm.s[10]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[2][1]++;
                    }
                    cov_64mjk2nhm.s[11]++;
                    return null;
                }
            },
            foreseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[5]++;
                    cov_64mjk2nhm.s[12]++;

                    if (date) {
                        cov_64mjk2nhm.b[3][0]++;
                        cov_64mjk2nhm.s[13]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[3][1]++;
                    }
                    cov_64mjk2nhm.s[14]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[6]++;
                    cov_64mjk2nhm.s[15]++;

                    if (date) {
                        cov_64mjk2nhm.b[4][0]++;
                        cov_64mjk2nhm.s[16]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[4][1]++;
                    }
                    cov_64mjk2nhm.s[17]++;
                    return null;
                }
            },
            shippingDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[7]++;
                    cov_64mjk2nhm.s[18]++;

                    if (date) {
                        cov_64mjk2nhm.b[5][0]++;
                        cov_64mjk2nhm.s[19]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[5][1]++;
                    }
                    cov_64mjk2nhm.s[20]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[8]++;
                    cov_64mjk2nhm.s[21]++;

                    if (date) {
                        cov_64mjk2nhm.b[6][0]++;
                        cov_64mjk2nhm.s[22]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[6][1]++;
                    }
                    cov_64mjk2nhm.s[23]++;
                    return null;
                }
            },
            confectionAccessoriesForeseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[9]++;
                    cov_64mjk2nhm.s[24]++;

                    if (date) {
                        cov_64mjk2nhm.b[7][0]++;
                        cov_64mjk2nhm.s[25]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[7][1]++;
                    }
                    cov_64mjk2nhm.s[26]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[10]++;
                    cov_64mjk2nhm.s[27]++;

                    if (date) {
                        cov_64mjk2nhm.b[8][0]++;
                        cov_64mjk2nhm.s[28]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[8][1]++;
                    }
                    cov_64mjk2nhm.s[29]++;
                    return null;
                }
            },
            packingAccessoriesForeseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[11]++;
                    cov_64mjk2nhm.s[30]++;

                    if (date) {
                        cov_64mjk2nhm.b[9][0]++;
                        cov_64mjk2nhm.s[31]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[9][1]++;
                    }
                    cov_64mjk2nhm.s[32]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[12]++;
                    cov_64mjk2nhm.s[33]++;

                    if (date) {
                        cov_64mjk2nhm.b[10][0]++;
                        cov_64mjk2nhm.s[34]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[10][1]++;
                    }
                    cov_64mjk2nhm.s[35]++;
                    return null;
                }
            },
            meshForeseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[13]++;
                    cov_64mjk2nhm.s[36]++;

                    if (date) {
                        cov_64mjk2nhm.b[11][0]++;
                        cov_64mjk2nhm.s[37]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[11][1]++;
                    }
                    cov_64mjk2nhm.s[38]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[14]++;
                    cov_64mjk2nhm.s[39]++;

                    if (date) {
                        cov_64mjk2nhm.b[12][0]++;
                        cov_64mjk2nhm.s[40]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[12][1]++;
                    }
                    cov_64mjk2nhm.s[41]++;
                    return null;
                }
            },
            decorationsForeseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[15]++;
                    cov_64mjk2nhm.s[42]++;

                    if (date) {
                        cov_64mjk2nhm.b[13][0]++;
                        cov_64mjk2nhm.s[43]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[13][1]++;
                    }
                    cov_64mjk2nhm.s[44]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[16]++;
                    cov_64mjk2nhm.s[45]++;

                    if (date) {
                        cov_64mjk2nhm.b[14][0]++;
                        cov_64mjk2nhm.s[46]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[14][1]++;
                    }
                    cov_64mjk2nhm.s[47]++;
                    return null;
                }
            },
            finishingsForeseenDate: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[17]++;
                    cov_64mjk2nhm.s[48]++;

                    if (date) {
                        cov_64mjk2nhm.b[15][0]++;
                        cov_64mjk2nhm.s[49]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[15][1]++;
                    }
                    cov_64mjk2nhm.s[50]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[18]++;
                    cov_64mjk2nhm.s[51]++;

                    if (date) {
                        cov_64mjk2nhm.b[16][0]++;
                        cov_64mjk2nhm.s[52]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[16][1]++;
                    }
                    cov_64mjk2nhm.s[53]++;
                    return null;
                }
            },
            decorationsSentAt: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[19]++;
                    cov_64mjk2nhm.s[54]++;

                    if (date) {
                        cov_64mjk2nhm.b[17][0]++;
                        cov_64mjk2nhm.s[55]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[17][1]++;
                    }
                    cov_64mjk2nhm.s[56]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[20]++;
                    cov_64mjk2nhm.s[57]++;

                    if (date) {
                        cov_64mjk2nhm.b[18][0]++;
                        cov_64mjk2nhm.s[58]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[18][1]++;
                    }
                    cov_64mjk2nhm.s[59]++;
                    return null;
                }
            },
            decorationsReceivedAt: {
                encode: function encode(date) {
                    cov_64mjk2nhm.f[21]++;
                    cov_64mjk2nhm.s[60]++;

                    if (date) {
                        cov_64mjk2nhm.b[19][0]++;
                        cov_64mjk2nhm.s[61]++;

                        return moment(date).format('YYYY-MM-DD');
                    } else {
                        cov_64mjk2nhm.b[19][1]++;
                    }
                    cov_64mjk2nhm.s[62]++;
                    return null;
                },
                decode: function decode(date) {
                    cov_64mjk2nhm.f[22]++;
                    cov_64mjk2nhm.s[63]++;

                    if (date) {
                        cov_64mjk2nhm.b[20][0]++;
                        cov_64mjk2nhm.s[64]++;

                        return new Date(date);
                    } else {
                        cov_64mjk2nhm.b[20][1]++;
                    }
                    cov_64mjk2nhm.s[65]++;
                    return null;
                }
            }
        }).mix('DirtyModel');
    }
})();