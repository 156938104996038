"use strict";

var cov_q6xcguawl = function () {
    var path = "/home/lobo/repos/mypedrosa-app/src/app/components/sampleHistory/sampleHistory.js",
        hash = "349d882d6a5cfd883379f570239a73aae402eda7",
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/lobo/repos/mypedrosa-app/src/app/components/sampleHistory/sampleHistory.js",
        statementMap: {
            "0": {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 30
                }
            },
            "1": {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 39
                }
            },
            "2": {
                start: {
                    line: 9,
                    column: 8
                },
                end: {
                    line: 9,
                    column: 33
                }
            },
            "3": {
                start: {
                    line: 10,
                    column: 20
                },
                end: {
                    line: 10,
                    column: 74
                }
            },
            "4": {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 56
                }
            },
            "5": {
                start: {
                    line: 15,
                    column: 20
                },
                end: {
                    line: 15,
                    column: 40
                }
            },
            "6": {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 35
                }
            },
            "7": {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 41
                }
            },
            "8": {
                start: {
                    line: 18,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 10
                }
            },
            "9": {
                start: {
                    line: 19,
                    column: 25
                },
                end: {
                    line: 19,
                    column: 62
                }
            },
            "10": {
                start: {
                    line: 20,
                    column: 36
                },
                end: {
                    line: 20,
                    column: 65
                }
            },
            "11": {
                start: {
                    line: 21,
                    column: 25
                },
                end: {
                    line: 21,
                    column: 60
                }
            },
            "12": {
                start: {
                    line: 23,
                    column: 27
                },
                end: {
                    line: 23,
                    column: 54
                }
            },
            "13": {
                start: {
                    line: 25,
                    column: 12
                },
                end: {
                    line: 25,
                    column: 59
                }
            },
            "14": {
                start: {
                    line: 26,
                    column: 12
                },
                end: {
                    line: 26,
                    column: 39
                }
            },
            "15": {
                start: {
                    line: 27,
                    column: 12
                },
                end: {
                    line: 27,
                    column: 27
                }
            },
            "16": {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 19
                }
            },
            "17": {
                start: {
                    line: 34,
                    column: 24
                },
                end: {
                    line: 34,
                    column: 43
                }
            },
            "18": {
                start: {
                    line: 35,
                    column: 18
                },
                end: {
                    line: 35,
                    column: 30
                }
            },
            "19": {
                start: {
                    line: 36,
                    column: 22
                },
                end: {
                    line: 36,
                    column: 40
                }
            },
            "20": {
                start: {
                    line: 37,
                    column: 24
                },
                end: {
                    line: 37,
                    column: 49
                }
            },
            "21": {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 41,
                    column: 9
                }
            },
            "22": {
                start: {
                    line: 40,
                    column: 12
                },
                end: {
                    line: 40,
                    column: 80
                }
            },
            "23": {
                start: {
                    line: 43,
                    column: 30
                },
                end: {
                    line: 43,
                    column: 54
                }
            },
            "24": {
                start: {
                    line: 45,
                    column: 28
                },
                end: {
                    line: 45,
                    column: 76
                }
            },
            "25": {
                start: {
                    line: 46,
                    column: 30
                },
                end: {
                    line: 46,
                    column: 109
                }
            },
            "26": {
                start: {
                    line: 47,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 51
                }
            },
            "27": {
                start: {
                    line: 51,
                    column: 24
                },
                end: {
                    line: 51,
                    column: 57
                }
            },
            "28": {
                start: {
                    line: 52,
                    column: 25
                },
                end: {
                    line: 52,
                    column: 59
                }
            },
            "29": {
                start: {
                    line: 53,
                    column: 29
                },
                end: {
                    line: 53,
                    column: 47
                }
            },
            "30": {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 52
                }
            },
            "31": {
                start: {
                    line: 58,
                    column: 20
                },
                end: {
                    line: 58,
                    column: 30
                }
            },
            "32": {
                start: {
                    line: 59,
                    column: 25
                },
                end: {
                    line: 59,
                    column: 47
                }
            },
            "33": {
                start: {
                    line: 60,
                    column: 29
                },
                end: {
                    line: 60,
                    column: 43
                }
            },
            "34": {
                start: {
                    line: 61,
                    column: 8
                },
                end: {
                    line: 61,
                    column: 52
                }
            },
            "35": {
                start: {
                    line: 66,
                    column: 0
                },
                end: {
                    line: 74,
                    column: 7
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 29
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            "1": {
                name: "(anonymous_1)",
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 38
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 8
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 35
                    },
                    end: {
                        line: 30,
                        column: 5
                    }
                },
                line: 14
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 18,
                        column: 21
                    },
                    end: {
                        line: 18,
                        column: 22
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 33
                    },
                    end: {
                        line: 28,
                        column: 9
                    }
                },
                line: 18
            },
            "4": {
                name: "(anonymous_4)",
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 36
                    },
                    end: {
                        line: 48,
                        column: 5
                    }
                },
                line: 33
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 50,
                        column: 4
                    },
                    end: {
                        line: 50,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 32
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 50
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 40
                    },
                    end: {
                        line: 62,
                        column: 5
                    }
                },
                line: 57
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }, {
                    start: {
                        line: 39,
                        column: 8
                    },
                    end: {
                        line: 41,
                        column: 9
                    }
                }],
                line: 39
            },
            "1": {
                loc: {
                    start: {
                        line: 40,
                        column: 19
                    },
                    end: {
                        line: 40,
                        column: 79
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 40,
                        column: 37
                    },
                    end: {
                        line: 40,
                        column: 56
                    }
                }, {
                    start: {
                        line: 40,
                        column: 59
                    },
                    end: {
                        line: 40,
                        column: 79
                    }
                }],
                line: 40
            },
            "2": {
                loc: {
                    start: {
                        line: 45,
                        column: 28
                    },
                    end: {
                        line: 45,
                        column: 76
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 45,
                        column: 42
                    },
                    end: {
                        line: 45,
                        column: 57
                    }
                }, {
                    start: {
                        line: 45,
                        column: 60
                    },
                    end: {
                        line: 45,
                        column: 76
                    }
                }],
                line: 45
            },
            "3": {
                loc: {
                    start: {
                        line: 46,
                        column: 30
                    },
                    end: {
                        line: 46,
                        column: 109
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 46,
                        column: 55
                    },
                    end: {
                        line: 46,
                        column: 80
                    }
                }, {
                    start: {
                        line: 46,
                        column: 83
                    },
                    end: {
                        line: 46,
                        column: 109
                    }
                }],
                line: 46
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleHistoryController = function () {
    function SampleHistoryController(AppSettings) {
        _classCallCheck(this, SampleHistoryController);

        cov_q6xcguawl.f[0]++;
        cov_q6xcguawl.s[0]++;

        this.now = new Date();
        cov_q6xcguawl.s[1]++;
        this.AppSettings = AppSettings;
    }

    _createClass(SampleHistoryController, [{
        key: "download",
        value: function download($event, sampleStateFile) {
            cov_q6xcguawl.f[1]++;
            cov_q6xcguawl.s[2]++;

            $event.stopPropagation();
            var url = (cov_q6xcguawl.s[3]++, this.AppSettings.mediaUrl + "/" + sampleStateFile.file);
            cov_q6xcguawl.s[4]++;
            this.downloadRequest(url, sampleStateFile.file);
        }
    }, {
        key: "downloadRequest",
        value: function downloadRequest(url, filename) {
            cov_q6xcguawl.f[2]++;

            var xhr = (cov_q6xcguawl.s[5]++, new XMLHttpRequest());
            cov_q6xcguawl.s[6]++;
            xhr.open("GET", url, true);
            cov_q6xcguawl.s[7]++;
            xhr.responseType = "arraybuffer";
            cov_q6xcguawl.s[8]++;
            xhr.onload = function () {
                cov_q6xcguawl.f[3]++;

                var type = (cov_q6xcguawl.s[9]++, xhr.getResponseHeader("content-type"));
                var arrayBufferView = (cov_q6xcguawl.s[10]++, new Uint8Array(this.response));
                var blob = (cov_q6xcguawl.s[11]++, new Blob([arrayBufferView], { type: type }));
                /* eslint-disable angular/document-service */
                var anchor = (cov_q6xcguawl.s[12]++, document.createElement('a'));
                /* eslint-disable angular/window-service */
                cov_q6xcguawl.s[13]++;
                anchor.href = window.URL.createObjectURL(blob);
                cov_q6xcguawl.s[14]++;
                anchor.download = filename;
                cov_q6xcguawl.s[15]++;
                anchor.click();
            };
            cov_q6xcguawl.s[16]++;
            xhr.send();
        }

        /** converts from microseconds to hours and minutes */

    }, {
        key: "durationFormatter",
        value: function durationFormatter(microseconds) {
            cov_q6xcguawl.f[4]++;

            var seconds = (cov_q6xcguawl.s[17]++, microseconds / 1000);
            var m = (cov_q6xcguawl.s[18]++, seconds / 60);
            var hours = (cov_q6xcguawl.s[19]++, Math.floor(m / 60));
            var minutes = (cov_q6xcguawl.s[20]++, String(Math.ceil(m % 60)));

            cov_q6xcguawl.s[21]++;
            if (!hours) {
                cov_q6xcguawl.b[0][0]++;
                cov_q6xcguawl.s[22]++;

                return minutes === "1" ? (cov_q6xcguawl.b[1][0]++, minutes + " MINUTO") : (cov_q6xcguawl.b[1][1]++, minutes + " MINUTOS");
            } else {
                cov_q6xcguawl.b[0][1]++;
            }

            var paddedMinutes = (cov_q6xcguawl.s[23]++, minutes.padStart(2, '0'));

            var hoursOutput = (cov_q6xcguawl.s[24]++, hours === 1 ? (cov_q6xcguawl.b[2][0]++, hours + " HORA") : (cov_q6xcguawl.b[2][1]++, hours + " HORAS"));
            var minutesOutput = (cov_q6xcguawl.s[25]++, paddedMinutes === "01" ? (cov_q6xcguawl.b[3][0]++, paddedMinutes + " MINUTO") : (cov_q6xcguawl.b[3][1]++, paddedMinutes + " MINUTOS"));
            cov_q6xcguawl.s[26]++;
            return hoursOutput + " E " + minutesOutput;
        }
    }, {
        key: "formatDuration",
        value: function formatDuration(sampleState) {
            cov_q6xcguawl.f[5]++;

            var checkin = (cov_q6xcguawl.s[27]++, new Date(sampleState.checkinDate));
            var checkout = (cov_q6xcguawl.s[28]++, new Date(sampleState.checkoutDate));
            var microseconds = (cov_q6xcguawl.s[29]++, checkin - checkout);
            cov_q6xcguawl.s[30]++;
            return this.durationFormatter(microseconds);
        }
    }, {
        key: "formatDurationWithNow",
        value: function formatDurationWithNow(checkoutDate) {
            cov_q6xcguawl.f[6]++;

            var now = (cov_q6xcguawl.s[31]++, new Date());
            var checkout = (cov_q6xcguawl.s[32]++, new Date(checkoutDate));
            var microseconds = (cov_q6xcguawl.s[33]++, now - checkout);
            cov_q6xcguawl.s[34]++;
            return this.durationFormatter(microseconds);
        }
    }]);

    return SampleHistoryController;
}();

cov_q6xcguawl.s[35]++;


angular.module('app').component('sampleHistory', {
    templateUrl: 'app/components/sampleHistory/sampleHistory.html',
    controller: SampleHistoryController,
    bindings: {
        sample: '<'
    }
});