'use strict';

var cov_1h37ndfuhc = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/finishingTypesView/finishingTypesView.js',
        hash = 'e55588756d40910f5a4c0d10b5dd9c088dee623b',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/finishingTypesView/finishingTypesView.js',
        statementMap: {
            '0': {
                start: {
                    line: 3,
                    column: 8
                },
                end: {
                    line: 3,
                    column: 33
                }
            },
            '1': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 28
                }
            },
            '2': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 52
                }
            },
            '4': {
                start: {
                    line: 16,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 45
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 62
                }
            },
            '6': {
                start: {
                    line: 17,
                    column: 41
                },
                end: {
                    line: 17,
                    column: 60
                }
            },
            '7': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 24,
                    column: 11
                }
            },
            '8': {
                start: {
                    line: 22,
                    column: 12
                },
                end: {
                    line: 22,
                    column: 32
                }
            },
            '9': {
                start: {
                    line: 23,
                    column: 12
                },
                end: {
                    line: 23,
                    column: 99
                }
            },
            '10': {
                start: {
                    line: 28,
                    column: 0
                },
                end: {
                    line: 36,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 2,
                        column: 4
                    },
                    end: {
                        line: 2,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 26
                    },
                    end: {
                        line: 4,
                        column: 5
                    }
                },
                line: 2
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 6,
                        column: 4
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 6,
                        column: 14
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 6
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 19
                    },
                    end: {
                        line: 13,
                        column: 5
                    }
                },
                line: 10
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 15,
                        column: 4
                    },
                    end: {
                        line: 15,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 15,
                        column: 11
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                line: 15
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 17,
                        column: 35
                    },
                    end: {
                        line: 17,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 41
                    },
                    end: {
                        line: 17,
                        column: 60
                    }
                },
                line: 17
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 13
                    },
                    end: {
                        line: 25,
                        column: 5
                    }
                },
                line: 20
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 21,
                        column: 38
                    },
                    end: {
                        line: 21,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 21,
                        column: 44
                    },
                    end: {
                        line: 24,
                        column: 9
                    }
                },
                line: 21
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FinishingTypesViewController = function () {
    function FinishingTypesViewController($mdToast) {
        _classCallCheck(this, FinishingTypesViewController);

        cov_1h37ndfuhc.f[0]++;
        cov_1h37ndfuhc.s[0]++;

        this.$mdToast = $mdToast;
    }

    _createClass(FinishingTypesViewController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1h37ndfuhc.f[1]++;
            cov_1h37ndfuhc.s[1]++;

            this._initNewType();
        }
    }, {
        key: '_initNewType',
        value: function _initNewType() {
            cov_1h37ndfuhc.f[2]++;
            cov_1h37ndfuhc.s[2]++;

            this.newTypeName = '';
            cov_1h37ndfuhc.s[3]++;
            this.newType = this.finishingTypes.$build();
        }
    }, {
        key: 'save',
        value: function save() {
            var _this = this;

            cov_1h37ndfuhc.f[3]++;
            cov_1h37ndfuhc.s[4]++;

            this.newType.name = this.newTypeName;
            cov_1h37ndfuhc.s[5]++;
            this.newType.$save().$then(function () {
                cov_1h37ndfuhc.f[4]++;
                cov_1h37ndfuhc.s[6]++;
                return _this._initNewType();
            });
        }
    }, {
        key: 'remove',
        value: function remove() {
            var _this2 = this;

            cov_1h37ndfuhc.f[5]++;
            cov_1h37ndfuhc.s[7]++;

            this.newType.$destroy().$then(function () {
                cov_1h37ndfuhc.f[6]++;
                cov_1h37ndfuhc.s[8]++;

                _this2._initNewType();
                cov_1h37ndfuhc.s[9]++;
                _this2.$mdToast.show(_this2.$mdToast.simple().textContent('Tipo de Acabamento removido!'));
            });
        }
    }]);

    return FinishingTypesViewController;
}();

cov_1h37ndfuhc.s[10]++;


angular.module('app').component('finishingTypesView', {
    templateUrl: 'app/components/finishingTypesView/finishingTypesView.html',
    controller: FinishingTypesViewController,
    bindings: {
        finishingTypes: '<'
    }
});