'use strict';

var cov_1rh1mqszj9 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleTileOverview/sampleTileOverview.js',
        hash = '075427befd9be8db7f8bd9ede8170d4aa8cafc46',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleTileOverview/sampleTileOverview.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 55
                }
            },
            '1': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 8,
                    column: 31
                }
            },
            '2': {
                start: {
                    line: 12,
                    column: 8
                },
                end: {
                    line: 12,
                    column: 35
                }
            },
            '3': {
                start: {
                    line: 13,
                    column: 8
                },
                end: {
                    line: 13,
                    column: 71
                }
            },
            '4': {
                start: {
                    line: 13,
                    column: 54
                },
                end: {
                    line: 13,
                    column: 69
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 0
                },
                end: {
                    line: 26,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 18
                    },
                    end: {
                        line: 5,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 7,
                        column: 4
                    },
                    end: {
                        line: 7,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 7,
                        column: 15
                    },
                    end: {
                        line: 9,
                        column: 5
                    }
                },
                line: 7
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 4
                    },
                    end: {
                        line: 11,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 30
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                line: 11
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 13,
                        column: 48
                    },
                    end: {
                        line: 13,
                        column: 49
                    }
                },
                loc: {
                    start: {
                        line: 13,
                        column: 54
                    },
                    end: {
                        line: 13,
                        column: 69
                    }
                },
                line: 13
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SampleTitleOverviewController = function () {
    function SampleTitleOverviewController() {
        _classCallCheck(this, SampleTitleOverviewController);

        cov_1rh1mqszj9.f[0]++;
        cov_1rh1mqszj9.s[0]++;

        this.updateField = this.updateField.bind(this);
    }

    _createClass(SampleTitleOverviewController, [{
        key: '_refresh',
        value: function _refresh() {
            cov_1rh1mqszj9.f[1]++;
            cov_1rh1mqszj9.s[1]++;

            this.sample.$fetch({});
        }
    }, {
        key: 'updateField',
        value: function updateField(field, value) {
            var _this = this;

            cov_1rh1mqszj9.f[2]++;
            cov_1rh1mqszj9.s[2]++;

            this.sample[field] = value;
            cov_1rh1mqszj9.s[3]++;
            return this.sample.$save([field]).$then(function () {
                cov_1rh1mqszj9.f[3]++;
                cov_1rh1mqszj9.s[4]++;
                return _this._refresh();
            });
        }
    }]);

    return SampleTitleOverviewController;
}();

cov_1rh1mqszj9.s[5]++;


angular.module('app').component('sampleTileOverview', {
    controller: SampleTitleOverviewController,
    templateUrl: 'app/components/sampleTileOverview/sampleTileOverview.html',
    bindings: {
        sample: '<',
        checkWorkItem: '<'
    }
});