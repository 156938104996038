'use strict';

var cov_15bxifb5aj = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/shared/js/sampleRouting.factory.js',
        hash = 'b9d6d6bd26fad6c9755d7fa41d26d53b4f8d84db',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/shared/js/sampleRouting.factory.js',
        statementMap: {
            '0': {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 27,
                    column: 5
                }
            },
            '1': {
                start: {
                    line: 4,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 20
                },
                end: {
                    line: 10,
                    column: 41
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 14,
                    column: 13
                }
            },
            '5': {
                start: {
                    line: 13,
                    column: 16
                },
                end: {
                    line: 13,
                    column: 27
                }
            },
            '6': {
                start: {
                    line: 15,
                    column: 29
                },
                end: {
                    line: 15,
                    column: 38
                }
            },
            '7': {
                start: {
                    line: 16,
                    column: 33
                },
                end: {
                    line: 23,
                    column: 13
                }
            },
            '8': {
                start: {
                    line: 24,
                    column: 12
                },
                end: {
                    line: 24,
                    column: 42
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 1,
                        column: 13
                    },
                    end: {
                        line: 27,
                        column: 1
                    }
                },
                line: 1
            },
            '1': {
                name: 'sampleRouting',
                decl: {
                    start: {
                        line: 9,
                        column: 13
                    },
                    end: {
                        line: 9,
                        column: 26
                    }
                },
                loc: {
                    start: {
                        line: 9,
                        column: 29
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 9
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 11,
                        column: 15
                    },
                    end: {
                        line: 11,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 11,
                        column: 31
                    },
                    end: {
                        line: 25,
                        column: 9
                    }
                },
                line: 11
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }, {
                    start: {
                        line: 12,
                        column: 12
                    },
                    end: {
                        line: 14,
                        column: 13
                    }
                }],
                line: 12
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_15bxifb5aj.s[0]++;
(function () {
    'use strict';

    cov_15bxifb5aj.f[0]++;
    cov_15bxifb5aj.s[1]++;
    angular.module('app').factory('sampleRouting', sampleRouting);

    /* @ngInject */
    function sampleRouting() {
        cov_15bxifb5aj.f[1]++;

        var all = (cov_15bxifb5aj.s[2]++, [1, 2, 3, 4, 6, 7, 8]);
        cov_15bxifb5aj.s[3]++;
        return function (user) {
            cov_15bxifb5aj.f[2]++;
            cov_15bxifb5aj.s[4]++;

            if (user.isSupervisor) {
                cov_15bxifb5aj.b[0][0]++;
                cov_15bxifb5aj.s[5]++;

                return all;
            } else {
                cov_15bxifb5aj.b[0][1]++;
            }
            var userType = (cov_15bxifb5aj.s[6]++, user.type);
            var routingRules = (cov_15bxifb5aj.s[7]++, {
                COMMERCIAL: all,
                CAD: all,
                CUTTING: all,
                CONFECTION: all,
                FINISHINGS: all,
                CONTROL: all
            });
            cov_15bxifb5aj.s[8]++;
            return routingRules[userType];
        };
    }
})();