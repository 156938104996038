'use strict';

var cov_1ewc4gtm9c = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/pricesDisplayGrid/pricesDisplayGrid.js',
        hash = '0e8d5a4f586d819ea4b3e4dbceb2189e76b683a3',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/pricesDisplayGrid/pricesDisplayGrid.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 35
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 41
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 29
                }
            },
            '3': {
                start: {
                    line: 7,
                    column: 8
                },
                end: {
                    line: 7,
                    column: 43
                }
            },
            '4': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 14,
                    column: 9
                }
            },
            '5': {
                start: {
                    line: 12,
                    column: 12
                },
                end: {
                    line: 12,
                    column: 80
                }
            },
            '6': {
                start: {
                    line: 13,
                    column: 12
                },
                end: {
                    line: 13,
                    column: 73
                }
            },
            '7': {
                start: {
                    line: 15,
                    column: 8
                },
                end: {
                    line: 15,
                    column: 27
                }
            },
            '8': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 72
                }
            },
            '9': {
                start: {
                    line: 20,
                    column: 8
                },
                end: {
                    line: 20,
                    column: 73
                }
            },
            '10': {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 32
                }
            },
            '11': {
                start: {
                    line: 25,
                    column: 0
                },
                end: {
                    line: 35,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 41
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 10,
                        column: 4
                    },
                    end: {
                        line: 10,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 10,
                        column: 14
                    },
                    end: {
                        line: 16,
                        column: 5
                    }
                },
                line: 10
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 18,
                        column: 4
                    },
                    end: {
                        line: 18,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 18,
                        column: 18
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 18
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                }, {
                    start: {
                        line: 11,
                        column: 8
                    },
                    end: {
                        line: 14,
                        column: 9
                    }
                }],
                line: 11
            },
            '1': {
                loc: {
                    start: {
                        line: 12,
                        column: 29
                    },
                    end: {
                        line: 12,
                        column: 79
                    }
                },
                type: 'cond-expr',
                locations: [{
                    start: {
                        line: 12,
                        column: 71
                    },
                    end: {
                        line: 12,
                        column: 74
                    }
                }, {
                    start: {
                        line: 12,
                        column: 77
                    },
                    end: {
                        line: 12,
                        column: 79
                    }
                }],
                line: 12
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PricesDisplayGridController = function () {
    function PricesDisplayGridController($location, $stateParams) {
        _classCallCheck(this, PricesDisplayGridController);

        cov_1ewc4gtm9c.f[0]++;
        cov_1ewc4gtm9c.s[0]++;

        this.$location = $location;
        cov_1ewc4gtm9c.s[1]++;
        this.$stateParams = $stateParams;
        cov_1ewc4gtm9c.s[2]++;
        this.direction = '-';
        cov_1ewc4gtm9c.s[3]++;
        this.sortField = 'brand__priority';
    }

    _createClass(PricesDisplayGridController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_1ewc4gtm9c.f[1]++;
            cov_1ewc4gtm9c.s[4]++;

            if (this.$stateParams.ordering) {
                cov_1ewc4gtm9c.b[0][0]++;
                cov_1ewc4gtm9c.s[5]++;

                this.direction = this.$stateParams.ordering[0] === '-' ? (cov_1ewc4gtm9c.b[1][0]++, '-') : (cov_1ewc4gtm9c.b[1][1]++, '');
                cov_1ewc4gtm9c.s[6]++;
                this.sortField = this.$stateParams.ordering.replace('-', '');
            } else {
                cov_1ewc4gtm9c.b[0][1]++;
            }
            cov_1ewc4gtm9c.s[7]++;
            this.changeOrder();
        }
    }, {
        key: 'changeOrder',
        value: function changeOrder() {
            cov_1ewc4gtm9c.f[2]++;
            cov_1ewc4gtm9c.s[8]++;

            this.samples.$params.ordering = this.direction + this.sortField;
            cov_1ewc4gtm9c.s[9]++;
            this.$location.search('ordering', this.samples.$params.ordering);
            cov_1ewc4gtm9c.s[10]++;
            this.samples.$refresh();
        }
    }]);

    return PricesDisplayGridController;
}();

cov_1ewc4gtm9c.s[11]++;


angular.module('app').component('pricesDisplayGrid', {
    templateUrl: 'app/components/pricesDisplayGrid/pricesDisplayGrid.html',
    controller: PricesDisplayGridController,
    bindings: {
        samples: '<',
        brand: '=',
        workloads: '<'
    }
});