'use strict';

var cov_2dgvgas88o = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardPrices.js',
        hash = '00c25948650c085c0b35acfee89c5fdb949b3867',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/dashboard/dashboardPrices.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 41
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 29
                }
            },
            '2': {
                start: {
                    line: 6,
                    column: 8
                },
                end: {
                    line: 6,
                    column: 30
                }
            },
            '3': {
                start: {
                    line: 8,
                    column: 8
                },
                end: {
                    line: 16,
                    column: 10
                }
            },
            '4': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 17,
                    column: 55
                }
            },
            '5': {
                start: {
                    line: 19,
                    column: 8
                },
                end: {
                    line: 21,
                    column: 23
                }
            },
            '6': {
                start: {
                    line: 20,
                    column: 12
                },
                end: {
                    line: 20,
                    column: 24
                }
            },
            '7': {
                start: {
                    line: 25,
                    column: 8
                },
                end: {
                    line: 25,
                    column: 37
                }
            },
            '8': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 29,
                    column: 44
                }
            },
            '9': {
                start: {
                    line: 30,
                    column: 8
                },
                end: {
                    line: 30,
                    column: 29
                }
            },
            '10': {
                start: {
                    line: 34,
                    column: 8
                },
                end: {
                    line: 34,
                    column: 55
                }
            },
            '11': {
                start: {
                    line: 35,
                    column: 8
                },
                end: {
                    line: 35,
                    column: 20
                }
            },
            '12': {
                start: {
                    line: 39,
                    column: 8
                },
                end: {
                    line: 39,
                    column: 51
                }
            },
            '13': {
                start: {
                    line: 44,
                    column: 0
                },
                end: {
                    line: 49,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 49
                    },
                    end: {
                        line: 22,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 19,
                        column: 18
                    },
                    end: {
                        line: 19,
                        column: 19
                    }
                },
                loc: {
                    start: {
                        line: 19,
                        column: 24
                    },
                    end: {
                        line: 21,
                        column: 9
                    }
                },
                line: 19
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 24,
                        column: 4
                    },
                    end: {
                        line: 24,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 24,
                        column: 20
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                line: 24
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 28,
                        column: 4
                    },
                    end: {
                        line: 28,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 28,
                        column: 11
                    },
                    end: {
                        line: 31,
                        column: 5
                    }
                },
                line: 28
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 33,
                        column: 4
                    },
                    end: {
                        line: 33,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 33,
                        column: 33
                    },
                    end: {
                        line: 36,
                        column: 5
                    }
                },
                line: 33
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 38,
                        column: 4
                    },
                    end: {
                        line: 38,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 38,
                        column: 21
                    },
                    end: {
                        line: 40,
                        column: 5
                    }
                },
                line: 38
            }
        },
        branchMap: {},
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0
        },
        b: {},
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardPricesController = function () {
    function DashboardPricesController(SamplesTable, $interval, $state) {
        var _this = this;

        _classCallCheck(this, DashboardPricesController);

        cov_2dgvgas88o.f[0]++;
        cov_2dgvgas88o.s[0]++;

        this.SamplesTable = SamplesTable;
        cov_2dgvgas88o.s[1]++;
        this.$state = $state;
        cov_2dgvgas88o.s[2]++;
        this.now = new Date();
        /* eslint-disable camelcase */
        cov_2dgvgas88o.s[3]++;
        this.filters = {
            has_commercial_validation: false,
            page_size: 15,
            ordering: '-brand__priority,-validation_count',
            is_price_reference: true,
            is_price_closed: false,
            before_price_feature: false,
            current_state__sample_state_type__in: '1,2,3,4,5,6,7,8,9'
        };
        cov_2dgvgas88o.s[4]++;
        this.samples = this.SamplesTable.$collection();

        cov_2dgvgas88o.s[5]++;
        $interval(function () {
            cov_2dgvgas88o.f[1]++;
            cov_2dgvgas88o.s[6]++;

            _this.load();
        }, 600 * 1000);
    }

    _createClass(DashboardPricesController, [{
        key: 'setLastUpdate',
        value: function setLastUpdate() {
            cov_2dgvgas88o.f[2]++;
            cov_2dgvgas88o.s[7]++;

            this.lastUpdate = new Date();
        }
    }, {
        key: 'load',
        value: function load() {
            cov_2dgvgas88o.f[3]++;
            cov_2dgvgas88o.s[8]++;

            this.samples.$refresh(this.filters);
            cov_2dgvgas88o.s[9]++;
            this.setLastUpdate();
        }
    }, {
        key: 'setCommercial',
        value: function setCommercial(commercial_id) {
            cov_2dgvgas88o.f[4]++;
            cov_2dgvgas88o.s[10]++;

            this.filters.brand__commercial = commercial_id;
            cov_2dgvgas88o.s[11]++;
            this.load();
        }
    }, {
        key: 'openPricesList',
        value: function openPricesList() {
            cov_2dgvgas88o.f[5]++;
            cov_2dgvgas88o.s[12]++;

            this.$state.go('pricesList', this.filters);
        }
    }]);

    return DashboardPricesController;
}();

cov_2dgvgas88o.s[13]++;


angular.module('app').component('dashboardPrices', {
    templateUrl: 'app/components/dashboard/dashboardPrices.html',
    controller: DashboardPricesController
});