'use strict';

var cov_264rzufod5 = function () {
    var path = '/home/lobo/repos/mypedrosa-app/src/app/components/sampleItemPrices/sampleItemPrices.js',
        hash = '786f37a682f690eebf775e24ca1d0a8aba734363',
        global = new Function('return this')(),
        gcv = '__coverage__',
        coverageData = {
        path: '/home/lobo/repos/mypedrosa-app/src/app/components/sampleItemPrices/sampleItemPrices.js',
        statementMap: {
            '0': {
                start: {
                    line: 4,
                    column: 8
                },
                end: {
                    line: 4,
                    column: 38
                }
            },
            '1': {
                start: {
                    line: 5,
                    column: 8
                },
                end: {
                    line: 5,
                    column: 51
                }
            },
            '2': {
                start: {
                    line: 10,
                    column: 24
                },
                end: {
                    line: 10,
                    column: 69
                }
            },
            '3': {
                start: {
                    line: 11,
                    column: 8
                },
                end: {
                    line: 11,
                    column: 63
                }
            },
            '4': {
                start: {
                    line: 15,
                    column: 25
                },
                end: {
                    line: 15,
                    column: 41
                }
            },
            '5': {
                start: {
                    line: 17,
                    column: 8
                },
                end: {
                    line: 19,
                    column: 11
                }
            },
            '6': {
                start: {
                    line: 18,
                    column: 12
                },
                end: {
                    line: 18,
                    column: 31
                }
            },
            '7': {
                start: {
                    line: 27,
                    column: 8
                },
                end: {
                    line: 27,
                    column: 38
                }
            },
            '8': {
                start: {
                    line: 28,
                    column: 8
                },
                end: {
                    line: 28,
                    column: 38
                }
            },
            '9': {
                start: {
                    line: 29,
                    column: 8
                },
                end: {
                    line: 50,
                    column: 10
                }
            },
            '10': {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 54,
                    column: 80
                }
            },
            '11': {
                start: {
                    line: 54,
                    column: 57
                },
                end: {
                    line: 54,
                    column: 78
                }
            },
            '12': {
                start: {
                    line: 58,
                    column: 8
                },
                end: {
                    line: 60,
                    column: 9
                }
            },
            '13': {
                start: {
                    line: 59,
                    column: 12
                },
                end: {
                    line: 59,
                    column: 53
                }
            },
            '14': {
                start: {
                    line: 64,
                    column: 25
                },
                end: {
                    line: 64,
                    column: 41
                }
            },
            '15': {
                start: {
                    line: 66,
                    column: 8
                },
                end: {
                    line: 68,
                    column: 11
                }
            },
            '16': {
                start: {
                    line: 67,
                    column: 12
                },
                end: {
                    line: 67,
                    column: 31
                }
            },
            '17': {
                start: {
                    line: 75,
                    column: 8
                },
                end: {
                    line: 75,
                    column: 37
                }
            },
            '18': {
                start: {
                    line: 76,
                    column: 8
                },
                end: {
                    line: 76,
                    column: 58
                }
            },
            '19': {
                start: {
                    line: 77,
                    column: 8
                },
                end: {
                    line: 77,
                    column: 33
                }
            },
            '20': {
                start: {
                    line: 78,
                    column: 8
                },
                end: {
                    line: 78,
                    column: 31
                }
            },
            '21': {
                start: {
                    line: 79,
                    column: 8
                },
                end: {
                    line: 79,
                    column: 35
                }
            },
            '22': {
                start: {
                    line: 80,
                    column: 8
                },
                end: {
                    line: 80,
                    column: 33
                }
            },
            '23': {
                start: {
                    line: 81,
                    column: 8
                },
                end: {
                    line: 81,
                    column: 59
                }
            },
            '24': {
                start: {
                    line: 82,
                    column: 8
                },
                end: {
                    line: 82,
                    column: 41
                }
            },
            '25': {
                start: {
                    line: 83,
                    column: 8
                },
                end: {
                    line: 83,
                    column: 33
                }
            },
            '26': {
                start: {
                    line: 84,
                    column: 8
                },
                end: {
                    line: 84,
                    column: 40
                }
            },
            '27': {
                start: {
                    line: 85,
                    column: 8
                },
                end: {
                    line: 85,
                    column: 29
                }
            },
            '28': {
                start: {
                    line: 86,
                    column: 8
                },
                end: {
                    line: 86,
                    column: 35
                }
            },
            '29': {
                start: {
                    line: 87,
                    column: 8
                },
                end: {
                    line: 87,
                    column: 33
                }
            },
            '30': {
                start: {
                    line: 88,
                    column: 8
                },
                end: {
                    line: 88,
                    column: 37
                }
            },
            '31': {
                start: {
                    line: 89,
                    column: 8
                },
                end: {
                    line: 89,
                    column: 77
                }
            },
            '32': {
                start: {
                    line: 93,
                    column: 8
                },
                end: {
                    line: 93,
                    column: 61
                }
            },
            '33': {
                start: {
                    line: 94,
                    column: 8
                },
                end: {
                    line: 94,
                    column: 36
                }
            },
            '34': {
                start: {
                    line: 96,
                    column: 8
                },
                end: {
                    line: 96,
                    column: 132
                }
            },
            '35': {
                start: {
                    line: 97,
                    column: 8
                },
                end: {
                    line: 97,
                    column: 49
                }
            },
            '36': {
                start: {
                    line: 101,
                    column: 8
                },
                end: {
                    line: 101,
                    column: 36
                }
            },
            '37': {
                start: {
                    line: 105,
                    column: 8
                },
                end: {
                    line: 105,
                    column: 30
                }
            },
            '38': {
                start: {
                    line: 109,
                    column: 26
                },
                end: {
                    line: 109,
                    column: 64
                }
            },
            '39': {
                start: {
                    line: 111,
                    column: 8
                },
                end: {
                    line: 113,
                    column: 9
                }
            },
            '40': {
                start: {
                    line: 112,
                    column: 12
                },
                end: {
                    line: 112,
                    column: 24
                }
            },
            '41': {
                start: {
                    line: 115,
                    column: 8
                },
                end: {
                    line: 121,
                    column: 9
                }
            },
            '42': {
                start: {
                    line: 116,
                    column: 12
                },
                end: {
                    line: 116,
                    column: 45
                }
            },
            '43': {
                start: {
                    line: 118,
                    column: 12
                },
                end: {
                    line: 120,
                    column: 13
                }
            },
            '44': {
                start: {
                    line: 119,
                    column: 16
                },
                end: {
                    line: 119,
                    column: 28
                }
            },
            '45': {
                start: {
                    line: 123,
                    column: 8
                },
                end: {
                    line: 123,
                    column: 21
                }
            },
            '46': {
                start: {
                    line: 127,
                    column: 8
                },
                end: {
                    line: 127,
                    column: 32
                }
            },
            '47': {
                start: {
                    line: 128,
                    column: 8
                },
                end: {
                    line: 128,
                    column: 33
                }
            },
            '48': {
                start: {
                    line: 129,
                    column: 8
                },
                end: {
                    line: 129,
                    column: 27
                }
            },
            '49': {
                start: {
                    line: 131,
                    column: 8
                },
                end: {
                    line: 131,
                    column: 58
                }
            },
            '50': {
                start: {
                    line: 135,
                    column: 8
                },
                end: {
                    line: 135,
                    column: 46
                }
            },
            '51': {
                start: {
                    line: 136,
                    column: 8
                },
                end: {
                    line: 136,
                    column: 81
                }
            },
            '52': {
                start: {
                    line: 138,
                    column: 8
                },
                end: {
                    line: 140,
                    column: 10
                }
            },
            '53': {
                start: {
                    line: 139,
                    column: 18
                },
                end: {
                    line: 139,
                    column: 45
                }
            },
            '54': {
                start: {
                    line: 141,
                    column: 8
                },
                end: {
                    line: 141,
                    column: 30
                }
            },
            '55': {
                start: {
                    line: 145,
                    column: 24
                },
                end: {
                    line: 149,
                    column: 38
                }
            },
            '56': {
                start: {
                    line: 151,
                    column: 8
                },
                end: {
                    line: 154,
                    column: 25
                }
            },
            '57': {
                start: {
                    line: 153,
                    column: 16
                },
                end: {
                    line: 153,
                    column: 39
                }
            },
            '58': {
                start: {
                    line: 158,
                    column: 8
                },
                end: {
                    line: 158,
                    column: 198
                }
            },
            '59': {
                start: {
                    line: 162,
                    column: 8
                },
                end: {
                    line: 168,
                    column: 11
                }
            },
            '60': {
                start: {
                    line: 163,
                    column: 12
                },
                end: {
                    line: 167,
                    column: 13
                }
            },
            '61': {
                start: {
                    line: 164,
                    column: 16
                },
                end: {
                    line: 164,
                    column: 49
                }
            },
            '62': {
                start: {
                    line: 165,
                    column: 16
                },
                end: {
                    line: 166,
                    column: 47
                }
            },
            '63': {
                start: {
                    line: 166,
                    column: 26
                },
                end: {
                    line: 166,
                    column: 45
                }
            },
            '64': {
                start: {
                    line: 172,
                    column: 27
                },
                end: {
                    line: 172,
                    column: 29
                }
            },
            '65': {
                start: {
                    line: 173,
                    column: 26
                },
                end: {
                    line: 181,
                    column: 9
                }
            },
            '66': {
                start: {
                    line: 182,
                    column: 8
                },
                end: {
                    line: 185,
                    column: 9
                }
            },
            '67': {
                start: {
                    line: 183,
                    column: 25
                },
                end: {
                    line: 183,
                    column: 40
                }
            },
            '68': {
                start: {
                    line: 184,
                    column: 12
                },
                end: {
                    line: 184,
                    column: 52
                }
            },
            '69': {
                start: {
                    line: 186,
                    column: 8
                },
                end: {
                    line: 186,
                    column: 28
                }
            },
            '70': {
                start: {
                    line: 190,
                    column: 8
                },
                end: {
                    line: 190,
                    column: 55
                }
            },
            '71': {
                start: {
                    line: 194,
                    column: 8
                },
                end: {
                    line: 194,
                    column: 75
                }
            },
            '72': {
                start: {
                    line: 198,
                    column: 8
                },
                end: {
                    line: 200,
                    column: 9
                }
            },
            '73': {
                start: {
                    line: 199,
                    column: 12
                },
                end: {
                    line: 199,
                    column: 85
                }
            },
            '74': {
                start: {
                    line: 199,
                    column: 47
                },
                end: {
                    line: 199,
                    column: 83
                }
            },
            '75': {
                start: {
                    line: 201,
                    column: 8
                },
                end: {
                    line: 201,
                    column: 26
                }
            },
            '76': {
                start: {
                    line: 205,
                    column: 24
                },
                end: {
                    line: 205,
                    column: 64
                }
            },
            '77': {
                start: {
                    line: 206,
                    column: 8
                },
                end: {
                    line: 208,
                    column: 9
                }
            },
            '78': {
                start: {
                    line: 207,
                    column: 12
                },
                end: {
                    line: 207,
                    column: 51
                }
            },
            '79': {
                start: {
                    line: 209,
                    column: 8
                },
                end: {
                    line: 209,
                    column: 20
                }
            },
            '80': {
                start: {
                    line: 213,
                    column: 24
                },
                end: {
                    line: 213,
                    column: 64
                }
            },
            '81': {
                start: {
                    line: 214,
                    column: 8
                },
                end: {
                    line: 227,
                    column: 9
                }
            },
            '82': {
                start: {
                    line: 215,
                    column: 12
                },
                end: {
                    line: 217,
                    column: 13
                }
            },
            '83': {
                start: {
                    line: 216,
                    column: 16
                },
                end: {
                    line: 216,
                    column: 41
                }
            },
            '84': {
                start: {
                    line: 218,
                    column: 12
                },
                end: {
                    line: 220,
                    column: 13
                }
            },
            '85': {
                start: {
                    line: 219,
                    column: 16
                },
                end: {
                    line: 219,
                    column: 64
                }
            },
            '86': {
                start: {
                    line: 221,
                    column: 12
                },
                end: {
                    line: 223,
                    column: 13
                }
            },
            '87': {
                start: {
                    line: 222,
                    column: 16
                },
                end: {
                    line: 222,
                    column: 70
                }
            },
            '88': {
                start: {
                    line: 224,
                    column: 12
                },
                end: {
                    line: 226,
                    column: 13
                }
            },
            '89': {
                start: {
                    line: 225,
                    column: 16
                },
                end: {
                    line: 225,
                    column: 93
                }
            },
            '90': {
                start: {
                    line: 228,
                    column: 8
                },
                end: {
                    line: 228,
                    column: 20
                }
            },
            '91': {
                start: {
                    line: 232,
                    column: 25
                },
                end: {
                    line: 232,
                    column: 77
                }
            },
            '92': {
                start: {
                    line: 234,
                    column: 23
                },
                end: {
                    line: 249,
                    column: 9
                }
            },
            '93': {
                start: {
                    line: 250,
                    column: 8
                },
                end: {
                    line: 250,
                    column: 35
                }
            },
            '94': {
                start: {
                    line: 254,
                    column: 25
                },
                end: {
                    line: 254,
                    column: 77
                }
            },
            '95': {
                start: {
                    line: 256,
                    column: 23
                },
                end: {
                    line: 272,
                    column: 9
                }
            },
            '96': {
                start: {
                    line: 273,
                    column: 8
                },
                end: {
                    line: 273,
                    column: 35
                }
            },
            '97': {
                start: {
                    line: 277,
                    column: 8
                },
                end: {
                    line: 277,
                    column: 29
                }
            },
            '98': {
                start: {
                    line: 281,
                    column: 23
                },
                end: {
                    line: 297,
                    column: 9
                }
            },
            '99': {
                start: {
                    line: 298,
                    column: 8
                },
                end: {
                    line: 298,
                    column: 35
                }
            },
            '100': {
                start: {
                    line: 303,
                    column: 0
                },
                end: {
                    line: 312,
                    column: 7
                }
            }
        },
        fnMap: {
            '0': {
                name: '(anonymous_0)',
                decl: {
                    start: {
                        line: 3,
                        column: 4
                    },
                    end: {
                        line: 3,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 3,
                        column: 47
                    },
                    end: {
                        line: 6,
                        column: 5
                    }
                },
                line: 3
            },
            '1': {
                name: '(anonymous_1)',
                decl: {
                    start: {
                        line: 8,
                        column: 4
                    },
                    end: {
                        line: 8,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 8,
                        column: 14
                    },
                    end: {
                        line: 12,
                        column: 5
                    }
                },
                line: 8
            },
            '2': {
                name: '(anonymous_2)',
                decl: {
                    start: {
                        line: 14,
                        column: 4
                    },
                    end: {
                        line: 14,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 14,
                        column: 18
                    },
                    end: {
                        line: 20,
                        column: 5
                    }
                },
                line: 14
            },
            '3': {
                name: '(anonymous_3)',
                decl: {
                    start: {
                        line: 17,
                        column: 30
                    },
                    end: {
                        line: 17,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 17,
                        column: 36
                    },
                    end: {
                        line: 19,
                        column: 9
                    }
                },
                line: 17
            },
            '4': {
                name: '(anonymous_4)',
                decl: {
                    start: {
                        line: 26,
                        column: 4
                    },
                    end: {
                        line: 26,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 26,
                        column: 28
                    },
                    end: {
                        line: 51,
                        column: 5
                    }
                },
                line: 26
            },
            '5': {
                name: '(anonymous_5)',
                decl: {
                    start: {
                        line: 53,
                        column: 4
                    },
                    end: {
                        line: 53,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 53,
                        column: 26
                    },
                    end: {
                        line: 55,
                        column: 5
                    }
                },
                line: 53
            },
            '6': {
                name: '(anonymous_6)',
                decl: {
                    start: {
                        line: 54,
                        column: 49
                    },
                    end: {
                        line: 54,
                        column: 50
                    }
                },
                loc: {
                    start: {
                        line: 54,
                        column: 57
                    },
                    end: {
                        line: 54,
                        column: 78
                    }
                },
                line: 54
            },
            '7': {
                name: '(anonymous_7)',
                decl: {
                    start: {
                        line: 57,
                        column: 4
                    },
                    end: {
                        line: 57,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 57,
                        column: 25
                    },
                    end: {
                        line: 61,
                        column: 5
                    }
                },
                line: 57
            },
            '8': {
                name: '(anonymous_8)',
                decl: {
                    start: {
                        line: 63,
                        column: 4
                    },
                    end: {
                        line: 63,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 63,
                        column: 18
                    },
                    end: {
                        line: 69,
                        column: 5
                    }
                },
                line: 63
            },
            '9': {
                name: '(anonymous_9)',
                decl: {
                    start: {
                        line: 66,
                        column: 30
                    },
                    end: {
                        line: 66,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 66,
                        column: 36
                    },
                    end: {
                        line: 68,
                        column: 9
                    }
                },
                line: 66
            },
            '10': {
                name: '(anonymous_10)',
                decl: {
                    start: {
                        line: 74,
                        column: 4
                    },
                    end: {
                        line: 74,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 74,
                        column: 107
                    },
                    end: {
                        line: 90,
                        column: 5
                    }
                },
                line: 74
            },
            '11': {
                name: '(anonymous_11)',
                decl: {
                    start: {
                        line: 92,
                        column: 4
                    },
                    end: {
                        line: 92,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 92,
                        column: 14
                    },
                    end: {
                        line: 98,
                        column: 5
                    }
                },
                line: 92
            },
            '12': {
                name: '(anonymous_12)',
                decl: {
                    start: {
                        line: 100,
                        column: 4
                    },
                    end: {
                        line: 100,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 100,
                        column: 19
                    },
                    end: {
                        line: 102,
                        column: 5
                    }
                },
                line: 100
            },
            '13': {
                name: '(anonymous_13)',
                decl: {
                    start: {
                        line: 104,
                        column: 4
                    },
                    end: {
                        line: 104,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 104,
                        column: 23
                    },
                    end: {
                        line: 106,
                        column: 5
                    }
                },
                line: 104
            },
            '14': {
                name: '(anonymous_14)',
                decl: {
                    start: {
                        line: 108,
                        column: 4
                    },
                    end: {
                        line: 108,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 108,
                        column: 31
                    },
                    end: {
                        line: 124,
                        column: 5
                    }
                },
                line: 108
            },
            '15': {
                name: '(anonymous_15)',
                decl: {
                    start: {
                        line: 126,
                        column: 4
                    },
                    end: {
                        line: 126,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 126,
                        column: 21
                    },
                    end: {
                        line: 132,
                        column: 5
                    }
                },
                line: 126
            },
            '16': {
                name: '(anonymous_16)',
                decl: {
                    start: {
                        line: 134,
                        column: 4
                    },
                    end: {
                        line: 134,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 134,
                        column: 18
                    },
                    end: {
                        line: 142,
                        column: 5
                    }
                },
                line: 134
            },
            '17': {
                name: '(anonymous_17)',
                decl: {
                    start: {
                        line: 139,
                        column: 12
                    },
                    end: {
                        line: 139,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 139,
                        column: 18
                    },
                    end: {
                        line: 139,
                        column: 45
                    }
                },
                line: 139
            },
            '18': {
                name: '(anonymous_18)',
                decl: {
                    start: {
                        line: 144,
                        column: 4
                    },
                    end: {
                        line: 144,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 144,
                        column: 29
                    },
                    end: {
                        line: 155,
                        column: 5
                    }
                },
                line: 144
            },
            '19': {
                name: '(anonymous_19)',
                decl: {
                    start: {
                        line: 152,
                        column: 12
                    },
                    end: {
                        line: 152,
                        column: 13
                    }
                },
                loc: {
                    start: {
                        line: 152,
                        column: 18
                    },
                    end: {
                        line: 154,
                        column: 13
                    }
                },
                line: 152
            },
            '20': {
                name: '(anonymous_20)',
                decl: {
                    start: {
                        line: 154,
                        column: 15
                    },
                    end: {
                        line: 154,
                        column: 16
                    }
                },
                loc: {
                    start: {
                        line: 154,
                        column: 21
                    },
                    end: {
                        line: 154,
                        column: 23
                    }
                },
                line: 154
            },
            '21': {
                name: '(anonymous_21)',
                decl: {
                    start: {
                        line: 157,
                        column: 4
                    },
                    end: {
                        line: 157,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 157,
                        column: 34
                    },
                    end: {
                        line: 159,
                        column: 5
                    }
                },
                line: 157
            },
            '22': {
                name: '(anonymous_22)',
                decl: {
                    start: {
                        line: 161,
                        column: 4
                    },
                    end: {
                        line: 161,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 161,
                        column: 34
                    },
                    end: {
                        line: 169,
                        column: 5
                    }
                },
                line: 161
            },
            '23': {
                name: '(anonymous_23)',
                decl: {
                    start: {
                        line: 162,
                        column: 56
                    },
                    end: {
                        line: 162,
                        column: 57
                    }
                },
                loc: {
                    start: {
                        line: 162,
                        column: 68
                    },
                    end: {
                        line: 168,
                        column: 9
                    }
                },
                line: 162
            },
            '24': {
                name: '(anonymous_24)',
                decl: {
                    start: {
                        line: 166,
                        column: 20
                    },
                    end: {
                        line: 166,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 166,
                        column: 26
                    },
                    end: {
                        line: 166,
                        column: 45
                    }
                },
                line: 166
            },
            '25': {
                name: '(anonymous_25)',
                decl: {
                    start: {
                        line: 171,
                        column: 4
                    },
                    end: {
                        line: 171,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 171,
                        column: 25
                    },
                    end: {
                        line: 187,
                        column: 5
                    }
                },
                line: 171
            },
            '26': {
                name: '(anonymous_26)',
                decl: {
                    start: {
                        line: 189,
                        column: 4
                    },
                    end: {
                        line: 189,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 189,
                        column: 21
                    },
                    end: {
                        line: 191,
                        column: 5
                    }
                },
                line: 189
            },
            '27': {
                name: '(anonymous_27)',
                decl: {
                    start: {
                        line: 193,
                        column: 4
                    },
                    end: {
                        line: 193,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 193,
                        column: 31
                    },
                    end: {
                        line: 195,
                        column: 5
                    }
                },
                line: 193
            },
            '28': {
                name: '(anonymous_28)',
                decl: {
                    start: {
                        line: 197,
                        column: 4
                    },
                    end: {
                        line: 197,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 197,
                        column: 23
                    },
                    end: {
                        line: 202,
                        column: 5
                    }
                },
                line: 197
            },
            '29': {
                name: '(anonymous_29)',
                decl: {
                    start: {
                        line: 199,
                        column: 37
                    },
                    end: {
                        line: 199,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 199,
                        column: 47
                    },
                    end: {
                        line: 199,
                        column: 83
                    }
                },
                line: 199
            },
            '30': {
                name: '(anonymous_30)',
                decl: {
                    start: {
                        line: 204,
                        column: 4
                    },
                    end: {
                        line: 204,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 204,
                        column: 39
                    },
                    end: {
                        line: 210,
                        column: 5
                    }
                },
                line: 204
            },
            '31': {
                name: '(anonymous_31)',
                decl: {
                    start: {
                        line: 212,
                        column: 4
                    },
                    end: {
                        line: 212,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 212,
                        column: 30
                    },
                    end: {
                        line: 229,
                        column: 5
                    }
                },
                line: 212
            },
            '32': {
                name: '(anonymous_32)',
                decl: {
                    start: {
                        line: 231,
                        column: 4
                    },
                    end: {
                        line: 231,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 231,
                        column: 34
                    },
                    end: {
                        line: 251,
                        column: 5
                    }
                },
                line: 231
            },
            '33': {
                name: '(anonymous_33)',
                decl: {
                    start: {
                        line: 253,
                        column: 4
                    },
                    end: {
                        line: 253,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 253,
                        column: 34
                    },
                    end: {
                        line: 274,
                        column: 5
                    }
                },
                line: 253
            },
            '34': {
                name: '(anonymous_34)',
                decl: {
                    start: {
                        line: 276,
                        column: 4
                    },
                    end: {
                        line: 276,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 276,
                        column: 37
                    },
                    end: {
                        line: 278,
                        column: 5
                    }
                },
                line: 276
            },
            '35': {
                name: '(anonymous_35)',
                decl: {
                    start: {
                        line: 280,
                        column: 4
                    },
                    end: {
                        line: 280,
                        column: 5
                    }
                },
                loc: {
                    start: {
                        line: 280,
                        column: 24
                    },
                    end: {
                        line: 299,
                        column: 5
                    }
                },
                line: 280
            }
        },
        branchMap: {
            '0': {
                loc: {
                    start: {
                        line: 58,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 58,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                }, {
                    start: {
                        line: 58,
                        column: 8
                    },
                    end: {
                        line: 60,
                        column: 9
                    }
                }],
                line: 58
            },
            '1': {
                loc: {
                    start: {
                        line: 58,
                        column: 12
                    },
                    end: {
                        line: 58,
                        column: 108
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 58,
                        column: 12
                    },
                    end: {
                        line: 58,
                        column: 41
                    }
                }, {
                    start: {
                        line: 58,
                        column: 45
                    },
                    end: {
                        line: 58,
                        column: 108
                    }
                }],
                line: 58
            },
            '2': {
                loc: {
                    start: {
                        line: 111,
                        column: 8
                    },
                    end: {
                        line: 113,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 111,
                        column: 8
                    },
                    end: {
                        line: 113,
                        column: 9
                    }
                }, {
                    start: {
                        line: 111,
                        column: 8
                    },
                    end: {
                        line: 113,
                        column: 9
                    }
                }],
                line: 111
            },
            '3': {
                loc: {
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 121,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 121,
                        column: 9
                    }
                }, {
                    start: {
                        line: 115,
                        column: 8
                    },
                    end: {
                        line: 121,
                        column: 9
                    }
                }],
                line: 115
            },
            '4': {
                loc: {
                    start: {
                        line: 118,
                        column: 12
                    },
                    end: {
                        line: 120,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 118,
                        column: 12
                    },
                    end: {
                        line: 120,
                        column: 13
                    }
                }, {
                    start: {
                        line: 118,
                        column: 12
                    },
                    end: {
                        line: 120,
                        column: 13
                    }
                }],
                line: 118
            },
            '5': {
                loc: {
                    start: {
                        line: 163,
                        column: 12
                    },
                    end: {
                        line: 167,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 163,
                        column: 12
                    },
                    end: {
                        line: 167,
                        column: 13
                    }
                }, {
                    start: {
                        line: 163,
                        column: 12
                    },
                    end: {
                        line: 167,
                        column: 13
                    }
                }],
                line: 163
            },
            '6': {
                loc: {
                    start: {
                        line: 163,
                        column: 16
                    },
                    end: {
                        line: 163,
                        column: 77
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 163,
                        column: 16
                    },
                    end: {
                        line: 163,
                        column: 30
                    }
                }, {
                    start: {
                        line: 163,
                        column: 34
                    },
                    end: {
                        line: 163,
                        column: 77
                    }
                }],
                line: 163
            },
            '7': {
                loc: {
                    start: {
                        line: 184,
                        column: 28
                    },
                    end: {
                        line: 184,
                        column: 51
                    }
                },
                type: 'binary-expr',
                locations: [{
                    start: {
                        line: 184,
                        column: 28
                    },
                    end: {
                        line: 184,
                        column: 43
                    }
                }, {
                    start: {
                        line: 184,
                        column: 47
                    },
                    end: {
                        line: 184,
                        column: 51
                    }
                }],
                line: 184
            },
            '8': {
                loc: {
                    start: {
                        line: 198,
                        column: 8
                    },
                    end: {
                        line: 200,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 198,
                        column: 8
                    },
                    end: {
                        line: 200,
                        column: 9
                    }
                }, {
                    start: {
                        line: 198,
                        column: 8
                    },
                    end: {
                        line: 200,
                        column: 9
                    }
                }],
                line: 198
            },
            '9': {
                loc: {
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 208,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 208,
                        column: 9
                    }
                }, {
                    start: {
                        line: 206,
                        column: 8
                    },
                    end: {
                        line: 208,
                        column: 9
                    }
                }],
                line: 206
            },
            '10': {
                loc: {
                    start: {
                        line: 214,
                        column: 8
                    },
                    end: {
                        line: 227,
                        column: 9
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 214,
                        column: 8
                    },
                    end: {
                        line: 227,
                        column: 9
                    }
                }, {
                    start: {
                        line: 214,
                        column: 8
                    },
                    end: {
                        line: 227,
                        column: 9
                    }
                }],
                line: 214
            },
            '11': {
                loc: {
                    start: {
                        line: 215,
                        column: 12
                    },
                    end: {
                        line: 217,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 215,
                        column: 12
                    },
                    end: {
                        line: 217,
                        column: 13
                    }
                }, {
                    start: {
                        line: 215,
                        column: 12
                    },
                    end: {
                        line: 217,
                        column: 13
                    }
                }],
                line: 215
            },
            '12': {
                loc: {
                    start: {
                        line: 218,
                        column: 12
                    },
                    end: {
                        line: 220,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 218,
                        column: 12
                    },
                    end: {
                        line: 220,
                        column: 13
                    }
                }, {
                    start: {
                        line: 218,
                        column: 12
                    },
                    end: {
                        line: 220,
                        column: 13
                    }
                }],
                line: 218
            },
            '13': {
                loc: {
                    start: {
                        line: 221,
                        column: 12
                    },
                    end: {
                        line: 223,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 221,
                        column: 12
                    },
                    end: {
                        line: 223,
                        column: 13
                    }
                }, {
                    start: {
                        line: 221,
                        column: 12
                    },
                    end: {
                        line: 223,
                        column: 13
                    }
                }],
                line: 221
            },
            '14': {
                loc: {
                    start: {
                        line: 224,
                        column: 12
                    },
                    end: {
                        line: 226,
                        column: 13
                    }
                },
                type: 'if',
                locations: [{
                    start: {
                        line: 224,
                        column: 12
                    },
                    end: {
                        line: 226,
                        column: 13
                    }
                }, {
                    start: {
                        line: 224,
                        column: 12
                    },
                    end: {
                        line: 226,
                        column: 13
                    }
                }],
                line: 224
            }
        },
        s: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
            '65': 0,
            '66': 0,
            '67': 0,
            '68': 0,
            '69': 0,
            '70': 0,
            '71': 0,
            '72': 0,
            '73': 0,
            '74': 0,
            '75': 0,
            '76': 0,
            '77': 0,
            '78': 0,
            '79': 0,
            '80': 0,
            '81': 0,
            '82': 0,
            '83': 0,
            '84': 0,
            '85': 0,
            '86': 0,
            '87': 0,
            '88': 0,
            '89': 0,
            '90': 0,
            '91': 0,
            '92': 0,
            '93': 0,
            '94': 0,
            '95': 0,
            '96': 0,
            '97': 0,
            '98': 0,
            '99': 0,
            '100': 0
        },
        f: {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0
        },
        b: {
            '0': [0, 0],
            '1': [0, 0],
            '2': [0, 0],
            '3': [0, 0],
            '4': [0, 0],
            '5': [0, 0],
            '6': [0, 0],
            '7': [0, 0],
            '8': [0, 0],
            '9': [0, 0],
            '10': [0, 0],
            '11': [0, 0],
            '12': [0, 0],
            '13': [0, 0],
            '14': [0, 0]
        },
        _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PricesHistoryDialogController = function () {
    function PricesHistoryDialogController(mdPanelRef, FieldUpdatesTable) {
        _classCallCheck(this, PricesHistoryDialogController);

        cov_264rzufod5.f[0]++;
        cov_264rzufod5.s[0]++;

        this._mdPanelRef = mdPanelRef;
        cov_264rzufod5.s[1]++;
        this.FieldUpdatesTable = FieldUpdatesTable;
    }

    _createClass(PricesHistoryDialogController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_264rzufod5.f[1]++;

            /* eslint-disable camelcase */
            var filters = (cov_264rzufod5.s[2]++, { object_id: this.sample_id, content_type: 15 });
            cov_264rzufod5.s[3]++;
            this.updates = this.FieldUpdatesTable.$search(filters);
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_264rzufod5.f[2]++;

            var panelRef = (cov_264rzufod5.s[4]++, this._mdPanelRef);

            cov_264rzufod5.s[5]++;
            panelRef.close().then(function () {
                cov_264rzufod5.f[3]++;
                cov_264rzufod5.s[6]++;

                panelRef.destroy();
            });
        }
    }]);

    return PricesHistoryDialogController;
}();

var PanelDialogController = function () {
    function PanelDialogController(mdPanelRef) {
        _classCallCheck(this, PanelDialogController);

        cov_264rzufod5.f[4]++;
        cov_264rzufod5.s[7]++;

        this._mdPanelRef = mdPanelRef;
        cov_264rzufod5.s[8]++;
        this.observationsMaxLen = 500;
        cov_264rzufod5.s[9]++;
        this.sampleItemPriceFormulas = [{
            id: 'N',
            description: 'Este item não tem preço'
        }, {
            id: 'P',
            description: 'Total = Preço Unitário'
        }, {
            id: 'QP',
            description: 'Total = Quantidade x Preço Unitário'
        }, {
            id: 'LP',
            description: 'Total = Comprimento (cm) x Preço Unitário (m)'
        }, {
            id: 'QLP',
            description: 'Total = Quantidade x Comprimento (cm) x Preço (m)'
        }];
    }

    _createClass(PanelDialogController, [{
        key: 'getFormula',
        value: function getFormula(formulaId) {
            cov_264rzufod5.f[5]++;
            cov_264rzufod5.s[10]++;

            return this.sampleItemPriceFormulas.find(function (item) {
                cov_264rzufod5.f[6]++;
                cov_264rzufod5.s[11]++;
                return item.id === formulaId;
            });
        }
    }, {
        key: 'updateObservations',
        value: function updateObservations() {
            cov_264rzufod5.f[7]++;
            cov_264rzufod5.s[12]++;

            if ((cov_264rzufod5.b[1][0]++, this.samplePrice.observations) && (cov_264rzufod5.b[1][1]++, this.samplePrice.observations.length <= this.observationsMaxLen)) {
                cov_264rzufod5.b[0][0]++;
                cov_264rzufod5.s[13]++;

                this.samplePrice.$save(['observations']);
            } else {
                cov_264rzufod5.b[0][1]++;
            }
        }
    }, {
        key: 'closeDialog',
        value: function closeDialog() {
            cov_264rzufod5.f[8]++;

            var panelRef = (cov_264rzufod5.s[14]++, this._mdPanelRef);

            cov_264rzufod5.s[15]++;
            panelRef.close().then(function () {
                cov_264rzufod5.f[9]++;
                cov_264rzufod5.s[16]++;

                panelRef.destroy();
            });
        }
    }]);

    return PanelDialogController;
}();

var SampleItemPricesController = function () {
    function SampleItemPricesController($timeout, $document, SampleItemPricesTable, $rootScope, SamplesTable, $mdDialog, $mdPanel) {
        _classCallCheck(this, SampleItemPricesController);

        cov_264rzufod5.f[10]++;
        cov_264rzufod5.s[17]++;

        this.$rootScope = $rootScope;
        cov_264rzufod5.s[18]++;
        this.items = this.$rootScope.sampleItemPriceTypes;
        cov_264rzufod5.s[19]++;
        this.selectedItem = null;
        cov_264rzufod5.s[20]++;
        this.searchText = null;
        cov_264rzufod5.s[21]++;
        this.$document = $document;
        cov_264rzufod5.s[22]++;
        this.$timeout = $timeout;
        cov_264rzufod5.s[23]++;
        this.SampleItemPricesTable = SampleItemPricesTable;
        cov_264rzufod5.s[24]++;
        this.SamplesTable = SamplesTable;
        cov_264rzufod5.s[25]++;
        this.previousPrices = {};
        cov_264rzufod5.s[26]++;
        this.showingPreviousPrices = {};
        cov_264rzufod5.s[27]++;
        this.editable = true;
        cov_264rzufod5.s[28]++;
        this.$mdDialog = $mdDialog;
        cov_264rzufod5.s[29]++;
        this.$mdPanel = $mdPanel;
        cov_264rzufod5.s[30]++;
        this.showValidations = false;
        cov_264rzufod5.s[31]++;
        this.position = this.$mdPanel.newPanelPosition().absolute().center();
    }

    _createClass(SampleItemPricesController, [{
        key: '$onInit',
        value: function $onInit() {
            cov_264rzufod5.f[11]++;
            cov_264rzufod5.s[32]++;

            this.editable = !this.sample.hasCommercialValidation;
            cov_264rzufod5.s[33]++;
            this.sample.$load('prices');
            /* eslint-disable camelcase */
            cov_264rzufod5.s[34]++;
            this.previousSamples = this.SamplesTable.$search({ order_reference: this.sample.orderReference, id__not_in: this.sample.id });
            cov_264rzufod5.s[35]++;
            this.previousSamples.$load('sampleType');
        }
    }, {
        key: 'load_samples',
        value: function load_samples() {
            cov_264rzufod5.f[12]++;
            cov_264rzufod5.s[36]++;

            this.sample.$load('prices');
        }
    }, {
        key: 'setEditable',
        value: function setEditable(state) {
            cov_264rzufod5.f[13]++;
            cov_264rzufod5.s[37]++;

            this.editable = state;
        }
    }, {
        key: 'hasWritePermissions',
        value: function hasWritePermissions(field) {
            cov_264rzufod5.f[14]++;

            var permissions = (cov_264rzufod5.s[38]++, this.$rootScope.sampleWritePermissions);

            cov_264rzufod5.s[39]++;
            if (this.$rootScope.me.isSupervisor) {
                cov_264rzufod5.b[2][0]++;
                cov_264rzufod5.s[40]++;

                return true;
            } else {
                cov_264rzufod5.b[2][1]++;
            }

            cov_264rzufod5.s[41]++;
            if (permissions.hasOwnProperty(field)) {
                cov_264rzufod5.b[3][0]++;
                cov_264rzufod5.s[42]++;

                permissions = permissions[field];

                cov_264rzufod5.s[43]++;
                if (permissions.indexOf(this.$rootScope.me.type) !== -1) {
                    cov_264rzufod5.b[4][0]++;
                    cov_264rzufod5.s[44]++;

                    return true;
                } else {
                    cov_264rzufod5.b[4][1]++;
                }
            } else {
                cov_264rzufod5.b[3][1]++;
            }

            cov_264rzufod5.s[45]++;
            return false;
        }
    }, {
        key: 'resetEntryLine',
        value: function resetEntryLine() {
            cov_264rzufod5.f[15]++;
            cov_264rzufod5.s[46]++;

            this.xxx.$setPristine(); // fix
            cov_264rzufod5.s[47]++;
            this.xxx.$setUntouched(); // fix
            cov_264rzufod5.s[48]++;
            this.newPrice = {};
            /* eslint-disable angular/document-service */
            cov_264rzufod5.s[49]++;
            document.querySelector('#autoCompleteId').focus();
        }
    }, {
        key: 'createPrice',
        value: function createPrice() {
            var _this = this;

            cov_264rzufod5.f[16]++;
            cov_264rzufod5.s[50]++;

            this.newPrice.sample = this.sample.id;
            cov_264rzufod5.s[51]++;
            this.newPrice.sampleItemPriceType = this.newPrice.sampleItemPriceType.id;

            cov_264rzufod5.s[52]++;
            this.SampleItemPricesTable.$create(this.newPrice).$then(function () {
                cov_264rzufod5.f[17]++;
                cov_264rzufod5.s[53]++;
                return _this.sample.$load('prices');
            });
            cov_264rzufod5.s[54]++;
            this.resetEntryLine();
        }
    }, {
        key: 'deletePrice',
        value: function deletePrice(samplePrice) {
            cov_264rzufod5.f[18]++;

            var confirm = (cov_264rzufod5.s[55]++, this.$mdDialog.confirm().title('Apagar Preço?').textContent('Esta ação é irreversível. Certifique-se que não está a remover informação relevante para outro utilizador. Quer prosseguir?').ok('Sim').cancel('Não, quero rever.'));

            cov_264rzufod5.s[56]++;
            this.$mdDialog.show(confirm).then(function () {
                cov_264rzufod5.f[19]++;
                cov_264rzufod5.s[57]++;

                samplePrice.$destroy();
            }, function () {
                cov_264rzufod5.f[20]++;
            });
        }
    }, {
        key: 'showPreviousPrices',
        value: function showPreviousPrices(sample_id) {
            cov_264rzufod5.f[21]++;
            cov_264rzufod5.s[58]++;

            this.previousPrices[sample_id] = this.SampleItemPricesTable.$search({ sample: sample_id, ordering: 'sample_item_price_type__type,sample_item_price_type__order,sample_item_price_type__name' });
        }
    }, {
        key: 'importPrices',
        value: function importPrices(sample_id, type) {
            var _this2 = this;

            cov_264rzufod5.f[22]++;
            cov_264rzufod5.s[59]++;

            angular.forEach(this.previousPrices[sample_id], function (newPrice) {
                cov_264rzufod5.f[23]++;
                cov_264rzufod5.s[60]++;

                if ((cov_264rzufod5.b[6][0]++, type === 'ALL') || (cov_264rzufod5.b[6][1]++, type === newPrice.$sampleItemPriceType.type)) {
                    cov_264rzufod5.b[5][0]++;
                    cov_264rzufod5.s[61]++;

                    newPrice.sample = _this2.sample.id;
                    cov_264rzufod5.s[62]++;
                    _this2.SampleItemPricesTable.$create(newPrice).$then(function () {
                        cov_264rzufod5.f[24]++;
                        cov_264rzufod5.s[63]++;
                        return _this2.load_samples();
                    });
                } else {
                    cov_264rzufod5.b[5][1]++;
                }
            });
        }
    }, {
        key: 'accentFolding',
        value: function accentFolding(value) {
            cov_264rzufod5.f[25]++;

            var folded_value = (cov_264rzufod5.s[64]++, '');
            var accentMap = (cov_264rzufod5.s[65]++, {
                á: 'a',
                à: 'a',
                ã: 'a',
                é: 'e',
                õ: 'o',
                ó: 'o',
                ç: 'c'
            });
            cov_264rzufod5.s[66]++;
            for (var i = 0; i < value.length; i++) {
                var char = (cov_264rzufod5.s[67]++, value.charAt(i));
                cov_264rzufod5.s[68]++;
                folded_value += (cov_264rzufod5.b[7][0]++, accentMap[char]) || (cov_264rzufod5.b[7][1]++, char);
            }
            cov_264rzufod5.s[69]++;
            return folded_value;
        }
    }, {
        key: 'normalize',
        value: function normalize(value) {
            cov_264rzufod5.f[26]++;
            cov_264rzufod5.s[70]++;

            return this.accentFolding(value.toLowerCase());
        }
    }, {
        key: 'matchOption',
        value: function matchOption(option, query) {
            cov_264rzufod5.f[27]++;
            cov_264rzufod5.s[71]++;

            return this.normalize(option).search(this.normalize(query)) !== -1;
        }
    }, {
        key: 'searchItems',
        value: function searchItems(query) {
            var _this3 = this;

            cov_264rzufod5.f[28]++;
            cov_264rzufod5.s[72]++;

            if (query) {
                cov_264rzufod5.b[8][0]++;
                cov_264rzufod5.s[73]++;

                return this.items.filter(function (option) {
                    cov_264rzufod5.f[29]++;
                    cov_264rzufod5.s[74]++;
                    return _this3.matchOption(option.name, query);
                });
            } else {
                cov_264rzufod5.b[8][1]++;
            }
            cov_264rzufod5.s[75]++;
            return this.items;
        }
    }, {
        key: 'isFormulaField',
        value: function isFormulaField(samplePrice, field) {
            cov_264rzufod5.f[30]++;

            var formula = (cov_264rzufod5.s[76]++, samplePrice.$sampleItemPriceType.formula);
            cov_264rzufod5.s[77]++;
            if (formula) {
                cov_264rzufod5.b[9][0]++;
                cov_264rzufod5.s[78]++;

                return formula.indexOf(field) !== -1;
            } else {
                cov_264rzufod5.b[9][1]++;
            }
            cov_264rzufod5.s[79]++;
            return null;
        }
    }, {
        key: 'getItemTotal',
        value: function getItemTotal(samplePrice) {
            cov_264rzufod5.f[31]++;

            var formula = (cov_264rzufod5.s[80]++, samplePrice.$sampleItemPriceType.formula);
            cov_264rzufod5.s[81]++;
            if (formula) {
                cov_264rzufod5.b[10][0]++;
                cov_264rzufod5.s[82]++;

                if (formula === 'P') {
                    cov_264rzufod5.b[11][0]++;
                    cov_264rzufod5.s[83]++;

                    return samplePrice.price;
                } else {
                    cov_264rzufod5.b[11][1]++;
                }
                cov_264rzufod5.s[84]++;
                if (formula === 'QP') {
                    cov_264rzufod5.b[12][0]++;
                    cov_264rzufod5.s[85]++;

                    return samplePrice.quantity * samplePrice.price;
                } else {
                    cov_264rzufod5.b[12][1]++;
                }
                cov_264rzufod5.s[86]++;
                if (formula === 'LP') {
                    cov_264rzufod5.b[13][0]++;
                    cov_264rzufod5.s[87]++;

                    return samplePrice.length * (samplePrice.price / 100);
                } else {
                    cov_264rzufod5.b[13][1]++;
                }
                cov_264rzufod5.s[88]++;
                if (formula === 'QLP') {
                    cov_264rzufod5.b[14][0]++;
                    cov_264rzufod5.s[89]++;

                    return samplePrice.quantity * samplePrice.length * (samplePrice.price / 100);
                } else {
                    cov_264rzufod5.b[14][1]++;
                }
            } else {
                cov_264rzufod5.b[10][1]++;
            }
            cov_264rzufod5.s[90]++;
            return null;
        }
    }, {
        key: 'showPriceDetails',
        value: function showPriceDetails(samplePrice) {
            cov_264rzufod5.f[32]++;

            var position = (cov_264rzufod5.s[91]++, this.$mdPanel.newPanelPosition().absolute().center());

            var config = (cov_264rzufod5.s[92]++, {
                controller: PanelDialogController,
                controllerAs: 'ctrl',
                templateUrl: 'app/components/sampleItemPrices/panel.tmpl.html',
                hasBackdrop: true,
                panelClass: 'sample-price-dialog',
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    samplePrice: samplePrice
                }
            });
            cov_264rzufod5.s[93]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'editObservations',
        value: function editObservations(samplePrice) {
            cov_264rzufod5.f[33]++;

            var position = (cov_264rzufod5.s[94]++, this.$mdPanel.newPanelPosition().absolute().center());

            var config = (cov_264rzufod5.s[95]++, {
                controller: PanelDialogController,
                controllerAs: 'ctrl',
                templateUrl: 'app/components/sampleItemPrices/observations.tmpl.html',
                hasBackdrop: true,
                panelClass: 'sample-price-dialog',
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    samplePrice: samplePrice,
                    editable: this.editable
                }
            });
            cov_264rzufod5.s[96]++;
            this.$mdPanel.open(config);
        }
    }, {
        key: 'openOptionsMenu',
        value: function openOptionsMenu($mdMenu, $event) {
            cov_264rzufod5.f[34]++;
            cov_264rzufod5.s[97]++;

            $mdMenu.open($event);
        }
    }, {
        key: 'showPricesHistory',
        value: function showPricesHistory() {
            cov_264rzufod5.f[35]++;

            var config = (cov_264rzufod5.s[98]++, {
                /** @ngInject */
                controller: PricesHistoryDialogController,
                controllerAs: '$ctrl',
                templateUrl: 'app/components/sampleItemPrices/pricesFieldUpdates.tmpl.html',
                hasBackdrop: true,
                panelClass: 'prices-field-updates-dialog',
                position: this.position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                locals: {
                    sample_id: this.sample.id
                }
            });
            cov_264rzufod5.s[99]++;
            this.$mdPanel.open(config);
        }
    }]);

    return SampleItemPricesController;
}();

cov_264rzufod5.s[100]++;


angular.module('app').controller('PanelDialogController', PanelDialogController).component('prices', {
    templateUrl: 'app/components/sampleItemPrices/sampleItemprices.html',
    controller: SampleItemPricesController,
    bindings: {
        sample: '<'
    }
});